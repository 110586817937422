import * as actionTypes from "../actionTypes";

export const createSuggestion = (payload) => {
  return { type: actionTypes.CREATE_SUGGESTION, payload };
};

export const getSuggestions = () => {
  return {
    type: actionTypes.GET_ALL_SUGGESTIONS,
  };
};

export const readSuggestion = (payload) => {
  return { type: actionTypes.READ_SUGGESTION, payload };
};

export const setSuggestionText = (payload) => {
  return { type: actionTypes.SET_SUGGESTION_TEXT, payload };
};
