import React, { useEffect, useRef, useState } from "react";
import "./Limitia.css";
import { limit } from "../../@types/client";
import ExtraBar from "../../components/UI/ExtraBar/ExtraBar";
import { fetchLimits } from "../Profile/getter";
import { useAsync } from "../../helpers/asyncFunc";
import { useQuery } from "../../App";
import DropDown from "../../components/elements/DropDown/DropDown";
import { site } from "../../@types/sites";
import { option } from "../../components/UI/FormGenerator/formTypes";
import {
  defaultOption,
  getDropDownValue,
  isNumber,
  makeInt,
} from "../../helpers/misc";
import { connect } from "react-redux";
import { formatNumber } from "../../helpers";
import useTranslate from "../../translate/useTranslate";

const Limitia = ({
  isCompany,
  sites,
  currentSiteId,
}: {
  isCompany: boolean;
  sites: site[];
  currentSiteId: number | undefined;
}) => {
  const { t } = useTranslate();
  const query = useQuery();
  const id = Number(query.get("id")) || null;

  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [selectedSite, setSelectedSite] = useState<option>();

  const [limits, setLimits] = useState<limit[]>([]);
  const [totalCount, setTotalCount] = useState<number>();

  const min = (num: number) => {
    if (num > 100) {
      return 100;
    } else {
      return num;
    }
  };

  const limit = 5;

  const limitRes = useAsync(
    {
      asyncFunc: fetchLimits,
      funcParams: {
        id,
        siteId: selectedSite?.value,
        isCompany,
        limit,
      },
      immediate: true,
    },
    [selectedSite?.value]
  );

  useEffect(() => {
    const options = sites.map((site) => ({ label: site.name, value: site.id }));

    if (currentSiteId && !selectedSite) {
      setSelectedSite(getDropDownValue(currentSiteId, options));
    }
    setSiteOptions([...options]);
  }, [sites]);

  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getLimitList = async () => {
    await fetchLimits({
      id,
      siteId: selectedSite?.value,
      isCompany,
      limit: limits.length + limit,
    }).then(
      (record) => {
        if (record && mountedRef.current) {
          setLimits(record.limits);
          setTotalCount(record.count);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    const record = limitRes.data;
    if (record) {
      setLimits(record.limits);
      setTotalCount(record.count);
    }
  }, [limitRes.data]);

  useEffect(() => {
    const record = limitRes.data;
    if (record) {
      setLimits(record.limits);
      setTotalCount(record.count);
    }
  }, [currentSiteId]);

  return (
    <div className="limitia_wrapper">
      <div className="profileRecords_header">
        <p>{t("Limits")}</p>
        <div className="center">
          <p className="profileRecords_header_date">{t("Site")}</p>
          <div className="profileRecords_header_dropD_container limitia_sites">
            <DropDown
              options={siteOptions}
              value={selectedSite || defaultOption}
              onSelect={(details) => {
                setSelectedSite(details);
              }}
              border={""}
              title={""}
              error={false}
            />
          </div>
        </div>
      </div>
      <div className="form_management_wrapper">
        <div className="form_management_container">
          <div className="fgHead limitia_box_tab">
            <div>{t("Wastes / Waste Groups")}</div>
            <div>{t("Delivery")}</div>
            <div>{t("Limit")}</div>
          </div>
          <div className="limit_box_wrapper">
            {limits.map((limit, n) => (
              <div key={n} className="fgRow limitia_box_tab">
                <div className="limitia_box">{limit.waste}</div>
                <div className="limitia_box">
                  <div className="limitia_box_progress">
                    {limit.weight ? formatNumber(limit.weight / 1000) : `0`} t /{" "}
                    {isNumber(limit.freeLimit) ? (
                      formatNumber(limit.freeLimit! / 1000)
                    ) : (
                      <>&infin;</>
                    )}{" "}
                    t
                    <div
                      style={{
                        width: `${min(
                          (makeInt(
                            limit.groupId
                              ? limit.groupTotalWeight || 0
                              : limit.weight || 0
                          ) *
                            100) /
                            makeInt(
                              isNumber(limit.freeLimit)
                                ? limit.freeLimit || 0
                                : Infinity
                            )
                        )}%`,
                      }}
                      className="limitia_box_progress_handle"
                    ></div>
                    {(limit.groupTotalWeight || 0) > (limit.weight || 0) ? (
                      <div
                        style={{
                          width: `${min(
                            (makeInt(limit.weight || 0) * 100) /
                              makeInt(
                                isNumber(limit.groupTotalWeight)
                                  ? limit.groupTotalWeight || 0
                                  : Infinity
                              )
                          )}%`,
                        }}
                        className="limitia_box_progress_handle2"
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="limitia_box">
                  {isNumber(limit.freeLimit) ? (
                    formatNumber(limit.freeLimit! / 1000)
                  ) : (
                    <>&infin;</>
                  )}{" "}
                  t
                </div>
              </div>
            ))}
          </div>
        </div>
        <ExtraBar
          currentAvailableCount={limits.length}
          expandFunc={() => {
            getLimitList();
          }}
          totalCount={totalCount || 0}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sites: state.siteReducer.sites,
  currentSiteId: state.commonReducer.currentSiteId,
});

export default connect(mapStateToProps)(Limitia);
