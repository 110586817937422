import useTranslate from "../../../translate/useTranslate";

const ExtraBar = ({
  totalCount,
  currentAvailableCount,
  expandFunc,
}: {
  totalCount: number;
  currentAvailableCount: number;
  expandFunc: () => void;
}) => {
  const { t } = useTranslate();
  return totalCount - currentAvailableCount > 0 ? (
    <div className="viewMore_wrapper">
      <span onClick={expandFunc}>{t("Show more")}</span>
    </div>
  ) : (
    <></>
  );
};

export default ExtraBar;
