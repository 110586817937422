import { Fetch } from "../../helpers/misc";
import { signed_user_type } from "./SignIn";

export const signIn = async (params: {
  uname: string;
  paswd: string;
  recaptcha_token: string | undefined;
}) => {
  const { paswd, uname, recaptcha_token } = params;

  if (!paswd || !uname) {
    return;
  }

  return await Fetch("/auth/signin", {
    method: "POST",
    body: JSON.stringify({ uname, paswd, recaptcha_token }),
  })
    .then((user) => {
      const response = user.json() as Promise<{
        authInfo: {
          user: signed_user_type;
          authToken: string;
        };
      }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};
