import * as actionTypes from "../actionTypes";

export const getFormations = function () {
  return { type: actionTypes.GET_FORMATIONS };
};

export const getInactiveFormations = () => ({
  type: actionTypes.GET_INACTIVE_FORMATIONS,
});

export const deactivateFormation = (payload) => ({
  type: actionTypes.DEACTIVATE_FORMATION,
  payload,
});

export const restoreFormation = (payload) => ({
  type: actionTypes.RESTORE_FORMATION,
  payload,
});

export const createFormation = (payload) => ({
  type: actionTypes.CREATE_FORMATION,
  payload,
});

export const editFormation = (payload) => ({
  type: actionTypes.EDIT_FORMATION,
  payload,
});

export const hideFormation = (payload) => ({
  type: actionTypes.HIDE_FORMATION,
  payload,
});
