import { connect } from "react-redux";

import UserForm from "./UserForm";

const mapStoreStateToProps = function (store) {
  return {
    sites: store.siteReducer.sites,
    features: store.featureReducer.features,
    roles: store.roleReducer.roles,
    userGroups: store.userGroupReducer.userGroups,
    id: store.authReducer?.currentUser?.id,
  };
};

const mapDispatchToProps = function () {
  return {};
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(UserForm);
