import React, { forwardRef, useEffect, useState } from "react";
import { isLTNum } from "../../../helpers/misc";

import "./style.scss";

const InputNumber = function (
  { value, onChange, onBlur, style, className, placeholder },
  ref
) {
  const transform = (value) => {
    if (!isNaN(value)) {
      // is number
      try {
        const val = value.toString().replace(/\./g, ",");
        return val;
      } catch {
        return ``;
      }
    } else if (isLTNum(value)) {
      // is formatted correctly
      return value;
    } else {
      // not formatted correctly
      return ``;
    }
  };
  const [currentValue, setCurrentValue] = useState(transform(value));

  const _onChange = (e) => {
    const value = e.target.value;
    if (isLTNum(value)) {
      const val = value.replace(/\,/g, ".");
      onChange && onChange(val);
      setCurrentValue(value);
    }
  };

  const _onClick = () => {
    if (currentValue === "0") {
      setCurrentValue("");
    }
  };

  const _onBlur = (e) => {
    if (currentValue === "") {
      setCurrentValue("0");
    }

    onBlur && onBlur(e);
  };

  return (
    <input
      placeholder={placeholder}
      ref={ref}
      type="text"
      lang="lt"
      value={currentValue}
      onClick={() => {
        if (_onClick) {
          _onClick();
        }
      }}
      onChange={(e) => {
        if (_onChange) {
          _onChange(e);
        }
      }}
      onBlur={(e) => {
        if (_onBlur) {
          _onBlur(e);
        }
      }}
      className={`input-number ${className ? className : ``}`}
      style={style}
    />
  );
};

export default forwardRef(InputNumber);
