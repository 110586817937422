import { alignment, defaultDataType } from "../../services/DataExportService";
import {
  json_key,
  translate,
  translate_options,
} from "../../translate/translate";

export type headerKey =
  | `date`
  | `startDate`
  | `endDate`
  | `weight`
  | `name`
  | `surname`
  | `companyName`
  | `waste`
  | `site`
  | `municipality`
  | `origin`
  | `primarySource`
  | `holderType`
  | `wasteHolder`
  | `companyHolder`
  | `carrier`
  | `recipient`
  | `carrier`
  | `contractNr`
  | `invoice`
  | `year`
  | `source`
  | `managementActivities`
  | `resultingPerform`
  | `billing`
  | `user`
  | "previous_value"
  | "new_value"
  | "paidPrice"
  | "paidPriceVat"
  | "totalPrice"
  | "totalPriceVat";

export type headerQuery =
  | `startDate`
  | `endDate`
  | `name`
  | `surname`
  | `companyName`
  | `wasteId`
  | `siteId`
  | `municipalityId`
  | `originId`
  | `primarySource`
  | `primarySourceWaste`
  | `primaryWasteSource`
  | `holderType`
  | `wasteHolder`
  | `carrier`
  | `recipient`
  | `carrier`
  | `contractNr`
  | `invoice`
  | `year`
  | `source`
  | `managementActivities`
  | `resultingPerform`;

export type headerTitle =
  | "Period"
  | "Name"
  | "Surname"
  | "Company name"
  | "Wastes"
  | "Site"
  | "Source"
  | "Type of waste holder"
  | "Municipality"
  | "Waste holder"
  | "Carrier"
  | "Origin of waste"
  | "Primary source of waste"
  | "Recipient of the waste"
  | "Waste carrier"
  | "Contract no."
  | "Formal Invoice"
  | "Year"
  | "Waste source"
  | "Waste management activities"
  | "Waste Generated"
  | "Billing"
  | "Amount received, t"
  | "Operator"
  | "Before correction (kg)"
  | "After correction (kg)"
  | "Amount without VAT"
  | "Price with VAT"
  | "Price (per kg) without VAT"
  | "Price (per kg) with VAT";

export type declarationType = {
  key: headerKey;
  width?: number;
  dataType?: typeof defaultDataType.string;
  style?: { alignment: alignment.middleLeft };
};

type declarationDefaultType = {
  key: string;
  width?: number;
  dataType?: typeof defaultDataType.string;
  style?: { alignment: alignment.middleLeft };
};
export const reportDeclaration: { [key in headerTitle]: declarationType } = {
  Period: {
    key: "date",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Name: {
    key: "name",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Surname: {
    key: "surname",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Company name": {
    key: "companyName",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Wastes: {
    key: "waste",
    width: 60,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Site: {
    key: "site",
    width: 40,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Source: {
    key: "primarySource",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Type of waste holder": {
    key: "holderType",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Municipality: {
    key: "municipality",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Waste holder": {
    key: "wasteHolder",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Carrier: {
    key: "companyHolder",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Origin of waste": {
    key: "origin",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Primary source of waste": {
    key: "primarySource",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Recipient of the waste": {
    key: "recipient",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Waste carrier": {
    key: "carrier",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Contract no.": {
    key: "contractNr",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Formal Invoice": {
    key: "invoice",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Year: {
    key: "year",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Waste source": {
    key: "source",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Waste management activities": {
    key: "managementActivities",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Waste Generated": {
    key: "resultingPerform",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Billing: {
    key: "billing",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Amount received, t": {
    key: "weight",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Operator: {
    key: "user",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "After correction (kg)": {
    key: "new_value",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Before correction (kg)": {
    key: "previous_value",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Amount without VAT": {
    key: "totalPrice",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Price with VAT": {
    key: "totalPriceVat",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Price (per kg) without VAT": {
    key: "paidPrice",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Price (per kg) with VAT": {
    key: "paidPriceVat",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
};

export const getReportDeclaration = (
  queryObj: headerTitle[]
): (
  | (declarationType & { name: headerTitle })
  | (declarationDefaultType & { name: headerTitle })
)[] => {
  const mapper = new Map();
  const t = (originalText: json_key, options: translate_options = {}) => {
    return translate(originalText, options).text;
  };

  queryObj.forEach((key) => {
    const declaration = reportDeclaration[key as headerTitle];
    if (declaration) {
      mapper.set(key, { ...declaration, name: t(key) });
    } else {
      mapper.set(key, {
        name: t(key),
        key: key,
        width: 12,
        dataType: defaultDataType.string,
        style: { alignment: alignment.middleLeft },
      });
    }
  });
  return Array.from(mapper.values());
};
