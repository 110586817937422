import { ofType } from "redux-observable";
import { mergeMap, catchError, switchMap, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const getCompanies = (action$) =>
  action$.pipe(
    ofType(actions.GET_COMPANIES),
    mergeMap(() =>
      FetchRX("/companies").pipe(
        map((result) => {
          return {
            type: actions.SET_COMPANIES,
            payload: result.companies,
          };
        }),
        catchError((error) => {
          return of({ type: actions.GET_COMPANIES_FAILED });
        })
      )
    )
  );

export const saveCompany = (action$) =>
  action$.pipe(
    ofType(actions.SAVE_COMPANY),
    mergeMap((action) =>
      FetchRX("/companies", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        switchMap((result) => [
          {
            type: actions.SAVE_COMPANY_SUCCEED,
            payload: result.company,
          },
          {
            type: actions.RESET_COMPANY_SAVING_STATUS,
          },
        ]),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.SAVE_COMPANY_FAILED });
        })
      )
    )
  );
