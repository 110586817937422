import { ofType } from "redux-observable";
import { map, mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { DataExportService } from "../../services";
import { getReportDeclaration } from "../../pages/Report/exportDeclaration";
import moment from "moment";
import { FetchRX } from "../../helpers/misc";

export const resolveQuery = (obj) => {
  const isOption = (obj) => typeof obj === "object" && obj.value && obj.label;

  const newObj = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (isOption(value)) {
        // Convert option to its opton.value property (probably consisting of only ids)
        newObj[key] = value.value;
      } else if (Array.isArray(value)) {
        const initItem = value[0];
        if (isOption(initItem)) {
          // Convert option array to its array of opton.value property (probably consisting of only ids)
          newObj[key] = value.map((d) => d.value);
        } else {
          newObj[key] = value;
        }
      } else {
        newObj[key] = value;
      }
    }
  }
  return newObj;
};

export const getAllReports = (action$) =>
  action$.pipe(
    ofType(actions.GET_REPORT),
    mergeMap((action) =>
      FetchRX(`/report${action.payload.url || ""}`, {
        method: "POST",
        body: resolveQuery(action?.payload?.query || {}),
      }).pipe(
        map((result) => {
          const declaration = getReportDeclaration(
            action?.payload?.headers || []
          );

          const dataExportService = new DataExportService();
          dataExportService.init(
            action?.payload?.title || `ASKAITOS`,
            declaration
          );

          if (
            result?.report &&
            Array.isArray(result.report) &&
            declaration.length > 0 &&
            result.report.length > 0
          ) {
            const Repor = result.report.map((report) => {
              return {
                ...report,
                ...(report.date
                  ? { date: moment(report.date).format("YYYY-MM-DD HH:mm:ss") }
                  : {}),
              };
            });
            dataExportService.export(Repor);
          } else {
            if (action?.payload) {
              action.payload.setReportNotifValue(
                "Įrašų nerasta, todėl '" +
                  (action?.payload?.title || `ATASKAITOS`) +
                  "' nesuformuota."
              );
            }
          }
          if (action?.payload) {
            action.payload.reset();
            action.payload.removeLoading(action?.payload?.title);
          }
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          if (action?.payload) {
            action.payload.setReportNotifValue(
              "An error occured while getting the reports for " +
                action?.payload?.title || `ATASKAITOS`
            );
            action.payload.reset();
            action.payload.removeLoading(action?.payload?.title);
          }
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
