import * as actionTypes from "../actionTypes";

export const getCarriers = function () {
  return { type: actionTypes.GET_CARRIERS };
};

export const getInactiveCarriers = () => ({
  type: actionTypes.GET_INACTIVE_CARRIERS,
});

export const deactivateCarrier = (payload) => ({
  type: actionTypes.DEACTIVATE_CARRIER,
  payload,
});

export const restoreCarrier = (payload) => ({
  type: actionTypes.RESTORE_CARRIER,
  payload,
});

export const createCarrier = (payload) => ({
  type: actionTypes.CREATE_CARRIER,
  payload,
});

export const editCarrier = (payload) => ({
  type: actionTypes.EDIT_CARRIER,
  payload,
});

export const hideCarrier = (payload) => ({
  type: actionTypes.HIDE_CARRIER,
  payload,
});
