import { connect } from "react-redux";
import Report from "./Report";
import * as actions from "../../stores/actions";
import { headerTitle } from "./exportDeclaration";

const mapStoreStateToProps = (store: any) => ({
  wastes: store.wasteReducer.wastes,
  sites: store.siteReducer.sites,
  billings: store.billingReducer.billings,
  serijas: store.serijaReducer.serijas,
  municipalities: store.municipalityReducer.municipalities,
  primarySources: store.primarySourceReducer.primarySources,
  wasteActivityTypes: store.wasteActivityTypeReducer.wasteActivityTypes,
  carriers: store.carrierReducer.carriers,
  origins: store.originReducer.origins,
  wastePerforms: [],
});

const mapDispatchToProps = (dispatch: (...arg: any[]) => void) => ({
  getReport: (data: {
    title: string;
    headers: headerTitle[];
    query: { [key: string]: any };
    url: string;
    reset: () => void;
    setReportNotifValue: (data: string) => void;
    removeLoading: (data: string) => void;
  }) => {
    dispatch(actions.getReport(data));
  },
  getWasteActivityTypes: function () {
    dispatch(actions.getWasteActivityTypes());
  },
  getWastePerforms: function () {},
});

export default connect(mapStoreStateToProps, mapDispatchToProps)(Report);
