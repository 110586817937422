import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../../store";
import {
  getCarriers as get_carriers,
  getInactiveCarriers as get_inactive_carriers,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getCarriers = (action$) =>
  action$.pipe(
    ofType(actions.GET_CARRIERS),
    mergeMap(() =>
      FetchRX("/carriers").pipe(
        map((result) => {
          return {
            type: actions.SET_CARRIERS,
            payload: result.carriers,
          };
        }),
        catchError((error) => {
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const getInactiveCarriers = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_CARRIERS),
    mergeMap(() =>
      FetchRX("/carriers/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_CARRIERS,
            payload: result.carriers,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateCarrier = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_CARRIER),
    mergeMap((action) =>
      FetchRX("/carriers/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_carriers());
          store.dispatch(get_inactive_carriers());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideCarrier = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_CARRIER),
    mergeMap((action) =>
      FetchRX("/carriers/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_carriers());
          store.dispatch(get_inactive_carriers());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreCarrier = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_CARRIER),
    mergeMap((action) =>
      FetchRX("/carriers/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_carriers());
          store.dispatch(get_inactive_carriers());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createCarrier = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_CARRIER),
    mergeMap((action) =>
      FetchRX("/carriers/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_CARRIERS,
            payload: result.carriers,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editCarrier = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_CARRIER),
    mergeMap((action) =>
      FetchRX("/carriers/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_CARRIERS,
            payload: result.carriers,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
