import { connect } from "react-redux";
import AcceptancePerson from "./AcceptancePerson";

const mapStateToProps = (state: any) => ({
  documents: state.documentReducer.documents,
  municipalities: state.municipalityReducer.municipalities,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptancePerson);
