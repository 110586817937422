import React, { ChangeEvent } from "react";
import "./checkBox.css";

export default function CheckBox({
  isChecked,
  onChange,
  readOnly,
  value,
}: {
  isChecked: boolean;
  readOnly?: boolean;
  value?: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <label
        className={`labelCheck ${readOnly ? "readOnly" : ""} ${
          isChecked ? "isChecked" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <input
          type="checkbox"
          value={value || ""}
          checked={isChecked}
          onChange={(e) => {
            onChange(e);
          }}
          readOnly={readOnly ? true : false}
          className="customListCheckBox"
        />
        <div className="labelCheckMark"></div>
      </label>
    </div>
  );
}

export const RadioButton = ({
  name,
  isChecked,
  onChange,
}: {
  name: string;
  isChecked: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div>
      <label
        className={`labelRadio ${isChecked ? "isChecked" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <input
          type="radio"
          checked={isChecked}
          onChange={onChange}
          name={name}
          className="customListRadio"
        />
        <div className="labelRadioCircle"></div>
      </label>
    </div>
  );
};
