import { ofType } from "redux-observable";
import { catchError, mergeMap, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const getPrimarySources = (action$) =>
  action$.pipe(
    ofType(actions.GET_PRIMARY_SOURCES),
    mergeMap(() =>
      FetchRX("/primarySources").pipe(
        map((result) => {
          return {
            type: actions.GET_PRIMARY_SOURCES_SUCCEED,
            payload: result.primarySources,
          };
        }),
        catchError((error) => {
          return of({ type: actions.GET_PRIMARY_SOURCES_FAILED });
        })
      )
    )
  );
