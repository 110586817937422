import { company, limit, person, wastesAccepted } from "../../@types/client";
import { Fetch, isNumber } from "../../helpers/misc";

export const fetchClient = async (details: {
  id: number | null;
  isCompany: boolean;
}) => {
  const { id, isCompany } = details;

  if (!id) {
    throw "No id was provided";
  }

  return await Fetch(
    `/clients/retrieve?id=${id}&isCompany=${!!isCompany}`
  ).then(
    (res) =>
      res.json() as Promise<{
        client: person | company;
      }>,
    (error) => {
      throw error;
    }
  );
};

export const fetchLimits = async (details: {
  id: number | null;
  siteId: number | undefined;
  isCompany: boolean;
  limit: number | undefined;
}) => {
  const { id, limit, isCompany, siteId } = details;
  if (!id || !siteId) {
    throw "No id was provided";
  }
  return await Fetch(
    `/clients/retrieve_limits?id=${id}&limit=${
      limit || ``
    }&isCompany=${!!isCompany}&siteId=${siteId || ""}`
  ).then(
    (res) =>
      res.json() as Promise<{
        limits: limit[];
        count: number;
      }>,
    (error) => {
      throw error;
    }
  );
};

export const fetchWastes = async (params: {
  id: number | null;
  year: string | undefined;
  isCompany: boolean;
  limit: number;
}) => {
  const { id, year, isCompany, limit } = params;

  if (!id) {
    throw "No id was provided";
  }

  return await Fetch(
    `/clients/retrieve_wastes?id=${id}&year=${year || ``}&limit=${
      limit || ``
    }&isCompany=${!!isCompany}`
  ).then(
    (res) =>
      res.json() as Promise<{
        wastesAccepted: wastesAccepted[];
        count: number;
      }>,
    (error) => {
      throw error;
    }
  );
};

export const addDeficit = async (params: {
  id: number;
  isCompany: boolean;
  deficit: number | undefined;
}) => {
  if (!isNumber(params.deficit)) {
    return;
  }

  return await Fetch("/clients/addDeficit", {
    method: "PUT",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const response = res.json() as Promise<{ success: true }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};
