import { connect } from "react-redux";

import DisposalJournal from "./DisposalJournal";

const mapStoreStateToProps = function (store) {
  return {
    sites: store.siteReducer.sites,
    currentUser: store.authReducer.currentUser,
  };
};

export default connect(mapStoreStateToProps)(DisposalJournal);
