import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";
import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const getNextFormNr = (action$) =>
  action$.pipe(
    ofType(actions.GET_NEXT_FORM_NR),
    mergeMap((action) =>
      FetchRX("/disposals/next-form-nr").pipe(
        map((result) => {
          return {
            type: actions.GET_NEXT_FORM_NR_SUCCEED,
            payload: result.nextFormNr,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
