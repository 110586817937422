import * as actionTypes from "../actionTypes";

const initialState = {
  billings: [],
  inactiveBillings: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_BILLINGS:
      return {
        ...state,
        billings: action.payload,
      };
    case actionTypes.SET_INACTIVE_BILLINGS:
      return {
        ...state,
        inactiveBillings: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
