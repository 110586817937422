import { connect } from "react-redux";

import Disposal from "./Disposal";
import * as actions from "../../stores/actions";

const mapStoreStateToProps = function (store) {
  return {
    sites: store.siteReducer.sites,
    wastes: store.wasteReducer.wastes,
    nextFormNr: store.disposalReducer.nextFormNr,
    companies: store.companyReducer.companies,
    currentUser: store.authReducer.currentUser,
    isAdmin: store.authReducer.currentUser?.isAdmin,
    carriers: store.carrierReducer.carriers,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    getNextFormNr: function () {
      dispatch(actions.getNextFormNr());
    },
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(Disposal);
