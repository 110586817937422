import * as actionTypes from "../actionTypes";

export const getUser = function (id) {
  return { type: actionTypes.GET_USER, payload: id };
};

export const saveUser = function (type, user) {
  return { type: actionTypes.SAVE_USER, payload: { type, user } };
};

export const delUser = function (params) {
  return { type: actionTypes.DEL_USER, payload: params };
};

export const resetUserStatus = function () {
  return { type: actionTypes.RESET_USER_STATUS };
};
