import * as actionTypes from "../actionTypes";

export const getMaterials = function () {
  return { type: actionTypes.GET_MATERIALS };
};

export const getInactiveMaterials = () => ({
  type: actionTypes.GET_INACTIVE_MATERIALS,
});

export const deactivateMaterial = (payload) => ({
  type: actionTypes.DEACTIVATE_MATERIAL,
  payload,
});

export const restoreMaterial = (payload) => ({
  type: actionTypes.RESTORE_MATERIAL,
  payload,
});

export const createMaterial = (payload) => ({
  type: actionTypes.CREATE_MATERIAL,
  payload,
});

export const editMaterial = (payload) => ({
  type: actionTypes.EDIT_MATERIAL,
  payload,
});

export const hideMaterial = (payload) => ({
  type: actionTypes.HIDE_MATERIAL,
  payload,
});
