import { useCallback, useEffect, useRef, useState } from "react";

/**
 * A hook to fetch async data.
 * @class useAsync
 * @borrows useAsyncObject
 * @param {object} _                props
 * @param {async} _.asyncFunc         Promise like async function
 * @param {bool} _.immediate=false    Invoke the function immediately
 * @param {object} _.funcParams       Function initial parameters
 * @param {object} _.initialData      Initial data
 * @returns {useAsyncObject}        Async object
 * @example
 *   const { execute, loading, data, error } = useAync({
 *    asyncFunc: async () => { return 'data' },
 *    immediate: false,
 *    funcParams: { data: '1' },
 *    initialData: 'Hello'
 *  })
 */

export interface asyncResType<Params, Response> {
  execute: (params: Params) => Promise<Response | void>;
  loading: boolean;
  data: Response | undefined;
  error: string | null;
  errorObject: Object | null;
  params: Params;
  called: boolean;
}

export const useAsync = <
  T extends { [key: string]: unknown },
  U extends unknown
>(
  props: {
    asyncFunc: (...data: T[]) => Promise<U>;
    immediate: boolean;
    funcParams: T;
    clearOnError?: boolean;
    canCall?: boolean;
  },
  changeEffectors?: unknown[]
): asyncResType<T, U> => {
  const { asyncFunc, immediate, funcParams } = {
    ...props,
  };
  const effectors = changeEffectors || [];
  const canCall = props.canCall === undefined ? true : props.canCall;
  const clearOnError = props.clearOnError || false;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<U>();
  const [asyncData, setAyncData] = useState<
    Partial<{
      response: U;
      params: T;
    }>
  >({
    params: undefined,
    response: undefined,
  });
  const [params, setParams] = useState({ ...funcParams });
  const [called, setCalled] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorObject, setErrorObject] = useState<Object | null>(null);
  const mountedRef = useRef(true);

  const asyncResponse = asyncData.response;

  const paramString = JSON.stringify(params);
  const asynParamString = JSON.stringify(asyncData.params);

  useEffect(() => {
    const equal = paramString === asynParamString;

    if (equal) {
      setData(asyncResponse);
    }
  }, [paramString, asynParamString, asyncResponse]);

  const errorSetter = (error: { error: any } | null) => {
    if (!error) {
      setError(null);
      setErrorObject(null);
    } else {
      setError(error?.error ? `${error.error}` : "Something went wrong!");
      setErrorObject(Object(error));
    }
  };

  const execute = useCallback(
    async (params: T) => {
      if (canCall) {
        setLoading(true);
        setCalled(true);
        const param_obj = { ...funcParams, ...params };
        setParams({ ...param_obj });
        errorSetter(null);

        return asyncFunc(param_obj)
          .then((res) => {
            console.log(`async call ...`);
            if (!mountedRef.current) return;

            const async_info = {
              params: param_obj,
              response: res,
            };
            setAyncData({ ...async_info });

            errorSetter(null);
            setLoading(false);
            return res;
          })
          .catch((err) => {
            if (clearOnError) {
              const async_info = {
                params: param_obj,
                response: undefined,
              };
              setAyncData({ ...async_info });
            }
            if (!mountedRef.current) return;
            errorSetter(err);
            setLoading(false);
            console.error(err);
          });
      }
    },
    [asyncFunc, funcParams]
  );

  useEffect(() => {
    mountedRef.current = true;
    if (immediate) {
      execute(funcParams);
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...effectors]);

  return {
    execute,
    loading,
    data,
    error,
    errorObject,
    params,
    called,
  };
};
