import { connect } from "react-redux";

import UserList from "./UserList";
import * as actions from "../../../../../stores/actions";

const mapStoreStateToProps = function (store) {
  return {
    roles: store.roleReducer.roles,
    userSavingStatus: store.userReducer.savingStatus,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    resetUserStatus: function () {
      dispatch(actions.resetUserStatus());
    },
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(UserList);
