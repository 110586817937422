import { Fetch } from "../../helpers/misc";
import { allDisposalType } from "./DisposalJournal";

export const getDisposals = async (params: {
  query: string;
  limit: number;
  offset: number;
}) => {
  const { query, limit, offset } = params;

  return await Fetch(`/disposals/all?${query}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      const disposals = res.json() as Promise<{
        success: true;
        disposals: allDisposalType[];
        count: number;
      }>;
      return disposals;
    })
    .catch((err) => {
      throw err;
    });
};

export type updateStatusParamsType =
  | {
      id: number;
      status: "COMPLETED";
      userId?: number;
    }
  | {
      id: number;
      status: "CANCELED";
      userId?: number;
      reason: number;
      reasonText: string;
      additionalInfo: string | null;
    };
export const updateStatus = async (params: updateStatusParamsType) => {
  if (!params.userId) {
    return;
  }

  return await Fetch("/disposals/update-status", {
    method: "POST",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const disposals = res.json() as Promise<{
        success: true;
        disposal: allDisposalType;
      }>;
      return disposals;
    })
    .catch((err) => {
      throw err;
    });
};
