import moment from "moment";
import { parseStringTemplate } from "../../../helpers";
import {
  alignment,
  defaultDataType,
} from "../../../services/DataExportService";
import {
  getValParams,
  userActivity,
} from "./elements/UserActivity/UserActivity";
import { localUserGroup } from "./elements/UserGroupList/UserGroupList";
import { user } from "./elements/UserList/UserList";
import { json_key, translate_options } from "../../../translate/translate";

type pathType = "" | "USER_LIST" | "USER_ACTIVITIES" | "USER_GROUP";

type paramType =
  | { type: "USER_LIST"; records: user[] }
  | { type: "USER_ACTIVITIES"; records: userActivity[] }
  | { type: "USER_GROUP"; records: localUserGroup[] }
  | { type: ""; records: any[] };

export const getData = (
  param: paramType,
  t: (originalText: json_key, options?: translate_options) => string
): unknown[] => {
  const { type, records } = param;
  if (type === "USER_LIST") {
    return (records || []).map((record) => ({
      uname: record.uname,
      fullName: `${record.firstName} ${record.lastName}`,
      email: `${record.email || ``}`,
      position: record.position || ``,
      roleName: record.roleName || ``,
      isActive: record.isActive ? t("Active") : t("Inactive"),
      lastLogin: record.lastLogin
        ? moment(record.lastLogin).format("YYYY-MM-DD")
        : "",
    }));
  } else if (type === "USER_ACTIVITIES") {
    return (records || []).map((record) => ({
      date: moment(record.createdAt).format("YYYY-MM-DD"),
      uname: record.uname,
      fullName: `${record.firstName || ""} ${record.lastName || ""}`,
      position: record.position,
      notification: parseStringTemplate(
        record.notifTxt,
        getValParams(record.params)
      ),
      siteName: record.siteName,
    }));
  } else if (type === "USER_GROUP") {
    return (records || []).map((record, n) => ({
      index: `${n + 1}`,
      name: `${record.name}`,
      userCount: `${record.userCount}`,
      isActive: record.isActive ? t("Yes") : "Ne",
      updatedAt: record.updatedAt
        ? moment(record.updatedAt).format("YYYY-MM-DD")
        : "",
    }));
  } else {
    return [];
  }
};

export const getXlsxObj = (
  type: pathType,
  t: (originalText: json_key, options?: translate_options) => string
): { title: string; description: { [key: string]: any }[] } => {
  if (type === "USER_LIST") {
    return {
      title: t("List"),
      description: [
        {
          name: t("Operator"),
          key: "uname",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Name and surname"),
          key: "fullName",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: `${t("Email")} ${t("Address").toLowerCase()}}`,
          key: "email",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Position"),
          key: "position",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Employee Type in System (Role)"),
          key: "roleName",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Active"),
          key: "isActive",
          width: 20,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: t("Last login"),
          key: "lastLogin",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
      ],
    };
  } else if (type === "USER_ACTIVITIES") {
    return {
      title: t("Activity log"),
      description: [
        {
          name: t("Period of activity"),
          key: "date",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: t("Operator"),
          key: "uname",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Name and surname"),
          key: "fullName",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Position"),
          key: "position",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Action"),
          key: "notification",
          width: 90,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: t("Place"),
          key: "siteName",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
      ],
    };
  } else if (type === "USER_GROUP") {
    return {
      title: t("Working groups"),
      description: [
        {
          name: t("Serial No."),
          key: "index",
          width: 15,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: t("Title"),
          key: "name",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: t("Employees"),
          key: "userCount",
          width: 15,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: t("Active"),
          key: "isActive",
          width: 20,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: t("Updated"),
          key: "updatedAt",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
      ],
    };
  } else {
    return {
      title: "",
      description: [],
    };
  }
};
