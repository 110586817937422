import React, { useEffect, useReducer } from "react";
import { stateType } from "../store";
import { useSelector } from "react-redux";

const useLocaleRender = () => {
  const locale = useSelector((state: stateType) => state.commonReducer?.locale);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, [locale]);
};

export default useLocaleRender;
