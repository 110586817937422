import * as actionTypes from '../actionTypes';

export const getUserGroups = function () {
  return { type: actionTypes.GET_USER_GROUPS };
};

export const getUserGroup = function (id) {
  return { type: actionTypes.GET_USER_GROUP, payload: id };
};

export const saveUserGroup = function (type, userGroup) {
  return { type: actionTypes.SAVE_USER_GROUP, payload: { type, userGroup } };
};

export const delUserGroup = function (id) {
  return { type: actionTypes.DEL_USER_GROUP, payload: id };
};

export const resetUserGroupStatus = function () {
  return { type: actionTypes.RESET_USER_GROUP_STATUS };
};
