import React, { useState } from "react";
import "./Declaration.css";
import { wasteItem } from "../../../pages/WasteAcceptance/defaultData";
import { companyType, personType } from "../../../pages/WasteAcceptance/@types";
import NewConfirmDialog from "../ConfirmDialog/NewConfirmDialog";
import DeclarationDoc from "./DeclarationDoc";
import { site } from "../../../@types/sites";
import useTranslate from "../../../translate/useTranslate";

const Declaration = ({
  close,
  next,
  waste_list,
  isCompany,
  date,
  details,
  setDetails,
  hasInvoice,
  allSites,
  loading,
}: {
  close: () => void;
  next: () => void;
  waste_list: Partial<wasteItem>[];
  isCompany: boolean;
  date: Date;
  details: personType | companyType;
  setDetails: (data: personType | companyType) => void;
  hasInvoice: boolean;
  allSites: site[];
  loading: boolean;
}) => {
  const { t } = useTranslate();
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  return (
    <div className={`declaration_wrap`}>
      <NewConfirmDialog
        txt={t(
          "A VAT invoice for the acceptance of this waste will not be generated, do you want to continue?"
        )}
        isVisible={submitConfirmation && !hasInvoice}
        onExit={() => {
          if (loading) {
            return;
          }
          setSubmitConfirmation(false);
        }}
        isLoading={loading}
        onContinue={() => {
          next();
        }}
      />
      <div className="declaration_float" onClick={close}></div>
      <DeclarationDoc
        readOnly={false}
        sites={allSites}
        date={date}
        isCompany={isCompany}
        waste_list={waste_list}
        hasInvoice={hasInvoice}
        close={close}
        next={next}
        details={details}
        setDetails={setDetails}
        setSubmitConfirmation={setSubmitConfirmation}
      />
    </div>
  );
};

export default Declaration;
