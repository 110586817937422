export const json = {
  "Something's wrong?": {
    LITHUANIAN: "Kažkas neveikia?",
    ENGLISH: "Something's wrong?",
  },
  Suggestions: { LITHUANIAN: "Pasiūlymai", ENGLISH: "Suggestions" },
  New: { LITHUANIAN: "Sukurti naują", ENGLISH: "New" },
  Title: { LITHUANIAN: "Pavadinimas", ENGLISH: "Title" },
  "Send suggestion": {
    LITHUANIAN: "Pateikti pasiūlymą",
    ENGLISH: "Send suggestion",
  },
  "Drag file here": {
    LITHUANIAN: "Užtempkite failą čia",
    ENGLISH: "Drag file here",
  },
  or: { LITHUANIAN: "arba", ENGLISH: "or" },
  Browse: { LITHUANIAN: "Naršyti", ENGLISH: "Browse" },
  "Waste owner data": {
    LITHUANIAN: "Duomenys apie atliekų turėtoją",
    ENGLISH: "Waste owner data",
  },
  "Company name": {
    LITHUANIAN: "Įmonės pavadinimas",
    ENGLISH: "Company name",
  },
  "Name, surname": {
    LITHUANIAN: "Vardas, pavardė",
    ENGLISH: "Name, surname",
  },
  "Company code": { LITHUANIAN: "Įmonės kodas", ENGLISH: "Company code" },
  "Personal ID number": {
    LITHUANIAN: "Asmens dokumento nr.",
    ENGLISH: "Personal ID number",
  },
  "Waste record created": {
    LITHUANIAN: "Sukurtas atliekų įrašas",
    ENGLISH: "Waste record created",
  },
  "Municipality where waste was created": {
    LITHUANIAN: "Savivaldybė, kurioje susidarė atliekos",
    ENGLISH: "Municipality where waste was created",
  },
  "Municipality where fee is paid": {
    LITHUANIAN: "Savivaldybė, kurioje mokama rinkliava",
    ENGLISH: "Municipality where fee is paid",
  },
  Carrier: { LITHUANIAN: "Vežėjas", ENGLISH: "Carrier" },
  "Car licence plate": {
    LITHUANIAN: "Automobilio nr.",
    ENGLISH: "Car licence plate",
  },
  "this is municipality names - what's up with it?": {
    LITHUANIAN:
      "Elektrėnų sav. - 42, Šalčininkų r. sav. - 85, Širvintų r. sav. - 89, Švenčionių r. sav. - 86, Trakų r. sav. - 79, Ukmergės r. sav. - 81, Vilniaus m. sav. - 13, Vilniaus r. sav. - 41",
    ENGLISH: "this is municipality names - what's up with it?",
  },
  "Accepted waste": {
    LITHUANIAN: "Priimtos atliekos",
    ENGLISH: "Accepted waste",
  },
  "Waste code": { LITHUANIAN: "Atliekos kodas", ENGLISH: "Waste code" },
  "Waste name": {
    LITHUANIAN: "Atliekos pavadinimas",
    ENGLISH: "Waste name",
  },
  "Accepted for paid kg/unit": {
    LITHUANIAN: "Priimta mokamai kg/vnt.",
    ENGLISH: "Accepted for paid kg/unit",
  },
  "Accepted for free kg/unit": {
    LITHUANIAN: "Priimta nemokamai kg/vnt.",
    ENGLISH: "Accepted for free kg/unit",
  },
  "Total: kg/unit": {
    LITHUANIAN: "Viso: kg/vnt.",
    ENGLISH: "Total: kg/unit",
  },
  "Bulky goods and/or green waste collection site": {
    LITHUANIAN:
      "Didelių gabaritų atliekų ir/ar žaliųjų atliekų surinkimo aikštelė",
    ENGLISH: "Bulky goods and/or green waste collection site",
  },
  "Site address": {
    LITHUANIAN: "Aikštelės adresas",
    ENGLISH: "Site address",
  },
  Date: { LITHUANIAN: "Data", ENGLISH: "Date" },
  "WASTE DECLARATION No.": {
    LITHUANIAN: "ATLIEKŲ DEKLARACIJA Nr.",
    ENGLISH: "Waste declaration no.",
  },
  "I confirm that declaration data is correct.": {
    LITHUANIAN: "Patvirtinu, kad deklaracijoje nurodyti duomenys yra teisingi.",
    ENGLISH: "I confirm that declaration data is correct.",
  },
  "Please be informed that the purpose of submitting reports to state authorities and to ensure that the amount of waste imposed is free of charge, we will process your personal data. Information on how your personal data will be processed can be found in an information report published on the waste collection site. If you have any questions, you can contact us with the contacts specified in the information message. More information on the processing of personal data can be found on our website address":
    {
      LITHUANIAN:
        "Informuojame Jus, kad ataskaitų pateikimo valstybės institucijoms tikslu ir siekiant užtikrinti, kad nebutų viršyti nemokamai primamų atliekų kiekiai, mes tvarkysime Jūsu asmens duomenis. Informaciją apie tai, kaip bus tvarkomi Jūsu asmens duomenys, rasite informaciniame pranešime, kuris skelbiamas atliekų surinkimo aikštelės informaciniame stende. Jei kiltų klausimų, galite kreiptis į mus informaciniame pranešime nurodytais kontaktais. Daugiau informacijos apie asmens duomenų tvarkymą galite rasti mūsų interneto svetainėje adresu",
      ENGLISH:
        "Please be informed that the purpose of submitting reports to state authorities and to ensure that the amount of waste imposed is free of charge, we will process your personal data. Information on how your personal data will be processed can be found in an information report published on the waste collection site. If you have any questions, you can contact us with the contacts specified in the information message. More information on the processing of personal data can be found on our website address",
    },
  Cancel: { LITHUANIAN: "Atšaukti", ENGLISH: "Cancel" },
  Confirm: { LITHUANIAN: "Patvirtinti", ENGLISH: "Confirm" },
  "VAT invoice": {
    LITHUANIAN: "PVM SĄSKAITA FAKTŪRA",
    ENGLISH: "VAT invoice",
  },
  Seller: { LITHUANIAN: "Pardavėjas", ENGLISH: "Seller" },
  Buyer: { LITHUANIAN: "Pirkėjas", ENGLISH: "Buyer" },
  Address: { LITHUANIAN: "Adresas", ENGLISH: "Address" },
  "VAT code": { LITHUANIAN: "PVM mokėtojo kodas", ENGLISH: "VAT code" },
  Email: { LITHUANIAN: "El. p.", ENGLISH: "Email" },
  "Invoice due": {
    LITHUANIAN: "Sąskaita apmokėti iki",
    ENGLISH: "Invoice due",
  },
  "Amount without VAT": {
    LITHUANIAN: "Suma be PVM",
    ENGLISH: "Amount without VAT",
  },
  "VAT sum": { LITHUANIAN: "PVM suma Eur", ENGLISH: "VAT sum" },
  "Total inc. VAT": {
    LITHUANIAN: "Viso su PVM",
    ENGLISH: "Total inc. VAT",
  },
  "Total inc. VAT in words": {
    LITHUANIAN: "Suma su PVM žodžiais",
    ENGLISH: "Total inc. VAT in words",
  },
  "Invoice issued by": {
    LITHUANIAN: "Sąskaita išrašė",
    ENGLISH: "Invoice issued by",
  },
  "Position, name, surname, signature": {
    LITHUANIAN: "Pareigos, vardas, pavardė, parašas",
    ENGLISH: "Position, name, surname, signature",
  },
  "Invoice received by": {
    LITHUANIAN: "Sąskaita gavo",
    ENGLISH: "Invoice received by",
  },
  "Candial account": {
    LITHUANIAN: "Atsiskaitomoji sąskaita",
    ENGLISH: "Candial account",
  },
  "Send VAT invoice by email": {
    LITHUANIAN:
      "Išsiųsti PVM sąskaitą faktūrą el. p. adresu nurodytu kliento rekvizituose",
    ENGLISH: "Send VAT invoice by email",
  },
  "Wrong email": {
    LITHUANIAN: "Nurodytas neteisingas el. pašto adresas",
    ENGLISH: "Wrong email",
  },
  Save: { LITHUANIAN: "Išsaugoti", ENGLISH: "Save" },
  "Access to your account has been disabled": {
    LITHUANIAN: "Prieiga prie jūsų paskyros išjungta",
    ENGLISH: "Access to your account has been disabled",
  },
  "Your username or password is wrong": {
    LITHUANIAN:
      "Jūsų įvestas darbuotojo slapyvardis arba slaptažodis yra neteisingas",
    ENGLISH: "Your username or password is wrong",
  },
  LOGIN: { LITHUANIAN: "PRISIJUNGIMAS", ENGLISH: "LOGIN" },
  Username: {
    LITHUANIAN: "Darbuotojo slapyvardis",
    ENGLISH: "Username",
  },
  "Enter the user name or email address": {
    LITHUANIAN: "Įveskite vartotojo vardą arba el. pašto adresą",
    ENGLISH: "Enter the user name or email address",
  },
  Password: { LITHUANIAN: "Slaptažodis", ENGLISH: "Password" },
  "Enter the password": {
    LITHUANIAN: "Įveskite slaptažodį",
    ENGLISH: "Enter the password",
  },
  Login: { LITHUANIAN: "Prisijungti", ENGLISH: "Login" },
  "New waste": { LITHUANIAN: "Naujos atliekos", ENGLISH: "New waste" },
  "Accumulated waste": {
    LITHUANIAN: "Sukauptos atliekos",
    ENGLISH: "Accumulated waste",
  },
  Reports: { LITHUANIAN: "Ataskaitos", ENGLISH: "Reports" },
  Select: { LITHUANIAN: "Pasirinkti", ENGLISH: "Select" },
  Logout: { LITHUANIAN: "Atsijungti", ENGLISH: "Logout" },
  Yes: { LITHUANIAN: "Taip", ENGLISH: "Yes" },
  "Made by": {
    LITHUANIAN: "Sistemą kūrė",
    ENGLISH: "Made by",
  },
  "Choose a site": {
    LITHUANIAN: "Pasirinkite objektą",
    ENGLISH: "Choose a site",
  },
  "Household waste": {
    LITHUANIAN: "Namų ūkių atliekos",
    ENGLISH: "Household waste",
  },
  Individual: { LITHUANIAN: "Fizinis asmuo", ENGLISH: "Individual" },
  "The data are saved": {
    LITHUANIAN: "Duomenys išsaugoti",
    ENGLISH: "The data are saved",
  },
  "The record could not be saved. Try again.": {
    LITHUANIAN: "Įrašo išsaugoti nepavyko. Bandykite dar kartą.",
    ENGLISH: "The record could not be saved. Try again.",
  },
  "The reason for the cancellation": {
    LITHUANIAN: "Atšaukimo priežastis",
    ENGLISH: "The reason for the cancellation",
  },
  "Record the reason for the cancellation": {
    LITHUANIAN: "Įrašykite atšaukimo priežastį",
    ENGLISH: "Record the reason for the cancellation",
  },
  Transmission: { LITHUANIAN: "Perdavimas", ENGLISH: "Transmission" },
  "Departure time": {
    LITHUANIAN: "Išvykimo laikas",
    ENGLISH: "Departure time",
  },
  "Order no.": { LITHUANIAN: "Užsakymo nr.", ENGLISH: "Order no." },
  "Order creation date": {
    LITHUANIAN: "Užsakymo suformavimo data",
    ENGLISH: "Order creation date",
  },
  "No.": { LITHUANIAN: "Nr.", ENGLISH: "No." },
  "T. p. number": {
    LITHUANIAN: "T. p. numeris",
    ENGLISH: "T. p. number",
  },
  "Waste sender": {
    LITHUANIAN: "Atliekų siuntėjas",
    ENGLISH: "Waste sender",
  },
  "Reverse Delivery": {
    LITHUANIAN: "Nutraukti pildymą",
    ENGLISH: "Reverse Delivery",
  },
  Arrived: { LITHUANIAN: "Atvyko", ENGLISH: "Arrived" },
  "Show more": { LITHUANIAN: "Rodyti daugiau", ENGLISH: "Show more" },
  "Contract no.": {
    LITHUANIAN: "Sutarties Nr.",
    ENGLISH: "Contract no.",
  },
  Municipality: { LITHUANIAN: "Savivaldybė", ENGLISH: "Municipality" },
  Name: { LITHUANIAN: "Vardas", ENGLISH: "Name" },
  Surname: { LITHUANIAN: "Pavardė", ENGLISH: "Surname" },
  "Personal document": {
    LITHUANIAN: "Asmens dokumentas",
    ENGLISH: "Personal document",
  },
  "Document No.": {
    LITHUANIAN: "Dokumento nr.",
    ENGLISH: "Document No.",
  },
  "last 4 digits of personal ID number": {
    LITHUANIAN: "4 paskutiniai a. k. sk.",
    ENGLISH: "last 4 digits of personal ID number",
  },
  Searched: { LITHUANIAN: "Ieškoma", ENGLISH: "Searched" },
  "Previously accepted": {
    LITHUANIAN: "Jau priimta",
    ENGLISH: "Previously accepted",
  },
  Delivery: { LITHUANIAN: "Pristatymas", ENGLISH: "Delivery" },
  Limit: { LITHUANIAN: "Limitas", ENGLISH: "Limit" },
  "Amount received": {
    LITHUANIAN: "Gautas kiekis",
    ENGLISH: "Amount received",
  },
  "Origin of waste": {
    LITHUANIAN: "Atliekų kilmė",
    ENGLISH: "Origin of waste",
  },
  "Payment document": {
    LITHUANIAN: "Mokėjimo dokumentas",
    ENGLISH: "Payment document",
  },
  "Management code": {
    LITHUANIAN: "Tvarkymo kodas",
    ENGLISH: "Management code",
  },
  Source: {
    LITHUANIAN: "Šaltinis",
    ENGLISH: "Source",
  },
  "Waste source": {
    LITHUANIAN: "Atliekų šaltinis",
    ENGLISH: "Waste source",
  },
  "Primary source of waste": {
    LITHUANIAN: "Pirminis atliekų šaltinis",
    ENGLISH: "Primary source of waste",
  },
  Invoice: { LITHUANIAN: "Sąskaita", ENGLISH: "Invoice" },
  "Search...": { LITHUANIAN: "Ieškoti...", ENGLISH: "Search..." },
  "Exceeded the amount of waste adopted per year": {
    LITHUANIAN: "Viršytas nemokamai priimamų atliekų kiekis per metus",
    ENGLISH: "Exceeded the amount of waste adopted per year",
  },
  "Limit of waste delivered in one day": {
    LITHUANIAN: "Per vieną dieną pristatomų atliekų limitas",
    ENGLISH: "Limit of waste delivered in one day",
  },
  "Paid quantity": {
    LITHUANIAN: "Mokamas kiekis",
    ENGLISH: "Paid quantity",
  },
  "Amount to be paid": {
    LITHUANIAN: "Mokėtina suma",
    ENGLISH: "Amount to be paid",
  },
  "The limit of waste delivered in one day has been exceeded": {
    LITHUANIAN: "Viršytas per vieną dieną pristatomų atliekų limitas",
    ENGLISH: "The limit of waste delivered in one day has been exceeded",
  },
  "Accepted so far": {
    LITHUANIAN: "Iki šiol priimta",
    ENGLISH: "Accepted so far",
  },
  "Exceeded in landfill / site storage of waste limit": {
    LITHUANIAN: "Viršytas sąvartyne / aikštelėje kaupiamos atliekos limitas",
    ENGLISH: "Exceeded in landfill / site storage of waste limit",
  },
  "If the recipient list already contains waste, their quantities are included in the calculation":
    {
      LITHUANIAN:
        "Jei priimamų atliekų sąraše jau yra įvestų atliekų, jų kiekiai įtraukti į skaičiavimą",
      ENGLISH:
        "If the recipient list already contains waste, their quantities are included in the calculation",
    },
  "Add a waste": {
    LITHUANIAN: "Pridėti atlieką",
    ENGLISH: "Add a waste",
  },
  "Hold on ...": {
    LITHUANIAN: "Luktelkite ...",
    ENGLISH: "Hold on ...",
  },
  "Loading ...": { LITHUANIAN: "Įkeliamas ...", ENGLISH: "Loading ..." },
  "Customer currently owes {amount} €": {
    LITHUANIAN: "Klientas šiuo metu skolingas {amount} €",
    ENGLISH: "Customer currently owes {amount} €",
  },
  "WASTE ACCEPTED": {
    LITHUANIAN: "PRIIMAMOS ATLIEKOS",
    ENGLISH: "WASTE ACCEPTED",
  },
  "The date and time of the waste acceptance": {
    LITHUANIAN: "Atliekų priėmimo data ir laikas",
    ENGLISH: "The date and time of the waste acceptance",
  },
  "Waste amount, units": {
    LITHUANIAN: "Atliekų kiekis, vnt.",
    ENGLISH: "Waste amount, units",
  },
  "Waste amount, t": {
    LITHUANIAN: "Atliekų kiekis, t",
    ENGLISH: "Waste amount, t",
  },
  "Processed wastes": {
    LITHUANIAN: "Sutvarkyta atlieka",
    ENGLISH: "Processed wastes",
  },
  "Processed quantity": {
    LITHUANIAN: "Sutvarkytas kiekis",
    ENGLISH: "Processed quantity",
  },
  units: { LITHUANIAN: "vnt.", ENGLISH: "units" },
  "The amount of waste accepted is unlimited": {
    LITHUANIAN: "Priimamų atliekų kiekis neribojamas",
    ENGLISH: "The amount of waste accepted is unlimited",
  },
  "Waste is accepted free of charge": {
    LITHUANIAN: "Nemokamai priimamos atliekos",
    ENGLISH: "Waste is accepted free of charge",
  },
  "Paid waste": {
    LITHUANIAN: "Mokamai priimamos atliekos",
    ENGLISH: "Paid waste",
  },
  "Exceeded within one day the limit of waste delivered or exceeded in a landfill / site storage of waste":
    {
      LITHUANIAN:
        "Viršytas per vieną dieną pristatomų atliekų limitas arba viršytas sąvartyne / aikštelėje kaupiamos atliekos limitas",
      ENGLISH:
        "Exceeded within one day the limit of waste delivered or exceeded in a landfill / site storage of waste",
    },
  "A VAT invoice for the acceptance of this waste will not be generated, do you want to continue?":
    {
      LITHUANIAN:
        "PVM sąskaita faktūra už šių atliekų priėmimą nebus suformuota, ar norite tęsti?",
      ENGLISH:
        "A VAT invoice for the acceptance of this waste will not be generated, do you want to continue?",
    },
  "Report of received-transferred waste quantities by customers": {
    LITHUANIAN: "Priimtų-perduotų atliekų kiekių pagal klientus ataskaita",
    ENGLISH: "Report of received-transferred waste quantities by customers",
  },
  "The amount of waste by sources of waste": {
    LITHUANIAN: "Atliekų kiekių pagal šaltinius ataskaita",
    ENGLISH: "The amount of waste by sources of waste",
  },
  "Report of waste holders by type": {
    LITHUANIAN: "Atliekų turėtojų pagal rūšį ataskaita",
    ENGLISH: "Report of waste holders by type",
  },
  "Summary of waste quantities by municipality": {
    LITHUANIAN: "Atliekų kiekių suvestinė pagal savivaldybes",
    ENGLISH: "Summary of waste quantities by municipality",
  },
  "Detailed report of waste acceptances": {
    LITHUANIAN: "Detali atliekų priėmimų ataskaita",
    ENGLISH: "Detailed report of waste acceptances",
  },
  "Detailed transfer report": {
    LITHUANIAN: "Detali perdavimų ataskaita",
    ENGLISH: "Detailed transfer report",
  },
  "Summary of acceptance by origin": {
    LITHUANIAN: "Priėmimų suvestinė pagal kilmę",
    ENGLISH: "Summary of acceptance by origin",
  },
  "Summary of Waste residue": {
    LITHUANIAN: "Atliekų likučių suvestinė",
    ENGLISH: "Summary of Waste residue",
  },
  "Detailed weighing report according to selected invoice": {
    LITHUANIAN: "Detali svėrimų ataskaita pagal pasirinktą sąskaitą faktūrą",
    ENGLISH: "Detailed weighing report according to selected invoice",
  },
  "Weighing reports": {
    LITHUANIAN: "Svėrimų ataskaitos",
    ENGLISH: "Weighing reports",
  },
  "Annual summary of received waste": {
    LITHUANIAN: "Gautų atliekų metinė suvestinė",
    ENGLISH: "Annual summary of received waste",
  },
  "Waste management accounting journal (adapted for submission to GPAIS)": {
    LITHUANIAN:
      "Atliekų tvarkymo apskaitos žurnalas (pritaikytas pateikti į GPAIS)",
    ENGLISH:
      "Waste management accounting journal (adapted for submission to GPAIS)",
  },
  "Weight Exchange Audit Report": {
    LITHUANIAN: "Svorių keitimo audito ataskaita",
    ENGLISH: "Weight Exchange Audit Report",
  },
  Period: {
    LITHUANIAN: "Laikotarpis",
    ENGLISH: "Period",
  },
  Site: { LITHUANIAN: "Objektas", ENGLISH: "Site" },
  "Price (per kg) without VAT": {
    LITHUANIAN: "Kaina (kg) be PVM",
    ENGLISH: "Price (per kg) without VAT",
  },
  "Price (per kg) with VAT": {
    LITHUANIAN: "Kaina (kg) su PVM",
    ENGLISH: "Price (per kg) with VAT",
  },
  "Price with VAT": {
    LITHUANIAN: "Suma su PVM",
    ENGLISH: "Price with VAT",
  },
  "Create Report": {
    LITHUANIAN: "Formuoti ataskaitą",
    ENGLISH: "Create Report",
  },
  "Legal person": {
    LITHUANIAN: "Juridinis asmuo",
    ENGLISH: "Legal person",
  },
  "Type of waste holder": {
    LITHUANIAN: "Atliekų turėtojo rūšis",
    ENGLISH: "Type of waste holder",
  },
  "Waste holder": {
    LITHUANIAN: "Atliekų turėtojas",
    ENGLISH: "Waste holder",
  },
  Billing: { LITHUANIAN: "Atsiskaitymas", ENGLISH: "Billing" },
  "Recipient of the waste": {
    LITHUANIAN: "Atliekų gavėjas",
    ENGLISH: "Recipient of the waste",
  },
  "Waste carrier": {
    LITHUANIAN: "Atliekų vežėjas",
    ENGLISH: "Waste carrier",
  },
  "Formal Invoice": {
    LITHUANIAN: "Sąskaita faktūra",
    ENGLISH: "Formal Invoice",
  },
  Year: { LITHUANIAN: "Metai", ENGLISH: "Year" },
  "Waste management activities": {
    LITHUANIAN: "Atliekų tvarkymo veikla",
    ENGLISH: "Waste management activities",
  },
  "Waste Generated": {
    LITHUANIAN: "Susidariusi atlieka",
    ENGLISH: "Waste Generated",
  },
  Operator: { LITHUANIAN: "Darbuotojas", ENGLISH: "Operator" },
  "Before correction (kg)": {
    LITHUANIAN: "Iki korekcijos (kg)",
    ENGLISH: "Before correction (kg)",
  },
  "After correction (kg)": {
    LITHUANIAN: "Po korekcijos (kg)",
    ENGLISH: "After correction (kg)",
  },
  "Amount received, t": {
    LITHUANIAN: "Gautas kiekis, t",
    ENGLISH: "Amount received, t",
  },
  "SELECT REPORT TYPE": {
    LITHUANIAN: "PASIRINKITE ATASKAITOS TIPĄ",
    ENGLISH: "SELECT REPORT TYPE",
  },
  Associates: { LITHUANIAN: "ASKAITOS", ENGLISH: "Associates" },
  "No records were found so {report title} was not formed.": {
    LITHUANIAN: "Įrašų nerasta, todėl {Report Title} nesuformuota.",
    ENGLISH: "No records were found so {report title} was not formed.",
  },
  "Newest date first": {
    LITHUANIAN: "Naujausi viršuje",
    ENGLISH: "Newest date first",
  },
  "Oldest date first": {
    LITHUANIAN: "Naujausi apačioje",
    ENGLISH: "Oldest date first",
  },
  less: { LITHUANIAN: "mažiau", ENGLISH: "less" },
  more: { LITHUANIAN: "daugiau", ENGLISH: "more" },
  "Date of receipt": {
    LITHUANIAN: "Gavimo data",
    ENGLISH: "Date of receipt",
  },
  "Anticipated handling activities": {
    LITHUANIAN: "Numatoma tvarkymo veikla",
    ENGLISH: "Anticipated handling activities",
  },
  "Are you sure you want to remove this accepted waste record?": {
    LITHUANIAN: "Ar tikrai norite pašalintį šį įrašą apie priimtas atliekas?",
    ENGLISH: "Are you sure you want to remove this accepted waste record?",
  },
  Upload: { LITHUANIAN: "Įkelti", ENGLISH: "Upload" },
  Manage: { LITHUANIAN: "Tvarkyti", ENGLISH: "Manage" },
  "Total quantity": {
    LITHUANIAN: "Bendras kiekis",
    ENGLISH: "Total quantity",
  },
  MANAGEMENT: { LITHUANIAN: "TVARKYMAS", ENGLISH: "MANAGEMENT" },
  "Show after": { LITHUANIAN: "Rodyti po", ENGLISH: "Show after" },
  "Clear filters": {
    LITHUANIAN: "Valyti filtrus",
    ENGLISH: "Clear filters",
  },
  Clear: {
    LITHUANIAN: "Valyti",
    ENGLISH: "Clear",
  },
  "Representative's signature": {
    LITHUANIAN: "Atstovo parašas",
    ENGLISH: "Representative's signature",
  },
  "Signature of Waste Holder": {
    LITHUANIAN: "Atliekų turėtojo parašas",
    ENGLISH: "Signature of Waste Holder",
  },
  Signature: {
    LITHUANIAN: "Pasirašyti",
    ENGLISH: "Signature",
  },
  "Processing Date": {
    LITHUANIAN: "Sutvarkymo data",
    ENGLISH: "Processing Date",
  },
  "Resulting material": {
    LITHUANIAN: "Gauta medžiaga",
    ENGLISH: "Resulting material",
  },
  "Resulting Material, Object": {
    LITHUANIAN: "Susidariusi medžiaga, daiktas",
    ENGLISH: "Resulting Material, Object",
  },
  "Used materials, objects": {
    LITHUANIAN: "Naudotos medžiagos, daiktai",
    ENGLISH: "Used materials, objects",
  },
  Formation: { LITHUANIAN: "Susidarymas", ENGLISH: "Formation" },
  Area: { LITHUANIAN: "Aikštelė", ENGLISH: "Area" },
  Note: { LITHUANIAN: "Pastaba", ENGLISH: "Note" },
  "New entry": { LITHUANIAN: "Naujas įrašas", ENGLISH: "New entry" },
  "The waste removal order has been created successfully": {
    LITHUANIAN: "Užsakymas atliekų išvežimui sukurtas sėkmingai",
    ENGLISH: "The waste removal order has been created successfully",
  },
  "Unable to create waste removal order": {
    LITHUANIAN: "Nepavyko sukurti atliekų išvežimo užsakymo",
    ENGLISH: "Unable to create waste removal order",
  },
  "Company number": {
    LITHUANIAN: "Įmonės numeris",
    ENGLISH: "Company number",
  },
  "Waste sender address": {
    LITHUANIAN: "Atliekų siuntėjo adresas",
    ENGLISH: "Waste sender address",
  },
  "Waste recipient's address": {
    LITHUANIAN: "Atliekų gavėjo adresas",
    ENGLISH: "Waste recipient's address",
  },
  "Additional information": {
    LITHUANIAN: "Papildoma informacija",
    ENGLISH: "Additional information",
  },
  "T.p. license plate": {
    LITHUANIAN: "T.P. valstybinis numeris",
    ENGLISH: "T.p. license plate",
  },
  "Preferred departure date": {
    LITHUANIAN: "Pageidaujama išvežimo data",
    ENGLISH: "Preferred departure date",
  },
  "Driver's first name": {
    LITHUANIAN: "Vairuotojo vardas",
    ENGLISH: "Driver's first name",
  },
  "Driver's last name": {
    LITHUANIAN: "Vairuotojo pavardė",
    ENGLISH: "Driver's last name",
  },
  "Order Recipient email": {
    LITHUANIAN: "Užsakymo gavėjo el. p.",
    ENGLISH: "Order Recipient email",
  },
  "Internal transportation": {
    LITHUANIAN: "Vidinis pervežimas",
    ENGLISH: "Internal transportation",
  },
  "Disposal site": {
    LITHUANIAN: "Atliekų tvarkymo vieta",
    ENGLISH: "Disposal site",
  },
  "Waste code and name": {
    LITHUANIAN: "Atliekos kodas ir pavadinimas",
    ENGLISH: "Waste code and name",
  },
  "Weight t": { LITHUANIAN: "Svoris t", ENGLISH: "Weight t" },
  Edit: { LITHUANIAN: "Redaguoti", ENGLISH: "Edit" },
  "Are you sure you want to submit a waste removal order?": {
    LITHUANIAN: "Ar tikrai norite pateikti atliekų išvežimo užsakymą?",
    ENGLISH: "Are you sure you want to submit a waste removal order?",
  },
  "Waste removal order no.": {
    LITHUANIAN: "Atliekų išvežimo užsakymas Nr.",
    ENGLISH: "Waste removal order no.",
  },
  "Date: {yyyy-mm-dd}": {
    LITHUANIAN: "Data: {YYYY-MM-DD}",
    ENGLISH: "Date: {yyyy-mm-dd}",
  },
  "Submit order": {
    LITHUANIAN: "Pateikti užsakymą",
    ENGLISH: "Submit order",
  },
  "List of waste deported": {
    LITHUANIAN: "Išvežamų atliekų sąrašas",
    ENGLISH: "List of waste deported",
  },
  "Limits and rates of new waste": {
    LITHUANIAN: "Naujų atliekų limitai ir įkainiai",
    ENGLISH: "Limits and rates of new waste",
  },
  "Limits of accumulated waste": {
    LITHUANIAN: "Sukauptų atliekų limitai",
    ENGLISH: "Limits of accumulated waste",
  },
  Employees: { LITHUANIAN: "Darbuotojai", ENGLISH: "Employees" },
  Clients: { LITHUANIAN: "Klientai", ENGLISH: "Clients" },
  Classification: {
    LITHUANIAN: "Klasifikatoriai",
    ENGLISH: "Classification",
  },
  Settings: { LITHUANIAN: "Nustatymai", ENGLISH: "Settings" },
  "Add a new waste": {
    LITHUANIAN: "Pridėti naują atlieką",
    ENGLISH: "Add a new waste",
  },
  "Series of invoices": {
    LITHUANIAN: "Sąskaitų faktūrų serijos",
    ENGLISH: "Series of invoices",
  },
  "The critical limit may not exceed the accumulation limit.": {
    LITHUANIAN: "Kritinė riba negali būti didesnė nei Kaupimo limitas.",
    ENGLISH: "The critical limit may not exceed the accumulation limit.",
  },
  "The warning threshold cannot be greater than the critical limit.": {
    LITHUANIAN: "Įspėjamoji riba negali būti didesnė už Kritinė riba.",
    ENGLISH: "The warning threshold cannot be greater than the critical limit.",
  },
  "The warning threshold cannot be higher than the accumulation limit.": {
    LITHUANIAN: "Įspėjamoji riba negali būti didesnė už Kaupimo limitas.",
    ENGLISH:
      "The warning threshold cannot be higher than the accumulation limit.",
  },
  "The free annual limit cannot be higher than the accumulation limit.": {
    LITHUANIAN:
      "Nemokamas metinis limitas negali būti didesnis už Kaupimo limitą.",
    ENGLISH:
      "The free annual limit cannot be higher than the accumulation limit.",
  },
  "Price is required. The price cannot be higher than the price with VAT.": {
    LITHUANIAN: "Kaina privaloma. Kaina negali būti didesnė, nei Kaina su PVM.",
    ENGLISH:
      "Price is required. The price cannot be higher than the price with VAT.",
  },
  "The new waste must be assigned to at least one site.": {
    LITHUANIAN: "Nauja atlieka privalo būti priskirta bent vienai aikštelei.",
    ENGLISH: "The new waste must be assigned to at least one site.",
  },
  "The waste name or waste code is required.": {
    LITHUANIAN: "Atliekos pavadinimas ar atliekos kodas privalomi laukai.",
    ENGLISH: "The waste name or waste code is required.",
  },
  "Add a limit for free waste acceptance.": {
    LITHUANIAN: "Pridėti nemokamai priimamų atliekų limitą.",
    ENGLISH: "Add a limit for free waste acceptance.",
  },
  "Please specify the weight of the unit (units) in kilograms (kg).": {
    LITHUANIAN: "Prašome nurodyti vieneto (vnt.) svorį kilogramais (kg).",
    ENGLISH: "Please specify the weight of the unit (units) in kilograms (kg).",
  },
  "Assign a group.": {
    LITHUANIAN: "Priskirti grupę.",
    ENGLISH: "Assign a group.",
  },
  "Is cumulative": {
    LITHUANIAN: "Sumuojamas kartu su...",
    ENGLISH: "Is cumulative",
  },
  "Not cumulative": {
    LITHUANIAN: "Nesumuojamas",
    ENGLISH: "Not cumulative",
  },
  Limited: { LITHUANIAN: "Ribojamas", ENGLISH: "Limited" },
  Unlimited: { LITHUANIAN: "Neribojamas", ENGLISH: "Unlimited" },
  "Single delivery limit": {
    LITHUANIAN: "Didžiausias vienu metu galimas pristatyti kiekis",
    ENGLISH: "Single delivery limit",
  },
  "Warning limit": {
    LITHUANIAN: "Įspėjamoji riba",
    ENGLISH: "Warning limit",
  },
  "Use the Rate with VAT for the calculations of the payable amount": {
    LITHUANIAN: "Mokėtinos sumos skaičiavimams naudoti kainą su PVM",
    ENGLISH: "Use the Rate with VAT for the calculations of the payable amount",
  },
  "Accepts units": {
    LITHUANIAN: "Priėmimas vienetais",
    ENGLISH: "Accepts units",
  },
  "Unit (units) weight in kilograms (kg)": {
    LITHUANIAN: "Vieneto (vnt.) svoris kilogramais (kg)",
    ENGLISH: "Unit (units) weight in kilograms (kg)",
  },
  "New wastes": {
    LITHUANIAN: "Nauja atlieka",
    ENGLISH: "New wastes",
  },
  Limits: { LITHUANIAN: "Limitai", ENGLISH: "Limits" },
  Sites: { LITHUANIAN: "Aikštelės", ENGLISH: "Sites" },
  "Enter the invoice series": {
    LITHUANIAN: "Įrašykite sąskaitos faktūros seriją",
    ENGLISH: "Enter the invoice series",
  },
  "Enter a series": {
    LITHUANIAN: "Pridėkite seriją.",
    ENGLISH: "Enter a series",
  },
  "This series already exists, you need to create another series.": {
    LITHUANIAN: "Ši serija jau yra arba jau buvo, turite sukurti kitą seriją.",
    ENGLISH: "This series already exists, you need to create another series.",
  },
  "The VAT invoice series must be made up of 4 characters.": {
    LITHUANIAN:
      "PVM sąskaitos faktūros serija turi būti sudaryta iš 4 simbolių.",
    ENGLISH: "The VAT invoice series must be made up of 4 characters.",
  },
  "VAT invoice series": {
    LITHUANIAN: "PVM sąskaitos faktūros serija",
    ENGLISH: "VAT invoice series",
  },
  Used: { LITHUANIAN: "Naudojama", ENGLISH: "Used" },
  Series: { LITHUANIAN: "Serija", ENGLISH: "Series" },
  "Date of creation": {
    LITHUANIAN: "Sukūrimo data",
    ENGLISH: "Date of creation",
  },
  "Free annual limit": {
    LITHUANIAN: "Nemokamas metinis limitas",
    ENGLISH: "Free annual limit",
  },
  "Rate without VAT": {
    LITHUANIAN: "Įkainis be PVM",
    ENGLISH: "Rate without VAT",
  },
  Code: { LITHUANIAN: "Kodas", ENGLISH: "Code" },
  ID: { LITHUANIAN: "ID", ENGLISH: "ID" },
  Wastes: { LITHUANIAN: "Atlieka", ENGLISH: "Wastes" },
  "Rate with VAT": {
    LITHUANIAN: "Įkainis su PVM",
    ENGLISH: "Rate with VAT",
  },
  "Mandatory invoice": {
    LITHUANIAN: "Privaloma sąskaita",
    ENGLISH: "Mandatory invoice",
  },
  "Do you really want to remove this waste from the waste list": {
    LITHUANIAN: "Ar tikrai norite pašalinti šią atlieką iš atliekų sąrašo",
    ENGLISH: "Do you really want to remove this waste from the waste list",
  },
  "Choose site": {
    LITHUANIAN: "Pasirinkite aikštelę",
    ENGLISH: "Choose site",
  },
  "Accumulation limit": {
    LITHUANIAN: "Kaupimo limitas",
    ENGLISH: "Accumulation limit",
  },
  "Critical Limit": {
    LITHUANIAN: "Kritinė riba",
    ENGLISH: "Critical Limit",
  },
  "The accumulation limit must be greater than or equal to the amount of waste accepted free of charge at the site / landfill.":
    {
      LITHUANIAN:
        "Kaupimo limitas turi būti didesnis arba lygus nemokamai priimamų atliekų aikštekėje / sąvartyne kiekiui.",
      ENGLISH:
        "The accumulation limit must be greater than or equal to the amount of waste accepted free of charge at the site / landfill.",
    },
  "When this limit is reached, a warning message will be displayed during acceptance about the accumulated amount of waste that is approaching the waste accumulation limit at the site / landfill.":
    {
      LITHUANIAN:
        "Pasiekus šią ribą, priėmimo metu, bus rodomas įspėjamasis pranešimas apie sukauptą atliekų kiekį, kuris artėja prie atliekų Kaupimo limito aikštekėje / sąvartyne. Kritinė riba negali būti didesnė už Kaupimo limitą ir mažesnė už Įspėjamąją ribą.",
      ENGLISH:
        "When this limit is reached, a warning message will be displayed during acceptance about the accumulated amount of waste that is approaching the waste accumulation limit at the site / landfill.",
    },
  "Unit of measurement": {
    LITHUANIAN: "Mato vienetas",
    ENGLISH: "Unit of measurement",
  },
  "Site List": {
    LITHUANIAN: "Objektų sąrašas",
    ENGLISH: "Site List",
  },
  "Municipalities of waste generation": {
    LITHUANIAN: "Atliekų susidarymo savivaldybės",
    ENGLISH: "Municipalities of waste generation",
  },
  "Waste carriers": {
    LITHUANIAN: "Atliekų vežėjai",
    ENGLISH: "Waste carriers",
  },
  "List of materials used during waste management": {
    LITHUANIAN: "Atliekų tvarkymo metu naudojamų medžiagų sąrašas",
    ENGLISH: "List of materials used during waste management",
  },
  "List of (obtained) materials formed during waste management": {
    LITHUANIAN: "Atliekų tvarkymo metu susidariusių (gautų) medžiagų sąrašas",
    ENGLISH: "List of (obtained) materials formed during waste management",
  },
  "List of Waste Management Actions": {
    LITHUANIAN: "Atliekų tvarkymo veiksmų sąrašas",
    ENGLISH: "List of Waste Management Actions",
  },
  "Classifier value": {
    LITHUANIAN: "Klasifikatoriaus reikšmė",
    ENGLISH: "Classifier value",
  },
  "Enter a new classifier value": {
    LITHUANIAN: "Įrašykite naują klaisifikatoriaus reikšmę",
    ENGLISH: "Enter a new classifier value",
  },
  "Enter the address": {
    LITHUANIAN: "Įrašykite adresą",
    ENGLISH: "Enter the address",
  },
  "Enter the company code": {
    LITHUANIAN: "Įrašykite įmonės kodą",
    ENGLISH: "Enter the company code",
  },
  "Associated company": {
    LITHUANIAN: "Susieta įmonė",
    ENGLISH: "Associated company",
  },
  "CHOOSE A CLASSIFIER": {
    LITHUANIAN: "PASIRINKITE KLASIFIKATORIŲ",
    ENGLISH: "CHOOSE A CLASSIFIER",
  },
  Search: { LITHUANIAN: "Paieška", ENGLISH: "Search" },
  Add: { LITHUANIAN: "Pridėti", ENGLISH: "Add" },
  "Active classifier values": {
    LITHUANIAN: "Aktyvios klasifikatoriaus reikšmės",
    ENGLISH: "Active classifier values",
  },
  "Inactive classifier values": {
    LITHUANIAN: "Neaktyvios klasifkatoriaus reikšmės",
    ENGLISH: "Inactive classifier values",
  },
  "Customer ID": { LITHUANIAN: "Kliento ID", ENGLISH: "Customer ID" },
  "Account created": {
    LITHUANIAN: "Paskyra sukurta",
    ENGLISH: "Account created",
  },
  Review: { LITHUANIAN: "Peržiūrėti", ENGLISH: "Review" },
  "Identity Card": {
    LITHUANIAN: "Asmens tapatybės kortelė",
    ENGLISH: "Identity Card",
  },
  Passport: { LITHUANIAN: "Pasas", ENGLISH: "Passport" },
  "Driver's license": {
    LITHUANIAN: "Vairuotojo pažymėjimas",
    ENGLISH: "Driver's license",
  },
  "Temporary residence permit": {
    LITHUANIAN: "Leidimas laikinai gyventi",
    ENGLISH: "Temporary residence permit",
  },
  "Permanent residence permit": {
    LITHUANIAN: "Leidimas nuolat gyventi",
    ENGLISH: "Permanent residence permit",
  },
  "Reminder sent successfully": {
    LITHUANIAN: "Priminimas sėkmingai išsiųstas",
    ENGLISH: "Reminder sent successfully",
  },
  "Strict reminder": {
    LITHUANIAN: "Griežtas priminimas",
    ENGLISH: "Strict reminder",
  },
  Reminder: { LITHUANIAN: "Priminimas", ENGLISH: "Reminder" },
  "Email address": {
    LITHUANIAN: "El. pašto adresas",
    ENGLISH: "Email address",
  },
  "Email p. address": {
    LITHUANIAN: "El. p. adresas",
    ENGLISH: "Email Address",
  },
  "Tel. no.": { LITHUANIAN: "Tel. nr.", ENGLISH: "Tel. no." },
  Debties: { LITHUANIAN: "Įsiskolinimai", ENGLISH: "Debties" },
  "Inform about the debt": {
    LITHUANIAN: "Informuoti apie įsiskolinimą",
    ENGLISH: "Inform about the debt",
  },
  "Update information": {
    LITHUANIAN: "Atnaujinti informaciją",
    ENGLISH: "Update information",
  },
  "Message Date": {
    LITHUANIAN: "Pranešimo data",
    ENGLISH: "Message Date",
  },
  "The recipient's email": {
    LITHUANIAN: "Gavėjo el. p.",
    ENGLISH: "The recipient's email",
  },
  "Enter the correct email address": {
    LITHUANIAN: "Įveskite teisingą el. pašto adresą.",
    ENGLISH: "Enter the correct email address",
  },
  Send: { LITHUANIAN: "Siųsti", ENGLISH: "Send" },
  Quantity: { LITHUANIAN: "Kiekis", ENGLISH: "Quantity" },
  "Site / Landfill": {
    LITHUANIAN: "Aikštelė / Savartynas",
    ENGLISH: "Site / Landfill",
  },
  Declaration: { LITHUANIAN: "Deklaracija", ENGLISH: "Declaration" },
  "Delivered / transferred waste": {
    LITHUANIAN: "Pristatytos / Perduotos atliekos",
    ENGLISH: "Delivered / transferred waste",
  },
  "Choose a year": {
    LITHUANIAN: "Pasirinkite metus",
    ENGLISH: "Choose a year",
  },
  "Waste Group": {
    LITHUANIAN: "Atliekų grupė",
    ENGLISH: "Waste Group",
  },
  "Wastes / Waste Groups": {
    LITHUANIAN: "Atlieka / atliekų grupė",
    ENGLISH: "Wastes / Waste Groups",
  },
  "Changes in the company account successfully save": {
    LITHUANIAN: "Pakeitimai įmonės paskyroje sėkmingai išsaugoti",
    ENGLISH: "Changes in the company account successfully save",
  },
  "Phone number": {
    LITHUANIAN: "Telefono nr.",
    ENGLISH: "Phone number",
  },
  "Creation Date": {
    LITHUANIAN: "Sudarymo data",
    ENGLISH: "Creation Date",
  },
  Validity: { LITHUANIAN: "Galiojimo terminas", ENGLISH: "Validity" },
  Number: { LITHUANIAN: "Numeris", ENGLISH: "Number" },
  "New contract": {
    LITHUANIAN: "Nauja sutartis",
    ENGLISH: "New contract",
  },
  "Do you really want to delete this contract?": {
    LITHUANIAN: "Ar tikrai norite ištrinti šią sutartį?",
    ENGLISH: "Do you really want to delete this contract?",
  },
  Contracts: { LITHUANIAN: "Sutartys", ENGLISH: "Contracts" },
  "Add a new contract": {
    LITHUANIAN: "Pridėti naują sutartį",
    ENGLISH: "Add a new contract",
  },
  "Contract Number": {
    LITHUANIAN: "Sutarties numeris",
    ENGLISH: "Contract Number",
  },
  "Date of conclusion of the contract": {
    LITHUANIAN: "Sutarties sudarymo data",
    ENGLISH: "Date of conclusion of the contract",
  },
  "Term of the contract": {
    LITHUANIAN: "Sutarties galiojimo terminas",
    ENGLISH: "Term of the contract",
  },
  "Type of waste": {
    LITHUANIAN: "Atliekos tipas",
    ENGLISH: "Type of waste",
  },
  "Limit under contract, t": {
    LITHUANIAN: "Limitas pagal sutartį, t",
    ENGLISH: "Limit under contract, t",
  },
  List: { LITHUANIAN: "Sąrašas", ENGLISH: "List" },
  "Activity log": {
    LITHUANIAN: "Veiklos žurnalas",
    ENGLISH: "Activity log",
  },
  "Working groups": {
    LITHUANIAN: "Darbo grupės",
    ENGLISH: "Working groups",
  },
  "New account": {
    LITHUANIAN: "Nauja paskyra",
    ENGLISH: "New account",
  },
  From: { LITHUANIAN: "Nuo", ENGLISH: "From" },
  Position: { LITHUANIAN: "Pareigos", ENGLISH: "Position" },
  "Last login": {
    LITHUANIAN: "Paskutinis prisijungimas",
    ENGLISH: "Last login",
  },
  To: { LITHUANIAN: "Iki", ENGLISH: "To" },
  "Period of activity": {
    LITHUANIAN: "Veiklos laikotarpis",
    ENGLISH: "Period of activity",
  },
  "Name and surname": {
    LITHUANIAN: "Vardas ir pavardė",
    ENGLISH: "Name and surname",
  },
  Place: { LITHUANIAN: "Vieta", ENGLISH: "Place" },
  Action: { LITHUANIAN: "Veiksmas", ENGLISH: "Action" },
  "Do you really want to undo this action?": {
    LITHUANIAN: "Ar tikrai norite anuliuoti šį veiksmą?",
    ENGLISH: "Do you really want to undo this action?",
  },
  "Serial No.": {
    LITHUANIAN: "Eilės Nr.",
    ENGLISH: "Serial No.",
  },
  Activie: { LITHUANIAN: "Aktyvumas", ENGLISH: "Active" },
  Updated: { LITHUANIAN: "Redaguota", ENGLISH: "Updated" },
  Actions: { LITHUANIAN: "Veiksmai", ENGLISH: "Actions" },
  "Do you really want to remove this working group?": {
    LITHUANIAN: "Ar tikrai norite pašalinti šią darbo grupę?",
    ENGLISH: "Do you really want to remove this working group?",
  },
  "Employee Type in System (Role)": {
    LITHUANIAN: "Darbuotojo tipas sistemoje (rolė)",
    ENGLISH: "Employee Type in System (Role)",
  },
  "New password": {
    LITHUANIAN: "Naujas slaptažodis",
    ENGLISH: "New password",
  },
  "Confirm the password you entered": {
    LITHUANIAN: "Patvirtinkite įvestą slaptažodį",
    ENGLISH: "Confirm the password you entered",
  },
  Summation: {
    LITHUANIAN: "Sumavimas",
    ENGLISH: "Summation",
  },
  Status: { LITHUANIAN: "Būsena", ENGLISH: "Status" },
  "Order Status": { LITHUANIAN: "Užsakymo būsena", ENGLISH: "Order Status" },
  Further: { LITHUANIAN: "Toliau", ENGLISH: "Further" },
  "System Settings (I Access Level) is allowed": {
    LITHUANIAN:
      "Leidžiama administruoti sistemos nustatymus (I prieigos lygis)",
    ENGLISH: "System Settings (I Access Level) is allowed",
  },
  "Allowed to adjust data (II Access Level)": {
    LITHUANIAN: "Leidžiama koreguoti duomenis (II prieigos lygis)",
    ENGLISH: "Allowed to adjust data (II Access Level)",
  },
  "Allowed to see data (III Access Level)": {
    LITHUANIAN: "Leidžiama matyti duomenis (III prieigos lygis)",
    ENGLISH: "Allowed to see data (III Access Level)",
  },
  "Management of an object assigned to the employee": {
    LITHUANIAN: "Darbuotojui priskirto objekto valdymas",
    ENGLISH: "Management of an object assigned to the employee",
  },
  "Employee's Access Control": {
    LITHUANIAN: "DARBUOTOJO PRIEIGOS VALDYMAS",
    ENGLISH: "Employee's Access Control",
  },
  "Next": {
    LITHUANIAN: "Toliau",
    ENGLISH: "Next",
  },
  Description: {
    LITHUANIAN: "Aprašas",
    ENGLISH: "Description",
  },
  "Unit of Measurement": {
    LITHUANIAN: "Mat. vnt",
    ENGLISH: "Unit of Measurement",
  },
  "Price, EUR": {
    LITHUANIAN: "Kaina, Eur",
    ENGLISH: "Price, EUR",
  },
  "Amount, EUR": {
    LITHUANIAN: "Suma, Eur",
    ENGLISH: "Amount, EUR",
  },
  Active: {
    LITHUANIAN: "Aktyvi",
    ENGLISH: "Active",
  },
  Inactive: {
    LITHUANIAN: "Neaktyvi",
    ENGLISH: "Inactive",
  },
  "Structural Unit": {
    LITHUANIAN: "Strukūrinis padalinys",
    ENGLISH: "Structural Unit",
  },
  "Assign / Remove Object (Site)": {
    LITHUANIAN: "Priskirti / Pašalinti objektą (aikštelę)",
    ENGLISH: "Assign / Remove Object (Site)",
  },
  "Access Level Management": {
    LITHUANIAN: "Prieigos lygių valdymas",
    ENGLISH: "Access Level Management",
  },
  "Activity History": {
    LITHUANIAN: "Veiklos istorija",
    ENGLISH: "Activity History",
  },
  "Delete Account": {
    LITHUANIAN: "Pašalinti paskyrą",
    ENGLISH: "Delete Account",
  },
  "Warning: deleting a site will also delete all employee access settings. The employees themselves will not be deleted.":
    {
      LITHUANIAN:
        "Dėmesio: ištrynus aikštelę, bus ištrinti ir visi darbuotojų prieigos nustatymai. Patys Darbuotojai nebus ištrinti.",
      ENGLISH:
        "Warning: deleting a site will also delete all employee access settings. The employees themselves will not be deleted.",
    },
  "Are you sure you want to delete your account?": {
    LITHUANIAN: "Ar tikrai norite pašalinti paskyrą?",
    ENGLISH: "Are you sure you want to delete your account?",
  },
  "Name of the working group": {
    LITHUANIAN: "Darbo grupės pavadinimas",
    ENGLISH: "Name of the working group",
  },
  "Add a new group": {
    LITHUANIAN: "Pridėti naują grupę",
    ENGLISH: "Add a new group",
  },
  "EMPLOYEE GROUPING CREATION": {
    LITHUANIAN: "DARBUOTOJŲ GRUPĖS SUKŪRIMAS",
    ENGLISH: "EMPLOYEE GROUPING CREATION",
  },
  Log: {
    LITHUANIAN: "Žurnalas",
    ENGLISH: "Log",
  },
  "Organized Waste": {
    LITHUANIAN: "Sutvarkytos atliekos",
    ENGLISH: "Organized Waste",
  },
  "Transfer Order Log": {
    LITHUANIAN: "Perdavimo užsakymų žurnalas",
    ENGLISH: "Transfer Order Log",
  },
  Show: {
    LITHUANIAN: "Rodyti",
    ENGLISH: "Show",
  },
  "Current weight": {
    LITHUANIAN: "Esamas svoris",
    ENGLISH: "Current weight",
  },
  "Limit Fraction": {
    LITHUANIAN: "Limito dalis",
    ENGLISH: "Limit Fraction",
  },
  "Form an order": {
    LITHUANIAN: "Formuoti užsakymą",
    ENGLISH: "Form an order",
  },
  "Understood, delete": {
    LITHUANIAN: "Supratau, ištrinti",
    ENGLISH: "Understood, delete",
  },
  "Go back": {
    LITHUANIAN: "Grįžti",
    ENGLISH: "Go back",
  },
};
