import { option } from "../../components/UI/FormGenerator/formTypes";

export type perCustomerType = {
  name: string;
  surname: string;
  companyName: string;
  wasteId: option[];
  siteId: option[];
} & range;
export const perCustomerValue: perCustomerType = {
  companyName: "",
  endDate: "",
  name: "",
  siteId: [],
  startDate: "",
  surname: "",
  wasteId: [],
};
export type perSourceType = {
  wasteId: option[];
  siteId: option[];
  primarySource: option[];
} & range;
export const perSourceValue: perSourceType = {
  endDate: "",
  startDate: "",
  siteId: [],
  wasteId: [],
  primarySource: [],
};

export type perTypeType = {
  wasteId: option[];
  siteId: option[];
  isCompany: boolean | string;
  primarySource: option[];
} & range;
export const perTypeValue: perTypeType = {
  endDate: "",
  startDate: "",
  siteId: [],
  wasteId: [],
  isCompany: "",
  primarySource: [],
};

export type perMunicipalityType = {
  wasteId: option[];
  siteId: option[];
  municipalityId: option[];
} & range;
export const perMunicipalityValue: perMunicipalityType = {
  endDate: "",
  startDate: "",
  siteId: [],
  wasteId: [],
  municipalityId: [],
};

export type perDetailedReportType = {
  wasteId: option[];
  wasteHolderId: option[];
  companyHolderId: option[];
  siteId: option[];
  municipalityId: option[];
  originId: option[];
  primarySource: option[];
  billing: option[];
} & range;
export const perDetailedReportValue: perDetailedReportType = {
  endDate: "",
  startDate: "",
  wasteId: [],
  wasteHolderId: [],
  companyHolderId: [],
  // ** //
  siteId: [],
  municipalityId: [],
  originId: [],
  primarySource: [],
  billing: [],
};

export type perTransferReportType = {
  wasteId: option[];
  siteId: option[];
  recipient: option[];
  carrierId: option[];
  contractId: option[];
} & range;
export const perTansferReportVaue: perTransferReportType = {
  endDate: "",
  startDate: "",
  siteId: [],
  wasteId: [],
  recipient: [],
  carrierId: [],
  contractId: [],
};

export type perOriginType = {
  wasteId: option[];
  siteId: option[];
  primarySource: option[];
  municipalityId: option[];
} & range;
export const perOriginValue: perOriginType = {
  endDate: "",
  startDate: "",
  siteId: [],
  wasteId: [],
  primarySource: [],
  municipalityId: [],
};

export type perWasteType = {
  wasteId: option[];
  siteId: option[];
} & range;
export const perWasteValue: perWasteType = {
  endDate: "",
  startDate: "",
  siteId: [],
  wasteId: [],
};

export type perInvoiceType = {
  siteId: option[];
  invoice: option[];
} & range;
export const perInvoiceValue: perInvoiceType = {
  endDate: "",
  startDate: "",
  siteId: [],
  invoice: [],
};

export type perWeighingInvoiceType = {
  wasteId: option[];
  invoice: option[];
} & range;
export const perWeighingInvoiceValue: perWeighingInvoiceType = {
  endDate: "",
  startDate: "",
  wasteId: [],
  invoice: [],
};

export type perAnnualType = {
  year: number | string;
  wasteId: option[];
  siteId: option[];
  primarySource: option[];
};
export const perAnnualValue: perAnnualType = {
  year: "",
  siteId: [],
  wasteId: [],
  primarySource: [],
};

export type perGpaisReportType = {
  wasteId: option[];
  siteId: option[];
  wasteActivityTypeId: option[];
  performId: option[];
} & range;
export const perGpaisReport: perGpaisReportType = {
  endDate: "",
  startDate: "",
  siteId: [],
  wasteId: [],
  wasteActivityTypeId: [],
  performId: [],
};

export type perExchangeReportType = {
  siteId: option[];
} & range;
export const perExchangeReport: perExchangeReportType = {
  endDate: "",
  startDate: "",
  siteId: [],
};

export type range = {
  startDate: Date | string;
  endDate: Date | string;
};
