import * as actionTypes from "../actionTypes";

const initialState = {
  authToken: null,
  currentUser: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SIGNIN_SUCCEED:
      return {
        ...state,
        authToken: action.payload.authToken,
        currentUser: action.payload.user,
      };
    case actionTypes.SIGNOUT:
      return {
        ...state,
        authToken: null,
        currentUser: null,
      };
    default:
      return state;
  }
}

export default reducer;
