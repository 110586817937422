import { connect } from "react-redux";
import Profile from "./Profile";
import * as actions from "../../stores/actions";
import { person, personDocuments } from "../../@types/client";

type extPerson = person & { PersonDocuments: personDocuments[] };
const mapStoreStateToProps = (store: any) => ({
  savingStatus: store.personReducer.savingStatus,
  person: store.personReducer.person,
  documents: store.documentReducer.documents,
});

const mapDispatchToProps = (dispatch: (...arg: any[]) => void) => ({
  savePerson: (person: extPerson) => {
    dispatch(actions.savePerson(false, person));
  },
});

export default connect(mapStoreStateToProps, mapDispatchToProps)(Profile);
