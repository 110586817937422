import * as actionTypes from "../actionTypes";

export const getOrigins = function () {
  return { type: actionTypes.GET_ORIGINS };
};

export const getInactiveOrigins = () => ({
  type: actionTypes.GET_INACTIVE_ORIGINS,
});

export const deactivateOrigin = (payload) => ({
  type: actionTypes.DEACTIVATE_ORIGIN,
  payload,
});

export const hideOrigin = (payload) => ({
  type: actionTypes.HIDE_ORIGIN,
  payload,
});

export const restoreOrigin = (payload) => ({
  type: actionTypes.RESTORE_ORIGIN,
  payload,
});

export const createOrigin = (payload) => ({
  type: actionTypes.CREATE_ORIGIN,
  payload,
});

export const editOrigin = (payload) => ({
  type: actionTypes.EDIT_ORIGIN,
  payload,
});
