import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../../store";

import {
  getMunicipalities as get_municipalities,
  getInactiveMunicipalities as get_inactive_municipalities,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getMunicipalities = (action$) =>
  action$.pipe(
    ofType(actions.GET_MUNICIPALITIES),
    mergeMap(() =>
      FetchRX("/municipalities").pipe(
        map((result) => {
          return {
            type: actions.SET_MUNICIPALITIES,
            payload: result.municipalities,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.GET_MUNICIPALITIES_FAILED });
        })
      )
    )
  );

export const getInactiveMunicipalities = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_MUNICIPALITIES),
    mergeMap((action) =>
      FetchRX("/municipalities/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_MUNICIPALITIES,
            payload: result.municipalities,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateMunicipality = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_MUNICIPALITY),
    mergeMap((action) =>
      FetchRX("/municipalities/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_municipalities());
          store.dispatch(get_inactive_municipalities());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideMunicipality = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_MUNICIPALITY),
    mergeMap((action) =>
      FetchRX("/municipalities/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_municipalities());
          store.dispatch(get_inactive_municipalities());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreMunicipality = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_MUNICIPALITY),
    mergeMap((action) =>
      FetchRX("/municipalities/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_municipalities());
          store.dispatch(get_inactive_municipalities());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createMunicipality = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_MUNICIPALITY),
    mergeMap((action) =>
      FetchRX("/municipalities/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_MUNICIPALITIES,
            payload: result.municipalities,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editMunicipality = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_MUNICIPALITY),
    mergeMap((action) =>
      FetchRX("/municipalities/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_MUNICIPALITIES,
            payload: result.municipalities,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
