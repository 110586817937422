import React, { useEffect, useState } from "react";
import DeclarationDoc from "../../components/UI/Declaration/DeclarationDoc";
import { InvoicePopUpContainer } from "../../components/UI/InvoicePopUp/InvoicePopUp";
import { useAsync } from "../../helpers/asyncFunc";
import { wasteItem } from "../WasteAcceptance/defaultData";
import { acceptance, getClientWasteInfo } from "./getter";
import "./DocView.css";
import {
  Fetch,
  convertParamsObjectToString,
  convertToParamsObject,
  getUrl,
} from "../../helpers/misc";
import { site } from "../../@types/sites";
import NavSign from "../SignIn/NavSign";
import { Location, useLocation } from "react-router-dom";
import { accptQueryWasteType } from "../../@types/waste";

type allowedType = "invoice" | "declaration";
const DocView = ({
  type,
  isCompany,
  id,
  token,
}: {
  type: allowedType | null;
  isCompany: string | null;
  id: string | null;
  token: string | null;
}) => {
  const location = useLocation();
  const allowedTypes: allowedType[] = ["invoice", "declaration"];
  const [acceptance, setAcceptance] = useState<acceptance["acceptance"]>();
  //confirm token
  //fetch document

  const acceptanceRes = useAsync(
    {
      asyncFunc: getClientWasteInfo,
      funcParams: { id, isCompany, token },
      immediate: true,
      clearOnError: true,
    },
    [id, isCompany]
  );

  useEffect(() => {
    const acceptanceObj = acceptanceRes.data;

    setAcceptance(acceptanceObj?.acceptance);
  }, [acceptanceRes.data]);

  type verificationError = { verificationError: boolean };
  const verificationError = !!(
    acceptanceRes.errorObject as verificationError | null
  )?.verificationError;

  const searchObj = convertToParamsObject(location.search) as Record<
    string,
    string
  >;
  delete (searchObj as { token?: string })["token"];
  const search = convertParamsObjectToString(searchObj);

  const from: Location | null = verificationError
    ? {
        ...location,
        search,
      }
    : null;

  return allowedTypes.indexOf(type as allowedType) >= 0 && id ? (
    acceptanceRes.error ? (
      <NavSign from={from} />
    ) : acceptance ? (
      <DocSeparator
        token={token}
        acceptance={acceptance}
        type={type as allowedType}
      />
    ) : (
      <div />
    )
  ) : (
    <NavSign from={null} />
  );
};

export default DocView;

const DocSeparator = ({
  type,
  acceptance,
  token,
}: {
  type: allowedType;
  acceptance: acceptance["acceptance"];
  token: string | null;
}) => {
  const [allSites, setAllSites] = useState<site[]>([]);

  useEffect(() => {
    Fetch(
      "/sites/all",
      token ? { headers: { Authorization: `Bearer ${token}` } } : undefined
    )
      .then(async (res) => {
        const siteRes = (await res.json()) as { success: true; sites: site[] };
        setAllSites([...siteRes.sites]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDateconst = (date?: string): Date => {
    try {
      if (date) {
        const newDate = new Date(date);

        if (newDate.getTime() !== newDate.getTime()) {
          throw `Invalid date!`;
        }

        return newDate;
      } else {
        return new Date();
      }
    } catch (err) {
      return new Date();
    }
  };

  const convertToWasteItems = (
    wasteItems: accptQueryWasteType[]
  ): wasteItem[] => {
    return wasteItems.map((item) => ({
      code: item.code,
      date: item.date as unknown as Date,
      handlingCode: item.handlingCode,
      invoice: item.invoice,
      name: item.name,
      origin: item.origin,
      paidQuantity: item.paidQuantity,
      paymentDocument: item.paymentDocument,
      primarySource: item.primarySource,
      quantity: item.quantity,
      selectedWaste: item.selectedWaste,
      siteId: item.siteId,
      volume: item.volume,
      weight: item.weight,
      paidPrice_raw: item.paidPrice,
      paidPriceVat_raw: item.paidPriceVat,
      // hard-coded because it is of no significance to the readonly declaration/invoice files
      error: false,
      direct_vat_calc: true,
      freeLimit: null,
      maxLimit: null,
      criticalLimit: null,
      accumulationLimit: null,
      groupId: null,
      kgPerUnit: null,
      uom: "kg",
      hasUnit: false,
      compInvoice: false,
    }));
  };

  return type === "declaration" ? (
    <div className="docView_container">
      <DeclarationDoc
        sites={allSites}
        readOnly
        acceptanceId={acceptance.id}
        date={getDateconst(acceptance.date)}
        details={acceptance.client}
        imageUrl={
          acceptance.signature
            ? `${getUrl}/image/?address=${acceptance.signature}`
            : ``
        }
        isCompany={acceptance.isCompany}
        waste_list={convertToWasteItems(acceptance.wastes)}
      />
    </div>
  ) : type === "invoice" ? (
    <div className="docView_container">
      <InvoicePopUpContainer
        details={acceptance.client}
        isCompany={acceptance.isCompany}
        readOnly
        acceptanceId={acceptance.id}
        serija_doc_nr={acceptance.serija_doc_nr}
        date={acceptance.date}
        serija={acceptance.serija || ``}
        wasteItems={convertToWasteItems(acceptance.wastes)}
      />
    </div>
  ) : (
    <NavSign from={null} />
  );
};
