import * as actionTypes from "../actionTypes";

const initialState = {
  serijas: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SERIJAS:
      return {
        ...state,
        serijas: [...action.payload],
      };
    default:
      return state;
  }
}

export default reducer;
