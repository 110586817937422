import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const getSiteWastes = (action$) =>
  action$.pipe(
    ofType(actions.GET_SITE_WASTES),
    mergeMap((action) =>
      FetchRX(`/siteWastes/${action.payload}`).pipe(
        map((result) => {
          return {
            type: actions.SET_SITE_WASTES,
            payload: result.siteWastes,
          };
        }),
        catchError(() => {
          return of({ type: actions.GET_SITE_WASTES_FAILED });
        })
      )
    )
  );
