import * as actionTypes from "../actionTypes";

export const getGroups = function () {
  return { type: actionTypes.GET_GROUPS };
};

export const getInactiveGroups = () => ({
  type: actionTypes.GET_INACTIVE_GROUPS,
});

export const deactivateGroup = (payload) => ({
  type: actionTypes.DEACTIVATE_GROUP,
  payload,
});

export const restoreGroup = (payload) => ({
  type: actionTypes.RESTORE_GROUP,
  payload,
});

export const createGroup = (payload) => ({
  type: actionTypes.CREATE_GROUP,
  payload,
});

export const editGroup = (payload) => ({
  type: actionTypes.EDIT_GROUP,
  payload,
});

export const hideGroup = (payload) => ({
  type: actionTypes.HIDE_GROUP,
  payload,
});
