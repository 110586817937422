import { connect } from "react-redux";
import Serija from "./Serija";
import * as actions from "../../stores/actions";

const mapStoreStateToProps = (store: any) => ({
  sites: store.siteReducer.sites,
  serijas: store.serijaReducer.serijas,
});

const mapDispatchToProps = (dispatch: (...arg: any[]) => void) => ({
  getSites: () => {
    dispatch(actions.getSites());
  },
  getUserSites: function () {
    dispatch(actions.getUserSites());
  },
  createSerija: (createObj: { siteId: number; serija: string }) => {
    dispatch(actions.createSerija(createObj));
  },
  getAllSerijas: () => {
    dispatch(actions.getAllSerijas());
  },
  getSiteSerijas: (siteId: { siteId: number }) => {
    dispatch(actions.getAllSerijas(siteId));
  },
  makeSerijaActive: (activeObj: { siteId: number; serija: string }) => {
    dispatch(actions.makeSerijaActive(activeObj));
  },
  deleteSerija: (serija: { serija: string }) => {
    dispatch(actions.deleteSerija(serija));
  },
});

export default connect(mapStoreStateToProps, mapDispatchToProps)(Serija);
