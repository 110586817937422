import { connect } from "react-redux";
import AcceptanceForm from "./AcceptanceForm";

const mapStateToProps = (state: any) => ({
  currentSiteId: state.commonReducer.currentSiteId,
  municipalities: state.municipalityReducer.municipalities,
  primarySources: state.primarySourceReducer.primarySources,
  origins: state.originReducer.origins,
  wasteActivityTypes: state.wasteActivityTypeReducer.wasteActivityTypes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceForm);
