import React from "react";

import timesDark from "../../../assets/images/times-dark.png";
// import timesDark from "../../../assets/images/times-dark.png";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";
import "./style.scss";

const PopupFrame = function ({ isShown, children, onExit, yOffset }) {
  return (
    <>
      <div
        className={`popupframe-bg ${isShown ? "shown" : ""}`}
        onClick={onExit}
      />
      <div
        className={`popupframe-cnt ${isShown ? "shown" : ""}`}
        style={{ top: `calc(50% + ${yOffset}px)` }}
      >
        <div className="popUp_Close center" onClick={onExit}>
          <CloseIcon />
        </div>
        {children}
      </div>
    </>
  );
};

export default PopupFrame;
