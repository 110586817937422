import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../components/UI/FormGenerator/FormGenerator";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import { ReactComponent as CloseIcon } from "../../assets/images/closeSVG.svg";
import { ReactComponent as IconDrop } from "../../assets/images/down-arrow.svg";
import { ReactComponent as SuggestionIcon } from "../../assets/images/suggestion.svg";
import "./Suggestion.css";
import { Link, useLocation } from "react-router-dom";
import Cover from "../../components/UI/Cover/Cover";
import { suggestion, suggestionBody } from "./suggestionType";
import moment from "moment";
import { getUrl } from "../../helpers/misc";
import useTranslate from "../../translate/useTranslate";

const Suggestion = ({
  suggestions,
  suggestionText,
  setSuggestionText,
  createSuggestion,
  readSuggestion,
  unReadCount,
}: {
  suggestions: suggestion[];
  suggestionText: { title: string; body: string };
  setSuggestionText: (data: { title: string; body: string }) => void;
  createSuggestion: (body: suggestionBody) => void;
  readSuggestion: (id: number) => void;
  unReadCount: number;
}) => {
  const [showBalloon, setShowBalloon] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [unRead, setUnRead] = useState(false);
  const [create, setCreate] = useState(false);
  const [showShadow, makeShadow] = useState(false);

  useEffect(() => setUnRead(!!unReadCount), [unReadCount]);

  const [timeoutId, setTimeoutId] = useState<number | undefined>();

  const mountedRef = useRef(true);
  useEffect(() => {
    mountedRef.current = true;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setShowBalloon(true);
    const copyTimeout = window.setTimeout(() => {
      if (mountedRef.current) {
        setShowBalloon(false);
      }
    }, 3500);
    setTimeoutId(copyTimeout);
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const { t } = useTranslate();

  return (
    <div className="suggestion_wrapper">
      {showBalloon ? (
        <div className="sug_balloon_wrapper">
          <div className="sub_balloon_box"> {t("Something's wrong?")}</div>
        </div>
      ) : (
        ""
      )}
      {showBox && (
        <NewPopUpFrame
          showShadow={showShadow}
          handleOpen={setShowBox}
          isShown={showBox}
          title={
            <div className="suggestion_header_wrapper">
              <div
                onClick={() => setCreate(false)}
                className={`suggestion_header_item ${create ? `` : `active`}`}
              >
                <span>{t("Suggestions")}</span>
                {unRead ? <span className="red_dot"></span> : ``}
              </div>
              <div
                onClick={() => setCreate(true)}
                className={`suggestion_header_item ${create ? `active` : ``}`}
              >
                {t("New")}
              </div>
            </div>
          }
          full={true}
        >
          <div
            onScroll={(e) => {
              const Y: number = (e.target as HTMLElement).scrollTop;
              makeShadow(Y > 2);
            }}
            className="suggestion_body"
          >
            {create ? (
              <SuggestionForm
                suggestionText={suggestionText}
                setSuggestionText={setSuggestionText}
                createSuggestion={createSuggestion}
              />
            ) : (
              <SuggestionList
                setShowBox={setShowBox}
                readSuggestion={readSuggestion}
                suggestions={suggestions}
              />
            )}
          </div>
        </NewPopUpFrame>
      )}
      {unRead && <div className="float_notif"></div>}
      <div
        onClick={() => {
          setShowBox(!showBox);
          if (unRead) {
            setUnRead(false);
          }
        }}
        title={t("Suggestions")}
        className="suggestion_icon_wrapper"
      >
        <SuggestionIcon />
      </div>
    </div>
  );
};

export default Suggestion;

const SuggestionForm = ({
  createSuggestion,
  suggestionText,
  setSuggestionText,
}: {
  createSuggestion: (body: suggestionBody) => void;
  suggestionText: { title: string; body: string };
  setSuggestionText: (data: { title: string; body: string }) => void;
}) => {
  const { body, title } = suggestionText;
  const { t } = useTranslate();
  const [officer, setOfficer] = useState(``);
  const [images, setImages] = useState<File[]>([]);

  const imgRef = useRef<HTMLInputElement>(null);
  const allowedFormats = [`png`, `jpg`, `jpeg`, `gif`];
  const allowed = allowedFormats.map((string) => `image/${string}`).join(`, `);
  const isAllowed = (string: string[]) => {
    try {
      return string[0] === `image` && allowedFormats.includes(string[1]);
    } catch {
      return false;
    }
  };
  const path = useLocation().pathname;

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file =
      e.target?.files && e.target?.files[0]
        ? ([e.target.files[0]] as File[])
        : [];

    if (file[0] && isAllowed(file[0].type.split(`/`))) {
      setImages([...images, ...file]);
    }
    clearFileInput();
  };

  const submit = () => {
    if (title && body) {
      createSuggestion({
        title,
        officer,
        body,
        images,
        location: path,
      });
      setSuggestionText({
        body: ``,
        title: ``,
      });
      setImages([]);
      clearFileInput();
    }
  };

  const removeImage = (n: number) => {
    const img = images.filter((image, index) => index !== n);
    setImages([...img]);
    clearFileInput();
  };

  const clearFileInput = () => {
    const node = imgRef.current;
    try {
      //@ts-ignore
      node.value = null;
    } catch (ex) {}
    if (node?.value) {
      node?.parentNode?.replaceChild(node?.cloneNode(true), node);
    }
  };

  const dropFile = (e: React.DragEvent) => {
    e.preventDefault();
    (e.currentTarget as Element).classList.remove(`fileOver`);
    const file = [e.dataTransfer.files[0]];
    if (file[0] && isAllowed(file[0].type.split(`/`))) {
      setImages([...images, ...file]);
    }
    clearFileInput();
  };

  return (
    <div className="suggestion_form">
      <div className="suggestion_form_left">
        <div className="suggestion_form_cont">
          <span>{t("Title")} </span>
          <Input
            type={"text"}
            placeholder={""}
            error={false}
            value={title}
            externalValue={title}
            setExternalValue={(value) => {
              setSuggestionText({
                ...suggestionText,
                title: value,
              });
            }}
            handler={() => {}}
          />
        </div>
        {/* <div className="suggestion_form_cont">
          <span>For:</span>
          <Input
            type={"text"}
            placeholder={"Officer"}
            error={false}
            value={officer}
            externalValue={officer}
            setExternalValue={(value) => setOfficer(value)}
            handler={() => {}}
          />
        </div> */}
        <div className="suggestion_form_cont">
          <span>Pasiūlymas</span>
          <textarea
            value={body}
            onChange={(e) => {
              const body = e.target.value;
              setSuggestionText({
                ...suggestionText,
                body,
              });
            }}
          ></textarea>
        </div>
        <button
          onClick={submit}
          disabled={!title || !body}
          className="btn btn-green"
        >
          {t("Send suggestion")}
        </button>
      </div>
      <div className="suggestion_form_right">
        <div
          onDragOver={(e) => {
            e.preventDefault();
            (e.currentTarget as Element).classList.add(`fileOver`);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            (e.currentTarget as Element).classList.remove(`fileOver`);
          }}
          onDrop={(e) => dropFile(e)}
          className="suggestion_img_label_wrap"
        >
          <div className="suggestion_img_drop center">
            <span>{t("Drag file here")}</span>
            <p>{t("or")}</p>
          </div>
          <label htmlFor="inputFile" className="center">
            {t("Browse")}
          </label>
          <input
            ref={imgRef}
            onChange={onChangeImage}
            onBlur={clearFileInput}
            type="file"
            name="file"
            accept={allowed}
            id="inputFile"
          />
        </div>
        <div className="sug_img_form_wrap">
          {images.length
            ? images.map((image, index) => (
                <div key={index} className="sug_img_form">
                  <span
                    onClick={() => removeImage(index)}
                    className="float_icon center"
                  >
                    <CloseIcon />
                  </span>
                  <img
                    src={URL.createObjectURL(image)}
                    alt=""
                    className="img_div_cover"
                  />
                </div>
              ))
            : ``}
        </div>
      </div>
    </div>
  );
};

const SuggestionList = ({
  setShowBox,
  readSuggestion,
  suggestions,
}: {
  setShowBox: (data: boolean) => void;
  readSuggestion: (id: number) => void;
  suggestions: suggestion[];
}) => {
  const [open, setOpen] = useState<number | null>(null);
  const [main, setMain] = useState<number>(0);
  const [imageExp, setImgExpand] = useState<string | null>(null);
  const [suggestionList, setSuggestionList] = useState<suggestion[]>([]);

  useEffect(() => {
    if (suggestions && Array.isArray(suggestions)) {
      setSuggestionList([...suggestions]);
    }
  }, [suggestions]);

  const makeAsRead = (suggestion: suggestion) => {
    if (!suggestion.read) {
      readSuggestion(suggestion.id);
    }
  };

  const openDetails = (ind: number, sug: suggestion) => {
    if (open === ind) {
      setOpen(null);
    } else {
      setOpen(ind);
      makeAsRead(sug);
    }
  };

  return (
    <div className="suggestionList">
      {imageExp && (
        <ExpandImage
          handleOpen={(isShown) => {
            setImgExpand(isShown);
          }}
        >
          <img src={imageExp} alt="" className="img_div_contain" />
        </ExpandImage>
      )}
      {suggestions.length ? (
        <div className="suggestionList_wrapper">
          {suggestionList.map((sug, ind) => (
            <div
              key={ind}
              className={`suggestionList_item ${sug.read ? `read` : `unread`} ${
                open === ind ? `active` : ``
              }`}
            >
              <div
                onClick={() => {
                  openDetails(ind, sug);
                }}
                className="suggestionList_item_date"
              >
                {moment(sug.createdAt).format(`YYYY-MM-DD HH:mm`)}
              </div>
              <div
                onClick={() => {
                  openDetails(ind, sug);
                }}
                className="suggestionList_item_col"
              >
                <span className="msg_link ellipsis">
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowBox(false);
                    }}
                    to={sug.location}
                  >{`${window.location.host + sug.location}`}</Link>
                </span>
                <span className="ellipsis">{sug.title}</span>
                <span className="ellipsis">{sug.body}</span>
                <span
                  className={`ellipsis ${sug.images.length ? `img_num` : ``}`}
                >{`${
                  sug.images.length ? `${sug.images.length} image(s)` : ``
                }`}</span>
                <span className="msg_list_icon_wrap ellipsis">
                  <IconDrop />
                </span>
              </div>
              {open === ind && (
                <div className="suggestionList_item_msg">
                  {sug.images.length ? (
                    <div className="left_pane">
                      <Cover className="">
                        <div
                          onClick={() =>
                            setImgExpand(
                              `${getUrl}/image/?address=${sug.images[main]}`
                            )
                          }
                          className="suggestionList_item_img_lg"
                        >
                          <img
                            src={`${getUrl}/image/?address=${sug.images[main]}`}
                            alt=""
                            className="img_div_contain"
                          />
                        </div>
                      </Cover>
                      <div className="suggestionList_item_img_cont">
                        {(sug.images || []).map((images, index) => (
                          <React.Fragment key={index}>
                            <Cover
                              onClick={() => {
                                setMain(index);
                              }}
                              className={`suggestionList_item_img_sm center ${
                                index === main ? `active` : ``
                              }`}
                            >
                              <img
                                src={`${getUrl}/image/?address=${images}`}
                                alt=""
                                className="img_div_cover"
                              />
                            </Cover>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ``
                  )}
                  <div className="right_pane">
                    <div className="title">{sug.title}</div>
                    <div className="body">
                      {(sug.body || ``)
                        .split(/(?:\r\n|\n)/g)
                        .map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                    </div>
                    {/* <div className="officer">{sug.officer}</div> */}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        ``
      )}
    </div>
  );
};

const ExpandImage = ({
  handleOpen,
  children,
}: {
  handleOpen: (url: null) => void;
  children: any;
}) => {
  const padNum = (width: number): number => {
    if (width >= 1200) {
      return 0.18 * width;
    } else if (width >= 1080) {
      return 0.1 * width;
    } else {
      return 48;
    }
  };
  const [padding, setPadding] = useState(padNum(window.outerWidth));
  const handleResize = () => {
    setPadding(padNum(window.outerWidth));
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    setPadding(padNum(window.outerWidth));
  }, []);

  return (
    <div
      style={{
        paddingLeft: `${padding}px`,
        paddingRight: `${padding}px`,
      }}
      className={`exp_img_wrap`}
    >
      <div className="newPopUp_float" onClick={() => handleOpen(null)}></div>
      <div className={`flex1 exp_img_container fdCol`}>
        <div
          className="exp_img_icon_cont center"
          onClick={() => handleOpen(null)}
        >
          <CloseIcon />
        </div>

        {children}
      </div>
    </div>
  );
};
