import * as actionTypes from "../actionTypes";

export const saveWasteAccumulation = function (wasteAccumulation) {
  return {
    type: actionTypes.SAVE_WASTE_ACCUMULATION,
    payload: wasteAccumulation,
  };
};

export const resetWasteAccumulationSavingStatus = function () {
  return { type: actionTypes.RESET_WASTE_ACCUMULATION_SAVING_STATUS };
};
