import { connect } from "react-redux";

import WasteAcceptance from "./WasteAcceptance";

const mapStoreStateToProps = function (store: any) {
  return {
    currentUser: store.authReducer.currentUser,
    currentSiteId: store.commonReducer.currentSiteId,
    sites: store.siteReducer.sites,
    origins: store.originReducer.origins,
  };
};
export default connect(mapStoreStateToProps)(WasteAcceptance);
