import * as d3 from "d3-dsv";
import xml2js from "xml2js";
import { Fetch, isNumber } from "../../helpers/misc";
import { clientWaste as ext_clientWaste } from "../../@types/waste";

export type csvIntegrationType = "deska" | "bilance";
export type integrationType = "kemek" | csvIntegrationType;

const removeQuotes = (chunk: string, remove: boolean) => {
  return remove ? chunk.replace(/"/g, "") : chunk;
};

export const getHandlingCodes = async () => {
  return await Fetch("/clientWastes/handlingCodes")
    .then((res) => {
      const codeRes = res.json() as Promise<{
        success: boolean;
        handlingCodes: { name: string }[];
      }>;
      return codeRes;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const submitIntegrationRecords = async (params: {
  data?: any[];
  type?: integrationType;
}) => {
  const { data, type } = params;

  if (!data && !type) {
    return;
  }

  return await Fetch(`/integrations/create?type=${type}`, {
    body: JSON.stringify({ data }),
    method: "POST",
  })
    .then((result) => {
      const intResult = result.json() as Promise<{
        type: integrationType;
      }>;

      return intResult;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const csvParser = async (data: string, type: csvIntegrationType) => {
  const delimiter = type === "bilance" ? ";" : ",";
  const hasQuotes = type === "deska" ? true : false;
  const parse = d3.dsvFormat(delimiter);

  try {
    const intData = parse.parse(removeQuotes(data, hasQuotes));
    if (type === "bilance") {
      const isComplete = isBilance(intData);
      if (isComplete) {
        return intData;
      } else {
        throw new Error("Invalid doc parsed");
      }
    } else if (type === "deska") {
      const isComplete = isDeska(intData);
      if (isComplete) {
        return intData;
      } else {
        throw new Error("Invalid doc parsed");
      }
    } else {
      throw new Error("Invalid doc parsed");
    }
  } catch (e) {
    throw e;
  }
};

export const xmlParser = async (data: string) => {
  try {
    var parser = new xml2js.Parser({ explicitArray: false });
    const result = (await parser.parseStringPromise(data)) as any;
    return result.KEMEK_Scales_Data_Export_File.operacija;
  } catch (e) {
    throw e;
  }
};

/**
 * export type bilance = {
  AMOUNT_DISCOUNT?: string; // number
  AMOUNT_GROSS: string; // number
  AMOUNT_INCL_TAX: string; // number
  AMOUNT_NET: string; // number
  AMOUNT_TAX?: string; // number
  CHANGED: string; // Date
  CLOSED: string; // Date
  CONTRACT?: string;
  CORRECTED_LOCATION?: string;
  CORRECTED_TICKETNO?: string; // number
  CUSTOMER: string; // ****
  DATASYNC: string; // Date // ****
  DATASYNCED: string; // number
  DESTINATION: string;
  DIRECTED_NET: string; // number
  DIRECTION: string;
  DISCOUNT?: string; // number
  DISCOUNT_PERCENTAGE?: string; // number
  HAULIER: string; // ****
  IS_MERGED: string; // number possibly indicates that 0 is false and 1 is true
  LAST_ERROR?: string;
  LOCATION: string; // number possiby ID
  METHOD_OF_PAYMENT?: string;
  NET: string; // number // ****
  NOTES: string;
  OPERATOR_1: string;
  OPERATOR_2: string;
  ORIGINAL_LOCATION: string; // number possibly // ****
  ORIGINAL_TICKETNO: string; // number
  PARTWEIGHING: string; // number
  PRICE: string; // number
  PRODUCT: string; // ****
  QUANTITY?: string; // number
  SCALE_1: string;
  SCALE_2: string;
  SELECTED_SCALE?: string;
  SEQNO_1: string; // number
  SEQNO_2: string; // number
  SOURCE: string; // ****
  STATUS: string;
  SUPPLIER?: string;
  TARE: string; // number
  TARE_1?: string;
  TARE_2?: string;
  TAX_PERCENTAGE?:string; // number
  TICKETNO: string; // number
  TIMESTAMP_1: string; // Date
  TIMESTAMP_2: string; // Date
  UNIT_OF_SALE: string;
  USERID: string;
  VEHICLE: string;
  WEIGHT_1: string; // number
  WEIGHT_2: string; // number
  Z_INCOMPLETE: string; // number
};
 */

export type integrationTypeObj = {
  id: number;
  name: integrationType;
  docType: "xml" | "csv";
};

export const getIntegrationType = async () => {
  return await Fetch("/integrations")
    .then((res) => {
      const types: Promise<{
        types: {
          id: number;
          name: integrationType;
          docType: "xml" | "csv";
        }[];
      }> = res.json();
      return types;
    })
    .catch((err) => {
      throw err;
    });
};

export const isDeska = (data: object[]): boolean => {
  return false;
};

export const isKemek = (data: object[]) => {
  return false;
};

export const isBilance = (data: object[]) => {
  try {
    const bilanceData = data[0];
    if (!bilanceData || typeof bilanceData !== "object") {
      return false;
    } else {
      const bilanceKeys = [
        "DATASYNC",
        "PRODUCT",
        "NET",
        "ORIGINAL_LOCATION",
        "HAULIER",
        "SOURCE",
        "CUSTOMER",
      ];

      const hasError = bilanceKeys.some((keys) => {
        return Object.keys(bilanceData).indexOf(keys) < 0;
      });

      return !hasError;
    }
  } catch {
    return false;
  }
};

export type clientWaste = ext_clientWaste & {
  billing: string | null;
  municipalityName: string | null;
  carrier: string | null;
  originName: string;
  siteName: string;
  wasteName: string;
  primarySourceName: string | null;
  holder: string;
  handlingCode: string | null;
  driver: string | null;
  uom: string;
  kgPerUnit: number | null;
  serija: string | null;
};

export const editClientWasteRecord = async (
  params: Partial<{
    filter: { [key: string]: any };
    weight: number;
    quantity: number | null;
    clientWasteId: number;
    userId: number;
  }>
) => {
  const { clientWasteId, filter, userId, weight } = params;
  if (!filter || !isNumber(weight) || !clientWasteId || !userId) {
    throw new Error("Incomplete details sent");
  }

  return await Fetch("/clientWastes", {
    method: "PUT",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const records = res.json() as Promise<{
        success: true;
      }>;
      return records;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteClientWasteRecord = async (
  params: Partial<{
    filter: { [key: string]: any };
    clientWasteId: number;
    userId: number;
  }>
) => {
  const { clientWasteId, filter, userId } = params;
  if (!filter || !clientWasteId || !userId) {
    throw new Error("Incomplete details sent");
  }

  return await Fetch("/clientWastes/delete", {
    method: "DELETE",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const records = res.json() as Promise<{
        success: true;
      }>;
      return records;
    })
    .catch((err) => {
      throw err;
    });
};
