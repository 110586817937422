import * as actionTypes from "../actionTypes";

const initialState = {
  formations: [],
  inactiveFormations: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_FORMATIONS:
      return {
        ...state,
        formations: action.payload,
      };
    case actionTypes.SET_INACTIVE_FORMATIONS:
      return {
        ...state,
        inactiveFormations: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
