import * as actionTypes from "../actionTypes";

export const setCurrentSite = function (siteId) {
  return { type: actionTypes.SET_CURRENT_SITE, payload: siteId };
};

export const setLoader = (isLoading) => {
  return { type: actionTypes.IS_LOADING, payload: isLoading };
};

export const setNotification = (type, txt) => {
  return { type: actionTypes.SET_NOTIFICATION, payload: { type, txt } };
};

export const setConfirmDialog = (txt) => {
  return { type: actionTypes.SET_CONFIRM_DIALOG, payload: { txt } };
};

export const setConfirmDialogYes = () => {
  return { type: actionTypes.SET_CONFIRM_DIALOG_YES };
};

export const resetConfirmDialog = () => {
  return { type: actionTypes.RESET_CONFIRM_DIALOG };
};

export const changeLocale = (locale) => ({
  type: actionTypes.CHANGE_LOCALE,
  payload: locale,
});
