import React, { useEffect, useState } from "react";

import { InputText } from "../../../../UI";
import CheckBox from "../../../../UI/checkBox/checkBox";
import "./style.scss";
import useTranslate from "../../../../../translate/useTranslate";

const UserGroupForm = function ({
  currentUser,
  formType,
  userGroup,
  onExit,
  save,
}) {
  const { t } = useTranslate();
  const defaultForm = { name: "", isActive: false };
  const [formData, setFormData] = useState(defaultForm);
  const [formError, setFormError] = useState({ name: false, isActive: false });

  useEffect(() => {
    const _userGroup = { ...userGroup };
    _userGroup.userId = currentUser.id;

    updateFormData(_userGroup);
  }, [userGroup]);

  const exit = () => {
    setFormData({ ...defaultForm });
    onExit();
  };

  const updateFormData = (objData) => {
    const _formData = { ...formData };
    const _formError = { ...formError };

    for (const key in objData) {
      _formData[key] = objData[key];
      _formError[key] = false;
    }

    setFormData(_formData);
    setFormError(_formError);
  };

  const btnSaveOnClick = () => {
    save({ ...formData, formType });
    exit();
  };

  return (
    <div className="user-group-form">
      <h5>{t("EMPLOYEE GROUPING CREATION")}</h5>
      <div className="form-group title">
        <label>{t("Name of the working group")}</label>
        <div className="input-group">
          <InputText
            value={formData.name}
            onChange={(e) => updateFormData({ name: e.target.value })}
          />
        </div>
      </div>
      <div className="form-group cb">
        <label>{t("Active")}</label>
        <div className="input-group">
          <CheckBox
            readOnly={false}
            isChecked={!!formData.isActive}
            onChange={(e) => updateFormData({ isActive: !formData.isActive })}
          />
        </div>
      </div>

      <div className="action-btns">
        <button type="button" className="btn btn-default" onClick={exit}>
          {t("Cancel")}
        </button>
        <button
          type="button"
          className="btn btn-green"
          onClick={btnSaveOnClick}
        >
          {t("Save")}
        </button>
      </div>
    </div>
  );
};

export default UserGroupForm;
