import { delivery } from "../../../pages/Disposal/Disposal";

export const defaultFormError = {
  recipientId: false,
  contractNr: false,
  disposalSite: false,
  carrierName: false,
  licensePlate: false,
  disposalDate: false,
  additionalInfo: false,
  recipientEmail: false,
  recipientCompanyCode: false,
  recipientAddress: false,
  disposalCompanyCode: false,
  disposalAddress: false,
  carrierId: false,
  carrierCompanyCode: false,
  driverFirstName: false,
  driverLastName: false,
};

export type disposalInit = {
  id: "" | number;
  formNr: "" | number;
  recipientId: "" | number;
  recipientName: string;
  recipientCompanyCode: string;
  recipientAddress: string;
  contractNr: string;
  disposalSite: "" | number;
  disposalSiteName: string;
  disposalCompanyCode: string;
  disposalAddress: string;
  carrierId: "" | number;
  carrierName: string;
  carrierCompanyCode: string;
  licensePlate: string;
  disposalDate: string;
  additionalInfo: string;
  recipientEmail: string;
  driverFirstName: string;
  driverLastName: string;
  disposalWastes: delivery[] | null;
  isEdit: boolean;
  transferType: "OUTER" | "INNER";
};

export const defaultFormData: disposalInit = {
  id: "",
  formNr: "",
  recipientId: "",
  recipientName: "",
  recipientCompanyCode: "",
  recipientAddress: "",
  contractNr: "",
  disposalSite: "",
  disposalSiteName: "",
  disposalCompanyCode: "",
  disposalAddress: "",
  carrierId: "",
  carrierName: "",
  carrierCompanyCode: "",
  licensePlate: "",
  disposalDate: "",
  additionalInfo: "",
  recipientEmail: "",
  driverFirstName: "",
  driverLastName: "",
  disposalWastes: [],
  isEdit: false,
  transferType: "OUTER",
};
