import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

import { DateTimePicker } from "../../../../components/UI";

import "./style.scss";
import DropDown from "../../../../components/elements/DropDown/DropDown";
import { site } from "../../../../@types/sites";
import { option } from "../../../../components/UI/FormGenerator/formTypes";
import { defaultOption } from "../../../../helpers/misc";
import { Input } from "../../../../components/UI/FormGenerator/FormGenerator";
import { defaultFormData } from "../../WasteAccumulation";
import { useAsync } from "../../../../helpers/asyncFunc";
import { getSiteWastes } from "../../../../components/elements/AcceptanceForm/helper";
import useTranslate from "../../../../translate/useTranslate";

type defaultFormError = {
  [key in keyof Omit<
    Partial<defaultFormData>,
    "id" | "notes" | "isEdit"
  >]: boolean;
};
const defaultFormError: defaultFormError = {
  siteId: false,
  date: false,
  wasteId: false,
  weight: false,
};

export const defaultDropDownStatus = {
  siteId: false,
  wasteId: false,
};

const WasteAccumulationForm = function ({
  formData,
  setFormData,
  sites,
  saveWasteAccumulation,
  onExit,
}: {
  formData: defaultFormData;
  setFormData: (formData: Partial<defaultFormData>) => void;
  isVisible: boolean;
  sites: site[];
  saveWasteAccumulation: (data: {
    id?: number;
    siteId: number;
    date: string;
    wasteId: number;
    weight: number;
    notes: string;
  }) => void;
  onExit: () => void;
}) {
  const { t } = useTranslate();
  const siteWasteRes = useAsync(
    {
      asyncFunc: getSiteWastes,
      funcParams: { siteId: formData.siteId },
      immediate: true,
    },
    [formData.siteId]
  );

  const siteWastes = siteWasteRes.data?.siteWastes || [];

  const [formError, setFormError] = useState<defaultFormError>({
    ...defaultFormError,
  });
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [wasteOptions, setWasteOptions] = useState<option[]>([]);
  const [theDate, setTheDate] = useState("");

  useEffect(() => {
    document.addEventListener("keyup", closeForm, false);

    return () => {
      document.removeEventListener("keyup", closeForm, false);
    };
  }, []);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const options = sites.map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  useEffect(() => {
    if (theDate) {
      const dateObj = moment(new Date(theDate));
      updateFormData({ date: dateObj.format("YYYY-MM-DD") });
    }
  }, [theDate]);

  useEffect(() => {
    if (siteWastes && siteWastes.length > 0) {
      const options = siteWastes.map((siteWaste) => {
        return {
          label: `${siteWaste.Waste.code} ${siteWaste.Waste.waste}`,
          value: siteWaste.Waste.id,
        };
      });

      setWasteOptions(options);
    }
  }, [JSON.stringify(siteWastes)]);

  const closeForm = useCallback((event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      onExitHandler();
    }
  }, []);

  const updateFormData = (objData: Partial<defaultFormData>) => {
    const _formError = { ...formError };
    for (const Key in objData) {
      const key = Key as keyof defaultFormError;
      _formError[key] = false;
    }
    setFormData({ ...formData, ...objData });
    setFormError(_formError);
  };

  const onExitHandler = () => {
    setFormData({ ...defaultFormData });
    setFormError({ ...defaultFormError });
    onExit();
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !formData.siteId ||
      !formData.date ||
      !formData.wasteId ||
      !parseFloat(`${formData.weight}`)
    ) {
      const _formError = { ...formError };

      if (!formData.siteId) {
        _formError.siteId = true;
      }

      if (!formData.date) {
        _formError.date = true;
      }

      if (!formData.wasteId) {
        _formError.wasteId = true;
      }

      if (!parseFloat(`${formData.weight}`)) {
        _formError.weight = true;
      }

      setFormError(_formError);

      return;
    }
    //ensure complete
    saveWasteAccumulation({ ...formData });
    resetForm();
  };

  const getOption = (value: number | undefined, options: option[]): option => {
    const option = options.find((item) => item.value === value);

    return option || defaultOption;
  };

  const resetForm = () => {
    setFormData({ ...defaultFormData });
    setFormError({ ...defaultFormError });
  };

  return (
    <div className="waste-accumulation-form">
      <form onSubmit={onSubmitHandler}>
        <div className="form-row grid2">
          <div className="cell-grid">
            <label className={formError.siteId ? "txt-error" : ""}>
              {t("Area")}
            </label>
            <DropDown
              clearable
              options={siteOptions}
              value={getOption(formData.siteId, siteOptions)}
              onSelect={(site) => {
                const selectedValue = site.value;
                // clear wasteId on every site change
                setWasteOptions([]);
                updateFormData({ siteId: selectedValue, wasteId: undefined });
              }}
              border={""}
              title={t("Search...")}
              error={!!formError.siteId}
            />
          </div>
          <div className="cell-grid">
            <label className={formError.siteId ? "txt-error" : ""}>
              {t("Date")}
            </label>
            <DateTimePicker
              value={formData.date}
              dateFormat={"Y-m-d"}
              onChange={(_, dt: string) => setTheDate(dt)}
              placeholder={`${t("Select")}...`}
              className={formError.date ? "both-error" : ""}
              options={{}}
            />
            <div
              className={`calendar-icon ${formError.date ? "both-error" : ""}`}
            ></div>
          </div>
        </div>
        <div className="form-row grid2">
          <div className="cell-grid">
            <label className={!!formError.wasteId ? "txt-error" : ""}>
              {t("Wastes")}
            </label>
            {formData.siteId ? (
              <DropDown
                clearable={true}
                options={wasteOptions}
                value={getOption(formData.wasteId, wasteOptions)}
                onSelect={(site) => {
                  const selectedValue = site.value;
                  updateFormData({ wasteId: selectedValue });
                }}
                border={""}
                title={t("Search...")}
                error={!!formError.wasteId}
              />
            ) : (
              <Input
                value={""}
                placeholder={""}
                handler={() => {}}
                error={false}
                type={"number"}
                disabled={true}
                externalValue={""}
                setExternalValue={() => {}}
              />
            )}
          </div>
          <div className="cell-grid">
            <label className={formError.weight ? "txt-error" : ""}>
              {t("Quantity")}, t
            </label>
            <Input
              value={`${formData.weight || ""}`}
              placeholder={""}
              handler={() => {}}
              error={false}
              type={"number"}
              externalValue={`${formData.weight || ""}`}
              setExternalValue={(weight) => {
                updateFormData({ weight: weight as unknown as number });
              }}
            />
          </div>
        </div>
        <div className="form-row grid1">
          <div className="cell-grid">
            <label>{t("Note")}</label>
            <textarea
              value={formData.notes}
              onChange={(e) => updateFormData({ notes: e.target.value })}
            ></textarea>
          </div>
        </div>
        <div className="form-row grid1">
          <div className="cell-grid flex-row-all-center">
            <button type="submit" className="btn-save">
              {t("Confirm")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WasteAccumulationForm;
