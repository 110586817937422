import { useAuth } from "../../App";
import { Location, Navigate, useLocation } from "react-router-dom";

const NavSign = ({ from }: { from?: Location | null }) => {
  const auth = useAuth();
  const Location = useLocation();

  const location = from === null ? undefined : from || Location;

  return auth.currentUser ? (
    <Navigate to={"/"} state={{ from: location }} />
  ) : (
    <Navigate to={"/signin"} state={{ from: location }} />
  );
};

export default NavSign;
