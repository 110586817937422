import React from "react";
import { option } from "../FormGenerator/formTypes";

import { CloseLogo } from "../iconContainer/iconContainer";
import { ReactComponent as CloseCirleLogo } from "../../../assets/images/close-circle.svg";
import useTranslate from "../../../translate/useTranslate";

const FilterButtons = <T extends { [key: string]: any }>({
  filter,
  defaultFilter,
  setFilter,
  exclude,
  multiFilters,
}: {
  filter: T;
  defaultFilter: T;
  setFilter: (key: any) => void;
  exclude: (keyof T)[];
  multiFilters: (keyof T)[];
}) => {
  const { t } = useTranslate();
  const removeFilter = (key: string, subkey: any) => {
    const _filter = { ...filter };
    if (multiFilters.indexOf(key) >= 0) {
      _filter[key as keyof T] =
        _filter[key]?.filter((x: option) => x.value !== subkey) || [];
    } else {
      // @ts-ignore
      _filter[key as keyof T] = "";
    }
    setFilter(_filter);
  };

  const clearFilter = () => {
    setFilter({ ...defaultFilter });
  };

  const _filterButton = (key: string, title: string, subkey?: number) => (
    <div key={key + (subkey || "")} className="btn_filter_wrapper">
      <button
        type="button"
        key={`btn-${subkey ? subkey : key}`}
        className="btn-filter"
        onClick={() => removeFilter(key, subkey)}
      >
        <span className="txt">{title}</span>
        <CloseLogo onClick={() => removeFilter(key, subkey)} />
      </button>
    </div>
  );

  const filterButtons = () => {
    const buttons = [];
    for (let key in filter) {
      if (
        key &&
        filter[key as keyof typeof filter] &&
        filter[key as keyof typeof filter] !== "0" &&
        exclude.indexOf(key) < 0
      ) {
        if (multiFilters.indexOf(key) >= 0) {
          filter[key].forEach((option: option) => {
            buttons.push(_filterButton(key, option.label, option.value));
          });
        } else if (key === "date") {
          if (filter.date.startDate && filter.date.endDate) {
            const title = `${filter.date.startDate} - ${filter.date.endDate}`;
            buttons.push(_filterButton(key, title));
          }
        } else {
          buttons.push(_filterButton(key, filter[key]));
        }
      }
    }

    return buttons;
  };

  const isFiltered = () => {
    for (let key in filter) {
      if (
        (exclude.indexOf(key) < 0 &&
          filter[key as keyof typeof filter] &&
          filter[key as keyof typeof filter] !== "0" &&
          key !== "date") ||
        (key === "date" && filter.date.startDate && filter.date.endDate)
      ) {
        if (Array.isArray(filter[key])) {
          return !!filter[key].length;
        } else {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <>
      {filterButtons()}{" "}
      {isFiltered() && (
        <button className="bare-btn btn-clear-filter" onClick={clearFilter}>
          <span className="bg">
            <CloseCirleLogo className="close-circle" />
          </span>
          <span className="txt">{t("Clear filters")}</span>
        </button>
      )}
    </>
  );
};

export default FilterButtons;
