import React from "react";
import { ReactComponent as CloseSvg } from "../../../assets/images/closeSVG.svg";
import "./NewConfirmDialog.css";
import loaderSvg from "../../../assets/images/loader.svg";
import useTranslate from "../../../translate/useTranslate";

const NewConfirmDialog = function ({
  txt,
  isVisible,
  onExit,
  onContinue,
  isLoading,
  returnText,
  continueText,
}: {
  txt: string;
  isVisible: boolean;
  onExit: () => void;
  onContinue: () => void;
  isLoading: boolean;
  returnText?: string;
  continueText?: string;
}) {
  const { t } = useTranslate();
  return (
    <div
      onClick={onExit}
      className={`newConfirmDialog_wrap ${isVisible ? "show" : ""}`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="newConfirmDialog_container"
      >
        <div onClick={onExit} className="close_container">
          <CloseSvg />
        </div>
        <p>{txt}</p>
        <div className="newConfirmDialog_btn_container">
          <button className="btn btn-default" onClick={() => onExit()}>
            {returnText ? returnText : t("Cancel")}
          </button>
          <button className="btn btn-green" onClick={() => onContinue()}>
            {continueText ? continueText : t("Yes")}
            {isLoading && (
              <span>
                <img src={loaderSvg} alt="" className="img_div_contain" />
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewConfirmDialog;
