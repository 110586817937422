import { Fetch } from "../../../../../helpers/misc";

export type restoreObjType = {
  params: { [key: string]: string };
  userActivityId: number;
  userId?: number;
  short: string;
};

export const restore_activity = async (obj: restoreObjType | undefined) => {
  return await Fetch("/userActivities/restore", {
    body: JSON.stringify({ ...obj }),
    method: "PUT",
  })
    .then(async (res) => {
      const result = res.json() as Promise<{
        success: true;
      }>;

      return result;
    })
    .catch((err) => {
      throw err;
    });
};
