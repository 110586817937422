import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { defaultFormData, defaultFormError } from "./defaultData";
import { FeaturePermission, SiteSelection } from "..";

import "./style.scss";
import { Input } from "../../../../UI/FormGenerator/FormGenerator";
import DropDown from "../../../DropDown/DropDown";
import {
  defaultOption,
  isStrongPassword,
  rearrangeRole,
} from "../../../../../helpers/misc";
import { site } from "../../../../../@types/sites";
import { Features } from "../../../../../@types/feature";
import { option } from "../../../../UI/FormGenerator/formTypes";
import { userFormData, userGroup } from "../../../../../@types/user";
import NewPopUpFrame from "../../../../UI/PopupFrame/NewPopUpFrame";
import useTranslate from "../../../../../translate/useTranslate";
import floatInfo from "../../../../UI/floatInfo/floatInfo";
import { detail_partial_user } from "../UserDetail/UserDetail";
import { ReactComponent as Info } from "../../../../../assets/images/info.svg";
import IconContainer from "../../../../UI/iconContainer/iconContainer";
import { useAsync } from "../../../../../helpers/asyncFunc";
import { saveUserDetails } from "../../UserManagement";
import { isEmail } from "../../../../../helpers/validate";

export type userData = Omit<Partial<userFormData>, "sites"> & {
  sites: number[];
};

export const ext_updateFormData = <T extends Object>(
  objData: Partial<T>,
  {
    setFormData,
    setFormError,
    formData,
    formError,
  }: {
    setFormData: React.Dispatch<T>;
    setFormError: React.Dispatch<{ [key in keyof T]?: boolean }>;
    formData: T;
    formError: { [key in keyof T]?: boolean };
  }
) => {
  const _formError = { ...formError };

  for (const k in objData) {
    const key = k;

    if (k === "paswd") {
      _formError[key] = objData[k]
        ? !isStrongPassword(String(objData[k]) || "")
        : true;
    } else {
      _formError[key] = false;
    }
  }

  setFormData({ ...formData, ...objData });
  setFormError(_formError);
};

const UserForm = function ({
  sites,
  features,
  roles,
  userGroups,
  id,
}: {
  sites: site[];
  features: Features[];
  roles: { id: number; name: string }[];
  userGroups: userGroup[];
  id: number;
}) {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<
    Omit<Partial<userFormData>, "id"> & { id: number }
  >({
    ...defaultFormData,
    id,
  });
  const [formError, setFormError] = useState<{
    [key in keyof userFormData]?: boolean;
  }>({ ...defaultFormError });
  const [siteSelectionShown, setSiteSelectionShown] = useState(false);
  const [featurePermissionShown, setFeaturePermissionShown] = useState(false);
  const [paswdConfirm, setPaswdConfirm] = useState("");

  const updateFormData = (objData: Partial<userFormData>) => {
    ext_updateFormData(objData, {
      formData,
      formError,
      setFormData,
      setFormError,
    });
  };

  const errorDetails = (
    keys: (keyof userFormData)[] = [
      "id",
      "uname",
      "email",
      "paswd",
      "firstName",
      "lastName",
      "roleId",
      "userGroupId",
    ]
  ) => {
    let error: Partial<typeof formError> = {};

    keys.forEach((key) => {
      const value = formData[key];
      if (key === "paswd") {
        if (
          formData.paswd !== paswdConfirm ||
          !formData.paswd ||
          !isStrongPassword(formData.paswd)
        ) {
          error = { ...error, [key]: true };
        }
      } else if(key === "email"){
        if(!isEmail((value) ? String(value): "" )) {
          error = { ...error, [key]: true };
        }
      } else{
        if (!value) {
          error = { ...error, [key]: true };
        }
      }
    });

    error = { ...error };

    const hasError = Object.keys(error).some((k) => {
      const key = k as keyof userFormData;
      return !!error[key];
    });

    return { hasError, error };
  };

  const showSiteSelection = () => {
    const { hasError, error } = errorDetails();
    if (hasError) {
      setFormError({ ...error });
      return;
    } else {
      setSiteSelectionShown(true);
    }
  };

  const compKeys: (keyof userFormData)[] = [
    "firstName",
    "id",
    "lastName",
    "paswd",
    "roleId",
    "userGroupId",
    "sites",
  ];

  const showFeaturePermission = () => {
    const { hasError } = errorDetails(compKeys);

    if (hasError) {
      return;
    } else {
      setFeaturePermissionShown(true);
      setSiteSelectionShown(false);
    }
  };

  const saveUserRes = useAsync({
    asyncFunc: saveUserDetails,
    funcParams: {
      user: {
        ...formData,
        sites: (formData.sites || []).map((site) => site.id),
      },
      type: "new",
    },
    immediate: false,
  });

  useEffect(() => {
    if (saveUserRes.data?.saved) {
      setFeaturePermissionShown(false);
      navigate("/settings/staff", { replace: true });
    }
  }, [saveUserRes.data?.saved]);

  const actualSave = () => {
    const { hasError } = errorDetails(compKeys);
    if (hasError) {
      return;
    } else {
      // Reconvert sites to array of ids
      const data: userData = {
        ...formData,
        sites: (formData.sites || []).map((site) => site.id),
      };

      saveUserRes.execute({ type: "new", user: data });
    }
  };

  return (
    <>
      <UserFormFields
        formData={formData}
        formError={formError}
        paswdConfirm={paswdConfirm}
        roles={roles}
        setPaswdConfirm={setPaswdConfirm}
        setFormData={setFormData}
        setFormError={setFormError}
        userGroups={userGroups}
        ButtonElement={
          <div className="user_form_btn_container">
            <div className="actions">
              <button type="button" className="btn btn-custom">
                {t("Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-green"
                onClick={showSiteSelection}
              >
                {t("Next")}
              </button>
            </div>
          </div>
        }
      />

      {siteSelectionShown && (
        <NewPopUpFrame
          isShown={siteSelectionShown}
          handleOpen={setSiteSelectionShown}
          title={t("Sites").toUpperCase()}
          showShadow={false}
        >
          <SiteSelection
            sites={sites}
            formData={formData}
            onCancel={() => setSiteSelectionShown(false)}
            onNext={() => showFeaturePermission()}
            updateFormData={updateFormData}
          />
        </NewPopUpFrame>
      )}

      {featurePermissionShown && (
        <NewPopUpFrame
          classNameWrap="feature_popup_wrap"
          isShown={featurePermissionShown}
          handleOpen={(val) => {
            if (saveUserRes.loading) {
              return;
            }
            setFeaturePermissionShown(val);
          }}
          title={t("Employee's Access Control")}
          showShadow={false}
        >
          <FeaturePermission
            features={features}
            formData={formData}
            onCancel={() => setFeaturePermissionShown(false)}
            updateFormData={updateFormData}
            onSave={() => actualSave()}
            loading={saveUserRes.loading}
          />
        </NewPopUpFrame>
      )}
    </>
  );
};

export default UserForm;

export const UserFormFields = ({
  roles,
  userGroups,
  ButtonElement,
  setFormData,
  setFormError,
  formData,
  formError,
  setPaswdConfirm,
  paswdConfirm,
  detailed,
  showFeaturePermission,
  delOnClick,
  url,
  showSiteSelection,
}: {
  roles: { id: number; name: string }[];
  userGroups: userGroup[];
  ButtonElement: React.ReactNode;
  setPaswdConfirm: React.Dispatch<string>;
  paswdConfirm: string;
} & (
  | {
      detailed?: never;
      showFeaturePermission?: never;
      delOnClick?: never;
      url?: never;
      setFormData: React.Dispatch<
        Omit<Partial<userFormData>, "id"> & {
          id: number;
        }
      >;
      setFormError: React.Dispatch<{
        [key in keyof userFormData]?: boolean;
      }>;
      formData: Omit<Partial<userFormData>, "id"> & {
        id: number;
      };
      formError: {
        [key in keyof userFormData]?: boolean;
      };
      showSiteSelection?: never;
    }
  | {
      detailed: true;
      showFeaturePermission: () => void;
      delOnClick: () => void;
      url: string;
      setFormData: React.Dispatch<detail_partial_user>;
      setFormError: React.Dispatch<{
        [key in keyof detail_partial_user]?: boolean;
      }>;
      formData: detail_partial_user;
      formError: {
        [key in keyof detail_partial_user]?: boolean;
      };
      showSiteSelection: () => void;
    }
)) => {
  const { t } = useTranslate();
  const [roleOptions, setRoleOptions] = useState<option[]>([]);
  const [userGroupOptions, setUserGroupOptions] = useState<option[]>([]);

  const updateFormData = (objData: Partial<userFormData>) => {
    ext_updateFormData(objData, {
      // @ts-ignore [Sharing of a setter strategy by 2 forms]
      formData,
      // @ts-ignore [Sharing of a setter strategy by 2 forms]
      setFormData,
      formError,
      setFormError,
    });
  };

  const isValid = () => {
    if (
      isStrongPassword(formData.paswd || "") &&
      formData.paswd &&
      paswdConfirm &&
      formData.paswd !== paswdConfirm
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (roles && roles.length > 0) {
      const _options = rearrangeRole(
        roles.map((role) => {
          return {
            label: role.name,
            value: role.id,
          };
        })
      );

      setRoleOptions(_options);
    }
  }, [roles]);

  useEffect(() => {
    if (userGroups && userGroups.length > 0) {
      const _options = userGroups.map((userGroup) => {
        return {
          label: userGroup.name,
          value: userGroup.id,
        };
      });

      setUserGroupOptions(_options);
    }
  }, [userGroups]);

  const getValue = (id: number | undefined, list: option[]) => {
    try {
      let list2 = Array.isArray(list) ? list : [];
      if (typeof id !== `number` || id < 1) {
        return defaultOption;
      } else {
        const result = list2.filter((item) => item.value === id)[0];
        if (result) {
          return {
            label: result.label,
            value: result.value,
          };
        } else {
          return defaultOption;
        }
      }
    } catch {
      return defaultOption;
    }
  };

  const activeOptions = [
    { label: t("Active"), value: 1 },
    { label: t("Inactive"), value: 2 },
  ];

  const isActiveChanged = (status: "active" | "inactive") => {
    const isActive = status === "active";
    updateFormData({ isActive });
  };

  return (
    <form>
      <div className="user-form user_form_wrapper">
        <div className="user_form_record">
          <div className="user_form_header">{t("Username")}</div>
          <div className="user_form_header split">
            <span>{t("Name")}</span>
            <span>{t("Surname")}</span>
          </div>
          <div className="user_form_header">
            {t("Employee Type in System (Role)")}
          </div>
          <div className="user_form_container">
            <div className="user_form_container_input_div">
              <Input
                handler={(value) => updateFormData({ uname: value })}
                type={"text"}
                value={formData.uname || ""}
                placeholder={t("Username")}
                error={!!formError.uname}
              />
            </div>
          </div>

          <div className="user_form_container split">
            <div className="user_form_container_input_div">
              <Input
                handler={(value) => updateFormData({ firstName: value })}
                type={"text"}
                value={formData.firstName || ""}
                placeholder={t("Name")}
                error={!!formError.firstName}
              />
            </div>
            <div className="user_form_container_input_div">
              <Input
                handler={(value) => updateFormData({ lastName: value })}
                type={"text"}
                value={formData.lastName || ""}
                placeholder={t("Surname")}
                error={!!formError.lastName}
              />
            </div>
          </div>

          <div className="user_form_container">
            <div className="user_form_container_input_div">
              <DropDown
                options={roleOptions}
                onSelect={(selectedVal) =>
                  updateFormData({ roleId: selectedVal.value })
                }
                value={getValue(formData.roleId || undefined, roleOptions)}
                border={""}
                title={`${t("Select")}...`}
                error={!!formError.roleId}
              />
            </div>
          </div>
        </div>
        <div className="user_form_record">
          <div className="user_form_header gap_flex">
            {t("New password")}{" "}
            <Info
              style={{ height: 16, width: 16 }}
              className="img_div_contain"
              onMouseOver={(e) => {
                floatInfo.subscribe(
                  e,
                  `Password should contain at least 8 characters, one uppercase letter, one lowercase, and at least one of the following symbols ${`!@#$%^&*(),.?":{}|<></>`}`
                );
              }}
              onMouseOut={(e) => {
                floatInfo.unsubscribe(e);
              }}
            />
          </div>

          <div className="user_form_header">{t("Email address")}</div>

          <div className="user_form_header">{t("Working groups")}</div>

          <div className="user_form_container">
            <div className="user_form_container_input_div">
              <Input
                handler={(value) => updateFormData({ paswd: value })}
                type={"password"}
                value={formData.paswd || ""}
                placeholder={t("New password")}
                error={!isValid() || !!formError.paswd}
              />
            </div>
          </div>
          <div className="user_form_container">
            <div className="user_form_container_input_div">
              <Input
                handler={(value) => updateFormData({ email: value })}
                type={"text"}
                value={formData.email || ""}
                placeholder={t("Email address")}
                error={!!formError.email}
              />
            </div>
          </div>
          <div className="user_form_container">
            <div className="user_form_container_input_div">
              <DropDown
                options={userGroupOptions}
                value={getValue(
                  formData.userGroupId || undefined,
                  userGroupOptions
                )}
                onSelect={(selected) =>
                  updateFormData({ userGroupId: selected.value })
                }
                border={""}
                title={t("Search...")}
                error={!!formError.userGroupId}
              />
            </div>
          </div>
        </div>

        <div className="user_form_record">
          <div className="user_form_header gap_flex">
            {t("Confirm the password you entered")}{" "}
            <Info
              style={{ height: 16, width: 16 }}
              className="img_div_contain"
              onMouseOver={(e) => {
                floatInfo.subscribe(
                  e,
                  `Password should contain at least 8 characters, one uppercase letter, one lowercase, and at least one of the following symbols ${`!@#$%^&*(),.?":{}|<></>`}`
                );
              }}
              onMouseOut={(e) => {
                floatInfo.unsubscribe(e);
              }}
            />
          </div>
          <div className={`user_form_header ${detailed ? "split" : ""}`}>
            <span>{t("Phone number")}</span>
            {detailed && <span>{t("Position")}</span>}
          </div>
          <div className="user_form_header">
            {detailed && t("Structural Unit")}
          </div>
          <div className="user_form_container">
            <div className="user_form_container_input_div">
              <Input
                handler={(value) => setPaswdConfirm(value)}
                type={"password"}
                value={paswdConfirm}
                placeholder={t("Confirm the password you entered")}
                error={!isValid()}
              />
            </div>
          </div>
          <div className={`user_form_container ${detailed ? "split" : ""}`}>
            <div className="user_form_container_input_div">
              <Input
                handler={(value) => updateFormData({ phone: value })}
                type={"number"}
                value={formData.phone || ""}
                placeholder={t("Tel. no.")}
                error={false}
              />
            </div>
            {detailed && (
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ position: value })}
                  type={"text"}
                  value={formData.position || ""}
                  placeholder={t("Position")}
                  error={false}
                />
              </div>
            )}
          </div>
          {detailed && (
            <div className="user_form_container">
              <button
                type="button"
                className="btn btn-default btn-stretch"
                onClick={() => showSiteSelection()}
              >
                {t("Assign / Remove Object (Site)")}
              </button>
            </div>
          )}
        </div>
        <div className="user_form_record">
          <div className="user_form_header">{t("Status")}</div>
          <div className="user_form_header">
            {detailed ? "" : t("Position")}
          </div>
          <div className="user_form_header"></div>
          <div className="user_form_container">
            <div className="user_form_container_input_div">
              <DropDown
                options={activeOptions}
                value={formData.isActive ? activeOptions[0] : activeOptions[1]}
                onSelect={(selected) =>
                  isActiveChanged(selected.value === 1 ? "active" : "inactive")
                }
                border={""}
                title={""}
                error={false}
              />
            </div>
          </div>

          {detailed ? (
            <>
              <div className="user_form_container">
                <button
                  type="button"
                  className={`btn btn-default ${
                    (formData.sites || []).length > 0 ? "" : "inActive"
                  }`}
                  onMouseOver={(e) => {
                    if (!((formData.sites || []).length > 0)) {
                      floatInfo.subscribe(
                        e,
                        `Please add a site before adding permissions`
                      );
                    }
                  }}
                  onMouseOut={(e) => {
                    if (!((formData.sites || []).length > 0)) {
                      floatInfo.unsubscribe(e);
                    }
                  }}
                  onClick={() => {
                    if ((formData.sites || []).length > 0) {
                      showFeaturePermission();
                    } else {
                      return;
                    }
                  }}
                >
                  {t("Access Level Management")}
                </button>
              </div>

              <div className="user_form_container split">
                <Link
                  className="userActivity_det_link"
                  to={`${url}/activities`}
                  state={{
                    userId: formData.id || "",
                    fullName: [formData.firstName, formData.lastName]
                      .filter((item) => item)
                      .join(" "),
                  }}
                >
                  <button type="button" className="btn btn-default">
                    {t("Activity History")}
                  </button>
                </Link>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={delOnClick}
                >
                  {t("Delete Account")}
                </button>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="user_form_container">
                <div className="user_form_container_input_div">
                  <Input
                    handler={(value) => updateFormData({ position: value })}
                    type={"text"}
                    value={formData.position || ""}
                    placeholder={t("Position")}
                    error={false}
                  />
                </div>
              </div>
              <div className="user_form_container split"></div>
            </>
          )}
        </div>
        {ButtonElement}
      </div>
    </form>
  );
};
