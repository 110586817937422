import { useEffect, useState } from "react";
import { useTimeout } from "./useTimeout";

/** Only true values are delayed */
export const useDelay = ({ value, time }: { value: boolean; time: number }) => {
  const [delayedValue, setDelayedValue] = useState(false);
  const caller = useTimeout(
    {
      func: () => {
        if (value) {
          setDelayedValue(true);
        }
      },
      time,
      call: false,
    },
    []
  );

  const clearDelayed = () => {
    caller.clear();
    setDelayedValue(false);
  };

  useEffect(() => {
    clearDelayed();
  }, []);

  useEffect(() => {
    if (!value) {
      clearDelayed();
    } else {
      caller.execute(time);
    }
  }, [value]);

  return delayedValue;
};
