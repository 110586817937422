import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/logoLen.svg";

import { UserProfile } from "../../UI";

import * as actions from "../../../stores/actions";

import "./style.scss";
import { useAuth } from "../../../App";
import DropDown from "../../elements/DropDown/DropDown";
import { getDropDownValue } from "../../../helpers/misc";
import useTranslate from "../../../translate/useTranslate";

const Main = ({
  currentSiteId,
  setCurrentSite,
  userSites,
  currentUser,
  children,
}) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [siteOptions, setSiteOptions] = useState([]);

  useEffect(() => {
    const options = userSites.map((site) => {
      return {
        label: site.name,
        value: site.id,
      };
    });

    setSiteOptions(options);

    const currentSite = userSites.find((site) => site.id === currentSiteId);
    if (!currentSite) {
      setCurrentSite(null);
    }
  }, [userSites]);

  const selectSite = (selectedValue) => {
    setCurrentSite(selectedValue);
    navigate("/waste-acceptance");
  };

  const { t } = useTranslate();

  return (
    <div className="HOMEPAGE">
      {auth.currentUser ? (
        <div className="HEADER">
          <div className="HEADER__logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <ul className="HEADER__menu text-uppercase">
            <li className="fl-3">
              <Link
                className={
                  ["/", "/waste-acceptance"].indexOf(location.pathname) >= 0
                    ? "nav-on"
                    : ""
                }
                to={currentSiteId ? "/waste-acceptance" : "/"}
              >
                {t("New waste")}
              </Link>
            </li>
            <li className="fl-3">
              <Link
                className={
                  [
                    "/waste-journal",
                    "/waste-management",
                    "/waste-disposal",
                    "/waste-accumulation",
                    "/disposal",
                    "/disposal-journal",
                  ].indexOf(location.pathname) >= 0
                    ? "nav-on"
                    : ""
                }
                to="/waste-journal"
              >
                {t("Accumulated waste")}
              </Link>
            </li>
            <li className="fl-3">
              <Link
                className={
                  ["/report"].indexOf(location.pathname) >= 0 ? "nav-on" : ""
                }
                to="/report"
              >
                {t("Reports")}
              </Link>
            </li>
            <li>
              <DropDown
                border=""
                onSelect={(site) => {
                  selectSite(site.value);
                }}
                options={siteOptions}
                title={`${t("Select")}...`}
                value={getDropDownValue(currentSiteId, siteOptions)}
              />
            </li>
            {currentUser && (
              <li>
                <UserProfile user={currentUser} />
              </li>
            )}
          </ul>
        </div>
      ) : (
        <></>
      )}
      {/* <div className="main_wrapper"></div> */}
      {children}
    </div>
  );
};

const mapStoreStateToProps = function (store) {
  return {
    currentSiteId: store.commonReducer.currentSiteId,
    userSites: store.siteReducer.userSites,
    currentUser: store.authReducer.currentUser,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    setCurrentSite: function (siteId) {
      dispatch(actions.setCurrentSite(siteId));
    },
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(Main);
