import { Fetch } from "../../helpers/misc";
import { groupSiteWasteResponse } from "../SiteWastes/getter";

export const getGroupedSiteWastes = async (details: { id: number | null }) => {
  const { id } = details;
  if (!id) {
    return;
  } else {
    return await Fetch(`/siteWastes/grouped/${id}`)
      .then(
        (res) => res.json() as Promise<groupSiteWasteResponse>,
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        throw error;
      });
  }
};

export type editDetails = {
  siteId: number;
  groupId: number | null;
  wasteId: number;
  info: {
    accumulationLimit: number | null;
    threshold: number | null;
    criticalLimit: number | null;
  };
};

export const editGroupedSiteWastes = async (details: editDetails) => {
  const { siteId, wasteId } = details;
  if (!wasteId || !siteId) {
    return;
  } else {
    return await Fetch(`/siteWastes/grouped`, {
      method: `PUT`,
      body: JSON.stringify(details),
    }).then(
      (res) => res.json() as Promise<groupSiteWasteResponse>,
      (error) => {
        throw error;
      }
    );
  }
};

export const deleteGroupedSiteWastes = async (details: {
  siteId: number;
  wasteId: number;
}) => {
  const { siteId, wasteId } = details;
  if (!wasteId || !siteId) {
    return;
  } else {
    return await Fetch(`/siteWastes`, {
      method: `DELETE`,
      body: JSON.stringify(details),
    }).then(
      (res) => res.json() as Promise<groupSiteWasteResponse>,
      (error) => {
        throw error;
      }
    );
  }
};
