import { useState } from "react";
import { Fetch } from "./misc";
import { useAsync } from "./asyncFunc";

export type downloadType =
  | "userList"
  | "userActivities"
  | "userGroups"
  | "disposal"
  | "disposalJournal"
  | "wasteAccumulation"
  | "wasteDisposal"
  | "wasteJournal"
  | "wasteManagement";

const useDownloadHook = ({
  type,
  query,
  reset,
}: {
  type: downloadType | undefined;
  query?: string;
  reset: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const downloadRes = useAsync({
    asyncFunc: callDownload,
    funcParams: { type, query, reset, errorHandler: (data: string) => {} },
    immediate: false,
    clearOnError: true,
  });

  return {
    isShown: open,
    handleOpen: () => {
      setOpen(!open);
    },
    execute: downloadRes.execute,
  };
};

export default useDownloadHook;

export const callDownload = async (params: {
  type: downloadType | undefined;
  query?: string;
  reset: () => void;
  errorHandler: (data: string) => void;
}) => {
  const { type, query, reset, errorHandler } = params;

  if (!type) {
    return;
  }

  await Fetch(`/logs/${type}?${query ? query : ""}`, {
    method: "GET",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  })
    .then(async (response) => {
      const contentDisposition = response.headers.get("Content-Disposition");
      const regex = /filename=(.+)$/i;
      const match = contentDisposition?.match(regex);
      const filename = decodeURIComponent(match ? match[1] : "file.xlsx");

      const blob = await response.blob();
      return { blob, filename };
    })
    .then(({ blob, filename }) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      reset();
    })
    .catch((err) => {
      const isEmpty = err?.empty;

      if (isEmpty) {
        const message = String(err?.error);
        errorHandler(message);
      }

      reset();
      throw err;
    });
};
