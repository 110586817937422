export const nonEmpty = function (val) {
  return val ? val : "";
};

export const nonEmptyZero = function (val) {
  return val ? val : "0";
};

export const formatNumber = function (val, fix = true) {
  if (val) {
    let _val = val;
    if (isNaN(val)) {
      _val = parseFloat(val.toString());
    }
    const isWhole = _val % 1 === 0;
    let num = 0;
    if (isWhole) {
      num = parseInt(_val.toString());
    } else {
      try {
        const place = Math.abs(Math.floor(Math.log10(_val))); // Fixed value to get the first digit after the zeros
        const numberOfZerosBeforeDigit = Math.floor(Math.abs(Math.log10(_val)));
        if (_val >= 1) {
          if (fix) {
            num = parseFloat(_val.toFixed(3));
          } else {
            num = Math.round(_val);
          }
        } else {
          if (numberOfZerosBeforeDigit <= 1) {
            num = parseFloat(_val.toFixed(3));
          } else {
            num = parseFloat(_val.toFixed(place));
          }
        }
      } catch {
        num = _val;
      }
    }
    const newVal = `${num}`;
    return newVal.replace(/\./, ",");
  }

  return "0";
};

export const parseStringTemplate = function (str, params) {
  let newStr = str;
  const matchTplStrs = newStr.match(/#[a-z_]+#/gi);
  matchTplStrs.forEach(function (matchTplStr) {
    const varName = matchTplStr.substring(1, matchTplStr.length - 1);
    newStr = newStr.replace(matchTplStr, params[varName]);
  });

  return newStr;
};
