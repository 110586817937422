import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const saveWasteAccumulation = (action$) =>
  action$.pipe(
    ofType(actions.SAVE_WASTE_ACCUMULATION),
    mergeMap((action) =>
      FetchRX("/wasteAccumulations", {
        method: action.payload.id ? "PUT" : "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SAVE_WASTE_ACCUMULATION_SUCCEED,
            payload: result.wasteAccumulation,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
