import React, { useEffect, useState } from "react";
import { site } from "../../../../../@types/sites";
import { userFormData } from "../../../../../@types/user";
import floatInfo from "../../../../UI/floatInfo/floatInfo";

import "./style.scss";
import useTranslate from "../../../../../translate/useTranslate";

const SiteSelection = function ({
  sites,
  formData,
  onCancel,
  updateFormData,
  onNext,
  onSave,
}: {
  sites: site[];
  formData: Partial<userFormData>;
  onCancel: () => void;
  updateFormData: (data: {
    [key in keyof Pick<userFormData, "sites">]: userFormData[key];
  }) => void;
  onNext?: () => void;
  onSave?: () => void;
}) {
  const { t } = useTranslate();
  const [checkedSites, setCheckedSites] = useState<site[]>([]);

  useEffect(() => {
    if (formData.sites && formData.sites.length > 0) {
      setCheckedSites(formData.sites);
    }
  }, []);

  const checkedSitesHandler = (site: site) => {
    const newCheckedSites = [...checkedSites];
    if (check(site)) {
      const index = checkIndex(site.id);
      if (index > -1) {
        newCheckedSites.splice(index, 1);
      }
    } else {
      newCheckedSites.push(site);
    }

    setCheckedSites(newCheckedSites);
  };

  /**
   *
   * @param site The site to check if it is checked
   * @returns Boolean to show if a site is checked or not
   */
  const check = (site: site) => {
    return checkedSites.some((checkedSite) => checkedSite.id === site.id);
  };
  /**
   *
   * @param id Id of site of which we are trying to get index in checked list
   * @returns The index or -1 if not found
   */
  const checkIndex = (id: number) => {
    let index = -1;
    for (let i = 0; i < checkedSites.length; i++) {
      const site = checkedSites[i];
      if (site.id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const siteCheckboxes = sites
    ? sites.map((site) => {
        return (
          <label key={`cb-${site.id}`}>
            <input
              type="checkbox"
              value={site.id}
              checked={check(site)}
              onChange={() => checkedSitesHandler(site)}
            />{" "}
            {site.name}
          </label>
        );
      })
    : [];

  const containsRemovedSite = (): boolean => {
    const contained = (site: site): boolean => {
      return checkedSites.some((cSite) => {
        return cSite.id === site.id;
      });
    };
    return (formData.sites || []).some((site) => {
      return !contained(site);
    });
    /**if the new set does not include all, call the function */
  };
  return (
    <div className="site-selection">
      <div className="site-list">{siteCheckboxes}</div>
      <div className="actions">
        <button type="button" className="btn btn-custom" onClick={onCancel}>
          {t("Cancel")}
        </button>
        {onNext && (
          <button
            onMouseOver={(e) => {
              if (!(checkedSites.length > 0)) {
                floatInfo.subscribe(
                  e,
                  `Please add at least one site before saving your edits`
                );
              } else {
                floatInfo.unsubscribe(e);
              }
            }}
            onMouseOut={(e) => {
              if (!(checkedSites.length > 0)) {
                floatInfo.unsubscribe(e);
              }
            }}
            type="button"
            className={`btn btn-green ${
              checkedSites.length > 0 ? "" : "inActive"
            }`}
            onClick={() => {
              if (checkedSites.length > 0) {
                updateFormData({ sites: checkedSites });
                onNext();
              } else {
                return;
              }
            }}
          >
            {t("Next")}
          </button>
        )}
        {onSave && (
          <button
            onMouseOver={(e) => {
              if (!(checkedSites.length > 0)) {
                floatInfo.subscribe(
                  e,
                  `Please add at least one site before saving your edits`
                );
              }
            }}
            onMouseOut={(e) => {
              if (!(checkedSites.length > 0)) {
                floatInfo.unsubscribe(e);
              }
            }}
            type="button"
            className={`btn btn-green ${
              checkedSites.length > 0 ? "" : "inActive"
            }`}
            onClick={() => {
              if (checkedSites.length > 0) {
                updateFormData({ sites: checkedSites });
                onSave();
              } else {
                return;
              }
            }}
          >
            {t("Save")}
          </button>
        )}
      </div>
    </div>
  );
};

export default SiteSelection;
