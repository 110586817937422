import { connect } from "react-redux";
import { stateType } from "../store";

const mapStateToProps = (state: stateType) => ({
  locale: state.commonReducer.locale,
});

export const BlankLocale = connect(mapStateToProps)(() => {
  return <></>;
});
