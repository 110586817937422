import React, { useEffect, useState } from "react";
import DropDown from "../../elements/DropDown/DropDown";
import IconContainer from "../iconContainer/iconContainer";
import { ReactComponent as ArrowLeft } from "../../../assets/images/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/arrow_right.svg";
import { ReactComponent as DoubleArrowLeft } from "../../../assets/images/double_arrow_left.svg";
import { ReactComponent as DoubleArrowRight } from "../../../assets/images/double_arrow_right.svg";

import "./FractionList.css";
import { makeLabelInt, pageSettingOptions } from "../../../helpers/misc";
import useTranslate from "../../../translate/useTranslate";
type dirDetails = {
  value: number | `...`;
  active: boolean;
};

/**
 *
 * @param props.fractions represents the total records for which cuts are made
 * @param props.currentStart The (index + 1) of the record of the current page
 * @param props.setCurrentPage SetState function for changing `currentStart`
 * @param props.setConstructArray Function to set the cut. `Cut` is gotten from `fractions`
 * @param props.pageRecordNum This represents the number of records per page
 * @param props.setPageRecordNum SetState function to change the number of record per page
 */
const FractionList = ({
  count,
  currentStart,
  setCurrentPage,
  pageRecordNum,
  setPageRecordNum,
}: {
  count: number;
  currentStart: number;
  setCurrentPage: (data: number) => void;
  pageRecordNum: {
    label: number;
    value: number;
  };
  setPageRecordNum: (data: { label: number; value: number }) => void;
}) => {
  const { t } = useTranslate();
  const makeLabelString = (option: {
    label: number;
    value: number;
  }): { label: string; value: number } => {
    return {
      label: option.label.toString(),
      value: option.value,
    };
  };

  const [Direction, setDirection] = useState<dirDetails[]>([]);

  const getStart = () => {
    const start = (CurrentPage() - 1) * pageRecordNum.label + 1;
    return start;
  };

  useEffect(() => {
    setCurrentPage(getStart());

    const viewAble = min(5, totalPages());
    const viewPad = Math.floor(viewAble / 2);
    const stop =
      CurrentPage() > viewPad
        ? min(CurrentPage() + viewPad, totalPages())
        : viewAble;
    const start =
      totalPages() === viewPad
        ? 1
        : max(
            1,
            stop === totalPages()
              ? stop - viewAble + 1
              : CurrentPage() - viewPad
          );

    const dirArray: dirDetails[] = [];
    for (let i = start; i <= stop; i++) {
      dirArray.push({
        active: CurrentPage() === i,
        value: i,
      });
      if (i === stop && stop !== totalPages()) {
        dirArray.push({
          active: false,
          value: `...`,
        });
      }
    }

    setDirection(dirArray);
  }, [currentStart, pageRecordNum, count]);

  const min = (num1: number, num2: number) => {
    if (num1 < num2) {
      return num1;
    }
    return num2;
  };
  const max = (num1: number, num2: number) => {
    if (num1 > num2) {
      return num1;
    }
    return num2;
  };

  const CurrentPage = () => {
    const currentPage = (currentStart - 1) / pageRecordNum.label + 1;
    //parent record
    return count < currentStart ? 1 : Math.floor(currentPage);
  };
  const increment = (num: number) => {
    if (num === CurrentPage() || num < 1 || num > totalPages()) {
      return;
    } else {
      setCurrentPage((num - 1) * pageRecordNum.label + 1); //Sets ecternal
    }
  };
  const totalPages = () => {
    return Math.ceil(count / pageRecordNum.label);
  };
  useEffect(() => {}, [currentStart, pageRecordNum.label]);

  return (
    <div className="fraction_wrapper_btn_footer_wrapper">
      <div className="flex">
        <span>{t("Show after")}</span>
        <div className="fraction_wrapper_btn_footer_wrapper_drop">
          <DropDown
            readOnly
            options={pageSettingOptions}
            value={makeLabelString(pageRecordNum)} // value from external component state
            onSelect={(details) => {
              setPageRecordNum(makeLabelInt(details)); // Sets external component
            }}
            border={""}
            title={""}
            error={false}
          />
        </div>
      </div>
      <div className="fraction_wrapper_btn_footer">
        <div
          className={`fraction_dir_icon_wrapper ${
            CurrentPage() === 1 ? "inactive" : ""
          }`}
        >
          <IconContainer defaultCursor={true}>
            <DoubleArrowLeft onClick={() => increment(1)} />
          </IconContainer>
        </div>
        <div
          className={`fraction_dir_icon_wrapper left ${
            CurrentPage() === 1 ? "inactive" : ""
          }`}
        >
          <IconContainer defaultCursor={true}>
            <ArrowLeft onClick={() => increment(CurrentPage() - 1)} />
          </IconContainer>
        </div>
        {Direction.map((dir, ind) => {
          return (
            <span
              key={`fraction_num_${ind}`}
              onClick={() => {
                if (typeof dir.value === "number") {
                  increment(dir.value);
                } else {
                  return;
                }
              }}
              className={`fraction_dir_span ${
                dir.active
                  ? "active"
                  : typeof dir.value === "string"
                  ? "non"
                  : ""
              }`}
            >
              {dir.value}
            </span>
          );
        })}

        <div
          className={`fraction_dir_icon_wrapper right ${
            CurrentPage() === totalPages() || totalPages() === 0
              ? "inactive"
              : ""
          }`}
        >
          <IconContainer defaultCursor={true}>
            <ArrowRight onClick={() => increment(CurrentPage() + 1)} />
          </IconContainer>
        </div>
        <div
          className={`fraction_dir_icon_wrapper ${
            CurrentPage() === totalPages() || totalPages() === 0
              ? "inactive"
              : ""
          }`}
        >
          <IconContainer defaultCursor={true}>
            <DoubleArrowRight onClick={() => increment(totalPages())} />
          </IconContainer>
        </div>
      </div>
    </div>
  );
};

export default FractionList;
//The takes the number of pages available as input

/**
 * The function takes in the number of records per page (becasue this component
 * calls a function that updates that and we notice in a useEffect),
 * spits out the slice to map and updates the state with it. The function takes
 * in a set state too
 *
 *
 */
