import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../../store";
import {
  getWasteActivityTypes as get_wasteactivitytypes,
  getInactiveWasteActivityTypes as get_inactive_wasteactivitytypes,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getWasteActivityTypes = (action$) =>
  action$.pipe(
    ofType(actions.GET_WASTEACTIVITYTYPES),
    mergeMap(() =>
      FetchRX("/wasteActivityTypes").pipe(
        map((result) => {
          return {
            type: actions.SET_WASTEACTIVITYTYPES,
            payload: result.wasteactivitytypes,
          };
        }),
        catchError((error) => {
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const getInactiveWasteActivityTypes = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_WASTEACTIVITYTYPES),
    mergeMap(() =>
      FetchRX("/wasteActivityTypes/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_WASTEACTIVITYTYPES,
            payload: result.wasteactivitytypes,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateWasteActivityType = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_WASTEACTIVITYTYPE),
    mergeMap((action) =>
      FetchRX("/wasteActivityTypes/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_wasteactivitytypes());
          store.dispatch(get_inactive_wasteactivitytypes());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideWasteActivityType = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_WASTEACTIVITYTYPE),
    mergeMap((action) =>
      FetchRX("/wasteActivityTypes/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_wasteactivitytypes());
          store.dispatch(get_inactive_wasteactivitytypes());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreWasteActivityType = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_WASTEACTIVITYTYPE),
    mergeMap((action) =>
      FetchRX("/wasteActivityTypes/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_wasteactivitytypes());
          store.dispatch(get_inactive_wasteactivitytypes());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createWasteActivityType = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_WASTEACTIVITYTYPE),
    mergeMap((action) =>
      FetchRX("/wasteActivityTypes/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_WASTEACTIVITYTYPES,
            payload: result.wasteactivitytypes,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editWasteActivityType = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_WASTEACTIVITYTYPE),
    mergeMap((action) =>
      FetchRX("/wasteActivityTypes/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_WASTEACTIVITYTYPES,
            payload: result.wasteactivitytypes,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
