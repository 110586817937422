import { connect } from "react-redux";

import WasteManagementForm from "./WasteManagementForm";

import * as actions from "../../../../stores/actions";

const mapStoreStateToProps = function (state) {
  return {
    wastes: state.wasteReducer.wastes,
    primarySources: state.primarySourceReducer.primarySources,
    sites: state.siteReducer.sites,
    wasteActivityTypes: state.wasteActivityTypeReducer.wasteActivityTypes,
    wasteActivitiesSavingStatus: state.wasteActivityReducer.savingStatus,
    formations: state.formationReducer.formations,
    materials: state.materialReducer.materials,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    saveWasteActivity: function (wasteActivity) {
      dispatch(actions.saveWasteActivity(wasteActivity));
    },
    getWastes: function (siteId) {
      dispatch(actions.getWastes(siteId));
    },
    getWasteActivityTypes: function () {
      dispatch(actions.getWasteActivityTypes());
    },
    resetWasteActivityStatus: function () {
      dispatch(actions.resetWasteActivityStatus());
    },
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(WasteManagementForm);
