import { Fetch } from "../../helpers/misc";

export const getInvoices = async () => {
  return await Fetch("/clientWastes/invoiceList")
    .then((res) => {
      const invoice = res.json() as Promise<{
        invoices: { id: number; serija: string }[];
      }>;

      return invoice;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
