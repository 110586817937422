import WastesComingIn from "./WastesComingIn";

import { connect } from "react-redux";

const mapStoreStateToProps = function (store) {
  return {
    currentSiteId: store.commonReducer.currentSiteId,
    currentUser: store.authReducer.currentUser,
  };
};

export default connect(mapStoreStateToProps)(WastesComingIn);
