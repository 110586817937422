import { useCallback } from "react";
import { json_key, translate_options, translate } from "./translate";
import { store } from "../store";
import useLocaleRender from "../helpers/useLocaleRender";

const useTranslate = () => {
  useLocaleRender();
  const t = useCallback(
    (originalText: json_key, options: translate_options = {}) => {
      const { text } = translate(originalText, options);
      return text;
    },
    []
  );

  return { t };
};

export default useTranslate;

export const useLocale = () => {
  const locale = store.getState().commonReducer.locale;

  return { locale };
};
