import React, { useEffect, useState } from "react";
import moment from "moment";

import { PopupFrame } from "../../../../UI";
import { UserGroupForm } from "..";

import { ReactComponent as EditLogo } from "../../../../../assets/images/edit.svg";
import { ReactComponent as DeleteLogo } from "../../../../../assets/images/del.svg";

import "./style.scss";
import IconContainer from "../../../../UI/iconContainer/iconContainer";
import NewConfirmDialog from "../../../../UI/ConfirmDialog/NewConfirmDialog";
import { useAsync } from "../../../../../helpers/asyncFunc";
import { userGroup } from "../../../../../@types/user";
import { Fetch } from "../../../../../helpers/misc";
import {
  childField,
  Header,
  textBox,
} from "../../../../UI/FormGenerator/formField";
import { pageConstructType } from "../../../../UI/FormGenerator/formTypes";
import FormGenerator, {
  GenerateForm,
} from "../../../../UI/FormGenerator/FormGenerator";
import { DataExportService } from "../../../../../services";
import { getData, getXlsxObj } from "../../dataExportFormat";
import { useLocation, useResolvedPath } from "react-router-dom";
import useTranslate from "../../../../../translate/useTranslate";

export type localUserGroup = userGroup & {
  userCount: number;
  createdAt: string;
  updatedAt: string;
};

const delete_user_group = async (params: {
  id: number | undefined;
  userId: number | undefined;
}) => {
  const { id, userId } = params;
  if (!id || !userId) {
    return;
  }
  return await Fetch("/userGroups", {
    method: "DELETE",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const response = res.json() as Promise<{ success: true }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const edit_user_group = async (params: {
  id: number | undefined;
  name: string;
  userId: number | undefined;
  isActive: boolean;
  formType: "new" | "edit";
}) => {
  const { id, userId } = params;
  if ((params.formType === "edit" && !id) || !userId) {
    return;
  }
  return await Fetch("/userGroups", {
    method: params.formType === "edit" ? "PUT" : "POST",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const response = res.json() as Promise<{
        success: true;
        userGroup: localUserGroup;
      }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const UserGroupList = function ({
  userGroups,
  getUserGroups,
  currentUserId,
  retrieve,
  xlsxExport,
}: {
  userGroups: localUserGroup[];
  getUserGroups: () => void;
  currentUserId: number | undefined;
  retrieve: number | undefined;
  xlsxExport: (func: any) => void;
}) {
  const url = useResolvedPath("").pathname;
  const { pathname } = useLocation();
  const dataExportService = new DataExportService();

  const [idToDel, setIdToDel] = useState<number>();
  const [userGroupFormShown, setUserGroupFormShown] = useState(false);
  const [formType, setFormType] = useState<"edit" | "new">("new");
  const [userGroup, setUserGroup] = useState<localUserGroup>();

  const { t } = useTranslate();

  const xlsx = () => {
    const { description, title } = getXlsxObj("USER_GROUP", t);
    dataExportService.init(title, description);

    const rows = getData(
      {
        type: "USER_GROUP",
        records: userGroups,
      },
      t
    );

    dataExportService.export(rows);
  };

  const canExport = retrieve && url === pathname;
  useEffect(() => {
    if (canExport) {
      xlsxExport(xlsx);
    }
  }, [retrieve]);

  const addBtnOnClick = () => {
    setUserGroup(undefined);
    setFormType("new");
    setUserGroupFormShown(true);
  };

  const editBtnOnClick = (userGroup: localUserGroup) => {
    setUserGroup({ ...userGroup });
    setFormType("edit");
    setUserGroupFormShown(true);
  };

  const userGroupFormOnExit = () => {
    setUserGroupFormShown(false);
  };

  const editGroupRes = useAsync({
    asyncFunc: edit_user_group,
    funcParams: {} as any,
    immediate: false,
    clearOnError: true,
  });

  const save = (formData: {
    id: number | undefined;
    name: string;
    userId: number | undefined;
    isActive: boolean;
    formType: "new" | "edit";
  }) => {
    editGroupRes.execute({
      ...formData,
    });
  };

  const deleteGroup = () => {
    if (idToDel) {
      deleteGroupRes.execute({ id: idToDel, userId: currentUserId });
      setIdToDel(undefined);
    }
  };

  const deleteGroupRes = useAsync({
    asyncFunc: delete_user_group,
    funcParams: { id: idToDel, userId: currentUserId },
    immediate: false,
    clearOnError: true,
  });

  useEffect(() => {
    getUserGroups();
  }, [editGroupRes.data, deleteGroupRes.data]);

  const dist = [1, 3, 1.5, 1.5, 2, 1];

  const textConstruct: pageConstructType[] = userGroups.map((userGroup, n) => ({
    sizeDist: dist,
    typeDist: [
      textBox(`${n + 1}`, false),
      textBox(`${userGroup.name}`, false),
      textBox(`${userGroup.userCount}`, false),
      textBox(userGroup.isActive ? t("Yes") : "Ne", false),
      textBox(
        userGroup.updatedAt
          ? moment(userGroup.updatedAt).format("YYYY-MM-DD HH:mm")
          : "",
        false
      ),
      childField(
        <div className="center" key={n}>
          <IconContainer size="lg">
            <EditLogo onClick={() => editBtnOnClick(userGroup)} />
          </IconContainer>
          <IconContainer size="lg">
            <DeleteLogo onClick={() => setIdToDel(userGroup.id)} />
          </IconContainer>
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  }));

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header([t("Serial No.")], false, true),
        Header([t("Title")], false, true),
        Header([t("Employees")], false, true),
        Header([t("Active")], false, true),
        Header([t("Updated")], false, true),
        Header([t("Actions")], false),
      ],
    },
  ];

  return (
    <>
      <div className="user-group-list">
        {idToDel && (
          <NewConfirmDialog
            txt={t("Do you really want to remove this working group?")}
            isVisible={!!idToDel}
            onExit={() => {
              setIdToDel(undefined);
            }}
            isLoading={false}
            onContinue={() => deleteGroup()}
          />
        )}
        <div
          className="addNewWaste"
          style={{ margin: "2rem 0 1rem 0" }}
          onClick={() => {
            addBtnOnClick();
          }}
        >
          <IconContainer>
            <img
              src={require("../../../../../assets/images/plus.svg").default}
              alt={``}
            />
          </IconContainer>
          <div>{t("Add a new group")}</div>
        </div>

        <div className="viewExt">
          <GenerateForm>
            {pageConstruct.map((construct, n) => (
              <FormGenerator
                key={`userGroupHead_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
            {textConstruct.map((construct, n) => (
              <FormGenerator
                key={`userGroupRow_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
          </GenerateForm>
        </div>
      </div>

      {userGroupFormShown && (
        <PopupFrame
          isShown={userGroupFormShown}
          onExit={() => setUserGroupFormShown(false)}
          yOffset={window.scrollY}
        >
          <UserGroupForm
            formType={formType}
            userGroup={userGroup}
            save={save}
            onExit={userGroupFormOnExit}
          />
        </PopupFrame>
      )}
    </>
  );
};

export default UserGroupList;
