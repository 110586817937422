import { useCallback, useEffect, useRef, useState } from "react";

/**
 * @param {()=>void} _.func         Interval function
 * @param {number} _.time    Interval time
 */
export const useInterval = (
  props: {
    func: () => void;
    time: number;
    call?: boolean;
  },
  changeEffectors?: unknown[]
) => {
  const {
    func,
    time,
    call: callFunc,
  } = {
    ...props,
  };

  const call = !!callFunc;

  const effectors = changeEffectors || [];
  const [intervalId, setIntervalId] = useState<number>();

  const [called, setCalled] = useState(false);
  const mountedRef = useRef(true);

  const execute = useCallback(
    async (time: number) => {
      setCalled(true);
      if (intervalId) {
        clearInterval(intervalId);
      }
      const copyInterval = window.setInterval(() => {
        if (mountedRef.current) {
          func();
        }
      }, time);
      setIntervalId(copyInterval);
    },
    [func, time]
  );

  useEffect(() => {
    mountedRef.current = true;

    if (call) {
      execute(time);
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...effectors, call]);

  return {
    execute,
    called,
  };
};
