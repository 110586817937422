import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DataExportService } from "../../services";
import { alignment, defaultDataType } from "../../services/DataExportService";
import { ReactComponent as UploadIcon } from "../../assets/images/upload.svg";
import { ReactComponent as EditLogo } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteLogo } from "../../assets/images/del.svg";
import { ReactComponent as AddBtn } from "../../assets/images/plus.svg";
import { ReactComponent as MinusBtn } from "../../assets/images/minus.svg";
import "./style.scss";
import { formatNumber } from "../../helpers";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import FractionList from "../../components/UI/fractionList/FractionList";
import {
  appendSort,
  enabled,
  makeLabelInt,
  pageSettingOptions,
  shortName,
} from "../../helpers/misc";
import Sorter from "../../components/elements/Sorter/Sorter";
import IntegrationPopUp from "./IntegrationPopUp";
import { site } from "../../@types/sites";
import { primarySource, wasteType } from "../../@types/waste";
import { billing } from "../../@types/client";
import { municipalityType, originType } from "../WasteAcceptance/@types";
import { carrier } from "../../@types/user";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import {
  childField,
  Header,
  multiDropField,
  numberField,
  textBox,
  textField,
} from "../../components/UI/FormGenerator/formField";
import DiscreteRangeDatePicker from "../../components/UI/DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../Report/@types";
import FilterButtons from "../../components/UI/filterButtons/FilterButtons";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import { useAsync } from "../../helpers/asyncFunc";
import {
  clientWaste,
  deleteClientWasteRecord,
  editClientWasteRecord,
  getHandlingCodes,
} from "./parser";
import NewConfirmDialog from "../../components/UI/ConfirmDialog/NewConfirmDialog";
import { getWasteJournal } from "./getter";
import ExcelDownload from "../../components/UI/DownloadPopUp/DownloadPopUp";
import useTranslate from "../../translate/useTranslate";

const WasteJournal = function ({
  sites,
  wastes,
  municipalities,
  origins,
  billings,
  carriers,
  primarySources,
  isAdmin,
  currentUserId,
}: {
  sites: site[];
  wastes: wasteType[];
  municipalities: municipalityType[];
  origins: originType[];
  billings: billing[];
  carriers: carrier[];
  primarySources: primarySource[];
  isAdmin: boolean;
  currentUserId?: number;
}) {
  const { t } = useTranslate();
  const sortOptions = {
    column: "createdAt",
    active: t("Newest date first"),
    inactive: t("Oldest date first"),
    main: t("Newest date first"),
  };

  const selectedRowId = `grouped__journalRecordSelectedId`;
  type journalDefaultFilter = {
    date: {
      startDate: string;
      endDate: string;
    };
    order: string[];
    siteId: option[];
    wasteId: option[];
    originId: option[];
    municipalityId: option[];
    primarySource: option[];
    billing: option[];
    carrier: option[];
    handlingCode: option[];
    name: string;
    surname: string;
    companyName: string;
    amount: number | "";
    holder: string;
  };

  const defaultFilter: journalDefaultFilter = {
    order: ["createdAt DESC"],
    siteId: [],
    date: {
      startDate: "",
      endDate: "",
    },
    wasteId: [],
    originId: [],
    primarySource: [],
    handlingCode: [],
    amount: "",
    carrier: [],
    billing: [],
    name: "",
    surname: "",
    companyName: "",
    municipalityId: [],
    holder: "",
  };

  const dataExportService = new DataExportService();

  const [openIntg, setOpenIntg] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [wasteOptions, setWasteOptions] = useState<option[]>([]);
  const [originOptions, setOriginOptions] = useState<option[]>([]);
  const [handlingCodeOptions, setHandlingCodeOptions] = useState<option[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<option[]>([]);
  const [municipalityOptions, setMunicipalityOptions] = useState<option[]>([]);
  const [billingOptions, setBillingOptions] = useState<option[]>([]);
  const [primarySourceOptions, setPrimarySourceOptions] = useState<option[]>(
    []
  );
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [deleteObj, setDeleteObj] = useState<{
    clientWasteId: number;
  }>();
  type editDetails = {
    clientWasteId: number;
    weight: number | undefined;
    quantity: number | undefined;
  };
  const [editingObj, setEditingObj] = useState<editDetails>();
  const [filter, setFilter] = useState({ ...defaultFilter });

  const [clientWastes, setClientWastes] = useState<clientWaste[]>([]);
  const [count, setCount] = useState(0);
  const [currentStart, setCurrentPage] = useState(1);

  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );
  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  useEffect(() => {
    if (carriers) {
      const options = carriers.map((carrier) => ({
        label: carrier.name,
        value: carrier.id,
      }));
      setCarrierOptions([...options]);
    }
  }, [carriers]);

  const setEditing = (data: editDetails | undefined) => {
    if (isAdmin) {
      setEditingObj(data ? { ...data } : data);
    } else {
      return;
    }
  };

  const deleteRecord = (
    data:
      | {
          clientWasteId: number;
        }
      | undefined
  ) => {
    if (isAdmin) {
      setDeleteObj(data ? { ...data } : data);
    } else {
      return;
    }
  };

  const mountedRef = useRef(true);
  useEffect(() => {
    const outsideActiveRowClick = (e: MouseEvent) => {
      const target = e.target as Node;
      const element = document.getElementById(selectedRowId);
      if (element && !element.contains(target)) {
        setEditingObj(undefined);
      }
    };

    document.addEventListener("click", outsideActiveRowClick);
    return () => {
      document.addEventListener("click", outsideActiveRowClick);
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (billings) {
      const options = billings.map((bill) => ({
        label: bill.name,
        value: bill.id,
      }));
      setBillingOptions([...options]);
    }
  }, [billings]);
  useEffect(() => {
    if (primarySources) {
      const options = primarySources.map((ps) => ({
        label: ps.name,
        value: ps.id,
      }));
      setPrimarySourceOptions([...options]);
    }
  }, [primarySources]);

  const getQueryString = ((): {
    query: string;
    queryObj: { [key: string]: string };
  } => {
    type objQuery = Partial<
      Omit<journalDefaultFilter, "date"> & {
        startDate: string;
        endDate: string;
      }
    >;
    const queryObj: { [key in keyof objQuery]: string } = {};
    const query = Object.keys(filter)
      .map((k) => {
        const multiFilterKeys: (keyof journalDefaultFilter)[] = [
          "siteId",
          "wasteId",
          "originId",
          "municipalityId",
          "primarySource",
          "billing",
          "carrier",
          "handlingCode",
        ];

        const key = k as keyof journalDefaultFilter;

        if (key === "date") {
          if (filter.date.startDate && filter.date.endDate) {
            queryObj["startDate"] = filter.date.startDate;
            queryObj["endDate"] = filter.date.endDate;
            return `startDate=${filter.date.startDate}&endDate=${filter.date.endDate}`;
          }

          return "startDate=&endDate=";
        } else if (multiFilterKeys.indexOf(key) >= 0) {
          queryObj[key] = (filter[key] as option[])
            .map((i) => i.value)
            .join(",");
          return (
            key + "=" + (filter[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          if (key === "order") {
            queryObj[key] = filter[key][0];
          } else {
            queryObj[key] = filter[key] as unknown as string;
          }
          return key + "=" + filter[key];
        }
      })
      .join("&");

    return { query, queryObj };
  })();

  const Filter = getQueryString.query;

  const handlingCodeRes = useAsync(
    {
      asyncFunc: getHandlingCodes,
      funcParams: undefined as never,
      immediate: false,
    },
    []
  );

  useEffect(() => {
    handlingCodeRes.execute(undefined as never).then(
      (res) => {
        if (res) {
          const codes = res.handlingCodes;
          setHandlingCodeOptions([
            ...codes.map((code) => ({
              label: code.name!,
              value: code.name! as unknown as number,
            })),
          ]);
        }
      },
      (err) => {
        console.log(err.error);
      }
    );
  }, []);

  useEffect(() => {
    if (municipalities && municipalities.length > 0) {
      const options = municipalities.map((municipality) => {
        return {
          label: shortName(municipality.name),
          value: municipality.id,
        };
      });

      setMunicipalityOptions(options);
    }
  }, [municipalities]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const options = sites.map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  useEffect(() => {
    if (wastes && wastes.length > 0) {
      const options = wastes.map((waste) => {
        return {
          label: `${waste.code} ${waste.waste}`,
          value: waste.id,
        };
      });

      setWasteOptions(options);
    }
  }, [wastes]);

  useEffect(() => {
    if (origins && origins.length > 0) {
      const options = origins.map((origin) => {
        return {
          label: origin.name,
          value: origin.id,
        };
      });

      setOriginOptions(options);
    }
  }, [origins]);

  const updateFilter = (objFilter: Partial<journalDefaultFilter>) => {
    setFilter({ ...filter, ...objFilter });
  };

  const expandFilter = () => {
    setExpanded(!expanded);
  };

  const journalRes = useAsync(
    {
      asyncFunc: getWasteJournal,
      funcParams: { query: Filter, limit, offset },
      immediate: true,
      clearOnError: true,
    },
    [Filter, limit, offset]
  );

  useEffect(() => {
    const clientWastes = journalRes.data?.clientWastes || [];
    const count = journalRes.data?.count || 0;

    setClientWastes([...clientWastes]);
    setCount(count);
  }, [journalRes.data]);

  const editClientWasteRes = useAsync({
    asyncFunc: editClientWasteRecord,
    funcParams: {},
    immediate: false,
    clearOnError: true,
  });

  const editClientWaste = () => {
    editClientWasteRes.execute({
      filter: getQueryString.queryObj,
      userId: currentUserId,
      ...(editingObj
        ? {
            ...editingObj,
            weight: editingObj.weight
              ? parseFloat(`${editingObj.weight}`) * 1000
              : undefined,
          }
        : {}),
    });
    setEditing(undefined);
  };

  useEffect(() => {
    if (editClientWasteRes.data) {
      journalRes.execute({ limit, offset, query: Filter });
    }
  }, [editClientWasteRes.data]);

  const deleteClientWasteRes = useAsync({
    asyncFunc: deleteClientWasteRecord,
    funcParams: {},
    immediate: false,
    clearOnError: true,
  });

  useEffect(() => {
    if (deleteClientWasteRes.data) {
      journalRes.execute({ limit, offset, query: Filter });
    }
  }, [deleteClientWasteRes.data]);

  const deleteClientWaste = () => {
    if (deleteObj) {
      deleteClientWasteRes.execute({
        clientWasteId: deleteObj?.clientWasteId,
        filter: getQueryString.queryObj,
        userId: currentUserId,
      });
      setDeleteObj(undefined);
    }
  };

  const isEditing = (clientWasteId: number) => {
    return !!(editingObj && editingObj.clientWasteId === clientWasteId);
  };
  const isQuantified = (item: clientWaste) =>
    item.uom === "unit" && item.kgPerUnit;

  const dist: (string | number)[] = expanded
    ? ["6rem"].concat(Array(11).fill("10rem").concat(["7rem", "9rem"]))
    : ["6rem", 1, 1, 1.5, 0.75, 1, 1, "9rem"];
  // const dist = [1.2, 1, 0.5, 1, 0.75, 1, 0.5, 1, 0.5, 1, 0.5];

  const textConstruct: (pageConstructType & { id: number })[] = (
    clientWastes || []
  ).map((journal, n) => {
    const id = journal.id;
    const editing = isEditing(journal.id);
    return {
      id,
      sizeDist: dist,
      typeDist: [
        textBox(
          `${journal.serija ? `${journal.serija.toUpperCase()}-` : ""}${
            journal.id
          }`,
          false
        ),
        textBox(journal.siteName, false),
        textBox(moment(journal.date).format("YYYY-MM-DD"), true),
        textBox(journal.wasteName, false),
        textBox(journal.originName, false),
        textBox(journal.primarySourceName || "", false),
        editing
          ? numberField(
              "0",
              false,
              () => {},
              isQuantified(journal)
                ? `${editingObj?.quantity || ""}`
                : `${editingObj?.weight || ""}`,
              (value) => {
                const kgPerUnit = journal.kgPerUnit;
                const quant = parseInt(value);

                setEditing({
                  ...editingObj!,
                  ...(isQuantified(journal)
                    ? {
                        quantity: quant,
                        weight:
                          (quant * kgPerUnit!) /
                          1000 /**because i will eventually multiply by 1000 */,
                      }
                    : { weight: value as unknown as number }),
                });
              }
            )
          : textBox(
              isQuantified(journal)
                ? `${journal.quantity || ""} vnt.`
                : `${formatNumber(journal.weight / 1000)} t`,
              true
            ),
        ...(expanded
          ? [
              textBox(journal.handlingCode || "", false),
              textBox(journal.carrier || "", false),
              textBox(journal.billing || "", false),
              textBox(journal.holder || "", false),
              textBox(shortName(journal.municipalityName || ""), false),
              childField(
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  key={n}
                  className={`flex1 center journal_icon_holder ${
                    isAdmin ? "" : "disabled"
                  }`}
                >
                  <button
                    className={`btn btn-green ${
                      editing ? "showSiteEdit" : "hideSiteEdit"
                    }`}
                    disabled={
                      !editingObj?.weight ||
                      !parseFloat(`${editingObj?.weight || ""}`)
                    }
                    onClick={() => {
                      if (
                        !editingObj?.weight ||
                        !parseFloat(`${editingObj?.weight || ""}`)
                      ) {
                        return;
                      } else {
                        editClientWaste();
                      }
                    }}
                  >
                    {t("Save")}
                  </button>
                  <div
                    className={`flex1 center ${
                      !editing ? "showSiteEdit" : "hideSiteEdit"
                    }`}
                  >
                    <IconContainer size="lg">
                      <EditLogo
                        onClick={(e) => {
                          setEditing({
                            clientWasteId: id,
                            weight: journal.weight / 1000,
                            quantity: journal.quantity || undefined,
                          });
                        }}
                      />
                    </IconContainer>
                    <IconContainer size="lg">
                      <DeleteLogo
                        onClick={() => {
                          deleteRecord({
                            clientWasteId: id,
                          });
                          setDialogVisibility(true);
                        }}
                      />
                    </IconContainer>
                  </div>
                </div>
              ),
            ]
          : []),
      ],
      hasHeader: false,
      headerText: [],
    };
  });

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        childField(<div key="space"></div>),
        multiDropField(
          siteOptions,
          t("Search..."),
          false,
          filter.siteId,
          (options) => {
            updateFilter({ siteId: options });
          }
        ),
        childField(
          <div
            className="userList_range_wrapper"
            key={"userList_range_wrapper"}
          >
            <DiscreteRangeDatePicker
              key={"userList_range"}
              onChange={(val: range) => {
                if (val.startDate || val.startDate) {
                  updateFilter({
                    date: {
                      startDate: val.startDate
                        ? moment(new Date(val.startDate)).format("YYYY-MM-DD")
                        : "",
                      endDate: val.endDate
                        ? moment(new Date(val.endDate)).format("YYYY-MM-DD")
                        : "",
                    },
                  });
                }
              }}
              clear={!filter.date.startDate && !filter.date.endDate}
              value={undefined}
            />
          </div>
        ),
        multiDropField(
          wasteOptions,
          t("Search..."),
          false,
          filter.wasteId,
          (options) => {
            updateFilter({ wasteId: options });
          }
        ),
        multiDropField(
          originOptions,
          t("Search..."),
          false,
          filter.originId,
          (options) => {
            updateFilter({ originId: options });
          }
        ),
        multiDropField(
          primarySourceOptions,
          t("Search..."),
          false,
          filter.primarySource,
          (options) => {
            updateFilter({ primarySource: options });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.amount}`,
          (value) => {
            updateFilter({
              amount: (value as unknown as number) || "",
            });
          }
        ),
        ...(expanded
          ? [
              multiDropField(
                handlingCodeOptions,
                t("Search..."),
                false,
                filter.handlingCode,
                (options) => {
                  updateFilter({ handlingCode: options });
                }
              ),
              multiDropField(
                carrierOptions,
                t("Search..."),
                false,
                filter.carrier,
                (options) => {
                  updateFilter({ carrier: options });
                }
              ),
              multiDropField(
                billingOptions,
                t("Search..."),
                false,
                filter.billing,
                (options) => {
                  updateFilter({ billing: options });
                }
              ),
              textField(
                "",
                false,
                () => {},
                filter.holder,
                (holder) => {
                  updateFilter({ holder });
                }
              ),
              multiDropField(
                municipalityOptions,
                t("Search..."),
                false,
                filter.municipalityId,
                (options) => {
                  updateFilter({ municipalityId: options });
                }
              ),
              childField(<div key={"space"}></div>),
            ]
          : []),
        childField(
          <div key="journal_expand" className="center journal_expand_head">
            <button className="btn-more" onClick={expandFilter}>
              {expanded ? <MinusBtn /> : <AddBtn />}
              <span>
                {t("Show")} {expanded ? t("less") : t("more")}
              </span>
            </button>
          </div>
        ),
      ],
      hasHeader: true,
      headerText: [
        Header([t("No.")], false, true),
        Header([t("Site")], false, true),
        Header([t("Date of receipt")], false),
        Header([t("Wastes")], false, true),
        Header([t("Origin of waste")], false, true),
        Header([t("Primary source of waste")], false, true),
        Header(
          [
            <span
              onClick={() =>
                appendSort(`weight`, true, true, { filter, updateFilter })
              }
              className="waste_accum_toggle waste_journal_toggle center"
            >
              {t("Amount received")}
            </span>,
          ],
          false,
          true
        ),
        ...(expanded
          ? [
              Header([t("Anticipated handling activities")], false, true),
              Header([t("Carrier")], false, true),
              Header([t("Billing")], false, true),
              Header([t("Waste holder")], false, true),
              Header([t("Municipality")], false, true),
              Header([t("Actions")], false),
            ]
          : []),
        Header([""], false, true),
      ],
    },
  ];

  return (
    <div className="waste-journal">
      {dialogVisibility && (
        <NewConfirmDialog
          txt={t("Are you sure you want to remove this accepted waste record?")}
          isVisible={dialogVisibility}
          onExit={() => {
            setDialogVisibility(false);
            setDeleteObj(undefined);
          }}
          isLoading={false}
          onContinue={() => {
            setDialogVisibility(false);
            deleteClientWaste();
          }}
        />
      )}
      {openIntg ? (
        <IntegrationPopUp
          close={() => {
            setOpenIntg(false);
          }}
        />
      ) : (
        <></>
      )}
      <div>
        <div className="DATASET__header-custom">
          <div className="table-action">
            <FilterButtons
              multiFilters={[
                "siteId",
                "wasteId",
                "originId",
                "municipalityId",
                "primarySource",
                "billing",
                "carrier",
                "handlingCode",
              ]}
              filter={filter}
              setFilter={setFilter}
              defaultFilter={defaultFilter}
              exclude={["order"]}
            />
            <div className="sortHolder">
              <Sorter
                valueOptions={sortOptions}
                onClick={(title) => {
                  const sortValue = sortOptions.column;
                  appendSort(sortValue, true, true, { filter, updateFilter });
                }}
                rotate={!enabled(sortOptions.column, { filter, updateFilter })}
              />
            </div>
            <div className="upload_wrapper">
              <UploadIcon
                style={{ pointerEvents: "none" }}
                title={t("Upload")}
                className="img_div_contain journal_upload"
                onClick={() => {
                  setOpenIntg(true);
                }}
              />
            </div>
            <ExcelDownload type={"wasteJournal"} query={Filter} />
            {/* <ButtonXlsx onClick={() => xlsxExport()} /> */}
          </div>
        </div>
      </div>
      <div className="viewExt">
        <GenerateForm
          className="journalFormMin"
          overflow
          loading={journalRes.loading}
          gridDist={pageConstruct[0].sizeDist}
        >
          {pageConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteAccumulation_1_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
          {textConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteAccumulation_2_${n}`}
              gridSizeDist={construct.sizeDist}
              id={isEditing(construct.id) ? selectedRowId : undefined}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
        </GenerateForm>
        <FractionList
          count={count}
          currentStart={currentStart}
          setCurrentPage={setCurrentPage}
          pageRecordNum={pageRecordNum}
          setPageRecordNum={setPageRecordNum}
        />
      </div>
    </div>
  );
};

export default WasteJournal;
