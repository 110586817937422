import React from "react";

const className = "floatInfo";
class FloatInfo {
  subscribe = (
    e: React.SyntheticEvent,
    text: string = "Ensure all requirements are met before moving forward"
  ) => {
    const target = e.target as HTMLElement;
    target.style.position = `relative`;
    const parent = target.parentElement;
    if (parent) {
      parent.style.position = `relative`;
    }
    const left = target.getBoundingClientRect().left;
    const bottom = target.getBoundingClientRect().bottom;
    const mainHeight = target.getBoundingClientRect().height;
    const mainWidth = target.getBoundingClientRect().width;
    const parentLeft = parent?.getBoundingClientRect().left;
    const parentBottom = parent?.getBoundingClientRect().bottom;
    const diffLeft = parentLeft ? left - parentLeft : 0;
    const diffBottom = parentBottom ? parentBottom - bottom : 0;
    const newElement = document.createElement("div");
    newElement.className = className;
    const Text = document.createTextNode(text);
    newElement.appendChild(Text);
    if (parent) {
      parent.appendChild(newElement);
    } else {
      target.appendChild(newElement);
    }
    const width = newElement.getBoundingClientRect().width;
    newElement.style.bottom = `${mainHeight + diffBottom + 12}px`;
    newElement.style.textAlign = `center`;
    const transform = width / 2 - mainWidth / 2;
    newElement.style.left = `${diffLeft - transform}px`;
  };
  unsubscribe = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const parent = target.parentElement;
    const Element = document.getElementsByClassName(className);
    if (parent) {
      for (let i = 0; i < Element.length; i++) {
        const element = Element[i];
        if (parent.contains(element)) {
          parent.removeChild(element);
          break;
        }
      }
    } else {
      for (let i = 0; i < Element.length; i++) {
        const element = Element[i];
        if (target.contains(element)) {
          target.removeChild(element);
          break;
        }
      }
    }
  };
}

const floatInfo = new FloatInfo();
export default floatInfo;
