import * as actionTypes from "../actionTypes";

export const getRoles = function () {
  return { type: actionTypes.GET_ROLES };
};

export const getInactiveRoles = () => ({
  type: actionTypes.GET_INACTIVE_ROLES,
});

export const deactivateRole = (payload) => ({
  type: actionTypes.DEACTIVATE_ROLE,
  payload,
});

export const restoreRole = (payload) => ({
  type: actionTypes.RESTORE_ROLE,
  payload,
});

export const createRole = (payload) => ({
  type: actionTypes.CREATE_ROLE,
  payload,
});

export const editRole = (payload) => ({
  type: actionTypes.EDIT_ROLE,
  payload,
});

export const hideRole = (payload) => ({
  type: actionTypes.HIDE_ROLE,
  payload,
});
