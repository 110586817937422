import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";

import "./assets/css/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import "flatpickr/dist/flatpickr.min.css";
import { store } from "./store";
import { getMode, recaptchaKey } from "./settings";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA || "";

if (localStorage.getItem("APP_VERSION") != APP_VERSION) {
  localStorage.clear();
  localStorage.setItem("APP_VERSION", APP_VERSION);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const mode = getMode();
root.render(
  <Provider store={store}>
    {mode === "production" ? (
      <GoogleReCaptchaProvider
        container={{ parameters: { badge: "bottomleft" } }}
        reCaptchaKey={recaptchaKey}
      >
        <Router>
          <App />
        </Router>
      </GoogleReCaptchaProvider>
    ) : (
      <Router>
        <App />
      </Router>
    )}
  </Provider>
);
