import { $CombinedState } from "redux";
import { stateType } from "../store";
// import { State } from "./reducers/initialStates";

type exclude = Exclude<keyof stateType, typeof $CombinedState>;

export class StateLoader {
  loadState() {
    try {
      let serializedState = localStorage.getItem("ROOT_STATE");

      if (serializedState === null) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state: stateType, exclude: exclude[] = []) {
    try {
      const newState = excludeSave(state, exclude);
      let serializedState = JSON.stringify(newState);
      localStorage.setItem("ROOT_STATE", serializedState);
    } catch (err) {}
  }

  initializeState() {
    return {
      //state object
    };
  }
}

export const excludeSave = (
  state: stateType,
  exclude: exclude[]
): stateType => {
  const stateCopy = { ...state };
  //Take care of later
  // exclude.forEach((key) => {
  //   // @ts-ignore
  //   stateCopy[key] = State[key];
  // });
  return stateCopy;
};
