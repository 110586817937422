import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";

const ExitButton = (props) => {
  return (
    <div className="popUp_Close center" onClick={props.action}>
      <CloseIcon />
    </div>
  );
};

export default ExitButton;
