import React, { useState, useEffect } from "react";

import { UserActivity, UserForm, UserList, UserGroupList } from "./elements";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusBtn.svg";

import "./style.scss";
import IconContainer from "../../UI/iconContainer/iconContainer";
import {
  Link,
  Route,
  useNavigate,
  useLocation,
  useResolvedPath,
  Routes,
} from "react-router-dom";
import { Input } from "../../UI/FormGenerator/FormGenerator";
import { userData } from "./elements/UserForm/UserForm";
import { userData as userData2 } from "./elements/UserDetail/UserDetail";
import ExcelDownload from "../../UI/DownloadPopUp/DownloadPopUp";
import { downloadType } from "../../../helpers/downloadHook";
import useTranslate from "../../../translate/useTranslate";
import { Fetch } from "../../../helpers/misc";
import { store } from "../../../store";
import { getUserSites } from "../../../stores/actions";
import { user } from "./elements/UserList/UserList";

export type rootFilterType = Partial<{ [key in downloadType]: string }>;

const UserManagement = function () {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const replace = (path: string) => navigate(path, { replace: true });
  const url = useResolvedPath("").pathname;
  const { pathname } = useLocation();

  const [globalKeywords, setGlobalKeywords] = useState<string | null>(null);
  const [retrieveXlsx, setRetrieveXlsx] = useState<number>();
  const [excelType, setExcelType] = useState<downloadType>();
  const [filter, setFilter] = useState<rootFilterType>({
    userList: "",
    userActivities: "",
    userGroups: "",
  });

  const urls = [`${url}`, `${url}/activities`, `${url}/groups`];

  const getType = (): downloadType | undefined => {
    switch (pathname) {
      case url: {
        return "userList";
      }
      case `${url}/activities`: {
        return "userActivities";
      }
      case `${url}/groups`: {
        return "userGroups";
      }

      default: {
        return undefined;
      }
    }
  };

  const type = getType();

  useEffect(() => {
    if (urls.indexOf(pathname) >= 0) {
      setExcelType(type);
    }
  }, [pathname]);

  const userDetailOnCancel = () => {
    replace(`${url}`);
  };

  const xlsxExport = (func: () => void) => {
    func();
    setRetrieveXlsx(undefined);
  };

  useEffect(() => {
    return () => {
      setRetrieveXlsx(undefined);
    };
  }, []);

  const [showDetails, setShowDetails] = useState(false);
  const [users, setUsers] = useState<user[]>([]);

  return (
    <div className="user-management">
      <div className="tab-content-header">
        <div className="table-actions">
          <div className="button_navigation">
            <Link
              onClick={() => {
                if (showDetails) {
                  setShowDetails(false);
                }
              }}
              to={`${url}`}
              className="userMgtNavLink"
            >
              <button
                className={`flexBtn tile ${
                  pathname === `${url}` ? "btn-green" : "btn-default"
                }`}
              >
                {t("List")}
              </button>
            </Link>
            <Link to={`${url}/activities`} className="userMgtNavLink">
              <button
                className={`flexBtn tile ${
                  pathname === `${url}/activities` ? "btn-green" : "btn-default"
                }`}
              >
                {t("Activity log")}
              </button>
            </Link>
            <Link to={`${url}/groups`} className="userMgtNavLink">
              <button
                className={`flexBtn tile ${
                  pathname === `${url}/groups` ? "btn-green" : "btn-default"
                }`}
              >
                {t("Working groups")}
              </button>
            </Link>
            <Link to={`${url}/create`} className="userMgtNavLink">
              <button
                className={`flexBtn spbtw tile ${
                  pathname === `${url}/create` ? "btn-green" : "btn-default"
                }`}
              >
                <IconContainer size="lg">
                  <PlusIcon className="userMgt_plus" />
                </IconContainer>
                <span>{t("New account")}</span>
              </button>
            </Link>
          </div>
          <Input
            error={false}
            handler={() => {}}
            className={"globalKeyword_input_wrapper"}
            placeholder={t("Search...")}
            type="text"
            value=""
            externalValue={globalKeywords || ""}
            setExternalValue={(txt) => setGlobalKeywords(txt)}
          />

          <ExcelDownload
            type={excelType}
            query={type ? filter[type] : ""}
            noRange={type === "userGroups"}
          />
          {/* <ButtonXlsx
            disabled={!exportable()}
            onClick={() => {
              if (exportable()) {
                setRetrieveXlsx(new Date().getTime());
              }
            }}
          /> */}
        </div>
      </div>
      <Routes>
        <Route
          path={""}
          // path={path}
          element={
            <UserList
              setUsers={setUsers}
              users={users}
              setShowDetails={setShowDetails}
              showDetails={showDetails}
              setRootFilter={setFilter}
              rootFilter={filter}
              xlsxExport={xlsxExport}
              retrieve={retrieveXlsx}
              globalKeywords={globalKeywords}
            />
          }
        />

        <Route
          path={"/activities"}
          element={
            <UserActivity
              setRootFilter={setFilter}
              rootFilter={filter}
              xlsxExport={xlsxExport}
              retrieve={retrieveXlsx}
              globalKeywords={globalKeywords}
            />
          }
        />
        <Route
          path={"groups"}
          element={
            <UserGroupList xlsxExport={xlsxExport} retrieve={retrieveXlsx} />
          }
        />
        <Route path={"create"} element={<UserForm />} />
      </Routes>
    </div>
  );
};

export default UserManagement;

export const saveUserDetails = async ({
  user,
  type,
}: { user: userData; type: "new" } | { user: userData2; type: "edit" }) => {
  try {
    await Fetch("/users", {
      method: type === "edit" ? "PUT" : "POST",
      body: typeof user === "string" ? user : JSON.stringify(user),
    });
    store.dispatch(getUserSites());

    return { saved: true };
  } catch (error) {
    // @ts-expect-error
    console.log(error?.error);
  }
};
