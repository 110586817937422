import { client } from "../../@types/client";
import { Fetch } from "../../helpers/misc";
import { contract } from "../Contract/Contract";

export const getClients = async (details: {
  option?: "true" | "false";
  query: string;
  limit: number;
  offset: number;
}) => {
  let { query, limit, offset, option } = details;

  return await Fetch(
    `/clients?${query}&limit=${limit}&offset=${offset}&option=${option || ""}`
  ).then(
    (res) => {
      const result: Promise<{ clients: client[]; count: number }> = res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};

export const getAllContracts = async () => {
  return await Fetch("/contracts/allContracts").then(
    (res) => {
      const result: Promise<{ contracts: contract[] }> = res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};
