import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { InputText } from "../../components/UI";
import { useAsync } from "../../helpers/asyncFunc";
import { signIn } from "./signInHandler";

import "./style.scss";
import useTranslate from "../../translate/useTranslate";
import { getMode } from "../../settings";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export type signed_user_type = {
  id: number;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  roleId: number | null;
  isActive: boolean;
  isDeleted: boolean;
  userGroupId: number | null;
};

const SignIn = function ({
  setLoggedUser,
  signOut,
}: {
  setLoggedUser: (
    value:
      | {
          user: signed_user_type;
          authToken: string;
        }
      | undefined
  ) => void;
  signOut: () => void;
}) {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const [uname, setUname] = useState("johnny");
  const [paswd, setPaswd] = useState("gerald");
  const [msg, setMsg] = useState("");

  const signInRes = useAsync(
    {
      asyncFunc: signIn,
      funcParams: {} as never,
      immediate: false,
      clearOnError: true,
    },
    []
  );

  const mode = getMode();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (mode !== "production") {
      return undefined;
    }
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("login");
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    const data = signInRes.data?.authInfo;
    if (data) {
      setLoggedUser(data);

      const link = location.state?.from;
      navigate(link && link.pathname !== "/signin" ? { ...link } : "/");
    }
  }, [signInRes.data]);

  useEffect(() => {
    const error = signInRes.error;
    const errorObj = signInRes.errorObject;
    if (errorObj || error) {
      const inactive = Object(errorObj).inactive;
      signOut();

      setMsg(
        inactive
          ? t("Access to your account has been disabled")
          : t("Your username or password is wrong")
      );
    }
  }, [signInRes.error]);

  useEffect(() => {
    setMsg("");
  }, [uname, paswd]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const recaptcha_token = await handleReCaptchaVerify();

    if ((mode === "production" && !recaptcha_token) || !uname || !paswd) {
      if (!recaptcha_token) {
        setMsg("Something went wrong");
      } else if (!uname) {
        setMsg("Username cannot be empty");
      } else if (!paswd) {
        setMsg("Password cannot be empty");
      }

      return;
    }

    signInRes.execute({ uname, paswd, recaptcha_token });
  };

  return (
    <div className="signin">
      <div className="container">
        <div className="content row justify-content-center">
          <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 col-xxl-4">
            <div className="panel panel-login">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12 col-xs-6">
                    <h3>{t("LOGIN")}</h3>
                  </div>
                </div>
                <hr />
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-12">
                    <form style={{ display: "block" }} onSubmit={onSubmit}>
                      <div className="form-group">
                        <label>{t("Username")}</label>
                        <InputText
                          name="userid"
                          value={uname}
                          onChange={(e) => setUname(e.target.value)}
                          placeholder={t(
                            "Enter the user name or email address"
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <label>{t("Password")}</label>
                        <InputText
                          isPassword
                          name="password"
                          value={paswd}
                          onChange={(e) => setPaswd(e.target.value)}
                          placeholder={t("Enter the password")}
                        />
                      </div>
                      <div className="form-group">
                        {msg && (
                          <div className="row">
                            <div className="col-12 txt-error">{msg}</div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12">
                            <input
                              type="submit"
                              name="login-submit"
                              id="login-submit"
                              className="btn btn-green"
                              disabled={signInRes.loading}
                              value={t("Login")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
