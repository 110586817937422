import { connect } from "react-redux";
import CompanyProfile from "./CompanyProfile";
import * as actions from "../../stores/actions";
import { company } from "../../@types/client";

const mapStoreStateToProps = (store: any) => ({
  savingStatus: store.companyReducer.savingStatus,
  company: store.companyReducer.company,
});

const mapDispatchToProps = (dispatch: (...arg: any[]) => void) => ({
  saveCompany: (company: company) => {
    dispatch(actions.saveCompany(company));
  },
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(CompanyProfile);
