import React, { useEffect, useRef, useState } from "react";
import { site } from "../../@types/sites";
import {
  primarySource,
  wasteActivityTypeType,
  wastePerformType,
  wasteType,
} from "../../@types/waste";
import {
  childField,
  textField,
  dropField,
  multiDropField,
} from "../../components/UI/FormGenerator/formField";
import { Search } from "../../components/UI/FormGenerator/FormGenerator";
import { gridType, option } from "../../components/UI/FormGenerator/formTypes";
import { defaultOption, docNumTransform, shortName } from "../../helpers/misc";
import { municipalityType, originType } from "../WasteAcceptance/@types";
import "./Report.css";
import { ReportBox } from "./ReportBox";
import { headerTitle } from "./exportDeclaration";
import {
  perAnnualType,
  perAnnualValue,
  perCustomerType,
  perCustomerValue,
  perDetailedReportType,
  perDetailedReportValue,
  perExchangeReport,
  perExchangeReportType,
  perGpaisReport,
  perGpaisReportType,
  perInvoiceType,
  perInvoiceValue,
  perMunicipalityType,
  perMunicipalityValue,
  perOriginType,
  perOriginValue,
  perSourceType,
  perSourceValue,
  perTansferReportVaue,
  perTransferReportType,
  perTypeType,
  perTypeValue,
  perWasteType,
  perWasteValue,
  perWeighingInvoiceType,
  perWeighingInvoiceValue,
  range,
} from "./@types";
import DiscreteRangeDatePicker from "../../components/UI/DiscreteRangePicker/DiscreteRangePicker";
import { getClients, getAllContracts } from "../Client/getter";
import { billing, client, company, person } from "../../@types/client";
import { getInvoices } from "./getter";
import { carrier } from "../../@types/user";
import useTranslate from "../../translate/useTranslate";

const Report = ({
  getReport,
  wastes,
  sites,
  municipalities,
  primarySources,
  origins,
  wasteActivityTypes,
  getWasteActivityTypes,
  wastePerforms,
  getWastePerforms,
  carriers,
  billings,
}: {
  getReport: (data: {
    title: string;
    headers: headerTitle[];
    query: { [key: string]: any } | {};
    url: string;
    reset: () => void;
    setReportNotifValue: (data: string) => void;
    removeLoading: (data: string) => void;
  }) => void;
  wastes: wasteType[];
  sites: site[];
  municipalities: municipalityType[];
  primarySources: primarySource[];
  origins: originType[];
  wasteActivityTypes: wasteActivityTypeType[];
  getWasteActivityTypes: () => void;
  wastePerforms: wastePerformType[];
  getWastePerforms: () => void;
  carriers: carrier[];
  billings: billing[];
}) => {
  const { t } = useTranslate();

  const reportHeaders: string[] = [
    t("Report of received-transferred waste quantities by customers"),
    t("The amount of waste by sources of waste"),
    t("Report of waste holders by type"),
    t("Summary of waste quantities by municipality"),
    t("Detailed report of waste acceptances"),
    t("Detailed transfer report"),
    t("Summary of acceptance by origin"),
    t("Summary of Waste residue"),
    t("Detailed weighing report according to selected invoice"),
    t("Weighing reports"),
    t("Annual summary of received waste"),
    t("Waste management accounting journal (adapted for submission to GPAIS)"),
    t("Weight Exchange Audit Report"),
  ];
  const [timeoutId, setTimeoutId] = useState<number | undefined>();
  const [loading, setLoading] = useState<string[]>([]);

  const [perCustomer, setPerCustomer] =
    useState<perCustomerType>(perCustomerValue);
  const [perSource, setPerSource] = useState<perSourceType>(perSourceValue);
  const [perType, setPerType] = useState<perTypeType>(perTypeValue);
  const [perMunicipality, setPerMunicipality] =
    useState<perMunicipalityType>(perMunicipalityValue);
  const [detailedReport, setDetailedReport] = useState<perDetailedReportType>(
    perDetailedReportValue
  ); // no acceptance
  const [transferReport, setTransferReport] =
    useState<perTransferReportType>(perTansferReportVaue); // no acceptance
  const [perOrigin, setPerOrigin] = useState<perOriginType>(perOriginValue);
  const [perWaste, setPerWaste] = useState<perWasteType>(perWasteValue);
  const [perInvoice, setPerInvoice] = useState<perInvoiceType>(perInvoiceValue);
  const [perWeighingInvoice, setPerWeighingInvoice] =
    useState<perWeighingInvoiceType>(perWeighingInvoiceValue);
  const [perAnnual, setPerAnnual] = useState<perAnnualType>(perAnnualValue);
  const [gpaisReport, setGpaisReport] =
    useState<perGpaisReportType>(perGpaisReport); //no accept
  const [exchangeReport, setExchangeReport] =
    useState<perExchangeReportType>(perExchangeReport); //no accept
  /////
  const [reportSearch, setReportSearch] = useState("");
  const [wasteOptions, setWasteOptions] = useState<option[]>([]);
  const [originOptions, setOriginOptions] = useState<option[]>([]);
  const [holderOptions, setHolderOptions] = useState<
    (option & { type: "person" | "company" })[]
  >([]);
  const [companyHolderOptions, setCompanyHolderOptions] = useState<option[]>(
    []
  );
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [contractOptions, setContractOptions] = useState<option[]>([]);
  const [invoiceOptions, setInvoiceOptions] = useState<option[]>([]);
  const [billingOptions, setBillingOptions] = useState<option[]>([]);
  const [municipalityOptions, setMunicipalityOptions] = useState<option[]>([]);
  const [primarySourcesOptions, setPrimarySourcesOptions] = useState<option[]>(
    []
  );
  const [wasteActivityTypeOptions, setWasteActivityTypeOptions] = useState<
    option[]
  >([]);
  const [wastePerformOptions, setWastePerformOptions] = useState<option[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<option[]>([]);

  const reportNotifRef = useRef<string[]>([]);
  const [reportNotif, setReportNotif] = useState<string[]>([]);

  const createDist = (cols: number, total: number): number[] => {
    return Array(cols).fill(total / cols);
  };
  const rangeDate: range = {
    startDate: "",
    endDate: "",
  };

  const getYears = (): option[] => {
    const startDate = 2022;
    const endDate = new Date().getFullYear();
    const diff = endDate - startDate;
    const options = Array(diff + 1)
      .fill(0)
      .map((arr, n) => {
        return {
          label: `${startDate + n}`,
          value: n,
        };
      });

    return options;
  };

  const years = getYears();

  const getYearOption = (): option => {
    if (perAnnual.year === perAnnualValue.year) {
      return defaultOption;
    } else {
      const selectedYear = perAnnual.year;

      const option: option | undefined = years.find(
        (value) => `${value.label}` === `${selectedYear}`
      );
      return option || defaultOption;
    }
  };

  const yearOption = getYearOption();

  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const isLoading = (data: string): boolean => {
    return loading.some((l) => l === data);
  };

  const getTrasferOptions = (options: option[], isSite: boolean): option[] => {
    const new_options = options.map((opt) => ({
      label: opt.label,
      value: JSON.stringify({
        id: opt.value,
        type: isSite ? "site" : "company",
      }),
    }));

    return new_options as unknown as option[];
  };

  const RecordConstruct: recordConstruct[] = [
    {
      sizeDist: [4, 1.5, 1.5, 2.5, 2.5, 2.5, 2.5],
      state: perCustomer,
      setter: setPerCustomer,
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper1"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: range) => {
                  setPerCustomer({
                    ...perCustomer,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!perCustomer.endDate && !perCustomer.startDate}
                value={undefined}
              />
            </div>
          ),
          textField(``, false, undefined, perCustomer.name, (text) => {
            setPerCustomer({ ...perCustomer, name: text });
          }),
          textField(``, false, undefined, perCustomer.surname, (text) => {
            setPerCustomer({ ...perCustomer, surname: text });
          }),
          textField(``, false, undefined, perCustomer.companyName, (text) => {
            setPerCustomer({ ...perCustomer, companyName: text });
          }),
          multiDropField(
            wasteOptions,
            "",
            false,
            perCustomer.wasteId,
            (options) => {
              setPerCustomer({
                ...perCustomer,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            perCustomer.siteId,
            (options) => {
              setPerCustomer({
                ...perCustomer,
                siteId: options,
              });
            }
          ),
          childField(
            <div
              key={"userList_range_wrapper2"}
              className="report_btn_container"
            >
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[0],
                    query: perCustomer,
                    url: "/perCustomer",
                    removeWeight: true,
                    headers: [
                      "Period",
                      "Name",
                      "Surname",
                      "Company name",
                      "Wastes",
                      "Site",
                      "Amount received, t",
                      "Price (per kg) without VAT",
                      "Price (per kg) with VAT",
                      "Amount without VAT",
                      "Price with VAT",
                    ],
                    reset() {
                      setPerCustomer({ ...perCustomerValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[0]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[0],
      headerDist: [
        [
          t("Period"),
          t("Name"),
          t("Surname"),
          t("Company name"),
          t("Wastes"),
          t("Site"),
        ],
      ],
    },
    {
      state: perSource,
      setter: setPerSource,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setPerSource({
                    ...perSource,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!perSource.endDate && !perSource.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            perSource.wasteId,
            (options) => {
              setPerSource({
                ...perSource,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            perSource.siteId,
            (options) => {
              setPerSource({
                ...perSource,
                siteId: options,
              });
            }
          ),
          multiDropField(
            primarySourcesOptions,
            "",
            false,
            perSource.primarySource,
            (options) => {
              setPerSource({
                ...perSource,
                primarySource: options,
              });
            }
          ),
          childField(<div key={`empty child`}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[1],
                    query: perSource,
                    headers: [
                      "Period",
                      "Wastes",
                      "Site",
                      "Primary source of waste",
                    ],
                    url: "/perSource",
                    reset() {
                      setPerSource({ ...perSourceValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[1]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[1],
      headerDist: [
        [t("Period"), t("Wastes"), t("Site"), t("Primary source of waste")],
      ],
    },
    {
      state: perType,
      setter: setPerType,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setPerType({
                    ...perType,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!perType.endDate && !perType.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            perType.wasteId,
            (options) => {
              setPerType({
                ...perType,
                wasteId: options,
              });
            }
          ),
          dropField(
            [
              {
                label: t("Individual"),
                value: 1,
              },
              {
                label: `Juridinis asmuo`,
                value: 2,
              },
            ],
            "",
            false,
            perType.isCompany === perTypeValue.isCompany
              ? defaultOption
              : perType.isCompany
              ? {
                  label: `Juridinis asmuo`,
                  value: 2,
                }
              : {
                  label: `Fizinis asmuo`,
                  value: 1,
                },
            (value) => {
              const isTrue = value === 2;
              setPerType({
                ...perType,
                isCompany: isTrue,
              });
            },
            true
          ),
          multiDropField(siteOptions, "", false, perType.siteId, (options) => {
            setPerType({
              ...perType,
              siteId: options,
            });
          }),
          multiDropField(
            primarySourcesOptions,
            "",
            false,
            perType.primarySource,
            (options) => {
              setPerType({ ...perType, primarySource: options });
            }
          ),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[2],
                    query: perType,
                    headers: [
                      "Period",
                      "Wastes",
                      "Type of waste holder",
                      "Site",
                      "Primary source of waste",
                    ],
                    url: "/perType",
                    reset() {
                      setPerType({ ...perTypeValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[2]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[2],
      headerDist: [
        [
          t("Period"),
          t("Wastes"),
          t("Type of waste holder"),
          t("Site"),
          t("Primary source of waste"),
        ],
      ],
    },
    {
      state: perMunicipality,
      setter: setPerMunicipality,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setPerMunicipality({
                    ...perMunicipality,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!perMunicipality.endDate && !perMunicipality.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            perMunicipality.wasteId,
            (options) => {
              setPerMunicipality({
                ...perMunicipality,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            perMunicipality.siteId,
            (options) => {
              setPerMunicipality({
                ...perMunicipality,
                siteId: options,
              });
            }
          ),
          multiDropField(
            municipalityOptions,
            "",
            false,
            perMunicipality.municipalityId,
            (options) => {
              setPerMunicipality({
                ...perMunicipality,
                municipalityId: options,
              });
            }
          ),
          childField(<div key={"space1"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[3],
                    query: perMunicipality,
                    headers: ["Period", "Wastes", "Site", "Municipality"],
                    url: "/perMunicipality",
                    reset() {
                      setPerMunicipality({ ...perMunicipalityValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[3]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[3],
      headerDist: [[t("Period"), t("Wastes"), t("Site"), t("Municipality")]],
    },
    {
      state: detailedReport,
      setter: setDetailedReport,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setDetailedReport({
                    ...detailedReport,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!detailedReport.endDate && !detailedReport.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            detailedReport.wasteId,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            holderOptions,
            "",
            false,
            detailedReport.wasteHolderId,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                wasteHolderId: options,
              });
            }
          ),
          multiDropField(
            companyHolderOptions,
            "",
            false,
            detailedReport.companyHolderId,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                companyHolderId: options,
              });
            }
          ),
          childField(<div key={"space2"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[4],
                    query: detailedReport,
                    headers: [
                      "Period",
                      "Wastes",
                      "Waste holder",
                      "Carrier",
                      "Site",
                      "Municipality",
                      "Origin of waste",
                      "Primary source of waste",
                      "Billing",
                    ],
                    url: "/detailedReport",
                    reset() {
                      setDetailedReport({ ...perDetailedReportValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[4]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
        [
          multiDropField(
            siteOptions,
            "",
            false,
            detailedReport.siteId,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                siteId: options,
              });
            }
          ),
          multiDropField(
            municipalityOptions,
            "",
            false,
            detailedReport.municipalityId,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                municipalityId: options,
              });
            }
          ),
          multiDropField(
            originOptions,
            "",
            false,
            detailedReport.originId,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                originId: options,
              });
            }
          ),
          multiDropField(
            primarySourcesOptions,
            "",
            false,
            detailedReport.primarySource,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                primarySource: options,
              });
            }
          ),
          multiDropField(
            billingOptions,
            "",
            false,
            detailedReport.billing,
            (options) => {
              setDetailedReport({
                ...detailedReport,
                billing: options,
              });
            }
          ),
        ],
      ],
      header: reportHeaders[4],
      headerDist: [
        [t("Period"), t("Wastes"), t("Waste holder"), t("Carrier")],
        [
          t("Site"),
          t("Municipality"),
          t("Origin of waste"),
          t("Primary source of waste"),
          t("Billing"),
        ],
      ],
    },
    {
      state: transferReport,
      setter: setTransferReport,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setTransferReport({
                    ...transferReport,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!transferReport.endDate && !transferReport.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            transferReport.wasteId,
            (options) => {
              setTransferReport({
                ...transferReport,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            [
              ...getTrasferOptions(siteOptions, true),
              ...getTrasferOptions(companyHolderOptions, false),
            ],
            "",
            false,
            transferReport.recipient,
            (options) => {
              setTransferReport({
                ...transferReport,
                recipient: [...options],
              });
            }
          ),
          childField(<div key={"space3"}></div>),
          childField(<div key={"space4"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                // disabled
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[5],
                    query: transferReport,
                    headers: [
                      "Period",
                      "Wastes",
                      "Recipient of the waste",
                      "Site",
                      "Waste carrier",
                      "Contract no.",
                    ],
                    url: "/transferReport",
                    reset() {
                      setTransferReport({ ...perTansferReportVaue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[5]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
        [
          multiDropField(
            siteOptions,
            "",
            false,
            transferReport.siteId,
            (options) => {
              setTransferReport({
                ...transferReport,
                siteId: options,
              });
            }
          ),
          multiDropField(
            carrierOptions,
            "",
            false,
            transferReport.carrierId,
            (options) => {
              setTransferReport({
                ...transferReport,
                carrierId: options,
              });
            }
          ),
          multiDropField(
            contractOptions,
            "",
            false,
            transferReport.contractId,
            (options) => {
              setTransferReport({
                ...transferReport,
                contractId: options,
              });
            }
          ),
        ],
      ],
      header: reportHeaders[5],
      headerDist: [
        [t("Period"), t("Wastes"), t("Recipient of the waste")],
        [t("Site"), t("Waste carrier"), t("Contract no.")],
      ],
    },
    {
      state: perOrigin,
      setter: setPerOrigin,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setPerOrigin({
                    ...perOrigin,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!perOrigin.endDate && !perOrigin.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            perOrigin.siteId,
            (options) => {
              setPerOrigin({
                ...perOrigin,
                siteId: options,
              });
            }
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            perOrigin.wasteId,
            (options) => {
              setPerOrigin({
                ...perOrigin,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            primarySourcesOptions,
            "",
            false,
            perOrigin.primarySource,
            (options) => {
              setPerOrigin({
                ...perOrigin,
                primarySource: options,
              });
            }
          ),
          multiDropField(
            municipalityOptions,
            "",
            false,
            perOrigin.municipalityId,
            (options) => {
              setPerOrigin({
                ...perOrigin,
                municipalityId: options,
              });
            }
          ),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[6],
                    query: perOrigin,
                    url: "/perOrigin",
                    headers: [
                      "Period",
                      "Site",
                      "Wastes",
                      "Primary source of waste",
                      "Municipality",
                    ],
                    reset() {
                      setPerOrigin({ ...perOriginValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[6]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[6],
      headerDist: [
        [
          t("Period"),
          t("Site"),
          t("Wastes"),
          t("Primary source of waste"),
          t("Municipality"),
        ],
      ],
    },
    {
      state: perWaste,
      setter: setPerWaste,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setPerWaste({
                    ...perWaste,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!perWaste.endDate && !perWaste.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(siteOptions, "", false, perWaste.siteId, (options) => {
            setPerWaste({
              ...perWaste,
              siteId: options,
            });
          }),
          multiDropField(
            wasteOptions,
            "",
            false,
            perWaste.wasteId,
            (options) => {
              setPerWaste({
                ...perWaste,
                wasteId: options,
              });
            }
          ),
          childField(<div key={"space5"}></div>),
          childField(<div key={"space6"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[7],
                    query: perWaste,
                    url: "/perWaste",
                    headers: ["Period", "Site", "Wastes"],
                    reset() {
                      setPerWaste({ ...perWasteValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[7]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[7],
      headerDist: [[t("Period"), t("Site"), t("Wastes")]],
    },
    {
      state: perInvoice,
      setter: setPerInvoice,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setPerInvoice({
                    ...perInvoice,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!perInvoice.endDate && !perInvoice.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            perInvoice.siteId,
            (options) => {
              setPerInvoice({
                ...perInvoice,
                siteId: options,
              });
            }
          ),
          multiDropField(
            invoiceOptions,
            "",
            false,
            perInvoice.invoice,
            (options) => {
              setPerInvoice({
                ...perInvoice,
                invoice: options,
              });
            }
          ),
          childField(<div key={"space7"}></div>),
          childField(<div key={"space8"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[8],
                    query: perInvoice,
                    url: "/perInvoice",
                    headers: [
                      "Period",
                      "Site",
                      "Waste holder",
                      "Formal Invoice",
                    ],
                    reset() {
                      setPerInvoice({ ...perInvoiceValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[8]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[8],
      headerDist: [[t("Period"), t("Site"), t("Formal Invoice")]],
    },
    {
      state: perWeighingInvoice,
      setter: setPerWeighingInvoice,
      sizeDist: [4, ...createDist(4, 10.5), 2.5],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setPerWeighingInvoice({
                    ...perWeighingInvoice,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={
                  !perWeighingInvoice.endDate && !perWeighingInvoice.startDate
                }
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            perWeighingInvoice.wasteId,
            (options) => {
              setPerWeighingInvoice({
                ...perWeighingInvoice,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            invoiceOptions,
            "",
            false,
            perWeighingInvoice.invoice,
            (options) => {
              setPerWeighingInvoice({
                ...perWeighingInvoice,
                invoice: options,
              });
            }
          ),
          childField(<div key={"space9"}></div>),
          childField(<div key={"space10"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[9],
                    query: perWeighingInvoice,
                    url: "/perWeighingInvoice",
                    headers: ["Period", "Wastes", "Formal Invoice"],
                    reset() {
                      setPerWeighingInvoice({ ...perWeighingInvoiceValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[9]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[9],
      headerDist: [[t("Period"), t("Wastes"), t("Formal Invoice")]],
    },
    {
      state: perAnnual,
      setter: setPerAnnual,
      sizeDist: [...createDist(5, 14.5), 2.5],
      rowDist: [
        [
          dropField(
            years,
            "",
            false,
            yearOption,
            (value) => {
              const option = years.find((year) => year.value === value);
              let yearRes: number =
                parseInt(option?.label!) || new Date().getFullYear();

              setPerAnnual({
                ...perAnnual,
                year: yearRes,
              });
            },
            true
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            perAnnual.siteId,
            (options) => {
              setPerAnnual({
                ...perAnnual,
                siteId: options,
              });
            }
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            perAnnual.wasteId,
            (options) => {
              setPerAnnual({
                ...perAnnual,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            primarySourcesOptions,
            "",
            false,
            perAnnual.primarySource,
            (options) => {
              setPerAnnual({
                ...perAnnual,
                primarySource: options,
              });
            }
          ),
          childField(<div key={"space11"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[10],
                    query: perAnnual,
                    url: "/perAnnual",
                    headers: [
                      "Year",
                      "Site",
                      "Wastes",
                      "Primary source of waste",
                    ],
                    reset() {
                      setPerAnnual({ ...perAnnualValue });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[10]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[10],
      headerDist: [
        [t("Year"), t("Site"), t("Wastes"), t("Primary source of waste")],
      ],
    },
    {
      state: gpaisReport,
      setter: setGpaisReport,
      sizeDist: [3, 2, 2, 2, 2, 2],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setGpaisReport({
                    ...gpaisReport,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!gpaisReport.endDate && !gpaisReport.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            gpaisReport.siteId,
            (options) => {
              setGpaisReport({
                ...gpaisReport,
                siteId: options,
              });
            }
          ),
          multiDropField(
            wasteOptions,
            "",
            false,
            gpaisReport.wasteId,
            (options) => {
              setGpaisReport({
                ...gpaisReport,
                wasteId: options,
              });
            }
          ),
          multiDropField(
            wasteActivityTypeOptions,
            "",
            false,
            gpaisReport.wasteActivityTypeId,
            (options) => {
              setGpaisReport({
                ...gpaisReport,
                wasteActivityTypeId: options,
              });
            }
          ),
          multiDropField(
            wastePerformOptions,
            "",
            false,
            gpaisReport.performId,
            (options) => {
              setGpaisReport({
                ...gpaisReport,
                performId: options,
              });
            }
          ),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                disabled
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[11]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[11],
      headerDist: [
        [
          t("Period"),
          t("Site"),
          t("Wastes"),
          t("Waste management activities"),
          t("Waste Generated"),
        ],
      ],
    },
    {
      state: exchangeReport,
      setter: setExchangeReport,
      sizeDist: [3, 2, 2, 2, 2, 2],
      rowDist: [
        [
          childField(
            <div
              className="userList_range_wrapper"
              key={"userList_range_wrapper"}
            >
              <DiscreteRangeDatePicker
                key={"userList_range"}
                onChange={(val: typeof rangeDate) => {
                  setExchangeReport({
                    ...exchangeReport,
                    endDate: val.endDate || ``,
                    startDate: val.startDate || ``,
                  });
                }}
                clear={!exchangeReport.endDate && !exchangeReport.startDate}
                value={undefined}
              />
            </div>
          ),
          multiDropField(
            siteOptions,
            "",
            false,
            exchangeReport.siteId,
            (options) => {
              setExchangeReport({
                ...exchangeReport,
                siteId: options,
              });
            }
          ),
          childField(<div key={"space12"}></div>),
          childField(<div key={"space13"}></div>),
          childField(<div key={"space14"}></div>),
          childField(
            <div key={"button_xlx"} className="report_btn_container">
              <button
                onClick={() => {
                  downloadReport({
                    title: reportHeaders[12],
                    query: exchangeReport,
                    url: "/perExchange",
                    removeWeight: true,
                    headers: [
                      "Period",
                      "Operator",
                      "Site",
                      "Wastes",
                      "Before correction (kg)",
                      "After correction (kg)",
                    ],
                    reset() {
                      setExchangeReport({ ...perExchangeReport });
                    },
                  });
                }}
                className={`report_btn btn-green ${
                  isLoading(reportHeaders[12]) ? "loading_report_btn" : ""
                }`}
              >
                {t("Create Report")}
              </button>
            </div>
          ),
        ],
      ],
      header: reportHeaders[12],
      headerDist: [[t("Period"), t("Site")]],
    },
  ];

  useEffect(() => {
    getClients({ option: `true`, query: "", offset: 0, limit: 10 ** 9 }).then(
      (result) => {
        const holders = result.clients as client[];
        const companyOptions: option[] = [];
        const options: (option & { type: "person" | "company" })[] =
          holders.map((holder: person | company) => {
            if (
              Object.keys(holder).indexOf(`name`) >= 0 &&
              (holder as person)["name"]
            ) {
              return {
                label: `${(holder as person).name} ${
                  (holder as person).surname
                }`,
                value: (`person_` + holder.id) as unknown as number,
                type: `person`,
              };
            } else {
              companyOptions.push({
                label: (holder as company).companyName,
                value: holder.id,
              });
              return {
                label: (holder as company).companyName,
                value: (`company_` + holder.id) as unknown as number,
                type: `company`,
              };
            }
          });
        setCompanyHolderOptions([...companyOptions]);
        setHolderOptions(options);
      },
      (err) => {
        console.log(err);
      }
    );
    //
    getInvoices()
      .then((Inv) => {
        const invoices = Inv.invoices;
        if (invoices) {
          const options: option[] = invoices.map((inv) => ({
            label: `${inv.serija || "--"} ${docNumTransform(inv.id)}`,
            value: inv.id as unknown as number,
          }));
          setInvoiceOptions(options);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //
    getWasteActivityTypes();
    //
    getWastePerforms();
    //
    getAllContracts()
      .then((res) => {
        const contracts = res.contracts;
        const options = contracts.map((item) => ({
          label: item.contractNr,
          value: item.id,
        }));
        setContractOptions([...options]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (wastes) {
      const options: option[] = wastes.map((waste) => ({
        label: waste.waste,
        value: waste.id,
      }));
      setWasteOptions([...options]);
    }
  }, [wastes]);
  useEffect(() => {
    if (sites) {
      const options: option[] = sites.map((site) => ({
        label: site.name,
        value: site.id,
      }));
      setSiteOptions(options);
    }
  }, [sites]);
  useEffect(() => {
    if (origins) {
      const options: option[] = origins.map((origin) => ({
        label: origin.name,
        value: origin.id,
      }));
      setOriginOptions(options);
    }
  }, [origins]);
  useEffect(() => {
    if (billings) {
      const options: option[] = billings.map((billing) => ({
        label: billing.name,
        value: billing.id,
      }));
      setBillingOptions(options);
    }
  }, [billings]);
  useEffect(() => {
    if (municipalities) {
      const options: option[] = municipalities.map((municipality) => ({
        label: shortName(municipality.name),
        value: municipality.id,
      }));
      setMunicipalityOptions(options);
    }
  }, [municipalities]);
  useEffect(() => {
    if (primarySources) {
      const options: option[] = primarySources.map((primarySource) => ({
        label: primarySource.name,
        value: primarySource.id,
      }));
      setPrimarySourcesOptions(options);
    }
  }, [primarySources]);
  useEffect(() => {
    if (wasteActivityTypes) {
      const option = wasteActivityTypes.map((item) => {
        return { label: item.name, value: item.id };
      });

      setWasteActivityTypeOptions([...option]);
    }
  }, [wasteActivityTypes]);
  useEffect(() => {
    if (wastePerforms) {
      const option = wastePerforms.map((item) => {
        return { label: item.name, value: item.id };
      });

      setWastePerformOptions([...option]);
    }
  }, [wastePerforms]);
  useEffect(() => {
    if (carriers) {
      const option = carriers.map((item) => {
        return { label: item.name, value: item.id };
      });

      setCarrierOptions([...option]);
    }
  }, [carriers]);

  const downloadReport = (data: {
    title: string;
    headers: headerTitle[];
    removeWeight?: boolean;
    query: { [key: string]: any };
    url: string;
    reset: () => void;
  }) => {
    const loadValues = [...loading].filter((l) => l !== data.title);
    setLoading([...loadValues, data.title]);

    getReport({
      ...data,
      headers: [
        ...data.headers,
        ...((data.removeWeight ? [] : ["Amount received, t"]) as headerTitle[]),
      ],
      removeLoading: (data: string) => {
        const loadValues = [...loading];
        setLoading([...loadValues.filter((l) => l !== data)]);
      },
      setReportNotifValue: (data: string) => {
        const nts = [...reportNotifRef.current];
        reportNotifRef.current = [...nts, data];
        setReportNotif(reportNotifRef.current);

        const copyTimeout = window.setTimeout(() => {
          if (mountedRef.current) {
            const nts = [...reportNotifRef.current];
            nts.shift();
            reportNotifRef.current = [...nts];
            setReportNotif(reportNotifRef.current);
          }
        }, 5000);
        setTimeoutId(copyTimeout);
      },
    });
  };

  type recordConstruct = {
    header: string;
    state: { [key: string]: any };
    setter: (...args: any[]) => void;
    headerDist: string[][];
    sizeDist: (number | string)[];
    rowDist: gridType[][];
  };

  const handleSubmit = (text: string) => {
    setReportSearch(text);
  };

  type removeFromList = (
    item: { label: string; value: number },
    list: { label: string; value: number }[],
    setter: (data: { label: string; value: number }[]) => void
  ) => void;
  const removeFromList: removeFromList = (item, list, setter) => {
    const newArray: option[] = [];
    list.forEach((l) => {
      if (l.value !== item.value) {
        newArray.push(l);
      }
    });
    setter(newArray);
  };

  return (
    <div className="report">
      <div
        style={!reportNotif.length ? { pointerEvents: "none" } : undefined}
        className="report_notification_message_wrapper"
      >
        {reportNotif.map((notif, n) => (
          <div key={n} className="report_notification_message_container">
            {notif}
          </div>
        ))}
      </div>
      <div className="report_search_wrapper">
        <p>{t("SELECT REPORT TYPE")}</p>
        <Search
          externalValue={reportSearch}
          setExternalValue={handleSubmit}
          search={handleSubmit}
          placeholder={t("Search...")}
        />
      </div>
      <div className="report_wrapper">
        {RecordConstruct.map(
          (construct, index) =>
            construct.header
              .toLowerCase()
              .includes(reportSearch.toLowerCase()) && (
              <ReportBox
                index={index}
                parentState={construct.state}
                setter={construct.setter}
                key={`record_table${index}`}
                sizeDist={construct.sizeDist}
                headerDist={construct.headerDist}
                rowDist={construct.rowDist}
                header={construct.header}
              />
            )
        )}
      </div>
    </div>
  );
};

export default Report;
