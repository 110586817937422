import React, { useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import _ from "lodash";
import { UserManagement } from "../../components/elements";

import "./style.scss";
import "./Settings.css";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import Serija from "../Serija";
import Client from "../Client/Client";
import Classifier from "../Classifier";
import SiteLimits from "../SiteLimits";
import SiteWastes from "../SiteWastes";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import Waste from "../Waste";
import Notification from "../../components/UI/Notification/Notification";
import useTranslate from "../../translate/useTranslate";
import { BlankLocale } from "../../translate/translationBlank";

const Settings = function () {
  const { t } = useTranslate();
  const url = useResolvedPath("").pathname;
  const { pathname } = useLocation();

  const [addingNewWaste, setAddingNewWaste] = useState(false);
  const [addingNewSerija, setAddingNewSerija] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [createError, setCreateError] = useState(false);

  const reset = () => {
    setCreateSuccess(false);
    setCreateError(false);
  };

  const links = [
    {
      path: "site-wastes",
      title: t("Limits and rates of new waste"),
      nested: false,
    },
    {
      path: "waste-limits",
      title: t("Limits of accumulated waste"),
      nested: false,
    },
    { path: "staff", title: t("Employees"), nested: true },
    { path: "clients", title: t("Clients"), nested: true },
    { path: "classifiers", title: t("Classification"), nested: false },
  ];

  const activePath = (path: string, nested: boolean) => {
    if (nested) {
      return pathname.startsWith(`${url}/${path}`);
    } else {
      return pathname === `${url}/${path}`;
    }
  };

  return (
    <div className="settings">
      <Notification
        reset={reset}
        isDone={createSuccess || createError}
        error={createError}
        text={
          createSuccess || createError ? (
            <div className={`DATASET__notification-text`}>
              {createSuccess ? (
                <>
                  <img
                    src={require("../../assets/images/tick.svg").default}
                    alt=""
                  />{" "}
                  {t("Waste record created")}
                </>
              ) : (
                "Error creating Waste record."
              )}
            </div>
          ) : undefined
        }
      />
      {/* txt={t("Do you really want to remove this waste from the waste list")} */}

      <div className="HOMEPAGE__section">
        <div className="HOMEPAGE__section-title">{t("Settings")}</div>
      </div>

      <div className="tab-nav">
        <ul>
          {links.map((link, n) => (
            <li key={n}>
              <Link
                to={`${url}/${link.path}`}
                className={`${
                  activePath(link.path, link.nested) ? "tab-link-active" : ""
                }`}
              >
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-contents">
        {(addingNewSerija || addingNewWaste) &&
          (addingNewWaste ? (
            <Waste
              reset={reset}
              setCreateError={setCreateError}
              setCreateSuccess={setCreateSuccess}
              handleOpen={() => {
                setAddingNewSerija(false);
                setAddingNewWaste(false);
              }}
            />
          ) : addingNewSerija ? (
            <NewPopUpFrame
              handleOpen={() => {
                setAddingNewSerija(false);
                setAddingNewWaste(false);
              }}
              full
              isShown={addingNewSerija || addingNewSerija}
              showShadow={false}
              title={addingNewWaste ? t("New waste") : t("Series")}
            >
              <div className="setting_new_item_wrapper">
                <Serija />
              </div>
            </NewPopUpFrame>
          ) : (
            ""
          ))}
        <Routes>
          <Route
            path={`site-wastes`}
            element={
              <>
                <div className="addNewWaste_wrapper">
                  <div
                    className="addNewWaste"
                    onClick={() => {
                      setAddingNewWaste(true);
                      setAddingNewSerija(false);
                    }}
                  >
                    <IconContainer>
                      <img
                        src={require("../../assets/images/plus.svg").default}
                        alt={``}
                      />
                    </IconContainer>
                    <div>{t("Add a new waste")}</div>
                  </div>
                  <div
                    className="addNewSerija"
                    onClick={() => {
                      setAddingNewWaste(false);
                      setAddingNewSerija(true);
                    }}
                  >
                    <IconContainer>
                      <img
                        src={require("../../assets/images/plus.svg").default}
                        alt={``}
                      />
                    </IconContainer>
                    <div>{t("Series of invoices")}</div>
                  </div>
                </div>

                <SiteWastes wasteOpen={addingNewWaste} />
              </>
            }
          ></Route>
          <Route path={`waste-limits`} element={<SiteLimits />}></Route>
          <Route path={`staff/*`} element={<UserManagement />}></Route>
          <Route path={`clients/*`} element={<Client />}></Route>
          <Route path={`classifiers`} element={<Classifier />}></Route>
          <Route
            path="*"
            element={<Navigate to={`site-wastes`} replace />}
          ></Route>
        </Routes>
      </div>
    </div>
  );
};

export default Settings;
