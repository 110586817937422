import React, { ReactChild } from "react";
import "./Cover.css";

const Cover = ({
  children,
  className,
  onClick,
}:
  | {
      children: ReactChild | ReactChild[];
      className: string;
      onClick: () => void;
    }
  | {
      children: ReactChild | ReactChild[];
      className: string;
      onClick?: never;
    }) => {
  return (
    <div className={`cover_wrap ${className}`}>
      <div onClick={onClick ? onClick : () => {}} className="inner">
        {children}
      </div>
    </div>
  );
};

export default Cover;
