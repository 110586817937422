import { Fetch } from "../../helpers/misc";
import { disposalInit } from "../../components/elements/DisposalForm/defaultData";
import { disposal } from "./Disposal";

export const saveDisposal = async (params: disposalInit) => {
  return await Fetch("/disposals", {
    method: "POST",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const response = res.json() as Promise<{ success: true }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDisposals = async (params: { query: string }) => {
  const { query } = params;

  return await Fetch(`/disposals?${query}`)
    .then((res) => {
      const response = res.json() as Promise<{
        disposals: {
          [key: string]: disposal;
        }[];
      }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const singleDisposals = async (params: {
  query: string;
  disposals: { [key: string]: disposal }[];
}) => {
  const { query, disposals } = params;

  return await Fetch(`/disposals/single?${query}`)
    .then(async (res) => {
      const response = (await res.json()) as {
        disposals: { [key: string]: disposal };
      };

      const siteName = Object.keys(
        response.disposals
      )[0] as keyof typeof disposals;

      const newDeliveries = disposals.map((del) => {
        const key = Object.keys(del)[0];
        if (key === siteName) {
          return response.disposals;
        } else {
          return del;
        }
      });

      return newDeliveries;
    })
    .catch((err) => {
      throw err;
    });
};
