import React, { useState } from "react";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import "./ProfileMessageBox.css";
import { ReactComponent as EditSVG } from "../../assets/images/edit.svg";
import moment from "moment";
import { isEmail } from "../../helpers/validate";
import { Fetch } from "../../helpers/misc";
import useTranslate from "../../translate/useTranslate";

export default function ProfileMessageBox({
  onCancel,
  onContinue,
  defEmail,
  hard,
}: {
  onCancel: () => void;
  onContinue: () => void;
  defEmail: string | null;
  hard: boolean;
}) {
  const { t } = useTranslate();
  //const defEmail = "mindaugas@gmail.com";
  const [mailerState, setMailerState] = useState({
    email: defEmail || "",
    text: "",
    hard: hard,
  });

  const [emailFocus, setEmailFocus] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const messageDate = moment().format(`YYYY-MM-DD`);

  function handleStateChange(e: any) {
    const { name, value } = e.target;

    setMailerState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const validateEmail = (email: string) => {
    const emailValid = isEmail(email);

    setValidEmail(emailValid);

    if (emailValid) {
      setEmailFocus(false);
    }
  };

  const sendMessage = async () => {
    await Fetch(`/sendMail/reminder`, {
      method: "POST",
      body: JSON.stringify({ mailerState }),
    }).then(
      (res) => {
        if (res.ok) {
          setMailerState({
            email: defEmail || "",
            text: "",
            hard: hard,
          });

          onContinue();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <div className="profileMessage">
      <p>
        {t("Message Date")} {messageDate}
      </p>
      <div className="profileMessage_details_edit_wrap email">
        <span>{t("The recipient's email")}</span>
        {emailFocus ? (
          <input
            className="invoice_scroller flex1"
            autoFocus={true}
            onBlur={(e) => validateEmail(e.target.value)}
            onChange={handleStateChange}
            name="email"
            id=""
            value={mailerState.email}
          />
        ) : (
          <p className="flex1">{mailerState.email}</p>
        )}
        <IconContainer size="lg">
          <EditSVG
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => (validEmail ? setEmailFocus(!emailFocus) : false)}
          />
        </IconContainer>
      </div>
      {!validEmail && (
        <p className="flex1">{t("Enter the correct email address")}</p>
      )}
      <textarea
        value={mailerState.text}
        onChange={handleStateChange}
        name="text"
        id=""
        cols={30}
        rows={70}
      ></textarea>
      <div className="profileMessage_button_wrapper">
        <button onClick={onCancel} className="btn btn-default">
          {t("Cancel")}
        </button>
        <button
          onClick={() => {
            sendMessage();
          }}
          className="btn btn-green"
          disabled={!validEmail}
        >
          {t("Send")}
        </button>
      </div>
    </div>
  );
}
