import WasteAccumulationForm from "./WasteAccumulationForm";

import { connect } from "react-redux";

import * as actions from "../../../../stores/actions";

const mapStoreStateToProps = function (store: any) {
  return {
    sites: store.siteReducer.sites,
    wastes: store.wasteReducer.wastes,
  };
};

const mapDispatchToProps = function (dispatch: (data: any) => void) {
  return {
    saveWasteAccumulation: function (wasteAccumulation: {
      id?: number;
      siteId: number;
      date: string;
      wasteId: number;
      weight: number;
      notes: string;
    }) {
      dispatch(actions.saveWasteAccumulation(wasteAccumulation));
    },
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(WasteAccumulationForm);
