import * as actionTypes from "../actionTypes";

const initialState = {
  suggestions: [],
  unReadCount: null,
  suggestionText: {
    title: ``,
    body: ``,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SUGGESTIONS: {
      return {
        ...state,
        suggestions: [...action.payload.suggestions],
        unReadCount: action.payload.unReadCount,
      };
    }
    case actionTypes.SET_SUGGESTION_TEXT: {
      return {
        ...state,
        suggestionText: {
          ...state.suggestionText,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
}

export default reducer;
