import React, { useEffect, useMemo, useState, useRef } from "react";

import { WasteAcceptedList } from "../../components/elements";
import { InvoicePopUp } from "../../components/UI";

import {
  defaultFormDataPerson,
  defaultFormDataCompany,
  wasteItem,
  wasteItem_init,
  nullPartial,
  getClientWastesList,
  extPerson,
  getPersonValue,
  saveClientWastes,
} from "./defaultData";

import * as statuses from "../../statuses";

import "./style.scss";
import "./WasteAcceptance.css";
import {
  companyType,
  currentUserType,
  originType,
  personType,
  disposalType,
  disposalTheWaste,
} from "./@types";
import { site } from "../../@types/sites";
import AcceptanceForm from "../../components/elements/AcceptanceForm";
import AcceptancePerson from "../../components/elements/AcceptancePerson";
import AcceptanceCompany from "../../components/elements/AcceptanceCompany";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import { Header } from "../../components/UI/FormGenerator/formField";
import CheckBox from "../../components/UI/checkBox/checkBox";
import { pageConstructType } from "../../components/UI/FormGenerator/formTypes";
import Declaration from "../../components/UI/Declaration/Declaration";
import { company, person } from "../../@types/client";
import { useNavigate } from "react-router-dom";
import { clientWasteType } from "../../@types/waste";
import { useAsync } from "../../helpers/asyncFunc";
import Notification from "../../components/UI/Notification/Notification";
import {
  Fetch,
  getWastePrice,
  isDigit,
  resolve_unit_price,
} from "../../helpers/misc";
import { isString } from "../../helpers/validate";
import WastesComingIn from "../../components/elements/WastesComingIn";
import moment from "moment";
import useTranslate from "../../translate/useTranslate";

export const filter_person_id = `filter_person_id`;
export const filter_companies_ID = `filter_companies_ID`;

const WasteAcceptance = function ({
  currentUser,
  currentSiteId,
  sites,
  origins,
}: {
  currentUser: currentUserType;
  currentSiteId: number;
  sites: site[];
  origins: originType[];
}) {
  const { t } = useTranslate();
  const defaultOrigin = t("Household waste");
  const defaultOriginId = useMemo(() => {
    const origin = origins.find(
      (item) => item.name.toLowerCase() === defaultOrigin.toLowerCase()
    );
    return origin?.id;
  }, [origins]);

  const mountedRef = useRef(true);
  const navigate = useNavigate();

  const wasteDate = new Date();
  const [clientWastes, setClientWaste] = useState<clientWasteType[]>([]);
  const [clientWastesCount, setClientWasteCount] = useState<number>(0);
  const [allSites, setAllSites] = useState<site[]>([]);

  // Notification
  const [acceptanceError, setAcceptanceError] = useState(false);
  const [acceptanceSuccess, setAcceptanceSuccess] = useState(false);

  useEffect(() => {
    Fetch("/sites/all")
      .then(async (res) => {
        const siteRes = (await res.json()) as { success: true; sites: site[] };
        if (!mountedRef.current) return null;
        setAllSites([...siteRes.sites]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [date, setDate] = useState<Date>(wasteDate);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const reinitializeWaste = (isCompany: boolean) => {
    if (isCompany) {
      setCompanyWaste_list([
        {
          ...wasteItem_init,
          date: wasteDate,
          siteId: currentSiteId,
        },
      ]);
    } else {
      setWaste_list([
        {
          ...wasteItem_init,
          date: wasteDate,
          siteId: currentSiteId,
          origin: defaultOriginId,
        },
      ]);
    }
  };

  useEffect(() => {
    // reinitialize waste if the site does not correspond
    const removeIndivWaste =
      waste_list.some((item) => item.siteId !== currentSiteId) ||
      !waste_list.length;

    const removeCompWaste =
      companyWaste_list.some((item) => item.siteId !== currentSiteId) ||
      !companyWaste_list.length;

    if (removeIndivWaste) {
      reinitializeWaste(false);
    }
    if (removeCompWaste) {
      reinitializeWaste(true);
    }
  }, [currentSiteId]);

  const [showDeclaration, setShowDeclaration] = useState(false);
  const [isCompany, setIsCompany] = useState(false);

  const [searchParams, setSearchParams] = useState<
    Partial<{ name: string; surname: string; pin: number }>
  >({});
  const [companySearchParams, setCompanySearchParams] = useState<
    Partial<{ companyName: string; companyCode: number }>
  >({});

  const [filtered_persons, setFilteredPersons] = useState<extPerson[]>([]);
  const [filtered_companies, setFilteredCompanies] = useState<company[]>([]);

  const [personDetails, setPersonDetails] = useState<nullPartial<personType>>(
    defaultFormDataPerson
  );
  const [companyDetails, setCompanyDetails] = useState<
    nullPartial<companyType>
  >(defaultFormDataCompany);

  const [waste_list, setWaste_list] = useState<Partial<wasteItem>[]>([]);
  const [companyWaste_list, setCompanyWaste_list] = useState<
    Partial<wasteItem>[]
  >([]);

  // Person standard refers to the details gottena and selected from filter
  // This is needed as a cache so we can compare with personDetails
  // We compare to ensure than the ID we are retaining is for the person
  // Should a change be made in more than 2 positions, we remove the ID and
  // The user is considered a new user
  const [personStandard, setPersonStandard] = useState<person>();
  const [companyStandard, setCompanyStandard] = useState<company>();

  const [isAcceptCompany, setIsAcceptCompany] = useState(false);
  const [invoicePopUp, setInvoicePopUp] = useState(false);
  const [arrivedDisposalId, setArrivedDisposalId] = useState(0);
  const [updatedAt, setUpdateAt] = useState(moment().unix());

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const clientId = params.get("client");
  const clientFirstName = params.get("firstname");
  const clientSurname = params.get("surname");
  const clientPin = params.get("code");

  useEffect(() => {
    if (clientId && clientId.length === 8) {
      getPersonValue({ id: clientId, uniqueId: true })
        .then(({ client }) => {
          const doc = client.PersonDocuments[0];
          const details: Partial<nullPartial<personType>> = {};

          if (doc) {
            details.documentNr = doc.documentNr;
            details.documentId = doc.documentId;
          }
          if (!mountedRef.current) return null;

          setPersonDetails({
            ...personDetails,
            ...details,
            address: client.address,
            email: client.email,
            id: client.id,
            mobile: client.mobile,
            municipalityId: client.municipalityId,
            name: client.name,
            pin: client.pin,
            signature: null,
            surname: client.surname,
          });
          setSearchParams({});
          setFilteredPersons([]);
          setPersonStandard(client);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [clientId]);

  useEffect(() => {
    if (!clientId && clientFirstName && clientSurname && clientPin) {
      if (
        isString(clientFirstName) &&
        isString(clientSurname) &&
        isDigit(clientPin) &&
        clientPin.toString().length === 4
      ) {
        setPersonDetails({
          ...personDetails,
          ...{
            name: clientFirstName,
            surname: clientSurname,
            pin: clientPin,
          },
        });
        setSearchParams({});
        setFilteredPersons([]);
      }
    }
  }, [clientFirstName, clientSurname, clientPin]);

  // Get client waste.
  const clientWasteRes = useAsync(
    {
      asyncFunc: getClientWastesList,
      funcParams: {
        id: isCompany ? companyDetails.id : personDetails.id,
        siteId: currentSiteId,
        isCompany,
      },
      immediate: true,
      clearOnError: true,
    },
    [isCompany, personDetails.id, companyDetails.id, currentSiteId]
  );
  useEffect(() => {
    const Obj = clientWasteRes.data;
    setClientWaste(Obj?.clientWastes || []);
    setClientWasteCount(Obj?.count || 0);
  }, [clientWasteRes.data]);

  const setClient = (isCompany: boolean, acceptCompanies = isAcceptCompany) => {
    setIsCompany(isCompany && acceptCompanies);
  };

  const invoiceItems = () => {
    const List = isCompany ? companyWaste_list : waste_list;
    return List.filter((item) => item.invoice);
  };

  useEffect(() => {
    if (currentSiteId) {
      const filteredSites = sites.filter((site) => site.id === currentSiteId);
      if (filteredSites[0].acceptCompanies) {
        setIsAcceptCompany(true);
      } else {
        setIsAcceptCompany(false);
      }

      setClient(isCompany, filteredSites[0].acceptCompanies);
    } else {
      navigate("/", { replace: true });
    }
  }, [currentSiteId]);

  const getCurrentSite = () => {
    return sites.filter((site) => site.id === currentSiteId)[0] || {};
  };

  const saveRes = useAsync(
    {
      asyncFunc: saveClientWastes,
      funcParams: {} as never,
      immediate: false,
      clearOnError: true,
    },
    []
  );

  const stateReset = () => {
    /*
     * [Reset states]
     * personDetails
     * companyDetails
     */

    setPersonDetails(defaultFormDataPerson);
    setCompanyDetails(defaultFormDataCompany);

    /**
     * [Reset states]
     * personStandard
     * searchParams
     * showFilter
     * filtered_persons
     * waste_list
     * companyWaste_list
     */

    setPersonStandard(undefined);
    setCompanyStandard(undefined);

    setSearchParams({});
    setCompanySearchParams({});

    setFilteredPersons([]);
    setFilteredCompanies([]);

    reinitializeWaste(false);
    reinitializeWaste(true);

    setArrivedDisposalId(0);

    setShowDeclaration(false);
    setInvoicePopUp(false);
  };

  const submit = async (sendInvoice: boolean) => {
    if (saveRes.loading) {
      return;
    }

    const details = (isCompany ? companyDetails : personDetails) as
      | personType
      | companyType;
    const Wastes = isCompany ? companyWaste_list : waste_list;
    const isNewClient = !details.id;
    const updateDisposalStatus = arrivedDisposalId != 0;
    const disposalId = arrivedDisposalId;

    const formatedWastes = Wastes.map((w) => {
      const { paidPrice_raw, paidPriceVat_raw, paidQuantity } = w;
      delete w["paidPrice_raw"];
      delete w["paidPriceVat_raw"];

      return {
        ...w,
        paidPrice: resolve_unit_price(paidPrice_raw, paidQuantity),
        paidPriceVat: resolve_unit_price(paidPriceVat_raw, paidQuantity),
      };
    });

    /*
     * Save client wastes
     */
    reset();

    saveRes.execute({
      userId: currentUser.id,
      siteId: currentSiteId,
      isCompany,
      isNewClient,
      client: { ...details }, // pass by value
      clientWastes: formatedWastes,
      sendInvoice,
      updateDisposalStatus,
      disposalId,
    });
  };

  const completeWasteList = useMemo((): boolean => {
    const genExclude: (keyof wasteItem)[] = [
      "handlingCode",
      "freeLimit",
      "maxLimit",
      "direct_vat_calc",
      "accumulationLimit",
      "volume",
      "quantity",
      "compInvoice",
      "uom",
      "groupId",
      "kgPerUnit",
      "hasUnit",
      "paymentDocument",
      ...(!!arrivedDisposalId
        ? (["paidPrice_raw", "paidPriceVat_raw"] as (keyof wasteItem)[])
        : ([] as (keyof wasteItem)[])),
    ];
    const exclude: (keyof wasteItem)[] = [...genExclude, "primarySource"];

    const numKeysAllowZero: (keyof wasteItem)[] = [
      "volume",
      "quantity",
      "paidPrice_raw",
      "paidPriceVat_raw",
      "paidQuantity",
    ];

    const numKeys: (keyof wasteItem)[] = ["weight"];

    //num allow zero
    const compExclude: (keyof wasteItem)[] = [...genExclude];

    const Exclude = isCompany ? compExclude : exclude;
    const List = isCompany ? companyWaste_list : waste_list;
    const hasError = List.some((list) => {
      const hasError = Object.keys(list).some((key_value) => {
        const key: keyof wasteItem = key_value as keyof wasteItem;
        if (key === `error`) {
          // When there is an error, return true
          return list[key];
        } else if (list.hasUnit && !parseInt(`${list.quantity}`)) {
          console.log(`key`, key, list[key], `error here`);
          return true;
        } else if (Exclude.indexOf(key) >= 0 || key === `invoice`) {
          // Values that can be excluded differ between company and person
          // Invoice value is of no significance, so it does not affect completeness
          return false;
        } else if (numKeysAllowZero.indexOf(key) >= 0) {
          // Values that are numbers and can be zero
          if (typeof list[key] !== `number`) {
            console.log(`key`, key, list[key], `error here`);
          }
          return typeof list[key] !== `number`;
        } else {
          if (numKeys.indexOf(key) >= 0) {
            // Values that are numbers and cannot be zero
            if (!parseFloat(list[key] as string)) {
              console.log(`key`, key, list[key], `error here`);
            }
            return !parseFloat(list[key] as string);
          } else {
            // values that are falsy but arent `0`
            if (!list[key]) {
              console.log(`key`, key, list[key], `error here`);
            }
            return !list[key];
          }
        }
      });
      return hasError;
    });

    return !hasError; // if there is an error, return true, else, return false
  }, [waste_list, companyWaste_list, isCompany]);

  const completeDetails = useMemo((): boolean => {
    if (isCompany) {
      const exclude: (keyof companyType)[] = [
        "vatcode",
        "email",
        "mobile",
        "address",
        "signature",
        "driver",
      ];
      return !Object.keys(companyDetails).some((Key) => {
        const key = Key as keyof companyType;
        if (key !== `id` && exclude.indexOf(key) < 0) {
          if (!companyDetails[key]) {
            console.log(
              "error details here: ",
              key,
              ` ${!companyDetails[key]}`
            );
          }
          return !companyDetails[key];
        } else {
          return false;
        }
      });
    } else {
      const pin = `${personDetails.pin}`;
      if ((pin && pin.length !== 4) || !parseInt(pin)) {
        console.log("error pin info here: ", pin);
        return false;
      }
      const exclude: (keyof personType)[] = [
        "email",
        "signature",
        "mobile",
        "address",
      ];
      return !Object.keys(personDetails).some((Key) => {
        const key = Key as keyof personType;
        if (key !== `id` && exclude.indexOf(key) < 0) {
          if (!personDetails[key]) {
            console.log("error details here: ", key, ` ${!personDetails[key]}`);
          }
          return !personDetails[key];
        } else {
          return false;
        }
      });
    }
  }, [companyDetails, personDetails, isCompany]);

  const canSubmit = useMemo((): boolean => {
    return completeWasteList && completeDetails;
  }, [completeDetails, completeWasteList]);

  const disposalArrived = (disposal: disposalType) => {
    setCompanyDetails({
      ...companyDetails,
      ...{
        carNr: disposal.licensePlate,
        carrier: disposal.carrierId,
        companyName: `Plum Waste UAB (${disposal.disposalSiteName})`,
        companyCode: disposal.disposalSiteCompanyCode || "",
        contractNr: disposal.contractNr,
        billing: 2,
        municipalityId: disposal.disposalSiteMunicipalityId || 0,
        address: disposal.disposalAddress,
      },
    });

    let wasteList: Partial<wasteItem>[] = [];
    disposal.theWastes.forEach((waste: disposalTheWaste) => {
      const prices = getWastePrice({
        direct_vat_calc: waste.direct_vat_calc,
        price: waste.paidPrice,
        priceVat: waste.paidPriceVat,
      });
      wasteList.push({
        ...wasteItem_init,
        date: wasteDate,
        siteId: currentSiteId,
        selectedWaste: waste.id,
        weight: waste.weight,
        uom: "kg",
        paidQuantity: 0,
        name: waste.name,
        code: waste.code,
        groupId: waste.groupId,
        paidPrice_raw: prices.price,
        paidPriceVat_raw: prices.priceVat,
        compInvoice: !!waste.compInvoice,
        invoice: !!waste.compInvoice,
      });
    });

    setCompanyWaste_list(wasteList);

    setSearchParams({});
    setFilteredPersons([]);
    setArrivedDisposalId(disposal.id);
    setIsCompany(true);

    document
      .getElementsByClassName("wasteacceptance_title_header")[1]
      .scrollIntoView({ behavior: "smooth" });
  };

  const resetDisposalArrived = () => {
    setIsCompany(false);
    setCompanyDetails(defaultFormDataCompany);

    setSearchParams({});
    setFilteredPersons([]);
    setCompanyStandard(undefined);
    setArrivedDisposalId(0);
  };

  const selectConstruct: pageConstructType = {
    sizeDist: [1],
    typeDist: [],
    hasHeader: true,
    headerText: [
      Header(
        [
          <div className="flex waste_select_head">
            <div className="waste_accpt_client_type_item">
              <CheckBox
                isChecked={!isCompany}
                onChange={() => setClient(false)}
              />
              {t("Individual")}
            </div>

            {isAcceptCompany ? (
              <div className="waste_accpt_client_type_item">
                <CheckBox
                  isChecked={isCompany}
                  onChange={() => setClient(true)}
                />
                {t("Legal person")}
              </div>
            ) : (
              ``
            )}
          </div>,
        ],
        false,
        true
      ),
    ],
  };

  const reset = () => {
    setAcceptanceError(false);
    setAcceptanceSuccess(false);
  };

  useEffect(() => {
    const reload = acceptanceSuccess;

    if (reload) {
      setUpdateAt(moment().unix());
    }
  }, [acceptanceSuccess]);

  useEffect(() => {
    if (saveRes.data) {
      // Reset only after a successful call
      stateReset();

      setAcceptanceError(false);
    }

    setAcceptanceSuccess(!!saveRes.data);
  }, [saveRes.data]);

  useEffect(() => {
    if (saveRes.error) {
      setAcceptanceSuccess(false);
    }

    setAcceptanceError(!!saveRes.error);
  }, [saveRes.error]);

  const openPopUp = (param: { invoice: boolean; declaration: boolean }) => {
    if (saveRes.loading) {
      return;
    }
    setInvoicePopUp(param.invoice);
    setShowDeclaration(param.declaration);
  };

  return (
    <div className="wasteacceptance">
      <Notification
        reset={reset}
        isDone={acceptanceError || acceptanceSuccess}
        error={acceptanceError}
        text={
          acceptanceError || acceptanceSuccess ? (
            <div className={`DATASET__notification-text`}>
              {acceptanceSuccess ? (
                <>
                  <img
                    src={require("../../assets/images/tick.svg").default}
                    alt=""
                  />{" "}
                  {t("The data are saved")}
                </>
              ) : (
                t("The record could not be saved. Try again.")
              )}
            </div>
          ) : undefined
        }
      />
      <div>
        <WastesComingIn
          disposalArrived={disposalArrived}
          resetDisposalArrived={resetDisposalArrived}
          arrivedDisposalId={arrivedDisposalId}
          updatedAt={updatedAt}
        />

        <div className="wasteacceptance_title_header">{t("Waste holder")}</div>
        <GenerateForm classNameWrap="waste_accpt_header_gen">
          <FormGenerator
            key={`acceptancePerson_headerField_1_${0}`}
            gridSizeDist={selectConstruct.sizeDist}
            gridTypeDist={selectConstruct.typeDist}
            hasHeader={selectConstruct.hasHeader}
            headerText={selectConstruct.headerText}
          />
          {isCompany ? (
            <AcceptanceCompany
              isComplete={completeDetails}
              companies={filtered_companies}
              companyStandard={companyStandard}
              setCompanyStandard={setCompanyStandard}
              setCompanies={setFilteredCompanies}
              setSearchParams={setCompanySearchParams}
              searchParams={companySearchParams}
              details={companyDetails as companyType}
              setDetails={setCompanyDetails}
            />
          ) : (
            <AcceptancePerson
              isComplete={completeDetails}
              persons={filtered_persons}
              personStandard={personStandard}
              setPersonStandard={setPersonStandard}
              setPersons={setFilteredPersons}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              details={personDetails as personType}
              setDetails={setPersonDetails}
            />
          )}
        </GenerateForm>

        {!!arrivedDisposalId ? (
          <div />
        ) : (
          <WasteAcceptedList
            currentSiteId={currentSiteId}
            clientId={isCompany ? companyDetails.id : personDetails.id}
            isCompany={isCompany}
            clientWastes={clientWastes}
            setClientWaste={setClientWaste}
            clientWasteCount={clientWastesCount}
            setClientWasteCount={setClientWasteCount}
          />
        )}

        <AcceptanceForm
          fromDisposal={!!arrivedDisposalId}
          setWaste_list={isCompany ? setCompanyWaste_list : setWaste_list}
          waste_list={isCompany ? companyWaste_list : waste_list}
          date={date}
          siteId={currentSiteId}
          isCompany={isCompany}
          completeDetails={completeDetails}
          id={isCompany ? companyDetails.id : personDetails.id}
        />

        <div className="acceptance_form_btn_wrapper center">
          <button
            disabled={!canSubmit}
            className={`flexBtn btn-green waste_submit_btn ${
              canSubmit ? "" : "disabled"
            }`}
            onClick={() => {
              openPopUp({ invoice: false, declaration: true });
            }}
          >
            {t("Confirm")}
          </button>
        </div>
      </div>
      {canSubmit && showDeclaration && (
        <Declaration
          loading={saveRes.loading}
          allSites={allSites}
          waste_list={isCompany ? companyWaste_list : waste_list}
          hasInvoice={!!invoiceItems().length}
          isCompany={isCompany}
          details={
            isCompany
              ? (companyDetails as companyType)
              : (personDetails as personType)
          }
          setDetails={
            isCompany
              ? (setCompanyDetails as (data: companyType | personType) => void)
              : (setPersonDetails as (data: companyType | personType) => void)
          }
          date={date}
          close={() => {
            openPopUp({ invoice: false, declaration: false });
          }}
          next={() => {
            if (saveRes.loading) {
              return;
            }
            const details = isCompany
              ? (companyDetails as companyType)
              : (personDetails as personType);
            if (details.signature) {
              if (!!invoiceItems().length) {
                openPopUp({ invoice: true, declaration: false });
              } else {
                submit(false);
              }
            }
          }}
        />
      )}
      {canSubmit && invoicePopUp && (
        <InvoicePopUp
          loading={saveRes.loading}
          closePopUp={() => {
            openPopUp({ invoice: false, declaration: true });
          }}
          wasteItems={invoiceItems() as wasteItem[]}
          currentSite={getCurrentSite()}
          isCompany={isCompany}
          details={
            isCompany
              ? (companyDetails as companyType)
              : (personDetails as personType)
          }
          setDetails={
            isCompany
              ? (setCompanyDetails as (data: companyType | personType) => void)
              : (setPersonDetails as (data: companyType | personType) => void)
          }
          handlePayButtonClick={(sendEmail: boolean) => {
            if (saveRes.loading) {
              return;
            }
            const details = isCompany
              ? (companyDetails as companyType)
              : (personDetails as personType);
            if (details.signature) {
              submit(sendEmail);
            }
          }}
        />
      )}
    </div>
  );
};

export default WasteAcceptance;
