import { connect } from "react-redux";

import Home from "./Home";
import * as actions from "../../stores/actions";

const mapStoreStateToProps = function (store) {
  return {
    storedSites: store.siteReducer.userSites,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    setCurrentSite: function (siteId) {
      dispatch(actions.setCurrentSite(siteId));
    },
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(Home);
