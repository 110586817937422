import * as actionTypes from '../actionTypes';

import * as statuses from '../../statuses';

const initialState = {
	userGroups: [],
  userGroup: null,
  status: statuses.STATUS_IDLE,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.GET_USER_GROUPS_SUCCEED:
			return {
				...state,
				userGroups: action.payload
			};
    case actionTypes.GET_USER_GROUP_SUCCEED:
      return {
        ...state,
        userGroup: action.payload,
      };
    case actionTypes.SAVE_USER_GROUP:
      return {
        ...state,
        status: statuses.STATUS_IN_PROGRESS,
      };
    case actionTypes.SAVE_USER_GROUP_SUCCEED:
      return {
        ...state,
        status: statuses.STATUS_DONE
      };
    case actionTypes.DEL_USER_GROUP:
      return {
        ...state,
        status: statuses.STATUS_IN_PROGRESS,
      };
    case actionTypes.DEL_USER_GROUP_SUCCEED:
      return {
        ...state,
        status: statuses.STATUS_DONE
      };
    case actionTypes.RESET_USER_GROUP_STATUS:
      return {
        ...state,
        status: statuses.STATUS_IDLE,
      };  
    default:
			return state;
	}
}


export default reducer;
