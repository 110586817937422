import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../../store";
import { getSites, getAllSerijas as get_all_serijas } from "../actions";
import { FetchRX } from "../../helpers/misc";

export const createSerija = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_SERIJA),
    mergeMap((action) =>
      FetchRX("/serijas", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(getSites());
          store.dispatch(get_all_serijas());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const getAllSerijas = (action$) =>
  action$.pipe(
    ofType(actions.GET_ALL_SERIJAS),
    mergeMap((action) =>
      FetchRX(`/serijas/${action.payload}`).pipe(
        map((result) => {
          return {
            type: actions.SET_SERIJAS,
            payload: result.serijas,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const makeSerijaActive = (action$) =>
  action$.pipe(
    ofType(actions.MAKE_SERIJA_ACTIVE),
    mergeMap((action) =>
      FetchRX("/serijas/makeActive", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(getSites());
          store.dispatch(get_all_serijas());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
export const deleteSerija = (action$) =>
  action$.pipe(
    ofType(actions.DELETE_SERIJA),
    mergeMap((action) =>
      FetchRX("/serijas/delete", {
        method: "DELETE",
        body: action.payload,
      }).pipe(
        map((result) => {
          store.dispatch(getSites());
          store.dispatch(get_all_serijas());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
