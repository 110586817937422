import * as actionTypes from "../actionTypes";

const initialState = {
  materials: [],
  inactiveMaterials: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_MATERIALS:
      return {
        ...state,
        materials: action.payload,
      };
    case actionTypes.SET_INACTIVE_MATERIALS:
      return {
        ...state,
        inactiveMaterials: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
