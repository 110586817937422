import * as actionTypes from "../actionTypes";

const getSerijaQuery = (id) => {
  if (id) {
    return `?siteId=${id}`;
  } else {
    return ``;
  }
};

export const createSerija = (payload) => {
  return { type: actionTypes.CREATE_SERIJA, payload };
};

export const getAllSerijas = (payload) => {
  return {
    type: actionTypes.GET_ALL_SERIJAS,
    payload: getSerijaQuery(payload),
  };
};

export const makeSerijaActive = (payload) => {
  return { type: actionTypes.MAKE_SERIJA_ACTIVE, payload };
};

export const deleteSerija = (payload) => {
  return { type: actionTypes.DELETE_SERIJA, payload };
};
