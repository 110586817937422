import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./subMenu.css";

const SubMenu = function ({ menus }) {
  const location = useLocation();

  const isNextActive = (value) => {
    let active = false;
    if (location.pathname === value) {
      active = true;
    }

    return active;
  };

  const generatedMenus = menus
    ? menus.map((menu, n) => {
        return (
          <Link
            key={menu.path}
            className={`link_btn center ${
              location.pathname === menu.path ? "nav_on" : ""
            } ${isNextActive(menus[n + 1]?.path) ? "prevNoBorder" : ""}`}
            to={menu.path}
          >
            {menu.title}
            <div className="link_divider">
              <div className="link_divider_line"></div>
            </div>
          </Link>
        );
      })
    : [];
  return <div className="nav_link_wrapper">{generatedMenus}</div>;
};

export default SubMenu;
