import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  childField,
  Header,
  searchField,
  textBox,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import { pageConstructType } from "../../components/UI/FormGenerator/formTypes";
import "./Client.css";
import { client, company, person } from "../../@types/client";
import Profile from "../Profile";
import CompanyProfile from "../CompanyProfile";
import { Link, Route, Routes, useResolvedPath } from "react-router-dom";
import FractionList from "../../components/UI/fractionList/FractionList";
import { makeLabelInt, pageSettingOptions } from "../../helpers/misc";
import { getClients } from "./getter";
import DiscreteRangeDatePicker from "../../components/UI/DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../Report/@types";
import { useAsync } from "../../helpers/asyncFunc";
import useTranslate from "../../translate/useTranslate";

const Client = () => {
  const url = useResolvedPath("").pathname;
  type clientFilterType = {
    date: {
      startDate: string;
      endDate: string;
    };
    uniqueId: string;
    name: string;
    surname: string;
    companyName: string;
  };

  const defaultFilter: clientFilterType = {
    date: {
      startDate: "",
      endDate: "",
    },
    uniqueId: "",
    name: "",
    surname: "",
    companyName: "",
  };

  const { t } = useTranslate();
  const [clients, setClients] = useState<client[]>([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [currentStart, setCurrentPage] = useState(1);

  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );
  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  const updateFilter = (objFilter: Partial<clientFilterType>) => {
    setFilter({ ...filter, ...objFilter });
  };

  const Filter = (() => {
    const queryObj: Partial<
      { [key in keyof clientFilterType]: string } & {
        startDate: string;
        endDate: string;
      }
    > = {};

    const query = Object.keys(filter)
      .map((Key) => {
        const key = Key as keyof clientFilterType;
        if (key === "date") {
          if (filter.date.startDate && filter.date.endDate) {
            queryObj["startDate"] = filter.date.startDate;
            queryObj["endDate"] = filter.date.endDate;
            return `startDate=${filter.date.startDate}&endDate=${filter.date.endDate}`;
          }

          return "startDate=&endDate=";
        } else {
          queryObj[key] = filter[key] as string;
          return key + "=" + filter[key];
        }
      })
      .join("&");

    return query;
  })();

  const clientsRes = useAsync(
    {
      asyncFunc: getClients,
      funcParams: {
        query: Filter,
        limit,
        offset,
      },
      immediate: true,
    },
    [Filter, limit, offset]
  );

  useEffect(() => {
    const clientObj = clientsRes.data;
    if (clientObj) {
      setCount(clientObj.count);
      setClients(clientObj.clients);
    }
  }, [clientsRes.data]);

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: [2, 3, 3, 3, 4, `5rem`],
      typeDist: [
        searchField(
          t("Search..."),
          () => {},
          filter.uniqueId,
          (txt) => updateFilter({ uniqueId: txt })
        ),
        searchField(
          t("Search..."),
          () => {},
          filter.name,
          (txt) => updateFilter({ name: txt })
        ),
        searchField(
          t("Search..."),
          () => {},
          filter.surname,
          (txt) => updateFilter({ surname: txt })
        ),
        searchField(
          t("Search..."),
          () => {},
          filter.companyName,
          (txt) => {
            updateFilter({ companyName: txt });
          }
        ),
        childField(
          <div
            className="userList_range_wrapper"
            key={"userList_range_wrapper"}
          >
            <DiscreteRangeDatePicker
              key={"userList_range"}
              onChange={(val: range) => {
                if (val.startDate || val.startDate) {
                  updateFilter({
                    date: {
                      startDate: val.startDate
                        ? moment(new Date(val.startDate)).format("YYYY-MM-DD")
                        : "",
                      endDate: val.endDate
                        ? moment(new Date(val.endDate)).format("YYYY-MM-DD")
                        : "",
                    },
                  });
                }
              }}
              clear={!filter.date.startDate && !filter.date.endDate}
              value={undefined}
            />
          </div>
        ),
      ],
      hasHeader: true,
      headerText: [
        Header([t("Customer ID")], false),
        Header([t("Name")], false),
        Header([t("Surname")], false),
        Header([t("Company name")], false),
        Header([t("Account created")], false),
      ],
    },
  ];

  const construct = (clients: client[]): pageConstructType[] =>
    clients.map((client, n) => {
      if ((client as company).companyName) {
        return {
          sizeDist: [2, 3, 3, 3, 4, `5rem`],
          typeDist: [
            textBox(`${client.uniqueId || `---`}`, true),
            textBox("", true),
            textBox("", true),
            textBox((client as company).companyName || "", true),
            textBox(moment(client.createdAt).format("YYYY-MM-DD"), true),
            childField(
              <Link
                key={`client_key_${n}`}
                className="link_btn"
                to={{
                  pathname: "company-profile",
                  search: `?id=${client.id}`,
                }}
              >
                <button
                  key={`${client.id}_company`}
                  className="flexBtn btn-green center client_btn"
                >
                  {t("Review")}
                </button>
              </Link>
            ),
          ],
          hasHeader: false,
          headerText: [],
        };
      } else {
        return {
          sizeDist: [2, 3, 3, 3, 4, `5rem`],
          typeDist: [
            textBox(`${client.uniqueId || `---`}`, true),
            textBox((client as person).name || "", true),
            textBox((client as person).surname || "", true),
            textBox("", true),
            textBox(moment(client.createdAt).format("YYYY-MM-DD"), true),
            childField(
              <Link
                className="link_btn"
                key={`client_key_${n}`}
                to={{
                  pathname: "profile",
                  search: `?id=${client.id}`,
                }}
              >
                <button
                  key={`${client.id}_person`}
                  className="flexBtn btn-green center client_btn"
                >
                  {t("Review")}
                </button>
              </Link>
            ),
          ],
          hasHeader: false,
          headerText: [],
        };
      }
    });

  return (
    <div className="client_wrapper">
      <Routes>
        <Route
          path={""}
          element={
            <div className="viewExt">
              <GenerateForm loading={clientsRes.loading}>
                {pageConstruct.map((construct, n) => (
                  <FormGenerator
                    key={`seria_headerField_1_${n}`}
                    gridSizeDist={construct.sizeDist}
                    gridTypeDist={construct.typeDist}
                    hasHeader={construct.hasHeader}
                    headerText={construct.headerText}
                  />
                ))}
                {construct(clients).map((construct, n) => (
                  <FormGenerator
                    key={`seria_headerField_1_${n}`}
                    gridSizeDist={construct.sizeDist}
                    gridTypeDist={construct.typeDist}
                    hasHeader={construct.hasHeader}
                    headerText={construct.headerText}
                  />
                ))}
              </GenerateForm>
              <FractionList
                count={count}
                currentStart={currentStart}
                setCurrentPage={setCurrentPage}
                pageRecordNum={pageRecordNum}
                setPageRecordNum={setPageRecordNum}
              />
            </div>
          }
        ></Route>
        <Route
          path={`profile`}
          element={
            <div className="viewExt">
              <Profile />
            </div>
          }
        ></Route>
        <Route
          path={`company-profile`}
          element={
            <div className="viewExt">
              <CompanyProfile />
            </div>
          }
        ></Route>
      </Routes>
    </div>
  );
};

export default Client;
