import { connect } from "react-redux";

import UserActivity from "./UserActivity";

const mapStoreStateToProps = function (store) {
  return {
    currentUser: store.authReducer.currentUser,
    sites: store.siteReducer.sites,
  };
};

export default connect(mapStoreStateToProps)(UserActivity);
