import { company, person, personDocuments } from "../../@types/client";
import { clientWasteType } from "../../@types/waste";
import { dataURItoBlob, Fetch } from "../../helpers/misc";
import { companyType, personType } from "./@types";

export type nullPartial<T> = { [P in keyof T]: T[P] | null };
export type extPerson = person & { PersonDocuments: personDocuments[] };

export const defaultFormDataPerson: nullPartial<personType> = {
  id: null,
  name: "",
  surname: "",
  documentId: null,
  documentNr: "",
  signature: null,
  pin: "",
  address: "",
  email: null,
  mobile: null,
  municipalityId: null,
};

export const defaultFormDataCompany: nullPartial<companyType> = {
  id: null,
  driver: "",
  carNr: "",
  carrier: null,
  companyName: "",
  companyCode: "",
  contractNr: "",
  billing: null,
  signature: null,
  municipalityId: null,
  vatcode: null,
  email: null,
  mobile: null,
  address: null,
};

export type wasteItem = {
  date: Date;
  siteId: number;
  selectedWaste: number;
  invoice: boolean;
  compInvoice: boolean;
  volume: number | null;
  weight: number | string;
  quantity: number | string;
  origin: number;
  paymentDocument: string | null;
  handlingCode: number | null;
  maxLimit: number | null;
  freeLimit: number | null;
  criticalLimit: number | null;
  direct_vat_calc: boolean;
  accumulationLimit: number | null;
  error: boolean;
  primarySource: number | null;
  paidPrice_raw: number;
  paidPriceVat_raw: number;
  name: string;
  code: string;
  paidQuantity: number;
  uom: "kg" | string;
  groupId: number | null;
  kgPerUnit: number | null;
  hasUnit: boolean;
};

export const wasteItem_init: Partial<wasteItem> = {
  date: undefined,
  siteId: undefined,
  selectedWaste: undefined,
  quantity: undefined,
  volume: undefined,
  weight: undefined,
  origin: undefined,
  paymentDocument: undefined,
  handlingCode: undefined,
  compInvoice: false,
  invoice: false,
  primarySource: undefined,
  uom: "kg",
  error: false,
  maxLimit: undefined,
  freeLimit: undefined,
  paidPrice_raw: undefined,
  paidPriceVat_raw: undefined,
  name: undefined,
  code: undefined,
  paidQuantity: 0,
};

export const getFilterPersons = async (
  params: Partial<{
    name: string;
    surname: string;
    pin: number;
    strict?: boolean;
  }>
) => {
  const name = params.name || ``;
  const surname = params.surname || ``;
  const pin = params.pin || ``;
  const strict = params.strict ? `true` : ``;

  return await Fetch(
    `/clients/persons?name=${name}&surname=${surname}&pin=${pin}&strict=${strict}`
  ).then(
    (res) => {
      const result: Promise<{ success: boolean; persons: extPerson[] }> =
        res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};

export const getFilterCompanies = async (
  params: Partial<{
    companyName: string;
    companyCode: number;
    strict?: boolean;
  }>
) => {
  const companyName = params.companyName || ``;
  const companyCode = params.companyCode || ``;
  const strict = params.strict ? `true` : ``;

  return await Fetch(
    `/clients/companies?companyName=${companyName}&companyCode=${companyCode}&strict=${strict}`
  ).then(
    (res) => {
      const result: Promise<{ success: boolean; companies: company[] }> =
        res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};

export const getPersonValue = async (params: {
  id: number | string;
  uniqueId?: boolean;
}) => {
  const { id, uniqueId } = params;

  let url = `/clients/retrieve?id=${id}`;

  if (uniqueId) url = url + "&uniqueId=true";

  return await Fetch(url).then(
    (res) => {
      const result: Promise<{ success: boolean; client: extPerson }> =
        res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};

export const getClientWastesList = async (params: {
  isCompany: boolean;
  siteId: number;
  id: number | null;
  limit?: number;
}) => {
  let { id, isCompany, limit, siteId } = params;
  limit = limit || 5;

  if (!id) {
    return;
  } else {
    return await Fetch(
      `/clientWastes/acceptedList?isCompany=${isCompany}&id=${id}&limit=${limit}&siteId=${siteId}`
    ).then(
      (res) => {
        const result: Promise<{
          clientWastes: clientWasteType[];
          count: number;
        }> = res.json();
        return result;
      },
      (error) => {
        throw error;
      }
    );
  }
};

export const saveClientWastes = async (params: {
  userId: number;
  siteId: number;
  isCompany: boolean;
  isNewClient: boolean;
  client: personType | companyType;
  clientWastes: Partial<
    Omit<wasteItem, "paidPrice_raw" | "paidPriceVat_raw"> & {
      paidPrice: number;
      paidPriceVat: number;
    }
  >[];
  sendInvoice: boolean;
  updateDisposalStatus: boolean;
  disposalId: number | null;
}) => {
  const { client } = params;
  const fd = new FormData();

  const blob = dataURItoBlob(client?.signature);
  fd.append(`signature`, blob);

  let acptObj = { ...params };
  delete (
    acptObj?.client as Omit<personType | companyType, "signature"> & {
      signature?: string;
    }
  )?.signature;

  fd.append(`acceptance`, JSON.stringify(acptObj));

  return await Fetch(
    "/clientWastes",
    {
      method: "POST",
      body: fd,
    },
    true
  )
    .then((res) => {
      const response = res.json() as Promise<person | company>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};
