import React, { useEffect, useRef, useState } from "react";
import { option } from "../FormGenerator/formTypes";
import dropLogo from "../../../assets/images/down-arrow.svg";
import "./NewMultiDropDown.css";

const NewMultiDropDown = ({
  options,
  selectedOptions,
  onSelect,
  border,
  placeholder,
}: {
  options: option[];
  selectedOptions: option[];
  onSelect: (data: option[]) => void;
  border: string;
  placeholder: string;
}) => {
  //Created this new dropdown because the former has some issues
  // This new one can be exported and used should the need arise
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [text, setText] = useState("");
  const [focused, setFocus] = useState(false);
  const [showDrop, drop] = useState(false);
  const [selectedList, setSelectedList] = useState<option[]>([]);
  const [filtered, setFilteredOptions] = useState<option[]>(options);

  const removeDrop = () => {
    drop(false);
  };

  const removeDropOnClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node | null)) {
      drop(false);
    }
  };

  useEffect(() => {
    if (selectedOptions && selectedOptions.length > 0) {
      const newOptions: option[] = [];
      options.forEach((opt) => {
        const isAvail = selectedOptions.some((v) => {
          return opt.value === v.value;
        });
        if (isAvail) {
          newOptions.push(opt);
        }
      });

      setSelectedList(newOptions);
    } else {
      setSelectedList([]);
    }
  }, [selectedOptions]);

  useEffect(() => {
    window.addEventListener("mousedown", removeDropOnClick);
    window.addEventListener("scroll", removeDrop);

    return () => {
      window.addEventListener("mousedown", removeDropOnClick);
      window.addEventListener("scroll", removeDrop);
    };
  });

  /**
   * When the input is changed, refilter
   */
  const textOnChange = (txt: string) => {
    setText(txt);
    drop(true);

    const _filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(txt.toLowerCase())
    );
    setFilteredOptions([..._filteredOptions]);
  };

  /**
   *
   * @param {
   * } opt This parameter is single filtered option
   *
   * When an option is clicked, it's label and also value (id) are added to separate arrays
   * If the option already exists in the arrays of selected values, they are removed
   *
   * The label and the value (id of the option) are added to different arrays
   */
  const optionOnClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    opt: option
  ) => {
    inputRef?.current?.focus();
    let index = -1;
    for (let i = 0; i < selectedList.length; i++) {
      const element = selectedList[i];
      if (element.value === opt.value) {
        index = i;
        break;
      }
    }
    if (index >= 0) {
      const newOptions = selectedList.filter((option, ind) => ind !== index);
      onSelect([...newOptions]);
      setSelectedList([...newOptions]);
    } else {
      onSelect([...selectedList, opt]);
      setSelectedList([...selectedList, opt]);
    }
  };

  /**
   *
   * @returns a string which is a combination of all the selected values separated by
   * `", "`
   *
   * This string is to be placed in the input element when the input is out of focus
   *
   */
  const getFilteredSites = () => {
    let string = ``;
    selectedList.map((option) => {
      string = string + option.label + ", ";
    });
    if (string.endsWith(", ")) {
      string = string.substring(0, string.length - 2);
    }
    return string;
  };

  const setMaxHeight = (): { [key: string]: string } => {
    //If boundaries were sent, use boundadry parameters instead ofwindow parameters
    // maxHeight:number, top:number
    const smallAllowed = 10 * 16;
    const maxAllowed = 18 * 16;
    const verticalMargin = 16; // 1rem margin for bottom
    const Bottom = inputRef.current?.getBoundingClientRect().bottom;
    const Top = inputRef.current?.getBoundingClientRect().top;
    const Left = inputRef.current?.getBoundingClientRect().left;
    const Height = inputRef.current?.getBoundingClientRect().height;
    const style: { [key: string]: any } = {};
    const WinHeight = window.innerHeight;
    if (Bottom && Top && Height && Left) {
      const bottomSpace = WinHeight - Bottom;
      const topSpace = WinHeight - bottomSpace - Height;
      if (bottomSpace <= smallAllowed) {
        if (topSpace < bottomSpace) {
          style["maxHeight"] = bottomSpace - verticalMargin;
          return style;
        } else {
          //flip
          style["bottom"] = Height + 2;
          if (topSpace >= maxAllowed + verticalMargin) {
            style["maxHeight"] = maxAllowed;
            return style;
          } else {
            style["maxHeight"] = topSpace - verticalMargin;
            return style;
          }
        }
      } else if (bottomSpace >= maxAllowed + verticalMargin) {
        style["maxHeight"] = maxAllowed;
        return style;
      } else {
        style["maxHeight"] = bottomSpace - verticalMargin;
        return style;
      }
    } else {
      style["maxHeight"] = maxAllowed; //Default to 18rem
      return style;
    }
  };

  return (
    <div className="dropDown newMultiDropDown" ref={ref}>
      <div
        style={{ borderLeft: border || "" }}
        className={`dropD_wrapper  ${
          showDrop && setMaxHeight()["bottom"]
            ? "dropFlip"
            : showDrop
            ? "drop"
            : ""
        }`}
        // className={`dropDown_wrapper  ${showDrop ? "drop" : ""}`}
      >
        <div className="dropDown_input flex1">
          <input
            autoComplete="off"
            ref={inputRef}
            onClick={() => {
              drop(true);
            }}
            onFocus={() => {
              if (!showDrop) {
                setText(``);
                setFilteredOptions([...options]);
              }
              drop(true);
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            title={focused || showDrop ? undefined : getFilteredSites()}
            value={focused || showDrop ? text : getFilteredSites()}
            onChange={(e) => textOnChange(e.target.value)}
            type="text"
            name=""
            placeholder={placeholder || ``}
          />
        </div>
        <div
          className="dropDown_btn"
          style={{ borderLeft: border || "" }}
          onClick={() => {
            if (showDrop) {
              inputRef?.current?.focus();
            } else {
              setText(``);
            }
            setFilteredOptions([...options]);
            drop(!showDrop);
          }}
        >
          <div>
            <img src={dropLogo} alt="" className="img_div_contain" />
          </div>
        </div>
      </div>
      {showDrop && (
        <div
          style={{
            maxHeight: setMaxHeight()["maxHeight"],
            bottom: setMaxHeight()["bottom"] ? setMaxHeight()["bottom"] : ``,
            top: setMaxHeight()["bottom"] ? `` : `calc(var(--height) + 2px)`,
          }}
          onScroll={(e) => e.stopPropagation()}
          className={`dropD_container ${
            setMaxHeight()["bottom"] ? "flip" : ""
          }`}
          //   className="dropDown_container"
        >
          {filtered.map((option, n) => (
            <div
              className={`dropDown_content ${
                selectedList.some((opt) => opt.value == option.value)
                  ? "picked"
                  : ""
              }`}
              key={`dropDown_content_${n}`}
              onClick={(e) => {
                inputRef?.current?.focus();
                optionOnClick(e, option);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NewMultiDropDown;
