import React, { ReactChild, useEffect, useState } from "react";
import "./NewPopUpFrame.css";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";
import { useLayoutEffect } from "react";

export default function NewPopUpFrame({
  handleOpen,
  showShadow,
  isShown,
  children,
  title,
  full,
  small,
  noVertPadding,
  className,
  classNameWrap,
}: {
  children: any;
  showShadow: boolean;
  handleOpen: (isShown: boolean) => void;
  isShown: boolean;
  full?: boolean;
  small?: boolean;
  noVertPadding?: boolean;
  className?: string;
  classNameWrap?: string;
  title: string | ReactChild;
}) {
  const padNum = (width: number): number => {
    if (width >= 1200) {
      return 0.18 * width;
    } else if (width >= 1080) {
      return 0.1 * width;
    } else {
      return 48;
    }
  };

  const [padding, setPadding] = useState(padNum(window.outerWidth));
  const handleResize = () => {
    setPadding(padNum(window.outerWidth));
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return isShown ? (
    <div
      style={{
        paddingLeft: `${padding}px`,
        paddingRight: `${padding}px`,
      }}
      className={`newPopUp_wrap ${full ? "noTopPadding" : ""} ${
        small ? `fitContent` : ``
      } ${noVertPadding ? `noVertPadding` : ``} ${
        classNameWrap ? classNameWrap : ""
      }`}
    >
      <div
        className="newPopUp_float"
        onClick={() => handleOpen(!isShown)}
      ></div>
      <div
        className={`flex1 newPopUp_container fdCol ${
          small ? `fitContent` : ``
        } ${className || ""}`}
      >
        <div className={`newPopUp_header ${showShadow ? "makeShadow" : ""} `}>
          <div className="flex1">{title}</div>
          <div
            className="popUpIcon_div center"
            onClick={() => handleOpen(!isShown)}
          >
            <CloseIcon />
          </div>
        </div>

        {children}
      </div>
    </div>
  ) : (
    <></>
  );
}
