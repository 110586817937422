import React, { useEffect, useState } from "react";
import { ReactComponent as UploadIcon } from "../../assets/images/upload.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/closeSVG.svg";
import "./IntegrationPopUp.css";
import {
  csvIntegrationType,
  csvParser,
  getIntegrationType,
  integrationType,
  integrationTypeObj,
  submitIntegrationRecords,
  xmlParser,
} from "./parser";
import { useAsync } from "../../helpers/asyncFunc";

const IntegrationPopUp = ({ close }: { close: () => void }) => {
  const [integrationTypes, setIntegrationTypes] = useState<
    integrationTypeObj[]
  >([]);

  const [kemekDoc, setKemekDoc] = useState<Object[]>();
  const [deskaDoc, setDeskaDoc] = useState<Object[]>();
  const [bilanceDoc, setBilanceDoc] = useState<Object[]>();

  const intData = (type: integrationType) => {
    if (type === "bilance") {
      return bilanceDoc;
    } else if (type === "deska") {
      return deskaDoc;
    } else if (type === "kemek") {
      return kemekDoc;
    } else {
      return undefined;
    }
  };

  const clearFileInput = (type: integrationType) => {
    const node = document.getElementById(type) as HTMLInputElement | null;
    try {
      //@ts-ignore
      node.value = null;
    } catch (ex) {}
    if (node?.value) {
      node?.parentNode?.replaceChild(node?.cloneNode(true), node);
    }
  };

  const clearIntegrationData = (type: integrationType) => {
    if (type === "bilance") {
      setBilanceDoc([]);
    } else if (type === "deska") {
      setDeskaDoc([]);
    } else if (type === "kemek") {
      setKemekDoc([]);
    }

    clearFileInput(type);
  };

  const typeRes = useAsync({
    asyncFunc: getIntegrationType,
    funcParams: {} as never,
    immediate: true,
  });

  const postIntegrationRes = useAsync(
    {
      asyncFunc: submitIntegrationRecords,
      funcParams: {},
      immediate: false,
    },
    []
  );

  useEffect(() => {
    const type = postIntegrationRes.data?.type;

    if (type) {
      clearIntegrationData(type);
    }
  }, [postIntegrationRes.data]);

  useEffect(() => {
    const inTtypesData = typeRes.data;

    if (inTtypesData) {
      setIntegrationTypes(inTtypesData.types);
    }
  }, [typeRes.data]);

  const submit = async (e: React.FormEvent, type: integrationType) => {
    e.preventDefault();
    postIntegrationRes.execute({
      data: intData(type),
      type,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = (e.target.files || [])[0];
      var fr = new FileReader();
      fr.onload = async function () {
        try {
          const kemekData = await xmlParser(fr.result as string);
          setKemekDoc([...kemekData]);
          setDeskaDoc([]);
          setBilanceDoc([]);
        } catch (e) {
          console.log(e);
        }
        clearFileInput("kemek");
      };
      fr.readAsText(file);
    } catch (e) {
      clearData();
      console.log(e);
    }
  };

  const isPopulated = (type: integrationType): boolean => {
    if (type === "bilance") {
      return !!bilanceDoc?.length;
    } else if (type === "deska") {
      return !!deskaDoc?.length;
    } else if (type === "kemek") {
      return !!kemekDoc?.length;
    } else {
      return false;
    }
  };

  const clearData = () => {
    setBilanceDoc([]);
    setDeskaDoc([]);
    setKemekDoc([]);
  };

  const csvOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: csvIntegrationType
  ) => {
    try {
      const file = (e.target.files || [])[0];
      var fr = new FileReader();
      fr.onload = async function () {
        try {
          const data = await csvParser(fr.result as string, type);
          if (type === "bilance") {
            setBilanceDoc([...data]);
            setDeskaDoc([]);
            setKemekDoc([]);
          } else if (type === "deska") {
            setDeskaDoc([...data]);
            setBilanceDoc([]);
            setKemekDoc([]);
          }
        } catch (e) {
          console.error(e);
          clearData();
        }
        clearFileInput(type);
      };

      fr.readAsText(file);
    } catch (e) {
      console.error(e);
    }
  };

  const parse = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: integrationType
  ) => {
    if (name === "bilance") {
      csvOnChange(e, name);
    } else if (name === "deska") {
      csvOnChange(e, name);
    } else if (name === "kemek") {
      onChange(e);
    } else {
      return;
    }
  };

  return (
    <div onClick={close} className="int_popUp_wrapper">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="int_popUp_container"
      >
        <div className="int_popUp_closeIcon_wrapper">
          <CloseIcon onClick={close} />
        </div>
        <p>Duomenų apie pristatytas atliekas importavimas</p>
        <div className="int_popUp_btn_wrapper">
          {integrationTypes.length ? (
            integrationTypes.map((i, n) => (
              <form key={n} action="GET">
                <div className="int_popUp_btn_container">
                  <div>
                    <button
                      disabled={
                        postIntegrationRes.loading || !isPopulated(i.name)
                      }
                      onClick={(e) => {
                        if (isPopulated(i.name)) {
                          submit(e, i.name);
                        }
                      }}
                      type="button"
                      className="int_popUp_btn"
                    >
                      {i.name}
                    </button>
                  </div>
                  <label
                    className="int_popUp_type_label"
                    onClick={(e) => {
                      if (i.name !== "bilance") {
                        e.stopPropagation();
                      }
                    }}
                    aria-disabled={i.name !== "bilance"}
                  >
                    <input
                      className="integration_hidden_input"
                      type="file"
                      name="inputfile"
                      id={i.name}
                      accept=".csv"
                      onChange={(e) =>
                        parse(e, i.name.toLocaleLowerCase() as integrationType)
                      }
                    />
                    <div className="int_popUp_type">
                      <div className="int_popUp_type_lnk">{i.docType}</div>
                      <div className="int_popUp_type_upload_icon_wrapper">
                        <UploadIcon className="img_div_contain" />
                      </div>
                    </div>
                  </label>
                </div>
              </form>
            ))
          ) : (
            <>
              {/* This is has no effect. Basically here to prevent collapsing 
             ...of popup when no integration type has been retrieved */}
              {Array(3)
                .fill(0)
                .map((a, n) => (
                  <div key={n} className="int_popUp_btn_container invisible">
                    <div>
                      <div className={`int_popUp_loaded`}>Loaded</div>
                      <button type="button" className="int_popUp_btn">
                        -
                      </button>
                    </div>
                    <div className="int_popUp_txt">-</div>
                    <label>
                      <div className="int_popUp_type">
                        <div className="int_popUp_type_lnk">-</div>
                        <div className="int_popUp_type_upload_icon_wrapper">
                          <UploadIcon className="img_div_contain" />
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegrationPopUp;
