import React, { useEffect, useState } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";

import { defaultFormData, defaultFormError } from "./defaultData";
import { FeaturePermission, SiteSelection } from "..";

import "./style.scss";
import "./userDetail.css";
import floatInfo from "../../../../UI/floatInfo/floatInfo";
import NewConfirmDialog from "../../../../UI/ConfirmDialog/NewConfirmDialog";
import moment from "moment";
import NewPopUpFrame from "../../../../UI/PopupFrame/NewPopUpFrame";
import { site } from "../../../../../@types/sites";
import { Features } from "../../../../../@types/feature";
import { userFormData, userGroup } from "../../../../../@types/user";
import useTranslate from "../../../../../translate/useTranslate";
import { UserFormFields, ext_updateFormData } from "../UserForm/UserForm";
import { useAsync } from "../../../../../helpers/asyncFunc";
import { saveUserDetails } from "../../UserManagement";
import { isEmail } from "../../../../../helpers/validate";

type PartialNull<T> = { [P in keyof T]: T[P] | null };
export type user = Omit<userFormData, "sites"> & {
  sites: site[];
  createdAt: string;
};
export type detail_partial_user = Omit<PartialNull<user>, "sites"> & {
  sites: site[];
};
export type userData = Omit<PartialNull<userFormData>, "sites"> & {
  sites: number[];
};

const UserDetail = function ({
  userId,
  user,
  sites,
  features,
  roles,
  userGroups,
  isConfirmYes,
  getUser,
  delUser,
  onCancel,
  setConfirmDialog,
  resetConfirmDialog,
  getUserSites,
  currentUserId,
}: {
  userId: number | null;
  user: user;
  sites: site[];
  features: Features[];
  roles: { id: number; name: string }[];
  userGroups: userGroup[];
  isConfirmYes: boolean;
  getUser: (id: number) => void;
  delUser: (params: { id: number; userId: number | undefined }) => void;
  onCancel: () => void;
  setConfirmDialog: (text: string) => void;
  resetConfirmDialog: () => void;
  getUserSites: () => void;
  currentUserId: number | undefined;
}) {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<detail_partial_user>({
    ...defaultFormData,
  });
  const [formError, setFormError] = useState<{
    [key in keyof user]?: boolean;
  }>({ ...defaultFormError });
  const [siteSelectionShown, setSiteSelectionShown] = useState(false);
  const [featurePermissionShown, setFeaturePermissionShown] = useState(false);
  const [paswdConfirm, setPaswdConfirm] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (userId) {
      getUser(userId);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  useEffect(() => {
    if (isConfirmYes) {
      resetConfirmDialog();
      actualDel();
    }
  }, [isConfirmYes]);

  const updateFormData = (objData: Partial<user>) => {
    ext_updateFormData(objData, {
      formData,
      formError,
      setFormData,
      setFormError,
    });
  };

  const showSiteSelection = () => {
    setSiteSelectionShown(true);
  };

  const showFeaturePermission = () => {
    setFeaturePermissionShown(true);
    setSiteSelectionShown(false);
  };

  const sanitizedFeatures = () => {
    const newFeatureList = (formData.featurePermissions || []).filter(
      (feature) => {
        return (formDataSites || []).some((site) => {
          return site.id === feature.site;
        });
      }
    );

    return newFeatureList;
  };

  const formDataSites = formData.sites || [];

  const saveUserRes = useAsync({
    asyncFunc: saveUserDetails,
    funcParams: {
      user: {
        ...formData,
        sites: (formDataSites || []).map((site) => site.id),
        featurePermissions:
          formData.featurePermissions && formData.featurePermissions.length > 0
            ? sanitizedFeatures()
            : formData.featurePermissions,
      },
      type: "edit",
    },
    immediate: false,
  });

  useEffect(() => {
    console.log("here", saveUserRes, saveUserRes.data?.saved);
    if (saveUserRes.data?.saved) {
      onCancel();
    }
  }, [saveUserRes.data?.saved]);

  const onSave = () => {
    const data: userData = {
      ...formData,
      sites: (formDataSites || []).map((site) => site.id),
      featurePermissions:
        formData.featurePermissions && formData.featurePermissions.length > 0
          ? sanitizedFeatures()
          : formData.featurePermissions,
    };

    saveUserRes.execute({ type: "edit", user: data });
  };

  useEffect(() => getUserSites, []);

  const actualDel = () => {
    delUser({ id: user.id, userId: currentUserId });
    onCancel();
  };

  const delOnClick = () => {
    setConfirmDialog(t("Are you sure you want to delete your account?"));
  };

  const containsRemovedSite = () => {
    const contained = (site: site) => {
      return formDataSites.some((fSite) => {
        return fSite.id === site.id;
      });
    };
    return (user?.sites || []).some((site) => {
      return !contained(site);
    });
    /**if the new set does not include all, call the function */
  };

  const url = useResolvedPath("").pathname;

  const checkFields = () => {
    const errorDetails = (
      keys: (keyof detail_partial_user)[] = [
        "uname",
        "email",
        "firstName",
        "lastName",
        "roleId",
        "userGroupId",
      ]
    ) => {
      let error: Partial<typeof formError> = {};
  
      keys.forEach((key) => {
        const value = formData[key];
        if(key === "email"){
          if(!isEmail((value) ? String(value): "" )) {
            error = { ...error, [key]: true };
          }
        } else{
          if (!value) {
            error = { ...error, [key]: true };
          }
        }
      });
  
      error = { ...error };
  
      const hasError = Object.keys(error).some((k) => {
        const key = k as keyof userFormData;
        return !!error[key];
      });
  
      return { hasError, error };
    };
  
    const check = () => {
      const { hasError, error } = errorDetails();
      if (hasError) {
        setFormError({ ...error });
        return true;
      } else {
        return (false);
      }
    };

   return check()
  }

  return (
    <>
      {" "}
      <UserFormFields
        showSiteSelection={showSiteSelection}
        delOnClick={delOnClick}
        formData={formData}
        formError={formError}
        paswdConfirm={paswdConfirm}
        roles={roles}
        setPaswdConfirm={setPaswdConfirm}
        showFeaturePermission={showFeaturePermission}
        setFormData={setFormData}
        setFormError={setFormError}
        url={url}
        userGroups={userGroups}
        ButtonElement={
          <div className="user_form_btn_container">
            <div className="col">
              {t("Account created")}:{" "}
              {moment(formData.createdAt || "").format("YYYY-MM-DD HH:mm")}
            </div>
            <div className="actions">
              <button
                type="button"
                className="btn btn-custom"
                onClick={() => onCancel()}
              >
                {t("Cancel")}
              </button>
              <button
                type="button"
                onMouseOver={(e) => {
                  if (!(formDataSites.length > 0)) {
                    floatInfo.subscribe(
                      e,
                      `Please add at least one site before saving your edits`
                    );
                  }
                }}
                onMouseOut={(e) => {
                  if (!(formDataSites.length > 0)) {
                    floatInfo.unsubscribe(e);
                  }
                }}
                className={`btn btn-green ${
                  saveUserRes.loading || formDataSites.length > 0
                    ? ""
                    : "inActive"
                }`}
                disabled={saveUserRes.loading}
                onClick={() => {
                  const hasError = checkFields()
                    if(hasError) {
                      return
                    }
                  if (containsRemovedSite()) {
                    if (formDataSites.length > 0) {
                      setShowPrompt(true);
                    } else {
                      return;
                    }
                  } else {
                    if (formDataSites.length > 0) {
                      onSave();
                    } else {
                      return;
                    }
                  }
                }}
              >
                {t("Save")}
              </button>
            </div>
          </div>
        }
        detailed
      />
      {showPrompt && (
        <NewConfirmDialog
          txt={t(
            "Warning: deleting a site will also delete all employee access settings. The employees themselves will not be deleted."
          )}
          isVisible={showPrompt}
          continueText={t("Understood, delete")}
          returnText={t("Go back")}
          onExit={() => setShowPrompt(false)}
          onContinue={() => onSave()}
          isLoading={false}
        />
      )}
      {siteSelectionShown && (
        <NewPopUpFrame
          isShown={siteSelectionShown}
          handleOpen={setSiteSelectionShown}
          title={t("Sites").toUpperCase()}
          showShadow={false}
        >
          <SiteSelection
            sites={sites}
            formData={formData as userFormData}
            onCancel={() => setSiteSelectionShown(false)}
            onSave={() => setSiteSelectionShown(false)}
            updateFormData={updateFormData}
          />
        </NewPopUpFrame>
      )}
      {featurePermissionShown && (
        <NewPopUpFrame
          showShadow={false}
          isShown={featurePermissionShown}
          handleOpen={setFeaturePermissionShown}
          title={t("Employee's Access Control")}
          classNameWrap="feature_popup_wrap"
        >
          <FeaturePermission
            features={features}
            formData={formData as userFormData}
            onCancel={() => setFeaturePermissionShown(false)}
            updateFormData={updateFormData}
            onSave={() => setFeaturePermissionShown(false)}
          />
        </NewPopUpFrame>
      )}
    </>
  );
};

export default UserDetail;
