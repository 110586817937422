import React, { ReactChild, useEffect, useRef, useState } from "react";
import IconContainer from "../iconContainer/iconContainer";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";
import "./Notification.css";

const Notification = ({
  error,
  isDone,
  text,
  reset,
}: {
  error: boolean;
  isDone: boolean;
  text?: ReactChild;
  reset: () => void;
}) => {
  const [timeoutId, setTimeoutId] = useState<number | undefined>();
  const [notification, setNotification] = useState<ReactChild>();

  const mountedRef = useRef(true);
  useEffect(() => {
    mountedRef.current = true;

    if (isDone) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      setNotification(text);
      const copyTimeout = window.setTimeout(() => {
        if (mountedRef.current) {
          setNotification("");
          reset();
        }
      }, 3500);
      setTimeoutId(copyTimeout);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [isDone]);

  return notification ? (
    <div className={`notification_wrapper ${error ? "error" : ""}`}>
      <div className="flex1">{notification}</div>
      <IconContainer size="lg">
        <CloseIcon
          onClick={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            setNotification("");
            reset();
          }}
        />
      </IconContainer>
    </div>
  ) : (
    <></>
  );
};

export default Notification;
