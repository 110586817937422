import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Suggestion } from "../../components/UI";

import "./style.scss";
import useTranslate from "../../translate/useTranslate";

const Home = function ({ setCurrentSite, storedSites }) {
  const { t } = useTranslate();
  const [siteOptions, setSiteOptions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const options = storedSites.map((site) => {
      return {
        label: site.name,
        value: site.id,
      };
    });

    setSiteOptions(options);
  }, [storedSites]);

  const selectSite = (selectedValue) => {
    setCurrentSite(selectedValue);
    const locationState = location.state?.from;
    const fromAcceptancePage = location.state?.acceptancePage;
    const search = fromAcceptancePage ? locationState?.search || "" : "";

    navigate("/waste-acceptance" + search);
  };

  return (
    <div className="home-wrapper">
      <div className="HOMEPAGE__section">
        <div className="HOMEPAGE__section-title">{t("Choose a site")}</div>
      </div>

      <div className="site-selection-wrapper">
        <Suggestion
          options={siteOptions}
          onSelect={selectSite}
          placeholder={`${t("Select")}...`}
        />
      </div>
    </div>
  );
};

export default Home;
