import * as actionTypes from "../actionTypes";

import * as statuses from "../../statuses";

const initialState = {
  user: null,
  status: statuses.STATUS_IDLE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_SUCCEED:
      return {
        ...state,
        user: action.payload,
      };
    case actionTypes.SAVE_USER:
      return {
        ...state,
        status: statuses.STATUS_IN_PROGRESS,
      };
    case actionTypes.SAVE_USER_SUCCEED:
      return {
        ...state,
        status: statuses.STATUS_DONE,
      };
    case actionTypes.DEL_USER:
      return {
        ...state,
        status: statuses.STATUS_IN_PROGRESS,
      };
    case actionTypes.DEL_USER_SUCCEED:
      return {
        ...state,
        status: statuses.STATUS_DONE,
      };
    case actionTypes.RESET_USER_STATUS:
      return {
        ...state,
        status: statuses.STATUS_IDLE,
      };
    default:
      return state;
  }
}

export default reducer;
