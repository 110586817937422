import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const getUserGroups = (action$) =>
  action$.pipe(
    ofType(actions.GET_USER_GROUPS),
    mergeMap(() =>
      FetchRX("/userGroups").pipe(
        map((result) => {
          return {
            type: actions.GET_USER_GROUPS_SUCCEED,
            payload: result.userGroups,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.GET_USER_GROUPS_FAILED });
        })
      )
    )
  );

export const getUserGroup = (action$) =>
  action$.pipe(
    ofType(actions.GET_USER_GROUP),
    mergeMap((action) =>
      FetchRX(`/userGroups/${action.payload}`).pipe(
        map((result) => {
          return {
            type: actions.GET_USER_GROUP_SUCCEED,
            payload: result.userGroup,
          };
        }),
        catchError((error) => {
          return of({ type: actions.GET_USER_GROUP_FAILED });
        })
      )
    )
  );

export const saveUserGroup = (action$) =>
  action$.pipe(
    ofType(actions.SAVE_USER_GROUP),
    mergeMap((action) =>
      FetchRX("/userGroups", {
        method: action.payload.type === "edit" ? "PUT" : "POST",
        body: action.payload.userGroup,
      }).pipe(
        map((result) => {
          return {
            type: actions.SAVE_USER_GROUP_SUCCEED,
          };
        }),
        catchError((error) => {
          return of({ type: actions.SAVE_USER_GROUP_FAILED });
        })
      )
    )
  );

export const delUserGroup = (action$) =>
  action$.pipe(
    ofType(actions.DEL_USER_GROUP),
    mergeMap((action) =>
      FetchRX("/userGroups", {
        method: "DELETE",
        body: { id: action.payload },
      }).pipe(
        map((result) => {
          return {
            type: actions.DEL_USER_GROUP_SUCCEED,
          };
        }),
        catchError((error) => {
          return of({ type: actions.DEL_USER_GROUP_FAILED });
        })
      )
    )
  );
