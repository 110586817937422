import * as actionTypes from "../actionTypes";

const initialState = {
  roles: [],
  inactiveRoles: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case actionTypes.SET_INACTIVE_ROLES:
      return {
        ...state,
        inactiveRoles: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
