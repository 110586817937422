import React, { ReactChild } from "react";
import { ReactComponent as CloseCircleSVG } from "../../../assets/images/close-circle.svg";
import { ReactComponent as CloseSVG } from "../../../assets/images/closeSVG.svg";
import { ReactComponent as DownArrowSVG } from "../../../assets/images/down-arrow.svg";
import { ReactComponent as SearchSVG } from "../../../assets/images/search.svg";
import "./iconContainer.css";

export default function IconContainer({
  children,
  size,
  width,
  defaultCursor,
  noHover,
}: {
  children: ReactChild;
  size?: string;
  width?: number;
  noHover?: boolean;
  defaultCursor?: boolean;
}) {
  return (
    <div
      style={{ width: `${width}px` || `` }}
      className={`iconContainer ${size ? "lg" : ""} ${
        noHover ? "" : "hover"
      }  ${defaultCursor ? "defaultCursor" : ""}`}
    >
      {children}
    </div>
  );
}
export const CloseCirleLogo = ({
  onClick,
}: {
  onClick: (data: any) => void;
}) => {
  return (
    <IconContainer>
      <CloseCircleSVG onClick={onClick}></CloseCircleSVG>
    </IconContainer>
  );
};
export const PlusLogo = ({ onClick }: { onClick: (data: any) => void }) => {
  return (
    <IconContainer>
      <img
        onClick={onClick}
        src={require("../../../assets/images/plus.svg").default}
      />
    </IconContainer>
  );
};
export const CloseLogo = ({ onClick }: { onClick: (data: any) => void }) => {
  return (
    <IconContainer>
      <CloseSVG onClick={onClick}></CloseSVG>
    </IconContainer>
  );
};
export const DownArrowLogo = ({
  onClick,
}: {
  onClick: (data: any) => void;
}) => {
  return (
    <IconContainer>
      <DownArrowSVG onClick={onClick}></DownArrowSVG>
    </IconContainer>
  );
};

export const SearchLogo = ({ onClick }: { onClick: (data: any) => void }) => {
  return (
    <IconContainer>
      <SearchSVG onClick={onClick}></SearchSVG>
    </IconContainer>
  );
};
