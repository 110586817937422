import { ofType } from "redux-observable";
import { catchError, mergeMap, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const getDocuments = (action$) =>
  action$.pipe(
    ofType(actions.GET_DOCUMENTS),
    mergeMap(() =>
      FetchRX("/documents").pipe(
        map((result) => {
          return {
            type: actions.SET_DOCUMENTS,
            payload: result.documents,
          };
        }),
        catchError((error) => {
          return of({ type: actions.GET_DOCUMENTS_FAILED });
        })
      )
    )
  );
