import { json } from "./translation_json";
import { text_replace } from "../helpers/misc";
import { store } from "../store";

export type locale = "LITHUANIAN" | "ENGLISH";
export type BCP_tag = "lt-LT" | "en-IE";
export type translate_options = {
  [key in translation_variables]?: (string | number) | (string | number)[];
};
export type json_key = keyof typeof json;

const getTranslateJson = () => {
  const locale = store.getState().commonReducer.locale as locale;
  const res: Partial<{ [key in json_key]: string }> = {};
  Object.keys(json).forEach((key) => {
    const translate_obj = json[key as json_key];
    res[key as json_key] = translate_obj[locale];
  });

  return { json: res, locale } as {
    json: { [key in json_key]: string };
    locale: locale;
  };
};
let translate_json = getTranslateJson();

export const translate = (
  originalText: json_key,
  options: translate_options = {}
): { text: string; locale: locale } => {
  const current_locale = store.getState().commonReducer.locale;
  const { locale } = translate_json;
  if (current_locale !== locale) {
    translate_json = getTranslateJson();
  }

  const text = translate_json.json[originalText] || "";
  return { text: text_replace(text, options), locale: current_locale };
};

export const locale_BCP_tag_dictionary: {
  [key in locale]: BCP_tag;
} = {
  ENGLISH: "en-IE",
  LITHUANIAN: "lt-LT",
};

export type translation_variables = "{{amount}}" | "{{time}}";
