import { Fetch } from "../../helpers/misc";
import { wasteMangementRecordType } from "./WasteManagement";

export const getAllWastes = async (details: {
  query: string;
  limit: number;
  offset: number;
}) => {
  let { query, limit, offset } = details;

  return await Fetch(
    `/clientWastes/allwastes?${query}&limit=${limit}&offset=${offset}`
  ).then(
    (res) => {
      const result: Promise<{
        allWastes: wasteMangementRecordType[];
        count: number;
      }> = res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};
