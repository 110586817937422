import { Fetch } from "../../helpers/misc";

export const saveAdjustedWasteWeight = async (details: {
  siteId: number;
  wasteId: number;
  totalWeight: number;
  totalWeightAdjusted: number;
  adjustedWeight: number;
  userId: number;
}) => {
  return await Fetch(
    `/wasteWeightAdjustments`, {
      method: 'POST',
      body: JSON.stringify(details)
    }
  ).then(
    (res) => {
      const result: Promise<{ success: boolean }> = res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};
