import { wasteActivityType } from "../../@types/waste";
import { Fetch } from "../../helpers/misc";

export const getWasteDisposal = async (params: {
  query: string;
  limit: number;
  offset: number;
}) => {
  const { query, limit, offset } = params;

  return await Fetch(
    `/wasteActivities?${query}&limit=${limit}&offset=${offset}`
  )
    .then((res) => {
      const disposals = res.json() as Promise<{
        success: true;
        wasteActivities: wasteActivityType[];
        count: number;
      }>;
      return disposals;
    })
    .catch((err) => {
      throw err;
    });
};
