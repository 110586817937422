import moment from "moment";
import { ReactChild, ReactElement } from "react";
import { extType, gridType, HeaderType, option } from "./formTypes";

export const textField = (
  placeholder = "",
  error: boolean = false,
  handler: (txt: string) => void = () => {},
  controlledText?: string,
  controlledFunction?: (txt: string) => void
): gridType => ({
  fieldType: "field",
  placeholder: placeholder,
  error: error,
  fieldHandler: (text: string) => {
    handler(text);
  },
  controlledText,
  controlledFunction,
  checker: (text) => true,
  type: `text`,
});

export const searchField = (
  placeholder = "",
  handler: (txt: string) => void = () => {},
  controlledText?: string,
  controlledFunction?: (txt: string) => void
): gridType => ({
  fieldType: "field",
  placeholder: placeholder,
  controlledText,
  controlledFunction,
  fieldHandler: (text: string) => {
    handler(text);
  },
  type: `search`,
});

export const dropField = (
  options: option[],
  placeholder = "",
  error: boolean,
  value = { label: "", value: 0 },
  handler: (value: number) => void = () => {},
  clearable = false
): gridType => ({
  fieldType: "field",
  options: options,
  value: value,
  error: error,
  fieldHandler: (value: number) => {
    handler(value);
  },
  dropDownTypeMultiple: false,
  clearable,
  checker: (text) => true,
  dropDown: true,
  type: "text",
});

export const multiDropField = (
  options: option[],
  placeholder = "",
  error: boolean,
  value: option[],
  handler: (value: option[]) => void = () => {}
): gridType => ({
  fieldType: "field",
  options: options,
  value: value,
  error: error,
  fieldHandler: (value: option[]) => {
    handler(value);
  },
  placeholder,
  dropDownTypeMultiple: true,
  checker: (text) => true,
  dropDown: true,
  type: "text",
});

export const dateField = (
  placeholder = "",
  handler: (txt: string) => void = () => {},
  error: boolean,
  value = moment(new Date()).format("YYYY-MM-DD")
): gridType => ({
  fieldType: "field",
  type: "date",
  placeholder: placeholder,
  value: value,
  error: error,
  fieldHandler: (text) => {
    handler(text);
  },
});

export const DecoratedDateField = (
  placeholder = "",
  handler: (txt: string) => void = () => {},
  error: boolean,
  value = moment(new Date()).format("YYYY-MM-DD")
): gridType => ({
  fieldType: "field",
  placeholder: placeholder,
  value: value,
  error: error,
  fieldHandler: (text) => {
    handler(text);
  },
  type: `decoratedDate`,
});

export const numberField = (
  placeholder = "",
  error: boolean = false,
  handler: (txt: string) => void = () => {},
  controlledText?: string,
  controlledFunction?: (txt: string) => void
): gridType => ({
  fieldType: "field",
  placeholder: placeholder,
  error: error,
  fieldHandler: (text: string) => {
    handler(text);
  },
  controlledText,
  controlledFunction,
  checker: () => true,
  type: "number",
});

export const textBox = (text: string, center: boolean): gridType => ({
  fieldType: "text",
  center,
  text,
});

export const childField = (child: ReactChild): gridType => ({
  fieldType: "child",
  child,
});

export const Header = (
  textArray: (ReactElement | string)[],
  error: boolean,
  alignLeft: boolean = false
): HeaderType => ({
  alignLeft,
  fieldText: textArray as ReactElement[],
  error: error,
});
