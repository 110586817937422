import { siteWasteType } from "../../../@types/waste";
import { Fetch } from "../../../helpers/misc";

export const getCombinedWastes = async (params: {
  clientId: number | string;
  isCompany: boolean;
}) => {
  const { clientId, isCompany } = params;
  if (!clientId) {
    return;
  }
  return await Fetch(
    `/clientWastes/annualDelivered?clientId=${clientId}&isCompany=${
      isCompany ? `true` : ``
    }`
  ).then(
    (res) => {
      const result = res.json() as Promise<{
        success: boolean;
        combinedWastes: { [wasteId: number]: number };
      }>;
      return result;
    },
    (error) => {
      throw error;
    }
  );
};

export const getSiteWastes = async (params: { siteId: number }) => {
  if (!params.siteId) {
    return;
  }
  return await Fetch(`/siteWastes/${params.siteId}`)
    .then((res) => {
      const response = res.json() as Promise<{ siteWastes: siteWasteType[] }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSiteAvailableWastes = async (params: { siteId: Number }) => {
  const { siteId } = params;
  if (!siteId) {
    return;
  }
  return await Fetch(`/clientWastes/siteAvailable?siteId=${siteId}`).then(
    (res) => {
      const result = res.json() as Promise<{
        remainingWeights: { [wasteId: number]: number };
      }>;

      return result;
    },
    (error) => {
      throw error;
    }
  );
};

export const getTranstionDeficit = async (params: {
  id: number | null;
  isCompany: boolean;
}) => {
  const { id, isCompany } = params;

  if (!id) {
    return;
  }

  return await Fetch(
    `/clients/transanction_deficit?id=${id}&isCompany=${isCompany}`
  )
    .then((res) => {
      const response = res.json() as Promise<{ deficit: number | null }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};
