import React, { useState, useEffect } from 'react'

import ExitButton from './ExitButton'

import './style.scss';

const Overlay = (props) => {
	const [visibility, setVisibility] = useState()

	useEffect(() => {
		setVisibility(props.visibility)
	}, [props.visibility])

	const exit = () => {
		if (props.needExit) {
			setVisibility('hidden')
			props.exit()
		}
	}

	const pressOverlay = () => {
		if (props.needExit) {
			setVisibility('hidden')
			props.exit()
		}
	}

	const stopPropagation = (e) => {
		e.stopPropagation()
	}

	return (
		<div onClick={pressOverlay} id="overlay" className="overlay" style={{ visibility }}>
			<div onClick={stopPropagation} id="overlay-container" className={`overlay-container ${props.scrollNeeded ? 'need-scroll' : ''}`} style={props.styleContainer ? props.styleContainer : null}>
				{props.needExit && <ExitButton action={exit} />}
				{props.children}
			</div>
		</div>
	)
}

export default Overlay
