import * as actionTypes from "../actionTypes";

const initialState = {
  wasteActivityTypes: [],
  inactiveWasteActivityTypes: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_WASTEACTIVITYTYPES:
      return {
        ...state,
        wasteActivityTypes: action.payload,
      };
    case actionTypes.SET_INACTIVE_WASTEACTIVITYTYPES:
      return {
        ...state,
        inactiveWasteActivityTypes: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
