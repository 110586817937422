import { connect, ConnectedProps } from "react-redux";
import Suggestion from "./Suggestion";
import * as actions from "../../stores/actions";
import { suggestionBody } from "./suggestionType";

const mapStoreStateToProps = (store: any) => ({
  suggestions: store.suggestionReducer.suggestions,
  suggestionText: store.suggestionReducer.suggestionText,
  unReadCount: store.suggestionReducer.unReadCount,
});

const mapDispatchToProps = (dispatch: (...arg: any[]) => void) => ({
  createSuggestion: (body: suggestionBody) => {
    dispatch(actions.createSuggestion(body));
  },
  readSuggestion: (id: number) => {
    dispatch(actions.readSuggestion(id));
  },
  setSuggestionText: (data: { title: string; body: string }) => {
    dispatch(actions.setSuggestionText(data));
  },
});

const connector = connect(mapStoreStateToProps, mapDispatchToProps);

type connectT = ConnectedProps<typeof connector>;

export default connector(Suggestion);
