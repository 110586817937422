import { connect } from "react-redux";
import SiteLimits from "./SiteLimits";
import * as actions from "../../stores/actions";

const mapStoreStateToProps = (store: any) => ({
  sites: store.siteReducer.sites,
  currentSiteId: store.commonReducer.currentSiteId,
});

const mapDispatchToProps = (dispatch: (...arg: any[]) => void) => ({});

export default connect(mapStoreStateToProps, mapDispatchToProps)(SiteLimits);
