import { applyMiddleware, createStore, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";
import rootReducer, { reducers } from "./stores/reducers";
import { StateLoader } from "./stores";
import * as actionCreators from "./stores/actions/index";
import { composeWithDevTools } from "@redux-devtools/extension";
import { getMode } from "./settings";
import rootEpic from "./stores/epics";

type reducerKeys = keyof typeof reducers;
type reducerType = { [key in reducerKeys]: ReturnType<(typeof reducers)[key]> };
export type stateType = Exclude<reducerType, undefined>;

const epicMiddleware = createEpicMiddleware();
const stateLoader = new StateLoader();
const initialState = stateLoader.loadState();

const composeEnhancers = composeWithDevTools(
  getMode() === "production"
    ? {}
    : {
        actionCreators,
        trace: true,
        traceLimit: 25,
      }
);

const middleware = [epicMiddleware, thunk];
export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

epicMiddleware.run(rootEpic);
store.subscribe(() => {
  stateLoader.saveState(store.getState(), []);
});
