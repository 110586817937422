import { wasteAccumulation } from "../../@types/waste";
import { Fetch } from "../../helpers/misc";

export const getWasteAccumulations = async (details: {
  query: string;
  limit: number;
  offset: number;
}) => {
  let { query, limit, offset } = details;

  return await Fetch(
    `/wasteAccumulations?${query}&limit=${limit}&offset=${offset}`
  ).then(
    (res) => {
      const result: Promise<{
        wasteAccumulations: wasteAccumulation[];
        count: number;
      }> = res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};
