import { Fetch } from "../../helpers/misc";
import { contract, createContractType } from "./Contract";

export const deleteContract = async (params: { id: number }) => {
  return await Fetch("/contracts", {
    method: "DELETE",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const contractRes = res.json() as Promise<{
        success: true;
      }>;
      return contractRes;
    })
    .catch((err) => {
      throw err;
    });
};

export const createContract = async (params: createContractType) => {
  params = {
    ...params,
    ...(isNaN(parseFloat(`${params.quantity}`))
      ? {}
      : {
          quantity: parseFloat(`${params.quantity}`) * 1000,
        }),
  };
  return await Fetch("/contracts/create", {
    method: "POST",
    body: JSON.stringify(params),
  })
    .then((res) => {
      const contractRes = res.json() as Promise<{
        success: true;
      }>;
      return contractRes;
    })
    .catch((err) => {
      throw err;
    });
};

export const getContracts = async (params: {
  clientId: number;
  limit: number;
}) => {
  return await Fetch(
    `/contracts?clientId=${params.clientId}&limit=${params.limit}`
  )
    .then((res) => {
      const contractRes = res.json() as Promise<{
        contracts: contract[];
        count: number;
      }>;

      return contractRes;
    })
    .catch((err) => {
      throw err;
    });
};
