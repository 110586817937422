import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { site, userSite } from "../../@types/sites";
import {
  childField,
  Header,
  numberField,
  textBox,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import "./SiteLimits.css";
import { useAsync } from "../../helpers/asyncFunc";
import { defaultOption } from "../../helpers/misc";
import {
  deleteGroupedSiteWastes,
  editDetails,
  editGroupedSiteWastes,
  getGroupedSiteWastes,
} from "./getter";
import DropDown from "../../components/elements/DropDown/DropDown";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import { ReactComponent as EditLogo } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteLogo } from "../../assets/images/del.svg";
import NewConfirmDialog from "../../components/UI/ConfirmDialog/NewConfirmDialog";
import {
  groupedSiteWastes,
  groupSiteWasteResponse,
} from "../SiteWastes/getter";
import useTranslate from "../../translate/useTranslate";

const SiteLimits = ({
  sites,
  currentSiteId,
}: {
  sites: site[];
  currentSiteId: number | null;
}) => {
  const { t } = useTranslate();
  const selectedRowId = `grouped__siteSelectedId`;
  const [groupedWastes, setGroupedWastes] = useState<groupSiteWasteResponse>();
  const [siteOptions, setSiteOptions] = useState<userSite[]>([]);
  const [selectedSite, setSelectedSite] = useState<number | null>(
    currentSiteId
  );
  const [editingObj, setEditingObj] = useState<editDetails>();
  const [deleteObj, setDeleteObj] = useState<{
    siteId: number;
    wasteId: number;
  }>();

  const [dialogVisibility, setDialogVisibility] = useState(false);

  useEffect(() => {
    if (sites) {
      const options: userSite[] = [];
      sites.forEach((site) => {
        options.push({ value: site.id, label: site.name });
      });
      setSiteOptions(options);
    }
  }, [sites]);

  const groupWastesRes = useAsync(
    {
      asyncFunc: getGroupedSiteWastes,
      funcParams: { id: selectedSite },
      immediate: true,
    },
    [selectedSite]
  );

  useEffect(() => {
    const groupedWastes = groupWastesRes.data;

    if (groupedWastes) {
      setGroupedWastes({ ...groupedWastes });
    } else {
      setGroupedWastes(groupedWastes);
    }
  }, [groupWastesRes.data]);

  const mountedRef = useRef(true);
  useEffect(() => {
    const outsideActiveRowClick = (e: MouseEvent) => {
      const target = e.target as Node;
      const element = document.getElementById(selectedRowId);
      if (element && !element.contains(target)) {
        setEditingObj(undefined);
      }
    };

    document.addEventListener("click", outsideActiveRowClick);
    return () => {
      document.addEventListener("click", outsideActiveRowClick);
      mountedRef.current = false;
    };
  }, []);

  const saveGroupWaste = () => {
    const obj = editingObj;
    if (obj) {
      setEditingObj(undefined);
      editGroupedSiteWastes(obj)
        .then((res) => {
          if (mountedRef.current && res) {
            setGroupedWastes({ ...res });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteSiteWaste = () => {
    const obj = deleteObj;
    if (obj) {
      setDeleteObj(undefined);
      deleteGroupedSiteWastes(obj)
        .then((res) => {
          if (mountedRef.current && res) {
            setGroupedWastes({ ...res });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };

  const siteValue = (id: number | null): option => {
    if (id) {
      const option = siteOptions.find((opt) => opt.value === id);
      return option || defaultOption;
    } else {
      return defaultOption;
    }
  };

  const dist = [1, 3, 1, 1, 1, 1, `6rem`];

  const limitString = (value: number | null | undefined): string => {
    const isNull = value === null || value === undefined;

    if (isNull) {
      return ``;
    } else {
      return `${value}`;
    }
  };

  type numberNull = number | null;
  const isNumber = (value: number | string | null) => {
    value = parseFloat(`${value}`);

    return !isNaN(value) && typeof value === "number";
  };

  const accumCriticError = (
    accumValue: numberNull,
    criticalValue: numberNull
  ) => {
    let allowed = true;
    const canCompare = isNumber(accumValue) && isNumber(criticalValue);

    if (canCompare) {
      allowed = accumValue! >= criticalValue!;
    }

    return !allowed;
  };

  const accumFreeError = (accumValue: numberNull, freeLimit: numberNull) => {
    let allowed = true;
    const canCompareLimit = isNumber(accumValue) && isNumber(freeLimit);

    if (canCompareLimit) {
      allowed = allowed && accumValue! >= freeLimit!;
    }

    return !allowed;
  };

  const accumError = (
    accumValue: numberNull,
    criticalValue: numberNull,
    freeLimit: numberNull
  ) => {
    return (
      accumCriticError(accumValue, criticalValue) ||
      accumFreeError(accumValue, freeLimit)
    );
  };

  const thresholdAccumError = (
    thresholdValue: numberNull,
    accumValue: numberNull
  ) => {
    let allowed = true;
    const canCompare = isNumber(thresholdValue) && isNumber(accumValue);

    if (canCompare) {
      allowed = thresholdValue! <= accumValue!;
    }

    return !allowed;
  };

  const thresholdCriticError = (
    thresholdValue: numberNull,
    criticalValue: numberNull
  ) => {
    let allowed = true;
    const canCompare = isNumber(thresholdValue) && isNumber(criticalValue);

    if (canCompare) {
      allowed = thresholdValue! <= criticalValue!;
    }

    return !allowed;
  };

  const thresholdError = (
    thresholdValue: numberNull,
    criticalValue: numberNull,
    accumValue: numberNull
  ) => {
    return (
      thresholdAccumError(thresholdValue, accumValue) ||
      thresholdCriticError(thresholdValue, criticalValue)
    );
  };

  const criticalError = (criticalValue: numberNull, accumValue: numberNull) => {
    let allowed = true;
    const canCompare = isNumber(criticalValue) && isNumber(accumValue);

    if (canCompare) {
      allowed = criticalValue! <= accumValue!;
    }

    return !allowed;
  };

  const isEditing = (
    wasteId: number,
    groupId: number | null,
    siteId: number | null
  ) => {
    return !!(
      editingObj &&
      editingObj.groupId === groupId &&
      editingObj.siteId === siteId &&
      editingObj.wasteId === wasteId
    );
  };

  const pageConstruct = (
    wastesObj: groupedSiteWastes
  ): (pageConstructType & { id: number })[] => {
    const siteWastes = wastesObj.siteWastes;
    const siteId = selectedSite!;
    const groupId = wastesObj.groupId;

    return siteWastes.map((waste, n) => {
      const id = waste.wasteId;
      const freeLimit = waste.freeLimit;
      const editing = isEditing(id, groupId, siteId);

      const accumString = limitString(waste.accumulationLimit)
        ? `${limitString(waste.accumulationLimit)}`
        : `-`;
      const thresholdString = limitString(waste.threshold)
        ? `${t("From")} ${limitString(waste.threshold)}`
        : `-`;
      const criticalString = limitString(waste.criticalLimit)
        ? `${t("From")} ${limitString(waste.criticalLimit)}`
        : `-`;

      const aEditingLimit = editing
        ? editingObj?.info.accumulationLimit || null
        : null;
      const tEditingLimit = editing ? editingObj?.info.threshold || null : null;
      const cEditingLimit = editing
        ? editingObj?.info.criticalLimit || null
        : null;

      const accumValue = limitString(aEditingLimit);
      const thresholdValue = limitString(tEditingLimit);
      const criticalValue = limitString(cEditingLimit);

      const aError = accumError(aEditingLimit, cEditingLimit, freeLimit);
      const tError = thresholdError(
        tEditingLimit,
        cEditingLimit,
        aEditingLimit
      );
      const cError = criticalError(cEditingLimit, aEditingLimit);

      return {
        id,
        sizeDist: dist,
        typeDist: [
          textBox(waste.code, false),
          textBox(waste.waste, false),
          textBox(`kg`, false),

          editing
            ? numberField(
                t("Accumulation limit"),
                aError,
                () => {},
                accumValue,
                (value) => {
                  setEditingObj({
                    ...editingObj!,
                    info: {
                      ...editingObj!.info,
                      accumulationLimit: isNumber(value)
                        ? (value as unknown as number)
                        : null,
                    },
                  });
                }
              )
            : textBox(accumString, false),

          editing
            ? numberField(
                t("Warning limit"),
                tError,
                () => {},
                thresholdValue,
                (value) => {
                  setEditingObj({
                    ...editingObj!,
                    info: {
                      ...editingObj!.info,
                      threshold: isNumber(value)
                        ? (value as unknown as number)
                        : null,
                    },
                  });
                }
              )
            : textBox(thresholdString, false),

          editing
            ? numberField(
                t("Critical Limit"),
                cError,
                () => {},
                criticalValue,
                (value) => {
                  setEditingObj({
                    ...editingObj!,
                    info: {
                      ...editingObj!.info,
                      criticalLimit: isNumber(value)
                        ? (value as unknown as number)
                        : null,
                    },
                  });
                }
              )
            : textBox(criticalString, false),

          childField(
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              key={n}
              className="flex1 jus_flex_end"
            >
              <button
                className={`btn btn-green ${
                  editing ? "showSiteEdit" : "hideSiteEdit"
                }`}
                disabled={aError || tError || cError}
                onClick={() => {
                  if (aError || tError || cError) {
                    return;
                  } else {
                    saveGroupWaste();
                  }
                }}
              >
                {t("Save")}
              </button>
              <div
                className={`flex1 jus_flex_end ${
                  !editing ? "showSiteEdit" : "hideSiteEdit"
                }`}
              >
                <IconContainer size="lg">
                  <EditLogo
                    onClick={(e) => {
                      setEditingObj({
                        groupId,
                        siteId,
                        wasteId: id,
                        info: {
                          accumulationLimit: waste.accumulationLimit,
                          criticalLimit: waste.criticalLimit,
                          threshold: waste.threshold,
                        },
                      });
                    }}
                  />
                </IconContainer>
                <IconContainer size="lg">
                  <DeleteLogo
                    onClick={() => {
                      setDeleteObj({
                        siteId,
                        wasteId: id,
                      });
                      setDialogVisibility(true);
                    }}
                  />
                </IconContainer>
              </div>
            </div>
          ),
          childField(
            editing ? (
              aError || tError || cError ? (
                <div
                  key={`error_msg_${n}`}
                  className="site_limit_error_wrapper"
                >
                  <div className="site_limit_error_container">
                    {aError ? (
                      <>
                        <p>
                          {t("Accumulation limit")}: {freeLimit || <>&infin;</>}
                        </p>
                        {accumFreeError(aEditingLimit, freeLimit) && (
                          <div>
                            {t(
                              "The accumulation limit must be greater than or equal to the amount of waste accepted free of charge at the site / landfill."
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      ``
                    )}
                    {tError || cError ? (
                      <div>
                        {t(
                          "When this limit is reached, a warning message will be displayed during acceptance about the accumulated amount of waste that is approaching the waste accumulation limit at the site / landfill."
                        )}
                      </div>
                    ) : (
                      ``
                    )}
                  </div>
                </div>
              ) : (
                <React.Fragment key={`error_msg_${n}`}></React.Fragment>
              )
            ) : (
              <React.Fragment key={`error_msg_${n}`}></React.Fragment>
            )
          ),
        ],
        hasHeader: false,
        headerText: [],
      };
    });
  };

  const headConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header([t("Code")], false, true),
        Header([t("Wastes")], false, true),
        Header([t("Unit of measurement")], false, true),
        Header([t("Accumulation limit")], false, true),
        Header([t("Warning limit")], false, true),
        Header([t("Critical Limit")], false, true),
      ],
    },
  ];

  const groupedArray = groupedWastes?.siteWastes.groupedWastes || [];

  return (
    <div>
      {dialogVisibility && (
        <NewConfirmDialog
          txt={t("Do you really want to remove this waste from the waste list")}
          isVisible={dialogVisibility}
          onExit={() => {
            setDialogVisibility(false);
            setDeleteObj(undefined);
          }}
          isLoading={false}
          onContinue={() => {
            setDialogVisibility(false);
            deleteSiteWaste();
          }}
        />
      )}
      <div className="siteLimit_drop_wrapper">
        <p>{t("Choose site")}</p>
        <DropDown
          error={false}
          border=""
          onSelect={(option) => {
            setSelectedSite(option.value);
          }}
          options={siteOptions}
          title={t("Search...")}
          value={siteValue(selectedSite)}
        />
      </div>
      <div className="viewExt">
        <GenerateForm classNameWrap="groupWaste_header">
          {headConstruct.map((construct, n) => (
            <FormGenerator
              key={n}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
        </GenerateForm>

        {groupedArray.map((wastesObj, n) => (
          <div key={n}>
            <div className="groupWaste_name_wrapper">
              <p>{wastesObj.groupName}</p>
            </div>
            <GenerateForm>
              {pageConstruct(wastesObj).map((construct, n) => (
                <FormGenerator
                  key={`groupWaste_${n}`}
                  id={
                    isEditing(construct.id, wastesObj.groupId, selectedSite)
                      ? selectedRowId
                      : undefined
                  }
                  gridSizeDist={construct.sizeDist}
                  gridTypeDist={construct.typeDist}
                  hasHeader={construct.hasHeader}
                  headerText={construct.headerText}
                />
              ))}
            </GenerateForm>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SiteLimits;
