export const defaultFormData = {
  id: null,
  userGroupId: null,
  roleId: null,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  position: "",
  uname: "",
  paswd: "",
  isActive: null,
  sites: [],
  createdAt: "",
  userSiteId: null,
  featurePermissions: [],
};

export const defaultFormError = {
  userGroupId: false,
  roleId: false,
  firstName: false,
  lastName: false,
  email: false,
  position: false,
  uname: false,
  paswd: false,
  isActive: false,
};
