import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";
import { getUserSites } from "../actions";

import * as actions from "../actionTypes";
import { store } from "../../store";
import { Fetch, FetchRX } from "../../helpers/misc";

export const getUser = (action$) =>
  action$.pipe(
    ofType(actions.GET_USER),
    mergeMap((action) =>
      FetchRX(`/users/${action.payload}`).pipe(
        map((result) => {
          return {
            type: actions.GET_USER_SUCCEED,
            payload: result.user,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const saveUser = (action$) =>
  action$.pipe(
    ofType(actions.SAVE_USER),
    mergeMap((action) => {
      console.log(action);
      return FetchRX("/users", {
        method: action.payload.type === "edit" ? "PUT" : "POST",
        body: action.payload.user,
      }).pipe(
        map((result) => {
          store.dispatch(getUserSites());
          return {
            type: actions.SAVE_USER_SUCCEED,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      );
    })
  );

export const delUser = (action$) =>
  action$.pipe(
    ofType(actions.DEL_USER),
    mergeMap((action) =>
      FetchRX("/users", {
        method: "DELETE",
        body: JSON.stringify(action.payload),
      }).pipe(
        map((result) => {
          return {
            type: actions.DEL_USER_SUCCEED,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
