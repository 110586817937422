import * as actionTypes from "../actionTypes";

export const getWasteActivityTypes = function () {
  return { type: actionTypes.GET_WASTEACTIVITYTYPES };
};

export const getInactiveWasteActivityTypes = () => ({
  type: actionTypes.GET_INACTIVE_WASTEACTIVITYTYPES,
});

export const deactivateWasteActivityType = (payload) => ({
  type: actionTypes.DEACTIVATE_WASTEACTIVITYTYPE,
  payload,
});

export const restoreWasteActivityType = (payload) => ({
  type: actionTypes.RESTORE_WASTEACTIVITYTYPE,
  payload,
});

export const createWasteActivityType = (payload) => ({
  type: actionTypes.CREATE_WASTEACTIVITYTYPE,
  payload,
});

export const editWasteActivityType = (payload) => ({
  type: actionTypes.EDIT_WASTEACTIVITYTYPE,
  payload,
});

export const hideWasteActivityType = (payload) => ({
  type: actionTypes.HIDE_WASTEACTIVITYTYPE,
  payload,
});
