import React from "react";
import { ReactComponent as IconDrop } from "../../../assets/images/down-arrow.svg";
import { option } from "../../UI/FormGenerator/formTypes";
import "./Sorter.css";

const Sorter = ({
  valueOptions: valueOptions,
  onClick,
  rotate,
}: {
  valueOptions: { active: string; inactive: string; main: string };
  onClick: (title: string) => any;
  rotate?: boolean;
}) => {
  return (
    <div className="sorter_wrapper">
      <div
        onClick={() => onClick(valueOptions.main)}
        className={`sorter_container`}
      >
        <div className={`sorter_btn ${rotate ? "rotate" : ""}`}>
          <div>
            <IconDrop />
          </div>
        </div>
        <div className="flex1 sorter_title">
          {rotate ? valueOptions.inactive : valueOptions.active}
        </div>
      </div>
    </div>
  );
};

export default Sorter;
