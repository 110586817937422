import { Fetch } from "../../helpers/misc";

export type groupedSiteWastes = {
  groupId: number | null;
  groupName: string | null;
  groupFreeLimit: number | null;
  siteWastes: {
    wasteId: number;
    code: string;
    waste: string;
    freeLimit: number | null;
    maxLimit: number | null;
    price: number | null;
    priceVat: number | null;
    accumulationLimit: number | null;
    compInvoice: boolean;
    threshold: number | null;
    criticalLimit: number | null;
    direct_vat_calc: boolean;
  }[];
};

export type groupSiteWasteResponse = {
  siteWastes: {
    siteName: string;
    siteId: number;
    groupedWastes: groupedSiteWastes[];
  };
};

export const getGroupedSiteWastes = async (details: { id: number | null }) => {
  const { id } = details;
  if (!id) {
    return;
  } else {
    return await Fetch(`/siteWastes/grouped/${id}`)
      .then(
        (res) => res.json() as Promise<groupSiteWasteResponse>,
        (error) => {
          throw error;
        }
      )
      .catch((error) => {
        throw error;
      });
  }
};

export const editGroupFreeLimit = async (
  details:
    | { freeLimit: number | null; groupId: number; siteId: number }
    | undefined
) => {
  if (details) {
    const { groupId, siteId } = details;
    if (!groupId || !siteId) {
      return;
    } else {
      return await Fetch(`/siteWastes/groupLimit`, {
        body: JSON.stringify(details),
        method: "PUT",
      })
        .then(
          (res) => res.json() as Promise<groupSiteWasteResponse>,
          (error) => {
            throw error;
          }
        )
        .catch((error) => {
          throw error;
        });
    }
  } else {
    return;
  }
};

export type editDetails = {
  siteId: number;
  groupId: number | null;
  wasteId: number;
  info: {
    freeLimit: number | null;
    maxLimit: number | null;
    price: number | null;
    priceVat: number | null;
  };
};

export type editDetailsInv = {
  siteId: number;
  groupId: number | null;
  wasteId: number;
  info: {
    compInvoice: boolean;
  };
};

export type editDetailsVatType = {
  siteId: number;
  groupId: number | null;
  wasteId: number;
  info: {
    direct_vat_calc: boolean;
  };
};

export const editGroupedSiteWastes = async (
  details: editDetails | editDetailsInv | editDetailsVatType
) => {
  const { siteId, wasteId } = details;
  if (!wasteId || !siteId) {
    return;
  } else {
    return await Fetch(`/siteWastes/grouped`, {
      method: `PUT`,
      body: JSON.stringify(details),
    }).then(
      (res) => res.json() as Promise<groupSiteWasteResponse>,
      (error) => {
        throw error;
      }
    );
  }
};

export const deleteGroupedSiteWastes = async (details: {
  siteId: number;
  wasteId: number;
}) => {
  const { siteId, wasteId } = details;
  if (!wasteId || !siteId) {
    return;
  } else {
    return await Fetch(`/siteWastes`, {
      method: `DELETE`,
      body: JSON.stringify(details),
    }).then(
      (res) => res.json() as Promise<groupSiteWasteResponse>,
      (error) => {
        throw error;
      }
    );
  }
};
