import { connect } from "react-redux";
import AcceptanceCompany from "./AcceptanceCompany";
import * as actions from "../../../stores/actions";

const mapStateToProps = (state: any) => ({
  municipalities: state.municipalityReducer.municipalities,
  billings: state.billingReducer.billings,
  carriers: state.carrierReducer.carriers,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCarriers: function () {
    dispatch(actions.getCarriers());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceCompany);
