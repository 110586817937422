import React, { useEffect, useRef } from "react";
import flatpickr from "flatpickr";
import { Lithuanian } from "flatpickr/dist/l10n/lt.js";
import { Options } from "flatpickr/dist/types/options";

flatpickr.localize(Lithuanian);

const DateTimePicker = ({
  options,
  value,
  placeholder,
  style,
  className,
  clear,
  hasButton,
  onChange,
}: Options & {
  value?: string | Date;
  placeholder?: string;
  style?: React.CSSProperties;
  options: Options;
  clear?: boolean;
  className?: string;
  hasButton?: boolean;
}) => {
  const node = useRef<HTMLInputElement>(null);
  const flatpickrInstance = useRef<flatpickr.Instance | null>(null);

  const createFlatpickrInstance = () => {
    const flatpickrOptions: Options = {
      onClose: () => {
        if (node?.current?.blur) {
          node.current.blur();
        }
      },
      onChange,
      ...options,
    };

    if (node.current) {
      flatpickrInstance.current = flatpickr(node.current, flatpickrOptions);
    }

    if (value && flatpickrInstance.current) {
      flatpickrInstance.current.setDate(value, false);
    }
  };

  useEffect(() => {
    createFlatpickrInstance();
    return () => {
      if (flatpickrInstance.current) {
        flatpickrInstance.current.destroy();
      }
    };
  }, [node.current]);

  useEffect(() => {
    if (clear) {
      flatpickrInstance.current?.clear();
    }
  }, [clear]);

  useEffect(() => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.set("onChange", onChange);
    }
  }, [onChange]);

  useEffect(() => {
    const optionsKeys = Object.getOwnPropertyNames(options);
    optionsKeys.forEach((_key) => {
      const key = _key as keyof Options;
      let value = options[key];

      if (flatpickrInstance.current) {
        flatpickrInstance.current.set(key, value);
      }
    });
  }, [JSON.stringify(options), options.disable]);

  useEffect(() => {
    if (value) {
      if (flatpickrInstance.current) {
        flatpickrInstance.current.setDate(value, false);
      }
    }
  }, [value]);

  const forceOpen = () => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current?.open();
    }
  };

  return (
    <>
      <input
        style={style}
        placeholder={placeholder}
        className={className && className}
        ref={node}
      />
      {hasButton ? (
        <div
          className={`calendar-icon ${className ? className : ""}`}
          onClick={forceOpen}
        ></div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DateTimePicker;
