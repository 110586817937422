import { useCallback, useEffect, useRef, useState } from "react";

/**
 * @param {()=>void} _.func         Timeout function
 * @param {number} _.time    Timeout time
 */
export const useTimeout = (
  props: {
    func: () => void;
    time: number;
    call?: boolean;
  },
  changeEffectors?: unknown[]
) => {
  const {
    func,
    time,
    call: callFunc,
  } = {
    ...props,
  };

  const call = !!callFunc;

  const effectors = changeEffectors || [];
  const [timeoutId, setTimeoutId] = useState<number>();

  const [called, setCalled] = useState(false);
  const mountedRef = useRef(true);

  const execute = useCallback(
    (time: number) => {
      setCalled(true);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const copyTimeout = window.setTimeout(() => {
        if (mountedRef.current) {
          func();
        }
      }, time);
      setTimeoutId(copyTimeout);
    },
    [func, time]
  );

  useEffect(() => {
    mountedRef.current = true;

    if (call) {
      execute(time);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [...effectors, call]);

  return {
    execute,
    called,
    clear: () => {
      clearTimeout(timeoutId);
    },
  };
};
