import React from "react";

import { DateTimePicker as BaseDateTimePicker } from "../../../base";
import "./style.scss";
import { Options } from "flatpickr/dist/types/options";

type Props = Options & {
  value?: string | Date;
  placeholder?: string;
  style?: React.CSSProperties;
  options: Options;
  clear?: boolean;
  className?: string;
  hasButton?: boolean;
};

const DateTimePicker = (props: Props) => {
  return (
    <div className="datetimepicker-wrapper" style={props.style}>
      <BaseDateTimePicker {...props} hasButton />
    </div>
  );
};

export default DateTimePicker;
