import { userFormData } from "../../../../../@types/user";

export const defaultFormData: Partial<userFormData> = {
  userGroupId: undefined,
  roleId: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  position: undefined,
  uname: undefined,
  paswd: undefined,
  isActive: true,
  sites: [],
  userSiteId: undefined,
  featurePermissions: [],
};

export const defaultFormError = {
  userGroupId: false,
  roleId: false,
  firstName: false,
  lastName: false,
  email: false,
  position: false,
  uname: false,
  paswd: false,
  isActive: false,
};
