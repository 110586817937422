import { ofType } from "redux-observable";
import { map, mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../../store";
import {
  getSites as get_sites,
  getInactiveSites as get_inactive_sites,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getSites = (action$) =>
  action$.pipe(
    ofType(actions.GET_SITES),
    mergeMap(() =>
      FetchRX("/sites").pipe(
        map((result) => {
          return {
            type: actions.SET_SITES,
            payload: result.sites,
          };
        }),
        catchError((error) => {
          return of({ type: actions.GET_SITES_FAILED });
        })
      )
    )
  );

export const getUserSites = (action$) => {
  console.log("fetching user site");
  return action$.pipe(
    ofType(actions.GET_USER_SITES),
    mergeMap((action) =>
      FetchRX(
        `/sites/user_sites?${
          action.payload || "" //user id in query format is the payload
        }`
      ).pipe(
        map((result) => {
          return {
            type: actions.SET_USER_SITES,
            payload: result.sites,
          };
        }),
        catchError((error) => {
          return of({ type: actions.GET_USER_SITES_FAILED });
        })
      )
    )
  );
};

export const getInactiveSites = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_SITES),
    mergeMap((action) =>
      FetchRX("/sites/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_SITES,
            payload: result.sites,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateSite = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_SITE),
    mergeMap((action) =>
      FetchRX("/sites/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_sites());
          store.dispatch(get_inactive_sites());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideSite = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_SITE),
    mergeMap((action) =>
      FetchRX("/sites/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_sites());
          store.dispatch(get_inactive_sites());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreSite = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_SITE),
    mergeMap((action) =>
      FetchRX("/sites/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_sites());
          store.dispatch(get_inactive_sites());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createSite = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_SITE),
    mergeMap((action) =>
      FetchRX("/sites/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_SITES,
            payload: result.sites,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editSite = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_SITE),
    mergeMap((action) =>
      FetchRX("/sites/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_SITES,
            payload: result.sites,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
