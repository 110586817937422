import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const createSuggestion = (action$) => {
  return action$.pipe(
    ofType(actions.CREATE_SUGGESTION),
    mergeMap((action) => {
      const fd = new FormData();
      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        if (Array.isArray(value)) {
          value.forEach((image) => {
            fd.append(key, image);
          });
        } else {
          fd.append(key, value);
        }
      });
      return FetchRX("/suggestions", {
        method: "POST",
        body: fd,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_SUGGESTIONS,
            payload: result,
          };
        })
      );
    }),
    catchError((error) => {
      console.log(error?.error);
      return of({ type: actions.EMPTY });
    })
  );
};

export const getSuggestions = (action$) =>
  action$.pipe(
    ofType(actions.GET_ALL_SUGGESTIONS),
    mergeMap((action) =>
      FetchRX("/suggestions").pipe(
        map((result) => {
          return {
            type: actions.SET_SUGGESTIONS,
            payload: result,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const readSuggestion = (action$) =>
  action$.pipe(
    ofType(actions.READ_SUGGESTION),
    mergeMap((action) =>
      FetchRX("/suggestions/read", {
        method: "PUT",
        body: { id: action.payload },
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_SUGGESTIONS,
            payload: result,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
