import * as actionTypes from "../actionTypes";

export const getBillings = function () {
  return { type: actionTypes.GET_BILLINGS };
};

export const getInactiveBillings = () => ({
  type: actionTypes.GET_INACTIVE_BILLINGS,
});

export const deactivateBilling = (payload) => ({
  type: actionTypes.DEACTIVATE_BILLING,
  payload,
});

export const restoreBilling = (payload) => ({
  type: actionTypes.RESTORE_BILLING,
  payload,
});

export const createBilling = (payload) => ({
  type: actionTypes.CREATE_BILLING,
  payload,
});

export const editBilling = (payload) => ({
  type: actionTypes.EDIT_BILLING,
  payload,
});

export const hideBilling = (payload) => ({
  type: actionTypes.HIDE_BILLING,
  payload,
});
