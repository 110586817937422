import React, { useEffect, useRef, useState } from "react";
import "./DownloadPopUp.css";
import NewPopUpFrame from "../PopupFrame/NewPopUpFrame";
import CheckBox from "../checkBox/checkBox";
import DiscreteRangeDatePicker from "../DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../../../pages/Report/@types";
import ButtonXlsx from "../../../components/elements/ButtonXlsx/ButtonXlsx";
import useDownloadHook, { downloadType } from "../../../helpers/downloadHook";
import moment from "moment";
import {
  convertParamsObjectToString,
  convertToParamsObject,
} from "../../../helpers/misc";

const DownloadPopUp = ({
  isShown,
  handleOpen,
  download,
  type,
  query,
  noRange,
  loading,
  setLoading,
  range,
  setRange,
}: {
  isShown: boolean;
  handleOpen: () => void;
  download: (params: {
    type: downloadType | undefined;
    query: string | undefined;
  }) => Promise<void | null | undefined>;
  type: downloadType | undefined;
  query: string | undefined;
  noRange?: boolean;
  loading: boolean;
  setLoading: (data: boolean) => void;
  range: range;
  setRange: (data: range) => void;
}) => {
  const [rangeSelect, setRangeSelect] = useState(!noRange);

  useEffect(() => {
    setRangeSelect(!Boolean(noRange));
  }, [Boolean(noRange)]);

  const rangeQuery = () => {
    let queryObject = convertToParamsObject(query || "") as Record<
      string,
      string
    >;

    if (range.startDate && range.endDate) {
      queryObject = {
        ...queryObject,
        startDate: range.startDate,
        endDate: range.endDate,
      } as Record<string, string>;
    }

    return convertParamsObjectToString(queryObject);
  };

  return (
    <NewPopUpFrame
      isShown={isShown}
      handleOpen={handleOpen}
      title={""}
      small
      showShadow={false}
    >
      <div
        className={`flex download_type_selector_wrapper ${
          noRange ? "blur" : ""
        }`}
      >
        <CheckBox
          readOnly={noRange}
          isChecked={rangeSelect}
          onChange={() => {
            if (noRange) {
              return;
            }
            setRangeSelect(!rangeSelect);
          }}
        />
        <span>Range download</span>
      </div>
      <div
        className={`download_calendar_wrapper ${rangeSelect ? "" : "no_visi"}`}
      >
        {!noRange ? (
          <DiscreteRangeDatePicker
            key={"userList_range"}
            onChange={(val: range) => {
              setRange({
                endDate: val.endDate
                  ? moment(val.endDate).format("YYYY-MM-DD")
                  : ``,
                startDate: val.startDate
                  ? moment(val.startDate).format("YYYY-MM-DD")
                  : ``,
              });
            }}
            clear={!range.endDate && !range.startDate}
            value={undefined}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="download_button_wrapper">
        <button
          disabled={loading}
          onClick={() => {
            setLoading(true);
            download({
              query: rangeQuery(),
              type,
            });
          }}
          className="flexBtn btn-green"
        >
          Download logs
        </button>
      </div>
    </NewPopUpFrame>
  );
};

const ExcelDownload = ({
  type,
  query,
  noRange,
}: {
  type: downloadType | undefined;
  query?: string;
  noRange?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const defaultRange = {
    endDate: "",
    startDate: "",
  };
  const [range, setRange] = useState<range>(defaultRange);
  const reportNotifRef = useRef<string[]>([]);
  const mountedRef = useRef(true);
  const [reportNotif, setReportNotif] = useState<string[]>([]);

  const reset = () => {
    setLoading(false);
    setRange({ ...defaultRange });
  };

  const errorHandler = (data: string) => {
    const nts = [...reportNotifRef.current];
    reportNotifRef.current = [...nts, data];
    setReportNotif(reportNotifRef.current);

    window.setTimeout(() => {
      if (mountedRef.current) {
        const nts = [...reportNotifRef.current];
        nts.shift();
        reportNotifRef.current = [...nts];
        setReportNotif(reportNotifRef.current);
      }
    }, 5000);
  };

  const { handleOpen, isShown, execute } = useDownloadHook({
    type,
    query,
    reset,
  });

  const exec = async (param: {
    type: downloadType | undefined;
    query: string | undefined;
  }) => {
    await execute({
      ...param,
      reset,
      errorHandler,
    });
  };

  return (
    <>
      {/* <DownloadPopUp
        noRange={noRange}
        loading={loading}
        setLoading={setLoading}
        range={range}
        setRange={setRange}
        download={exec}
        handleOpen={handleOpen}
        isShown={isShown}
        type={type}
        query={query}
      /> */}
      <div className="report_notification_message_wrapper">
        {reportNotif.map((notif, n) => (
          <div key={n} className="report_notification_message_container">
            {notif}
          </div>
        ))}
      </div>
      <ButtonXlsx
        onClick={() => {
          exec({
            query,
            type,
          });
        }}
      />
    </>
  );
};

export default ExcelDownload;
