type mode = "development" | "test" | "stage" | "production";
export const getMode = (): mode => {
  const mode = process.env.REACT_APP_MODE;
  const environment = process.env.NODE_ENV;

  if (environment === "production") {
    switch (mode) {
      case "test": {
        return "test";
      }

      case "stage": {
        return "stage";
      }

      case "production": {
        return "production";
      }

      default: {
        return "test";
      }
    }
  } else {
    return "development";
  }
};

export const recaptchaKey =
  (getMode() === "production"
    ? process.env.REACT_APP_RECAPTCHA_KEY_PROD
    : process.env.REACT_APP_RECAPTCHA_KEY_TEST) || "";
