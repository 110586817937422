import React, { useEffect, useState } from "react";
import moment from "moment";
import "./DiscreteRangePicker.css";
import { Options } from "flatpickr/dist/types/options";

import DateTimePicker from "../DateTimePicker";
import "../RangeDatePicker/style.scss";
import { range } from "../../../pages/Report/@types";
import useTranslate from "../../../translate/useTranslate";

const DiscreteRangeDatePicker = function ({
  value,
  onChange,
  clear,
}: {
  value: range | undefined;
  onChange: (data: range) => void;
  clear: boolean;
}) {
  const { t } = useTranslate();
  const [startDate, setStartDate] = useState<Date | string>("");
  const [endDate, setEndDate] = useState<Date | string>("");

  useEffect(() => {
    onChange({ startDate, endDate });

    const _startDate = startDate && moment(new Date(startDate)).format("x");
    const _endDate = endDate && moment(new Date(endDate)).format("x");

    if (_startDate && _endDate && _startDate > _endDate) {
      setDate(moment().format("YYYY-MM-DD"), { start: false });
      return;
    }

    if (startDate && endDate) {
      onChange({ startDate, endDate });
    }
  }, [startDate, endDate]);

  const disabledFrom = (dt: Date) => {
    const disable = moment(new Date(dt)).unix() > moment().unix();
    if (disable) {
      return true;
    } else {
      if (endDate) {
        // disable if date is older that endDate
        return moment(new Date(dt)).unix() > moment(new Date(endDate)).unix();
      } else {
        return false;
      }
    }
  };

  const setDate = (date: string, start: { start: boolean }) => {
    if (date) {
      date = moment(date).format("YYYY-MM-DD");
    }
    const { start: s } = start;

    if (s) {
      let time = date ? " 00:00:00" : "";
      return setStartDate(`${date}${time}`);
    } else {
      let time = date ? " 23:59:59" : "";
      return setEndDate(`${date}${time}`);
    }
  };

  return (
    <div className="range-datepicker-wrapper">
      <DateTimePicker
        value={value ? value.startDate : ""}
        onChange={(_, dtString) => setDate(dtString, { start: true })}
        placeholder={`${t("From")} ...`}
        clear={clear}
        options={{
          dateFormat: "Y-m-d",
          disable: [disabledFrom],
        }}
      />
      <DateTimePicker
        value={value ? value.endDate : ""}
        onChange={(_, dtString) => setDate(dtString, { start: false })}
        placeholder={`${t("To")} ...`}
        clear={clear}
        options={{
          dateFormat: "Y-m-d",
          disable: [
            (dt: string | number | Date) => {
              return (
                moment(new Date(dt).toDateString()).unix() <
                moment(
                  (startDate ? new Date(startDate) : new Date()).toDateString()
                ).unix()
              );
            },
          ],
        }}
      />
    </div>
  );
};

export default DiscreteRangeDatePicker;
