import { connect } from "react-redux";

import * as actions from "../../stores/actions";
import WasteAccumulation from "./WasteAccumulation";

const mapStoreStateToProps = function (store) {
  return {
    sites: store.siteReducer.sites,
    wastes: store.wasteReducer.wastes,
    wasteAccumulationsSavingStatus: store.wasteAccumulationReducer.savingStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetWasteAccumulationSavingStatus: () => {
    dispatch(actions.resetWasteAccumulationSavingStatus());
  },
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(WasteAccumulation);
