import React, { useEffect, useState, useRef, MouseEventHandler } from "react";
import "./DropDown.css";
import { ReactComponent as IconDrop } from "../../../assets/images/down-arrow.svg";
import { option } from "../../UI/FormGenerator/formTypes";
import { defaultOption } from "../../../helpers/misc";

const DropDown = ({
  options,
  value,
  onSelect,
  border,
  title,
  error,
  reverse,
  clearable,
  readOnly,
}: {
  options: option[];
  value: option;
  onSelect: (details: option) => any;
  border: string;
  title: string;
  error: boolean;
  reverse?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
}) => {
  //Created this new dropdown because the former has some issues
  // This new one can be exported and used should the need arise

  const [text, setText] = useState("");
  const [focused, setFocus] = useState(false);
  const [showDrop, drop] = useState(false);
  const [selected, setSelected] = useState(value || defaultOption);
  const [filtered, setFilteredOptions] = useState(options);

  useEffect(() => {
    // if (value.label && value.value > -1) {
    //   setSelected(value);
    // }
    if (clearable || (value.label && value.value > -1)) {
      setSelected(value);
    }
  }, [value, clearable]);

  const selectedExist = options.find(
    (item) => JSON.stringify(item) === JSON.stringify(selected)
  );
  useEffect(() => {
    if (
      !selectedExist &&
      JSON.stringify(selected) !== JSON.stringify(defaultOption)
    ) {
      setSelected({ ...defaultOption });
    }
  }, [selectedExist]);

  const ref = useRef<HTMLDivElement>(null);
  const removeDrop = () => {
    drop(false);
  };

  const removeDropOnClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      drop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", removeDropOnClick);
    document.addEventListener("scroll", removeDrop);

    return () => {
      document.removeEventListener("mousedown", removeDropOnClick);
      document.removeEventListener("scroll", removeDrop);
    };
  }, []);

  /**
   * When the input is changed, refilter
   */
  const textOnChange = (txt: string) => {
    setText(txt);
    drop(true);

    const _filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(txt.toLowerCase())
    );
    setFilteredOptions([..._filteredOptions]);
  };

  /**
   *
   * @param {
   * } opt This parameter is single filtered option
   *
   * When an option is clicked, it's label and also value (id) are added to separate arrays
   * If the option already exists in the arrays of selected values, they are removed
   *
   * The label and the value (id of the option) are added to different arrays
   */
  const optionOnClick = (opt: { value: number; label: string }) => {
    drop(false);
    setSelected(opt);
    onSelect(opt);
  };

  /**
   *
   * @returns a string which is the selected label
   *
   * This string is to be placed in the input element when the input is out of focus
   *
   */
  const getFilteredOption = () => {
    return selected.label;
  };

  const setMaxHeight = (): { [key: string]: string } => {
    //If boundaries were sent, use boundadry parameters instead ofwindow parameters
    // maxHeight:number, top:number
    const smallAllowed = 10 * 16;
    const maxAllowed = 18 * 16;
    const verticalMargin = 16; // 1rem margin for bottom
    const Bottom = inputRef.current?.getBoundingClientRect().bottom;
    const Top = inputRef.current?.getBoundingClientRect().top;
    const Left = inputRef.current?.getBoundingClientRect().left;
    const Height = inputRef.current?.getBoundingClientRect().height;
    const style: { [key: string]: any } = {};
    const WinHeight = window.innerHeight;
    if (Bottom && Top && Height && Left) {
      const bottomSpace = WinHeight - Bottom;
      const topSpace = WinHeight - bottomSpace - Height;
      if (bottomSpace <= smallAllowed) {
        if (topSpace < bottomSpace) {
          style["maxHeight"] = bottomSpace - verticalMargin;
          return style;
        } else {
          //flip
          style["bottom"] = Height + 2;
          if (topSpace >= maxAllowed + verticalMargin) {
            style["maxHeight"] = maxAllowed;
            return style;
          } else {
            style["maxHeight"] = topSpace - verticalMargin;
            return style;
          }
        }
      } else if (bottomSpace >= maxAllowed + verticalMargin) {
        style["maxHeight"] = maxAllowed;
        return style;
      } else {
        style["maxHeight"] = bottomSpace - verticalMargin;
        return style;
      }
    } else {
      style["maxHeight"] = maxAllowed; //Default to 18rem
      return style;
    }
  };
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div ref={ref} className="dropD">
      <div
        style={{ border: border || "" }}
        className={`dropD_wrapper  ${
          showDrop && setMaxHeight()["bottom"]
            ? "dropFlip"
            : showDrop
            ? "drop"
            : ""
        } ${reverse ? `reverse` : ``}`}
      >
        <div className="flex1">
          <input
            style={readOnly ? { cursor: "default" } : undefined}
            name="dropDown"
            className={error ? "both-error" : ""}
            autoComplete="off"
            readOnly
            ref={inputRef}
            onClick={() => {
              drop(true);
            }}
            onFocus={(e) => {
              if (readOnly) {
                return;
              }
              e.target.removeAttribute(`readOnly`);
              if (!showDrop) {
                setText(``);
                setFilteredOptions([...options]);
              }
              drop(true);
              setFocus(true);
            }}
            onBlur={(e) => {
              e.target.setAttribute(`readOnly`, `true`);
              setFocus(false);
            }}
            value={focused || showDrop ? text : getFilteredOption()}
            onChange={(e) => {
              if (readOnly) {
                return;
              }
              textOnChange(e.target.value);
            }}
            type="text"
            placeholder={`${title}`}
          />
        </div>
        <div
          className={`dropD_btn ${reverse ? `reverse` : ``}`}
          style={
            reverse
              ? { borderRight: border || "" }
              : { borderLeft: border || "" }
          }
          onClick={() => {
            if (!showDrop && !readOnly) {
              inputRef?.current?.focus();
            }
            drop(!showDrop);
          }}
        >
          <div>
            <IconDrop />
          </div>
        </div>
      </div>
      {showDrop && (
        <div
          style={{
            maxHeight: setMaxHeight()["maxHeight"],
            bottom: setMaxHeight()["bottom"] ? setMaxHeight()["bottom"] : ``,
            top: setMaxHeight()["bottom"] ? `` : `calc(var(--height) + 2px)`,
          }}
          onScroll={(e) => e.stopPropagation()}
          className={`dropD_container ${
            setMaxHeight()["bottom"] ? "flip" : ""
          }`}
        >
          {filtered.map((option, n) => (
            <div
              className={`dropD_content ${
                selected.value === option.value &&
                selected.label === option.label
                  ? "picked"
                  : ""
              }`}
              key={`dropD_content_${n}`}
              onClick={(e) => {
                optionOnClick(option);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
