import React from "react";
import "./ButtonXlsx.css";
import { ReactComponent as Xlsx } from "../../../assets/images/xlsx.svg";

const ButtonXlsx = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={!!disabled}
      onClick={onClick}
      type="button"
      className="xlsx_button"
    >
      <Xlsx title="Atsisiųsti" className="img_div_contain" />
    </button>
  );
};

export default ButtonXlsx;
