import { Fetch } from "../../../../../helpers/misc";
import { user } from "./UserList";

export const getUsers = async (params: {
  query: string;
  limit: number;
  offset: number;
  hide: boolean;
}) => {
  const { limit, offset, query, hide } = params;
  if (hide) {
    return;
  }

  return await Fetch(`/users?${query}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      const response = res.json() as Promise<{ users: user[]; count: number }>;
      return response;
    })
    .catch((err) => {
      throw err;
    });
};
