import { connect } from "react-redux";

import UserDetail from "./UserDetail";
import * as actions from "../../../../../stores/actions";

const mapStoreStateToProps = function (store) {
  return {
    user: store.userReducer.user,
    sites: store.siteReducer.sites,
    features: store.featureReducer.features,
    roles: store.roleReducer.roles,
    userGroups: store.userGroupReducer.userGroups,
    isConfirmYes: store.commonReducer.isConfirmYes,
    currentUserId: store.authReducer?.currentUser?.id,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    getUserSites: function () {
      dispatch(actions.getUserSites());
    },
    getUser: function (id) {
      dispatch(actions.getUser(id));
    },
    delUser: function (params) {
      dispatch(actions.delUser(params));
    },
    setConfirmDialog: function (txt) {
      dispatch(actions.setConfirmDialog(txt));
    },
    resetConfirmDialog: function () {
      dispatch(actions.resetConfirmDialog());
    },
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(UserDetail);
