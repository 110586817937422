import { connect } from "react-redux";
import Classifier from "./Classifier";
import * as actions from "../../stores/actions";
import { stateType } from "../../store";

const mapStoreStateToProps = (store: stateType) => ({
  locale: store.commonReducer.locale,
  //Sites
  sites: store.siteReducer.sites,
  inactiveSites: store.siteReducer.inactiveSites,
  //Municipalities
  municipalities: store.municipalityReducer.municipalities,
  inactiveMunicipalities: store.municipalityReducer.inactiveMunicipalities,
  //Origins
  origins: store.originReducer.origins,
  inactiveOrigins: store.originReducer.inactiveOrigins,
  //Roles
  roles: store.roleReducer.roles,
  inactiveRoles: store.roleReducer.inactiveRoles,
  //Carriers
  carriers: store.carrierReducer.carriers,
  inactiveCarriers: store.carrierReducer.inactiveCarriers,
  //Groups
  groups: store.groupReducer.groups,
  inactiveGroups: store.groupReducer.inactiveGroups,
  //Waste Activity Types
  wasteActivityTypes: store.wasteActivityTypeReducer.wasteActivityTypes,
  inactiveWasteActivityTypes:
    store.wasteActivityTypeReducer.inactiveWasteActivityTypes,
  //Formations
  formations: store.formationReducer.formations,
  inactiveFormations: store.formationReducer.inactiveFormations,
  //Materials
  materials: store.materialReducer.materials,
  inactiveMaterials: store.materialReducer.inactiveMaterials,
  //Companies
  companies: store.companyReducer.companies,
});

const mapDispatchToProps = (dispatch: (...arg: any[]) => void) => ({
  //Sites
  get_inactive_sites: () => {
    dispatch(actions.getInactiveSites());
  },
  deactivate_site: (data: { id: number }) => {
    dispatch(actions.deactivateSite(data));
  },
  hide_site: (data: { id: number }) => {
    dispatch(actions.hideSite(data));
  },
  restore_site: (data: { id: number }) => {
    dispatch(actions.restoreSite(data));
  },
  create_site: (data: { name: string }) => {
    dispatch(actions.createSite(data));
  },
  edit_site: (data: {
    name: string;
    id: number;
    address?: string;
    companyCode?: string;
    municipalityId?: number;
  }) => {
    dispatch(actions.editSite(data));
  },
  //Municipalities
  get_inactive_municipalities: () => {
    dispatch(actions.getInactiveMunicipalities());
  },
  deactivate_municipality: (data: { id: number }) => {
    dispatch(actions.deactivateMunicipality(data));
  },
  hide_municipality: (data: { id: number }) => {
    dispatch(actions.hideMunicipality(data));
  },
  restore_municipality: (data: { id: number }) => {
    dispatch(actions.restoreMunicipality(data));
  },
  create_municipality: (data: { name: string }) => {
    dispatch(actions.createMunicipality(data));
  },
  edit_municipality: (data: { name: string; id: number }) => {
    dispatch(actions.editMunicipality(data));
  },
  //Origins
  get_inactive_origins: () => {
    dispatch(actions.getInactiveOrigins());
  },
  deactivate_origin: (data: { id: number }) => {
    dispatch(actions.deactivateOrigin(data));
  },
  hide_origin: (data: { id: number }) => {
    dispatch(actions.hideOrigin(data));
  },
  restore_origin: (data: { id: number }) => {
    dispatch(actions.restoreOrigin(data));
  },
  create_origin: (data: { name: string }) => {
    dispatch(actions.createOrigin(data));
  },
  edit_origin: (data: { name: string; id: number }) => {
    dispatch(actions.editOrigin(data));
  },
  //Roles
  get_inactive_roles: () => {
    dispatch(actions.getInactiveRoles());
  },
  deactivate_role: (data: { id: number }) => {
    dispatch(actions.deactivateRole(data));
  },
  hide_role: (data: { id: number }) => {
    dispatch(actions.hideRole(data));
  },
  restore_role: (data: { id: number }) => {
    dispatch(actions.restoreRole(data));
  },
  create_role: (data: { name: string }) => {
    dispatch(actions.createRole(data));
  },
  edit_role: (data: { name: string; id: number }) => {
    dispatch(actions.editRole(data));
  },
  //Carriers
  get_inactive_carriers: () => {
    dispatch(actions.getInactiveCarriers());
  },
  deactivate_carrier: (data: { id: number }) => {
    dispatch(actions.deactivateCarrier(data));
  },
  hide_carrier: (data: { id: number }) => {
    dispatch(actions.hideCarrier(data));
  },
  restore_carrier: (data: { id: number }) => {
    dispatch(actions.restoreCarrier(data));
  },
  create_carrier: (data: { name: string }) => {
    dispatch(actions.createCarrier(data));
  },
  edit_carrier: (data: { name: string; id: number; companyId?: number }) => {
    dispatch(actions.editCarrier(data));
  },
  //Groups
  get_inactive_groups: () => {
    dispatch(actions.getInactiveGroups());
  },
  deactivate_group: (data: { id: number }) => {
    dispatch(actions.deactivateGroup(data));
  },
  hide_group: (data: { id: number }) => {
    dispatch(actions.hideGroup(data));
  },
  restore_group: (data: { id: number }) => {
    dispatch(actions.restoreGroup(data));
  },
  create_group: (data: { name: string }) => {
    dispatch(actions.createGroup(data));
  },
  edit_group: (data: { name: string; id: number }) => {
    dispatch(actions.editGroup(data));
  },
  //WasteActivityTypes
  get_inactive_wasteActivityTypes: () => {
    dispatch(actions.getInactiveWasteActivityTypes());
  },
  deactivate_wasteActivityType: (data: { id: number }) => {
    dispatch(actions.deactivateWasteActivityType(data));
  },
  hide_wasteActivityType: (data: { id: number }) => {
    dispatch(actions.hideWasteActivityType(data));
  },
  restore_wasteActivityType: (data: { id: number }) => {
    dispatch(actions.restoreWasteActivityType(data));
  },
  create_wasteActivityType: (data: { name: string }) => {
    dispatch(actions.createWasteActivityType(data));
  },
  edit_wasteActivityType: (data: { name: string; id: number }) => {
    dispatch(actions.editWasteActivityType(data));
  },
  //Formations
  get_inactive_formations: () => {
    dispatch(actions.getInactiveFormations());
  },
  deactivate_formation: (data: { id: number }) => {
    dispatch(actions.deactivateFormation(data));
  },
  hide_formation: (data: { id: number }) => {
    dispatch(actions.hideFormation(data));
  },
  restore_formation: (data: { id: number }) => {
    dispatch(actions.restoreFormation(data));
  },
  create_formation: (data: { name: string }) => {
    dispatch(actions.createFormation(data));
  },
  edit_formation: (data: { name: string; id: number }) => {
    dispatch(actions.editFormation(data));
  },
  //Materials
  get_inactive_materials: () => {
    dispatch(actions.getInactiveMaterials());
  },
  deactivate_material: (data: { id: number }) => {
    dispatch(actions.deactivateMaterial(data));
  },
  hide_material: (data: { id: number }) => {
    dispatch(actions.hideMaterial(data));
  },
  restore_material: (data: { id: number }) => {
    dispatch(actions.restoreMaterial(data));
  },
  create_material: (data: { name: string }) => {
    dispatch(actions.createMaterial(data));
  },
  edit_material: (data: { name: string; id: number }) => {
    dispatch(actions.editMaterial(data));
  },
  changeLocale: function (locale: "ENGLISH" | "LITHUANIAN") {
    dispatch(actions.changeLocale(locale));
  },
});

export default connect(mapStoreStateToProps, mapDispatchToProps)(Classifier);
