import { combineEpics } from "redux-observable";

import { saveWasteAccumulation } from "./wasteAccumulation";
import {
  createBilling,
  deactivateBilling,
  editBilling,
  getBillings,
  getInactiveBillings,
  hideBilling,
  restoreBilling,
} from "./billing";
import {
  createCarrier,
  deactivateCarrier,
  editCarrier,
  getCarriers,
  getInactiveCarriers,
  hideCarrier,
  restoreCarrier,
} from "./carrier";
import { getCompanies, saveCompany } from "./company";
import { getNextFormNr } from "./disposal";
import { getDocuments } from "./document";
import {
  getFormations,
  createFormation,
  deactivateFormation,
  editFormation,
  getInactiveFormations,
  hideFormation,
  restoreFormation,
} from "./formation";
import { getFeatures } from "./feature";
import {
  getGroups,
  createGroup,
  deactivateGroup,
  editGroup,
  getInactiveGroups,
  hideGroup,
  restoreGroup,
} from "./group";
import {
  getMaterials,
  createMaterial,
  deactivateMaterial,
  editMaterial,
  getInactiveMaterials,
  hideMaterial,
  restoreMaterial,
} from "./material";
import {
  getMunicipalities,
  getInactiveMunicipalities,
  deactivateMunicipality,
  restoreMunicipality,
  createMunicipality,
  editMunicipality,
  hideMunicipality,
} from "./municipality";
import {
  getOrigins,
  getInactiveOrigins,
  deactivateOrigin,
  restoreOrigin,
  createOrigin,
  editOrigin,
  hideOrigin,
} from "./origin";
import { savePerson } from "./person";
import {
  getRoles,
  createRole,
  deactivateRole,
  editRole,
  getInactiveRoles,
  hideRole,
  restoreRole,
} from "./role";
import { getAllReports } from "./report";
import {
  createSerija,
  getAllSerijas,
  makeSerijaActive,
  deleteSerija,
} from "./serija";
import {
  getSites,
  getUserSites,
  getInactiveSites,
  deactivateSite,
  restoreSite,
  createSite,
  editSite,
  hideSite,
} from "./site";
import { createSuggestion, getSuggestions, readSuggestion } from "./suggestion";
import { getSiteWastes } from "./siteWaste";
import { getUser, saveUser, delUser } from "./user";
import {
  getUserGroups,
  getUserGroup,
  saveUserGroup,
  delUserGroup,
} from "./userGroup";
import { getWastes, deleteWaste } from "./waste";
import { getPrimarySources } from "./primarySource";
import { saveWasteActivity } from "./wasteActivity";
import {
  createWasteActivityType,
  deactivateWasteActivityType,
  editWasteActivityType,
  getInactiveWasteActivityTypes,
  getWasteActivityTypes,
  hideWasteActivityType,
  restoreWasteActivityType,
} from "./wasteActivityType";

const rootEpic = combineEpics(
  saveWasteAccumulation,
  createBilling,
  deactivateBilling,
  editBilling,
  getBillings,
  getInactiveBillings,
  hideBilling,
  restoreBilling,
  getCompanies,
  saveCompany,
  createCarrier,
  deactivateCarrier,
  editCarrier,
  getCarriers,
  getInactiveCarriers,
  hideCarrier,
  restoreCarrier,
  getNextFormNr,
  getDocuments,
  getFormations,
  createFormation,
  deactivateFormation,
  editFormation,
  getInactiveFormations,
  hideFormation,
  restoreFormation,
  getFeatures,
  getGroups,
  createGroup,
  deactivateGroup,
  editGroup,
  getInactiveGroups,
  hideGroup,
  restoreGroup,
  getMaterials,
  createMaterial,
  deactivateMaterial,
  editMaterial,
  getInactiveMaterials,
  hideMaterial,
  restoreMaterial,
  getMunicipalities,
  getInactiveMunicipalities,
  deactivateMunicipality,
  restoreMunicipality,
  createMunicipality,
  editMunicipality,
  hideMunicipality,
  getOrigins,
  getInactiveOrigins,
  deactivateOrigin,
  restoreOrigin,
  createOrigin,
  editOrigin,
  hideOrigin,
  savePerson,
  getRoles,
  createRole,
  deactivateRole,
  editRole,
  getInactiveRoles,
  hideRole,
  restoreRole,
  getAllReports,
  createSerija,
  getAllSerijas,
  makeSerijaActive,
  deleteSerija,
  getSites,
  hideSite,
  getUserSites,
  getInactiveSites,
  deactivateSite,
  restoreSite,
  createSite,
  editSite,
  createSuggestion,
  getSuggestions,
  readSuggestion,
  getSiteWastes,
  getUser,
  saveUser,
  delUser,
  getUserGroups,
  getUserGroup,
  saveUserGroup,
  delUserGroup,
  getWastes,
  deleteWaste,
  getPrimarySources,
  saveWasteActivity,
  createWasteActivityType,
  deactivateWasteActivityType,
  editWasteActivityType,
  getInactiveWasteActivityTypes,
  getWasteActivityTypes,
  hideWasteActivityType,
  restoreWasteActivityType
);

export default rootEpic;
