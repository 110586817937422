import React, { useEffect, useRef, useState } from "react";
import "./LocaleBalloon.css";
import { ReactComponent as LocaleIcon } from "../../assets/images/locale.svg";
import { locale } from "../../translate/translate";
import { connect } from "react-redux";
import { stateType } from "../../store";
import { dispatch } from "d3";
import { changeLocale } from "../../stores/actions";
import { getMode } from "../../settings";

const LocaleBalloon = ({
  changeLocale,
  locale,
}: {
  changeLocale: (data: locale) => void;
  locale: locale;
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !(
        ref.current === event.target ||
        ref.current.contains(event.target as Node)
      )
    ) {
      event.stopPropagation();
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  const mode = getMode();
  return mode === "production" ? (
    <></>
  ) : (
    <div ref={ref} className="locale_balloon_wrapper center">
      {open && (
        <div className="change_locale_btn_wrapper floating_locale_bar">
          <div
            onClick={() => {
              changeLocale("ENGLISH");
            }}
            className={`change_locale_btn ${
              locale === "ENGLISH" ? "active" : "inactive"
            }`}
          >
            en
          </div>
          <div
            onClick={() => {
              changeLocale("LITHUANIAN");
            }}
            className={`change_locale_btn ${
              locale === "ENGLISH" ? "inactive" : "active"
            }`}
          >
            lt
          </div>
        </div>
      )}
      <div className="locale_balloon_container">
        <div
          onClick={() => {
            setOpen(!open);
          }}
          className="locale_balloon_icon_wrapper center"
        >
          <LocaleIcon />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: stateType) => ({
  locale: state.commonReducer.locale,
});
const mapDispatchToProps = (dispatch: (...args: any[]) => void) => ({
  changeLocale: function (locale: locale) {
    dispatch(changeLocale(locale));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LocaleBalloon);
