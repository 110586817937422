import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./WastesComingIn.css";
import { Fetch, makeLabelInt } from "../../../helpers/misc";
import ExtraBar from "../../UI/ExtraBar/ExtraBar";
import NewPopUpFrame from "../../UI/PopupFrame/NewPopUpFrame";
import DropDown from "../../elements/DropDown/DropDown";
import { cancellationReasonOptions } from "../../../pages/DisposalJournal/defaultData";
import { option } from "../../UI/FormGenerator/formTypes";
import {
  currentUserType,
  disposalType,
} from "../../../pages/WasteAcceptance/@types";
import { useAsync } from "../../../helpers/asyncFunc";
import {
  updateStatusParamsType,
  updateStatus,
} from "../../../pages/DisposalJournal/getSet";
import useTranslate from "../../../translate/useTranslate";

export const getWastesList = async (params: {
  currentSiteId: number | null;
  limit?: number;
}) => {
  const limit = params.limit || 5;

  if (!params.currentSiteId) return;

  return await Fetch(
    `/disposals/all?recipientId=${params.currentSiteId}&accepted=false&transferType=INNER&limit=${limit}`
  ).then(
    (res) => {
      const result: Promise<{
        disposals: disposalType[];
        count: number;
      }> = res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};

const WastesComingIn = ({
  currentSiteId,
  disposalArrived,
  currentUser,
  arrivedDisposalId,
  resetDisposalArrived,
  updatedAt,
}: {
  currentSiteId: number;
  disposalArrived: (disposal: disposalType) => void;
  currentUser: currentUserType;
  arrivedDisposalId: number;
  resetDisposalArrived: () => void;
  updatedAt: number;
}) => {
  const { t } = useTranslate();
  const [preparedDisposals, setPreparedDisposals] = useState<disposalType[]>(
    []
  );
  const [wasteCount, setWasteCount] = useState(0);
  const [cancellationPopUpVisibility, setCancellationPopUpVisibility] =
    useState(false);
  const [currentDisposalId, setCurrentDisposalId] = useState(0);
  const [cancellationReason, setCancellationReason] = useState<option>({
    label: "",
    value: -1,
  });
  const [
    cancellationReasonAdditionalInfo,
    setCancellationReasonAdditionalInfo,
  ] = useState("");
  const [additionalInfoFieldVisible, setAdditionalInfoFieldVisible] =
    useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateStatusRes = useAsync(
    {
      asyncFunc: updateStatus,
      funcParams: {} as unknown as updateStatusParamsType,
      immediate: false,
    },
    []
  );

  const getWasteList = async (limit: number) => {
    if (!limit) return;
    getWastesList({
      currentSiteId: currentSiteId,
      limit: limit,
    })
      .then((result) => {
        if (mountedRef.current) {
          setPreparedDisposals(result?.disposals || []);
          setWasteCount(result?.count || 0);
        }
      })
      .catch((err) => {
        console.log(err);
        if (mountedRef.current) {
          setPreparedDisposals([]);
          setWasteCount(0);
        }
      });
  };

  useEffect(() => {
    getWasteList(preparedDisposals.length);
  }, [updateStatusRes.data]);

  const maxCount = 2;

  useEffect(() => {
    setPreparedDisposals([]);
    setWasteCount(0);
    getWasteList(maxCount);
  }, [currentSiteId, updatedAt]);

  return preparedDisposals.length === 0 ? (
    <div />
  ) : (
    <div>
      {cancellationPopUpVisibility && (
        <NewPopUpFrame
          small={true}
          showShadow={false}
          handleOpen={setCancellationPopUpVisibility}
          isShown={cancellationPopUpVisibility}
          title={t("The reason for the cancellation")}
        >
          <div className="cancellation_popUp">
            <DropDown
              value={cancellationReason}
              options={cancellationReasonOptions}
              onSelect={(selected) => {
                setCancellationReason(selected);

                selected.value === 6
                  ? setAdditionalInfoFieldVisible(true)
                  : setAdditionalInfoFieldVisible(false);
              }}
              title=""
              border=""
              error={false}
            />
            {additionalInfoFieldVisible && (
              <textarea
                placeholder={t("Record the reason for the cancellation")}
                value={cancellationReasonAdditionalInfo}
                onChange={(e) => {
                  setCancellationReasonAdditionalInfo(e.target.value);
                }}
                name=""
                id=""
              ></textarea>
            )}
            <div className="cancellationPopUpButton_wrapper">
              <button
                onClick={() => setCancellationPopUpVisibility(false)}
                className="btn btn-default"
              >
                {t("Cancel")}
              </button>
              <button
                disabled={
                  !cancellationReason ||
                  (cancellationReason.value === 6 &&
                    !cancellationReasonAdditionalInfo)
                }
                onClick={async () => {
                  updateStatusRes.execute({
                    additionalInfo: cancellationReasonAdditionalInfo,
                    id: currentDisposalId,
                    reason: cancellationReason.value,
                    reasonText:
                      cancellationReason.value === 6
                        ? cancellationReasonAdditionalInfo
                        : cancellationReason.label,
                    status: "CANCELED",
                    userId: currentUser.id,
                  });
                  setCancellationPopUpVisibility(false);
                }}
                className="btn btn-green"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </NewPopUpFrame>
      )}
      <div className="wasteacceptance_title_header">{t("Transmission")}</div>

      <div className="form_management_wrapper">
        <div className="form_management_container">
          <div className="fgHead limitia_box_tab waste_coming_tab">
            <div>{t("Departure time")}</div>
            <div>{t("Order no.")}</div>
            <div>{t("T. p. number")}</div>
            <div>{t("Waste sender")}</div>
            <div>{t("Waste carrier")}</div>
            <div>&nbsp;</div>
          </div>
          <div className="limit_box_wrapper">
            {preparedDisposals &&
              preparedDisposals.map((record: disposalType, n) => (
                <div key={n} className="fgRow limitia_box_tab waste_coming_tab">
                  <div className="limitia_box">
                    {moment(record.createdAt).format("YYYY-MM-DD HH:mm")}
                  </div>
                  <div className="limitia_box">{record.formNr}</div>
                  <div className="limitia_box">{record.licensePlate}</div>
                  <div className="limitia_box">{record.disposalSiteName}</div>
                  <div className="limitia_box">{record.carrierName}</div>
                  <div className="limitia_box">
                    {arrivedDisposalId && arrivedDisposalId === record.id ? (
                      <button
                        className="btn btn-orange"
                        onClick={() => resetDisposalArrived()}
                      >
                        {t("Reverse Delivery")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-green"
                        onClick={() => disposalArrived(record)}
                      >
                        {t("Arrived")}
                      </button>
                    )}
                    <button
                      className="btn btn-cancel"
                      onClick={() => {
                        setCurrentDisposalId(record.id);
                        setCancellationReason({ label: "", value: -1 });
                        setCancellationReasonAdditionalInfo("");
                        setAdditionalInfoFieldVisible(false);
                        setCancellationPopUpVisibility(true);
                      }}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ExtraBar
        currentAvailableCount={preparedDisposals.length}
        expandFunc={() => {
          getWasteList(preparedDisposals.length + maxCount);
        }}
        totalCount={wasteCount}
      />
    </div>
  );
};

export default WastesComingIn;
