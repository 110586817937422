import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../../store";
import {
  getOrigins as get_origins,
  getInactiveOrigins as get_inactive_origins,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getOrigins = (action$) =>
  action$.pipe(
    ofType(actions.GET_ORIGINS),
    mergeMap(() =>
      FetchRX("/origins").pipe(
        map((result) => {
          return {
            type: actions.SET_ORIGINS,
            payload: result.origins,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.GET_ORIGINS_FAILED });
        })
      )
    )
  );

export const getInactiveOrigins = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_ORIGINS),
    mergeMap((action) =>
      FetchRX("/origins/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_ORIGINS,
            payload: result.origins,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateOrigin = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_ORIGIN),
    mergeMap((action) =>
      FetchRX("/origins/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_origins());
          store.dispatch(get_inactive_origins());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideOrigin = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_ORIGIN),
    mergeMap((action) =>
      FetchRX("/origins/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_origins());
          store.dispatch(get_inactive_origins());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreOrigin = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_ORIGIN),
    mergeMap((action) =>
      FetchRX("/origins/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_origins());
          store.dispatch(get_inactive_origins());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createOrigin = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_ORIGIN),
    mergeMap((action) =>
      FetchRX("/origins/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_ORIGINS,
            payload: result.origins,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editOrigin = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_ORIGIN),
    mergeMap((action) =>
      FetchRX("/origins/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_ORIGINS,
            payload: result.origins,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
