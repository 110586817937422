import { json_key, translate_options } from "./translate/translate";

/**
 *
 * @param {(originalText: json_key , options?: translate_options) => string } t
 * @returns
 */
const pathArray = (t) => [
  { path: "/waste-journal", title: t("Log") },
  { path: "/waste-management", title: t("MANAGEMENT") },
  { path: "/waste-disposal", title: t("Organized Waste") },
  { path: "/waste-accumulation", title: t("Formation") },
  { path: "/disposal", title: t("Transmission") },
  { path: "/disposal-journal", title: t("Transfer Order Log") },
];
export default pathArray;
