import React, { useEffect, useState } from "react";
import DropDown from "../../components/elements/DropDown/DropDown";
import {
  checkValue,
  Input,
  sizeToStyle,
} from "../../components/UI/FormGenerator/FormGenerator";
import {
  DropDownFieldType,
  gridType,
  MultiDropDownFieldType,
  option,
} from "../../components/UI/FormGenerator/formTypes";
import NewMultiDropDown from "../../components/UI/NewMultiDropDown/NewMultiDropDown";
import { CloseLogo } from "../../components/UI/iconContainer/iconContainer";
import "./Report.css";

export const ReportBox = ({
  header,
  headerDist,
  sizeDist,
  rowDist,
  parentState,
  setter,
  index,
}: {
  header: string;
  headerDist: string[][];
  sizeDist: (number | string)[];
  rowDist: gridType[][];
  parentState: { [key: string]: any };
  setter: (...args: any[]) => void;
  index: number;
}) => {
  const getTiles = (): (option & { key: string })[] => {
    const tileResult: (option & { key: string })[] = [];
    Object.keys(parentState).forEach((key) => {
      const value = parentState[key];
      if (Array.isArray(value)) {
        const isObj =
          !!value.length && !value.some((v) => typeof v !== `object`);
        if (isObj) {
          value.map((v) => {
            if (
              v.label &&
              typeof v.label === `string` &&
              (typeof v.value === `number` || typeof v.value === `string`)
            ) {
              tileResult.push({ ...v, key: key });
            }
          });
        }
      }
    });
    return tileResult;
  };

  type removeFromList = (
    item: option & { key: string },
    list: { [key: string]: any },
    setter: (data: { [key: string]: any }) => void
  ) => void;
  const removeFromList: removeFromList = (item, list, setter) => {
    const newArray: option[] = [];
    (list[item.key] as option[]).forEach((l) => {
      if (l.value !== item.value) {
        newArray.push(l);
      }
    });
    setter({ ...list, [item.key]: newArray });
  };

  return (
    <div className="reportBox_wrapper">
      <div className="report_option_wrapper">
        {getTiles().map((option, n) => (
          <div
            key={`report_filter_${index}_${n}`}
            className="report_btn_filter_wrapper"
          >
            <button
              className="report_btn_filter"
              onClick={() => removeFromList(option, parentState, setter)}
            >
              <span>{option.label}</span>
              <CloseLogo
                onClick={() => removeFromList(option, parentState, setter)}
              />
            </button>
          </div>
        ))}
      </div>

      <div className="form_management_wrapper">
        <div className="form_management_container">
          <div className="fgHead">
            <div>{header}</div> {/** Header */}
          </div>
          <div>
            {rowDist.map((typeDist, n) => (
              <div key={`report_row_${index}_${n}`} className="fgRow">
                <div
                  style={{
                    gridTemplateColumns: sizeToStyle(sizeDist),
                  }}
                  className="report_box_tab"
                >
                  {headerDist.length > n &&
                    headerDist[n].map((headerLabel, ind) => (
                      <div
                        key={`report_label_${index}_${n}_${ind}`}
                        className="report_label"
                      >
                        {headerLabel}
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    gridTemplateColumns: sizeToStyle(sizeDist),
                  }}
                  className="report_box_tab"
                >
                  {typeDist.map((gType, n) =>
                    gType.fieldType === "field" ? (
                      <div
                        key={`gridRow_field_${n}`}
                        className="fgFieldInputWrap report_box"
                      >
                        {(gType as DropDownFieldType).dropDown &&
                        !(gType as DropDownFieldType).dropDownTypeMultiple ? (
                          <DropDown
                            error={gType.error!}
                            clearable={!!gType.clearable}
                            value={
                              checkValue((gType as DropDownFieldType).value)
                                ? (gType as DropDownFieldType).value
                                : { label: "", value: 0 }
                            }
                            border={gType.error ? "1px solid #FD4B0D" : ""}
                            options={gType.options || []}
                            onSelect={(detail: option) =>
                              gType.fieldHandler(detail.value)
                            } //sends out a number
                            title={gType.placeholder || ""}
                          />
                        ) : (gType as MultiDropDownFieldType)
                            .dropDownTypeMultiple ? (
                          <NewMultiDropDown
                            options={gType.options || []}
                            selectedOptions={
                              (gType as MultiDropDownFieldType).value
                            }
                            onSelect={(detail: option[]) =>
                              gType.fieldHandler(detail)
                            }
                            border={""}
                            placeholder={gType.placeholder || ""}
                          />
                        ) : (
                          <Input
                            value={
                              typeof gType.value === "string" ? gType.value : ""
                            }
                            placeholder={gType.placeholder!}
                            handler={gType.fieldHandler}
                            error={gType.error!}
                            type={gType.type!}
                            externalValue={
                              typeof gType.controlledText === `string`
                                ? gType.controlledText
                                : undefined
                            }
                            setExternalValue={
                              gType.controlledFunction || undefined
                            }
                          />
                        )}
                      </div>
                    ) : gType.fieldType === "text" ? (
                      <div
                        key={`gridRow_text_${n}`}
                        className={`fgFieldTextWrap ${
                          gType.center ? "center" : ""
                        }`}
                      >
                        {gType.text || ""}
                      </div> //More to be added here
                    ) : (
                      gType.fieldType === "child" && gType.child
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
