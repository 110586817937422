import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";

import { alignment, defaultDataType } from "../../services/DataExportService";
import { DataExportService } from "../../services";
import { ReactComponent as ExpandIcon } from "../../assets/images/down-arrow.svg";

import "./style.scss";
import "./DisposalJournal.css";
import { formatNumber } from "../../helpers";
import FractionList from "../../components/UI/fractionList/FractionList";
import {
  appendSort,
  enabled,
  getDropDownValue,
  makeLabelInt,
  pageSettingOptions,
} from "../../helpers/misc";
import Sorter from "../../components/elements/Sorter/Sorter";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import { cancellationReasonOptions } from "./defaultData";
import { site } from "../../@types/sites";
import FilterButtons from "../../components/UI/filterButtons/FilterButtons";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import {
  childField,
  dropField,
  Header,
  multiDropField,
  numberField,
  textBox,
  textField,
} from "../../components/UI/FormGenerator/formField";
import DiscreteRangeDatePicker from "../../components/UI/DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../Report/@types";
import DropDown from "../../components/elements/DropDown/DropDown";
import { currentUserType } from "../WasteAcceptance/@types";
import { useAsync } from "../../helpers/asyncFunc";
import { getDisposals, updateStatus, updateStatusParamsType } from "./getSet";
import ExcelDownload from "../../components/UI/DownloadPopUp/DownloadPopUp";
import useTranslate from "../../translate/useTranslate";

const statusOptions = [
  { label: "Vykdoma", value: "ONGOING" },
  { label: "Įvykdytas", value: "COMPLETED" },
  { label: "Atšauktas", value: "CANCELED" },
] as unknown as option[];

export type allDisposalType = {
  id: number;
  createdAt: string;
  updatedAt: Date;
  disposalDate: Date;
  formNr: number;
  statusDate: Date | null;
  recipientId: number;
  disposalSite: number;
  status: "ONGOING" | "COMPLETED" | "CANCELED";
  transferType: "INNER" | "OUTER";
  reason: string;
  accepted: boolean;
  acceptedDate: Date | null;
  licensePlate: string;
  carrierId: number;
  contractNr: string;
  driverFirstName: string;
  driverLastName: string;
  recipientName: string;
  disposalAddress: string | null;
  disposalSiteName: string;
  disposalSiteCompanyCode: string | null;
  disposalSiteMunicipalityId: number | null;
  carrierName: string;
  totalWeight: number;
  theWastes: {
    id: number;
    code: string;
    name: string;
    weight: number;
    groupId: number | null;
    paidPrice: number | null;
    compInvoice: boolean;
    paidPriceVat: number | null;
  }[];
};

const DisposalJournal = function ({
  sites,
  currentUser,
}: {
  sites: site[];
  currentUser: currentUserType;
}) {
  type defaultFilter = {
    formNr: "" | number;
    recipient: string;
    disposalSite: option[];
    carrierName: string;
    status: string;
    order: string[];
    date: {
      startDate: string;
      endDate: string;
    };
  };

  const defaultFilter: defaultFilter = {
    date: {
      startDate: "",
      endDate: "",
    },
    formNr: "",
    recipient: "",
    disposalSite: [],
    carrierName: "",
    status: "",
    order: ["createdAt DESC"],
  };

  const dataExportService = new DataExportService();

  const { t } = useTranslate();

  const sortOptions = {
    column: "createdAt",
    active: t("Newest date first"),
    inactive: t("Oldest date first"),
    main: t("Newest date first"),
  };

  const [currentStart, setCurrentPage] = useState(1);
  const [allDisposals, setAllDisposals] = useState<allDisposalType[]>([]);
  const [count, setCount] = useState(0);
  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );

  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [expands, setExpands] = useState<
    Partial<{ id: number; open: boolean }>[]
  >([]);

  const [cancellationPopUpVisibility, setCancellationPopUpVisibility] =
    useState(false);
  const [currentDisposalId, setCurrentDisposalId] = useState<number | null>(
    null
  );
  const [cancellationReason, setCancellationReason] = useState<option>();
  const [
    cancellationReasonAdditionalInfo,
    setCancellationReasonAdditionalInfo,
  ] = useState("");
  const [additionalInfoFieldVisible, setAdditionalInfoFieldVisible] =
    useState(false);

  useEffect(() => {
    setExpands([]);
  }, [allDisposals]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const options = sites.map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  const Filter = (() => {
    const query = Object.keys(filter)
      .map((Key) => {
        const multiFilterKeys: (keyof defaultFilter)[] = ["disposalSite"];

        const key = Key as keyof defaultFilter;
        if (key === "date") {
          if (filter.date.startDate && filter.date.endDate) {
            return `startDate=${filter.date.startDate}&endDate=${filter.date.endDate}`;
          }

          return "startDate=&endDate=";
        } else if (multiFilterKeys.indexOf(key) >= 0) {
          return (
            key + "=" + (filter[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          return key + "=" + filter[key];
        }
      })
      .join("&");

    return query;
  })();

  const getDisposalsRes = useAsync(
    {
      asyncFunc: getDisposals,
      funcParams: { query: Filter, limit, offset },
      immediate: true,
    },
    [Filter, limit, offset]
  );

  useEffect(() => {
    const disposalResponse = getDisposalsRes.data;
    const disposals = disposalResponse?.disposals;
    const count = disposalResponse?.count!;

    if (disposals) {
      setCount(count);
      setAllDisposals([...disposals]);
    }
  }, [getDisposalsRes.data]);

  const updateStatusRes = useAsync(
    {
      asyncFunc: updateStatus,
      funcParams: {} as unknown as updateStatusParamsType,
      immediate: false,
    },
    []
  );

  useEffect(() => {
    const statusResponse = updateStatusRes.data;
    const disposals = statusResponse?.disposal;

    if (disposals) {
      getDisposalsRes.execute({
        query: Filter,
        limit,
        offset,
      });
    }
  }, [updateStatusRes.data]);

  const updateFilter = (objFilter: Partial<defaultFilter>) => {
    setFilter({ ...filter, ...objFilter });
  };

  const toggleExpand = (id: number) => {
    let seen = false;
    const newArray = [];
    for (let i = 0; i < expands.length; i++) {
      const item = expands[i];
      if (item.id === id) {
        seen = true;
        newArray.push({ ...item, open: !item.open });
      } else {
        newArray.push(item);
      }
    }

    if (!seen) {
      newArray.push({ id, open: true });
    }

    setExpands([...newArray]);
  };

  const statusValue = useMemo(
    () => getDropDownValue(filter.status as unknown as number, statusOptions),
    [filter.status, statusOptions]
  );

  const dist = [2, 1, 1, 1, 1, 1, "6rem", "7.5rem"];
  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        childField(
          <div
            className="userList_range_wrapper"
            key={"userList_range_wrapper"}
          >
            <DiscreteRangeDatePicker
              key={"userList_range"}
              onChange={(val: range) => {
                if (val.startDate || val.startDate) {
                  updateFilter({
                    date: {
                      startDate: val.startDate
                        ? moment(new Date(val.startDate)).format("YYYY-MM-DD")
                        : "",
                      endDate: val.endDate
                        ? moment(new Date(val.endDate)).format("YYYY-MM-DD")
                        : "",
                    },
                  });
                }
              }}
              clear={!filter.date.startDate && !filter.date.endDate}
              value={undefined}
            />
          </div>
        ),
        numberField(
          "",
          false,
          () => {},
          `${filter.formNr}`,
          (formNr) => {
            updateFilter({ formNr: formNr as unknown as number });
          }
        ),
        multiDropField(
          siteOptions,
          t("Search..."),
          false,
          filter.disposalSite,
          (options) => {
            updateFilter({ disposalSite: options });
          }
        ),
        textField(
          "",
          false,
          () => {},
          `${filter.recipient}`,
          (recipient) => {
            updateFilter({ recipient });
          }
        ),
        textField(
          "",
          false,
          () => {},
          `${filter.carrierName}`,
          (carrierName) => {
            updateFilter({ carrierName });
          }
        ),
        dropField(
          statusOptions,
          "",
          false,
          statusValue,
          (status) => {
            updateFilter({ status: `${status}` });
          },
          true
        ),
      ],
      hasHeader: true,
      headerText: [
        Header([t("Order creation date")], false, true),
        Header([t("Order no.")], false, true),
        Header([t("Disposal site")], false, true),
        Header([t("Recipient of the waste")], false, true),
        Header([t("Waste carrier")], false, true),
        Header([t("Order Status")], false, true),
        Header([""], false, true),
      ],
    },
  ];

  const isExpanded = (id: number) => {
    const item = expands.find((ex) => ex.id === id);

    return !!item?.open;
  };

  const recordSummaryConstruct = (
    record: allDisposalType
  ): pageConstructType[] => [
    {
      sizeDist: dist,
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header(
          [moment(record.createdAt).format("YYYY-MM-DD HH:mm")],
          false,

          true
        ),
        Header([`${t("No.")} ${record.formNr}`], false, true),
        Header([record.disposalSiteName], false, true),
        Header([record.recipientName], false, true),
        Header([record.carrierName], false, true),
        Header(
          [
            record.status === "ONGOING"
              ? "Vykdoma"
              : record.status === "COMPLETED"
              ? record.transferType === "INNER"
                ? record.accepted
                  ? "Įvykdytas"
                  : "Vykdoma"
                : "Įvykdytas"
              : record.status === "CANCELED"
              ? "Atšauktas"
              : "",
          ],

          false,
          true
        ),
        Header(
          [
            record.status === "ONGOING" ? (
              <div className="disposalBtn_wrapper">
                <button
                  className="btn btn-default btn-cancel"
                  onClick={() => {
                    setCurrentDisposalId(record.id);
                    setCancellationReason(undefined);
                    setCancellationReasonAdditionalInfo("");
                    setAdditionalInfoFieldVisible(false);
                    setCancellationPopUpVisibility(true);
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  className="btn btn-green btn-completed"
                  onClick={() =>
                    updateStatusRes.execute({
                      id: record.id,
                      status: "COMPLETED",
                      userId: currentUser.id,
                    })
                  }
                >
                  Atlikta
                </button>
              </div>
            ) : (
              <div></div>
            ),
          ],
          false,
          true
        ),
        Header(
          [
            <div className="disposal_expand_container flex">
              <div
                onClick={() => toggleExpand(record.id)}
                className={`expandBtn_container center ${
                  !isExpanded(record.id) ? "" : "rotate"
                }`}
              >
                <ExpandIcon />
              </div>
              <span onClick={() => toggleExpand(record.id)}>{`${t("Show")} ${
                isExpanded(record.id) ? t("less") : t("more")
              }`}</span>
            </div>,
          ],
          false,
          true
        ),
      ],
    },
  ];

  type typeDist = {
    date: string;
    text: string;
    name?: string;
    weight?: string;
    reason?: string;
    wasteList?: {
      id: number;
      code: string;
      name: string;
      weight: number;
      groupId: number | null;
      paidPrice: number | null;
      compInvoice: boolean;
      paidPriceVat: number | null;
    }[];
    spread: boolean;
  };
  const statusDesignArangement = (disposal: allDisposalType): typeDist[] => {
    const statusDate =
      disposal.status !== "ONGOING"
        ? {
            date: moment(disposal.statusDate).format("YYYY-MM-DD HH:mm"),
            text:
              disposal.status === "COMPLETED"
                ? "Atliekos pristatytos gavėjui"
                : "Atšauktas užsakymas",
            spread: true,
            reason:
              disposal.status === "CANCELED" ? disposal.reason : undefined,
          }
        : null;
    const completionDate = !!disposal.accepted
      ? {
          date: moment(disposal.acceptedDate || disposal.updatedAt).format(
            "YYYY-MM-DD HH:mm"
          ),
          text: "Atliekos priimtos",
          spread: true,
        }
      : null;

    const statusText: { date: string; text: string; spread: boolean }[] = [];
    if (statusDate) {
      statusText.push(statusDate);
    }
    if (completionDate) {
      statusText.push(completionDate);
    }

    return statusText.sort((a, b) => {
      if (a.date > b.date) {
        return 1;
      } else if (a.date < b.date) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const recordTypeDist = (record: allDisposalType): typeDist[] => {
    const disposalInfo: typeDist = {
      date: moment(record.createdAt).format("YYYY-MM-DD HH:mm"),
      text: "Suformuotas užsakymas",
      name: `${record.driverFirstName} ${record.driverLastName}`,
      wasteList: record.theWastes,
      weight: `${formatNumber(record.totalWeight)} kg`,
      spread: false,
    };
    const extraInfo = statusDesignArangement(record);
    return [disposalInfo, ...extraInfo];
  };

  const textConstruct = (record: allDisposalType): pageConstructType[] => {
    const rows = isExpanded(record.id) ? recordTypeDist(record) : [];
    return rows.length
      ? [
          {
            sizeDist: dist,
            typeDist: [
              childField(<b key={"h1"}>Laikas</b>),
              childField(<b key={"h2"}>{t("Action")}</b>),
              childField(<b key={"h3"}>Vairuotojas</b>),
              childField(<b key={"h4"}>Perduodamas svoris</b>),
              childField(<b key={"h5"}>Gautas svoris</b>),
              childField(<b key={"h6"}>Išvežamos atliekos</b>),
              textBox("", false),
              textBox("", false),
            ],
            hasHeader: false,
            headerText: [],
          },
          ...rows.map((row, n) => ({
            sizeDist: dist,
            typeDist: [
              textBox(row.date, false),
              textBox(row.text, false),
              ...(row.spread
                ? [
                    childField(<div key="spaceblock"></div>),
                    childField(
                      <div
                        style={{
                          gridColumn: "4/8",
                        }}
                        key="reasonblock"
                      >
                        {row.reason}
                      </div>
                    ),
                  ]
                : [
                    textBox(row.name || "", false),
                    textBox(row.weight || "", false),
                    childField(
                      <div key={n}>
                        {row.wasteList?.length &&
                          row.wasteList.map((waste, i) => (
                            <div key={i}>
                              {waste.name} ({waste.weight} kg)
                            </div>
                          ))}
                      </div>
                    ),
                  ]),
            ],
            hasHeader: false,
            headerText: [],
          })),
        ]
      : [];
  };

  const cancelOption = getDropDownValue(
    cancellationReason as unknown as number,
    cancellationReasonOptions
  );

  return (
    <div className="disposal-journal">
      {cancellationPopUpVisibility && (
        <NewPopUpFrame
          small={true}
          showShadow={false}
          handleOpen={setCancellationPopUpVisibility}
          isShown={cancellationPopUpVisibility}
          title={t("The reason for the cancellation")}
        >
          <div className="cancellation_popUp">
            <DropDown
              options={cancellationReasonOptions}
              value={cancelOption}
              onSelect={(value) => {
                const selectedValue = value;
                setCancellationReason(selectedValue);

                selectedValue.value === 6
                  ? setAdditionalInfoFieldVisible(true)
                  : setAdditionalInfoFieldVisible(false);
              }}
              border={""}
              title={""}
              error={false}
            />
            {additionalInfoFieldVisible && (
              <textarea
                placeholder={t("Record the reason for the cancellation")}
                value={cancellationReasonAdditionalInfo}
                onChange={(e) => {
                  setCancellationReasonAdditionalInfo(e.target.value);
                }}
                name=""
                id=""
              ></textarea>
            )}
            <div className="cancellationPopUpButton_wrapper">
              <button
                onClick={() => setCancellationPopUpVisibility(false)}
                className="btn btn-default"
              >
                {t("Cancel")}
              </button>
              <button
                disabled={
                  !cancellationReason ||
                  (cancellationReason.value === 6 &&
                    !cancellationReasonAdditionalInfo)
                }
                onClick={() => {
                  if (currentDisposalId && cancellationReason) {
                    updateStatusRes.execute({
                      id: currentDisposalId,
                      status: "CANCELED",
                      reason: cancellationReason.value,
                      reasonText:
                        cancellationReason.value === 6
                          ? cancellationReasonAdditionalInfo
                          : cancellationReason.label,
                      additionalInfo: cancellationReasonAdditionalInfo,
                      userId: currentUser.id,
                    });
                  }
                  setCancellationPopUpVisibility(false);
                }}
                className="btn btn-green"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </NewPopUpFrame>
      )}
      <div>
        <div className="sortFilterHeader">
          <div className="table-action">
            <FilterButtons
              multiFilters={["disposalSite"]}
              filter={filter}
              setFilter={setFilter}
              defaultFilter={defaultFilter}
              exclude={["order", "status"]}
            />
            <div className="sortHolder">
              <Sorter
                valueOptions={sortOptions}
                onClick={(title) => {
                  const sortValue = sortOptions.column;
                  appendSort(sortValue, true, undefined, {
                    filter,
                    updateFilter,
                  });
                }}
                rotate={!enabled(sortOptions.column, { filter, updateFilter })}
              />
            </div>
            <ExcelDownload type={"disposalJournal"} query={Filter} />
            {/* <ButtonXlsx onClick={() => xlsxExport()} /> */}
          </div>
        </div>
      </div>
      <div className="viewExt">
        <GenerateForm
          className="disposalJournalTable"
          loading={getDisposalsRes.loading}
        >
          {pageConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteAccumulation_1_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
        </GenerateForm>
        {allDisposals.map((disposal, i) => (
          <GenerateForm key={i} className="disposalJournalTable">
            {recordSummaryConstruct(disposal).map((construct, n) => (
              <FormGenerator
                key={`wasteAccumulation_1_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
            {textConstruct(disposal).map((construct, n) => (
              <FormGenerator
                key={`wasteAccumulation_1_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
          </GenerateForm>
        ))}
        <FractionList
          count={count}
          currentStart={currentStart}
          setCurrentPage={setCurrentPage}
          pageRecordNum={pageRecordNum}
          setPageRecordNum={setPageRecordNum}
        />
      </div>
    </div>
  );
};

export default DisposalJournal;
